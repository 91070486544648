import React, { ForwardRefExoticComponent, forwardRef } from 'react'
import { Linking, Platform, TextProps } from 'react-native'
import { View, Text, SxProp } from 'dripsy'
import Press from '../press'
import { APP_URL } from '@beatgig/constants'
import useLinkTo from '@beatgig/navigation/hooks/use-link-to'

export type LinkProps = React.PropsWithChildren<
  Omit<TextProps, 'ref'> & {
    /** @platform web */
    href?: string
    /** @platform web */
    target?: '_blank'
    disableOnNative?: boolean
    /** @platform web */
    rel?: string
    /** @platform web */
    hrefAttrs?: {
      target?: string
      rel?: string
    }
  }
>

const A = forwardRef(function A(
  {
    href,
    target,
    isText = true,
    disableOnNative = false,
    ...props
  }: Omit<LinkProps, 'hrefAttrs'> & { isText?: boolean; sx?: SxProp },
  ref?: any
) {
  const linkTo = useLinkTo()
  const nativeProps = Platform.select<
    LinkProps & { hrefAttrs?: { href?: string; target?: string; rel?: string } }
  >({
    web: {
      href,
      // target,
      hrefAttrs: {
        // href,
        target: target?.replace('_', ''), // patch old RNW
        rel: 'noopener',
      },
      // rel: 'noopener',
    },
    default: {
      onPress: (event) => {
        props.onPress?.(event)
        if (href !== undefined && !disableOnNative) {
          let destination = href
          console.log('[link-pressed] ', destination)
          if (href.startsWith('/') && !target) {
            // TODO in react navigation v6, we can use useLinkTo() outside of a screen!
            // https://reactnavigation.org/docs/6.x/upgrading-from-5.x
            destination = `https://${APP_URL}${destination}`
            return linkTo(href)
          }
          Linking.openURL(destination)
        }
      },
    },
  })
  const Component = isText
    ? Text
    : Platform.select({ web: View, default: Press as any })
  return (
    <Component
      accessibilityRole="link"
      {...props}
      {...nativeProps}
      ref={ref as any}
    />
  )
}) as ForwardRefExoticComponent<
  LinkProps & { isText?: boolean; ref?: any; sx?: SxProp }
>

export default A
