import React, { Fragment, useEffect } from 'react'
import { Formik } from 'formik'
import { View } from 'dripsy'
import {
  BuyerUserType,
  BuyerUserTypeNames,
  parseBuyerUserTypeFromDisplay,
} from '@beatgig/api-services/user'
import { useNavigation } from '@react-navigation/native'
import type { BuyerTypeNavigation } from '@beatgig/navigation/stacks/buyer-onboarding-stack/types'
import OnboardingHeader from '../onboarding-header'
import { UserOnboardingFormPersistence } from '@beatgig/features/user/onboarding/form-persist'
import { useFieldFast } from '@beatgig/forms/hooks/use-fast-field'
import { MultiStepFormLayout } from '@beatgig/features/multi-step-form/layout'
import EntityField from '@beatgig/design/components/entity/entity-field'
import { ScrollView, Target, useScrollTo } from '@nandorojo/anchor'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Spacer from '@beatgig/design/components/spacer'
import { OnboardingLayout } from '@beatgig/features/onboarding/layout'

type BuyerType = { userTypeDisplay: string | null }

type Props = {
  onSubmit: (data: { userType: BuyerUserType }) => void
}

const AliasUserTypes: Record<string, BuyerUserType> = {
  'Private Event': BuyerUserType.OTHER_BUYER,
}

const userTypes = [
  Object.keys(AliasUserTypes)[0],
  ...Object.values(BuyerUserTypeNames),
].filter(Boolean)

function PickUserType() {
  const [{ value }, , { setValue }] = useFieldFast<
    BuyerType['userTypeDisplay'],
    BuyerType
  >('userTypeDisplay')

  const optionsLength = userTypes.length
  const selectedIndex =
    value && userTypes.indexOf(value) != -1
      ? userTypes.indexOf(value)
      : undefined

  const { scrollTo } = useScrollTo()

  useEffect(
    function scroll() {
      if (value) {
        // scrollTo(`buyer-type-${value}`)
      }
    },
    [value, scrollTo]
  )

  return (
    <>
      {userTypes.map((type, index) => {
        const isSelected = type === value
        return (
          <Fragment key={type}>
            <Target name={`buyer-type-${type}`} />
            <MultiStepFormLayout.ActionItem
              onPress={() => setValue(type)}
              isSelected={isSelected}
              sx={{ mb: '$3', py: '$3' }}
            >
              <EntityField title={type} />
            </MultiStepFormLayout.ActionItem>
          </Fragment>
        )
      })}
    </>
  )
}

export default function BuyerType() {
  const { navigate } = useNavigation<BuyerTypeNavigation>()

  const insets = useSafeAreaInsets()

  console.log('[buyer-type]')

  return (
    <Formik<BuyerType>
      initialValues={{ userTypeDisplay: null }}
      onSubmit={async ({ userTypeDisplay }) => {
        console.log('[buyer-type] submit', userTypeDisplay)
        if (!userTypeDisplay) {
          return
        }
        let userType = AliasUserTypes[userTypeDisplay]
        if (!userType) {
          const parsed = parseBuyerUserTypeFromDisplay(userTypeDisplay)
          if (parsed) {
            userType = parsed
          }
        }
        if (userType) {
          navigate('BuyerGenericInfo', {
            userType,
          })
        }
        return
      }}
    >
      {({ values, isValid }) => {
        return (
          <View sx={{ px: 3, flex: 1 }}>
            <ScrollView>
              <View
                sx={{
                  maxWidth: [null, null, 450],
                  alignSelf: [null, null, 'center'],
                  width: [null, null, '100%'],
                  py: 3,
                }}
              >
                <UserOnboardingFormPersistence name="buyer-type" />
                <OnboardingHeader>
                  {'What are you booking events for?'}
                </OnboardingHeader>
                {true && <PickUserType />}
              </View>
              <Spacer y="$6" />
            </ScrollView>
            {!!values.userTypeDisplay && (
              <MultiStepFormLayout.Presence>
                <OnboardingLayout.Footer>
                  <OnboardingLayout.Submit formName="Buyer Type">
                    Continue{' '}
                    {values.userTypeDisplay && `- ${values.userTypeDisplay}`}
                  </OnboardingLayout.Submit>
                </OnboardingLayout.Footer>
              </MultiStepFormLayout.Presence>
            )}
          </View>
        )
      }}
    </Formik>
  )
}
