import Button from '@beatgig/components/button'
import { useFormikContext } from 'formik'
import React from 'react'
import { Sentry } from '@beatgig/helpers/sentry'

type Props = {
  children?: React.ReactNode
  networkError?: boolean
  /**
   * Used to send to sentry
   */
  formName: string
  // onSubmit: () => void
} & Omit<React.ComponentProps<typeof Button>, 'children'>

export default function SubmitButton({
  sx = {},
  children = 'Save',
  networkError = false,
  disabled,
  formName,
  ...props
}: Props) {
  const { isSubmitting, submitForm, errors, submitCount, values } =
    useFormikContext()
  const errorCount = Object.keys(errors).length
  const hasErrors = (!!submitCount && !!errorCount) || networkError
  const bg = hasErrors ? 'danger' : undefined
  const borderColor = hasErrors ? 'danger' : undefined
  const color = hasErrors ? 'text' : undefined

  // if (hasErrors) console.log('[submit button]', errors)
  const onSubmit = async () => {
    // will error
    console.log('[form-submit] will submit')
    await submitForm()
    console.log('[form-submit] did submit', { errorCount })
    if (errorCount) {
      console.log('[form-submit] errors', errors)
      Sentry.captureEvent({
        message: formName + ' Form Submission Error',
        environment: __DEV__ ? 'development' : 'production',
        extra: {
          submitCount,
          errors: JSON.parse(JSON.stringify(errors ?? null)),
          values: JSON.parse(JSON.stringify(values ?? null)),
        },
      })
    }
  }

  return (
    <Button
      {...props}
      sx={{ bg, borderColor, color, ...sx }}
      loading={isSubmitting}
      onPress={() => {
        console.log('[submit-button] press')
        onSubmit()
      }}
      disabled={isSubmitting || disabled}
    >
      {children}
    </Button>
  )
}
