import React from 'react'

export type AutoCompleteContext = {
  value?: string
  updateValue?: (info: { value: string; label: string }) => void
  visible?: boolean
  updateVisible?: (next: boolean) => void
  updateTextState?: (text: string) => void
}

export const AutoCompleteContext = React.createContext<AutoCompleteContext>({
  visible: false,
})

export const useAutoCompleteContext = () =>
  React.useContext(AutoCompleteContext)
