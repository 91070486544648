import React from 'react'
import { View } from 'dripsy'
import AutoCompleteEmpty from './auto-complete-empty'

type Props = {
  children?: React.ReactNode
} & React.ComponentProps<typeof View>

export default function AutoCompleteSearching(props: Props) {
  const { sx = {}, children } = props

  return (
    <AutoCompleteEmpty closeOnPress={false} allowsCustomValue={false} sx={sx}>
      {children}
    </AutoCompleteEmpty>
  )
}
