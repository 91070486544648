import React, { ReactNode } from 'react'
import { View } from 'dripsy'
import useResponsiveValue from '@beatgig/hooks/use-responsive-value'
import Sizer from '@beatgig/components/sizer'
import AdminNav from './nav'
import { Modal } from 'react-native'
import Ionicons from '@beatgig/design/components/ionicons'
import Press from '@beatgig/components/press'
import { MotiView } from 'moti'
import useTheme from '@beatgig/theme/use-theme'
import Router from 'next/router'
import useIntercom from '@beatgig/hooks/use-intercom'

type Props = {
  children: ReactNode
}

function useModal() {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const isDocked = useResponsiveValue([false, false, false, false, true])

  React.useEffect(() => {
    const onRouteChangeStart = () => setIsModalOpen(false)

    Router.events.on('routeChangeStart', onRouteChangeStart)

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart)
    }
  }, [])

  const handlers = React.useMemo(() => {
    return {
      toggleModal: () => setIsModalOpen((state) => !state),
      showModal: () => setIsModalOpen(true),
      hideModal: () => setIsModalOpen(false),
    }
  }, [])

  return [
    {
      isDocked,
      showModal: !isDocked && isModalOpen,
    },
    handlers,
  ] as const
}

export default function AdminLayout({ children }: Props) {
  const [{ isDocked, showModal }, { toggleModal }] = useModal()
  const { hide } = useIntercom()

  React.useEffect(() => {
    hide()
  }, [hide])

  const nav = <AdminNav />

  const { colors } = useTheme()

  const toggle = (
    <View sx={{ position: 'fixed', bottom: 0, left: 0, p: 3 }}>
      <Press onPress={toggleModal}>
        {({ hovered, pressed }) => (
          <MotiView
            transition={{ type: 'timing', duration: 200 }}
            animate={{ scale: pressed ? 1.03 : hovered ? 1.1 : 1 }}
          >
            <View
              sx={{
                borderRadius: 'rounded',
                size: 55,
                bg: 'text',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              style={{
                shadowColor: colors.text,
                shadowOpacity: 0.9,
                shadowRadius: 20,
                shadowOffset: {
                  height: 0,
                  width: 0,
                },
              }}
            >
              <Ionicons
                name={!showModal ? 'menu-outline' : 'close-outline'}
                color="background"
                size={40}
                selectable={false}
              />
            </View>
          </MotiView>
        )}
      </Press>
    </View>
  )

  const dockedWidth = isDocked ? [0, 0, 200, 275] : 0

  return (
    <View sx={{ flex: 1 }}>
      <View sx={{ flex: 1, flexDirection: 'row' }}>
        <Modal animationType="slide" visible={showModal}>
          {nav}
          {toggle}
        </Modal>
        {isDocked && (
          <Sizer
            width={dockedWidth}
            borderRightWidth={0.5}
            borderColor="border"
            position="fixed"
            left={0}
            top={0}
            bottom={0}
          >
            {nav}
          </Sizer>
        )}
        <View sx={{ flex: 1, ml: dockedWidth }}>{children}</View>
        {!isDocked && !showModal && toggle}
      </View>
    </View>
  )
}
