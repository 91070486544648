import { useCallback, useState, useRef, useEffect } from 'react'
import Analytics from '../analytics/typez'
import { User } from '@beatgig/api/user'
import { resetGlobalSearchState } from '@beatgig/search/hooks/use-global-state'
import { signOutHeadless } from 'react-doorman'
import { NotificationApi } from '@beatgig/api/notification'
import { useResetNativeApp } from '@beatgig/native/reset-native-app/use-reset-native-app'
import { Platform } from 'react-native'
import { swr } from '@beatgig/providers/swr/cache'
import { clearAuthTokenCookie } from './auth-cookies'
import * as Updates from 'expo-updates'

export default function useSignOut() {
  const [, rerender] = useState({})
  const loading = useRef(false)
  const isTracking = useRef({
    loading: false,
  })
  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const setLoading = useCallback((next) => {
    const current = loading.current
    loading.current = next
    if (isTracking.current.loading && mounted.current && next !== current) {
      rerender({})
    }
  }, [])

  const resetNativeApp = useResetNativeApp()

  const signOut: (() => Promise<void>) & {
    isLoading: boolean
  } = useCallback(async () => {
    clearAuthTokenCookie()
    setLoading(true)
    NotificationApi.signOut()
    Analytics.track('Sign Out')
    await signOutHeadless()
    swr.clear()
    await User.removeFromAsyncStorage()
    resetGlobalSearchState()
    // resetNativeApp()
    setLoading(false)

    // navigate to / to clear out the user 😢
    // necessary for URQL to refresh the app
    // https://github.com/FormidableLabs/urql/issues/2511#issuecomment-1160680616
    if (Platform.OS === 'web') {
      if (window.location.pathname.startsWith('/dashboard')) {
        window.location.href = '/'
      } else {
        window.location.reload()
      }
    } else {
      if (!__DEV__) {
        Updates.reloadAsync()
      } else {
        // import('expo-dev-menu/app/native-modules/DevMenu').then((menu) => {
        //   menu.reloadAsync()
        // })
        console.warn('you should shake & restart the dev app')
        // NativeModules.ExpoDevMenuInternal.dispatchCallableAsync('reload')
      }
    }
  }, [setLoading]) as (() => Promise<void>) & {
    isLoading: boolean
  }

  Object.defineProperty(signOut, 'isLoading', {
    get: () => {
      isTracking.current.loading = true
      return loading.current
    },
    enumerable: true,
    configurable: true,
  })

  return signOut
}
