import { Sx, View } from 'dripsy'
import { ComponentProps } from 'react'
import { ReactNode } from 'react'
import { StyleSheet } from 'react-native'

type Props = Partial<
  Pick<
    ComponentProps<typeof View>,
    | 'onLayout'
    | 'as'
    | 'accessibilityLabel'
    | 'accessibilityRole'
    | 'focusable'
    | 'href'
    | 'hrefAttrs'
    | 'pointerEvents'
    | 'nativeID'
  >
> &
  Omit<Sx, 'pointerEvents'> & {
    children?: ReactNode
    stretch?: boolean
    onMouseLeave?: (event: MouseEvent) => void
  }

export const Box = ({
  children,
  onLayout,
  as,
  accessibilityLabel,
  accessibilityRole,
  focusable,
  href,
  hrefAttrs,
  pointerEvents,
  //   @ts-expect-error backwards compat
  sx: _sx,
  stretch,
  nativeID,
  onMouseLeave,
  ...sx
}: Props) => (
  <View
    sx={{ ..._sx, ...sx }}
    {...{
      children,
      onLayout,
      as,
      accessibilityLabel,
      accessibilityRole,
      focusable,
      href,
      hrefAttrs,
      pointerEvents,
      nativeID,
      onMouseLeave,
    }}
    style={stretch && StyleSheet.absoluteFill}
  />
)
