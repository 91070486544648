import React from 'react'
import GenericUserOnboardingInfo from '../generic-user-info'
import { useNavigation, useRoute } from '@react-navigation/native'
import type {
  BuyerGenericInfoNavigation,
  BuyerGenericInfoRoute,
} from '@beatgig/navigation/stacks/buyer-onboarding-stack/types'
import { BuyerUserType, UserRole } from '@beatgig/api-services/user'
import useCreateUser from '@beatgig/auth-hooks/use-create-user'
import { UserType } from '@beatgig/gql'

export default function GenericBuyerInfo() {
  const { navigate } = useNavigation<BuyerGenericInfoNavigation>()
  const {
    params: { userType },
  } = useRoute<BuyerGenericInfoRoute>()

  const { execute, error } = useCreateUser()

  return (
    <GenericUserOnboardingInfo
      networkError={error}
      userType={userType}
      userRole={UserRole.BUYER}
      onSubmit={async ({ signUpMessage, ...user }) => {
        switch (userType) {
          case BuyerUserType.FRATERNITY_SORORITY:
            navigate('Greek', {
              ...user,
              userType,
              signUpMessage,
            })
            return null
          case BuyerUserType.UNIVERSITY_PROGRAM_BOARD:
            navigate('UPB', {
              ...user,
              userType,
              signUpMessage,
            })
            return null
          default:
            await execute({
              input: {
                metadata: { userType: userType as unknown as UserType },
                userRole: UserRole.BUYER,
                ...user,
              },
              signUpMessage,
            })
            return null
        }
      }}
    />
  )
}
