/* eslint-disable */
// @ts-nocheck
// prettier-ignore
import { Query as Query0 } from '../../../../applications/next-app/pages/dashboard/artists/[slug]/edit/[step]'
// prettier-ignore
import { Query as Query1 } from '../../../../applications/next-app/pages/dashboard/artists/new/confirm'
// prettier-ignore
import { Query as Query2 } from '../../../../applications/next-app/pages/dashboard/artists/new/name'
// prettier-ignore
import { Query as Query3 } from '../../../../applications/next-app/pages/dashboard/artists/new/spotify'
// prettier-ignore
import { Query as Query4 } from '../../../../applications/next-app/pages/dashboard/artists/payouts'
// prettier-ignore
import { Query as Query5 } from '../../../../applications/next-app/pages/venues/[slug]'

// prettier-ignore
export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  ambassadors: {
    $url: (url?: { hash?: string }) => ({ pathname: '/ambassadors' as const, hash: url?.hash })
  },
  artists: {
    _slug: (slug: string | number) => ({
      cover_songs: {
        edit: {
          $url: (url?: { hash?: string }) => ({ pathname: '/artists/[slug]/cover-songs/edit' as const, query: { slug }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/artists/[slug]/cover-songs' as const, query: { slug }, hash: url?.hash })
      },
      reviews: {
        $url: (url?: { hash?: string }) => ({ pathname: '/artists/[slug]/reviews' as const, query: { slug }, hash: url?.hash })
      },
      share: {
        $url: (url?: { hash?: string }) => ({ pathname: '/artists/[slug]/share' as const, query: { slug }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/artists/[slug]' as const, query: { slug }, hash: url?.hash })
    })
  },
  auth: {
    $url: (url?: { hash?: string }) => ({ pathname: '/auth' as const, hash: url?.hash })
  },
  dashboard: {
    account: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/account' as const, hash: url?.hash })
    },
    artists: {
      _slug: (slug: string | number) => ({
        edit: {
          _step: (step: string | number) => ({
            $url: (url: { query: Query0, hash?: string }) => ({ pathname: '/dashboard/artists/[slug]/edit/[step]' as const, query: { slug, step, ...url.query }, hash: url.hash })
          }),
          $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/artists/[slug]/edit' as const, query: { slug }, hash: url?.hash })
        }
      }),
      analytics: {
        $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/artists/analytics' as const, hash: url?.hash })
      },
      new: {
        confirm: {
          $url: (url: { query: Query1, hash?: string }) => ({ pathname: '/dashboard/artists/new/confirm' as const, query: url.query, hash: url.hash })
        },
        name: {
          $url: (url: { query: Query2, hash?: string }) => ({ pathname: '/dashboard/artists/new/name' as const, query: url.query, hash: url.hash })
        },
        spotify: {
          $url: (url: { query: Query3, hash?: string }) => ({ pathname: '/dashboard/artists/new/spotify' as const, query: url.query, hash: url.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/artists/new' as const, hash: url?.hash })
      },
      payouts: {
        $url: (url: { query: Query4, hash?: string }) => ({ pathname: '/dashboard/artists/payouts' as const, query: url.query, hash: url.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/artists' as const, hash: url?.hash })
    },
    avail_checks: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/avail-checks/[id]' as const, query: { id }, hash: url?.hash })
      })
    },
    bookings: {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/bookings/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/bookings' as const, hash: url?.hash })
    },
    calendar: {
      requests: {
        _bookingRequestId: (bookingRequestId: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/calendar/requests/[bookingRequestId]' as const, query: { bookingRequestId }, hash: url?.hash })
        }),
        $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/calendar/requests' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/calendar' as const, hash: url?.hash })
    },
    confirmations: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/confirmations' as const, hash: url?.hash })
    },
    inbox: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/inbox' as const, hash: url?.hash })
    },
    invoices: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/invoices' as const, hash: url?.hash })
    },
    likes: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/likes' as const, hash: url?.hash })
    },
    magic: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/magic' as const, hash: url?.hash })
    },
    outbox: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/outbox' as const, hash: url?.hash })
    },
    promo: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/promo' as const, hash: url?.hash })
    },
    refer: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/refer' as const, hash: url?.hash })
    },
    requests: {
      _bookingRequestId: (bookingRequestId: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/requests/[bookingRequestId]' as const, query: { bookingRequestId }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/requests' as const, hash: url?.hash })
    },
    venue_theme: {
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/venue-theme' as const, hash: url?.hash })
    },
    venues: {
      _slug: (slug: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/venues/[slug]' as const, query: { slug }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/dashboard/venues' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/dashboard' as const, hash: url?.hash })
  },
  discover: {
    $url: (url?: { hash?: string }) => ({ pathname: '/discover' as const, hash: url?.hash })
  },
  download: {
    $url: (url?: { hash?: string }) => ({ pathname: '/download' as const, hash: url?.hash })
  },
  embed: {
    venue_calendar: {
      _slug: (slug: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/embed/venue-calendar/[slug]' as const, query: { slug }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/embed/venue-calendar' as const, hash: url?.hash })
    }
  },
  events: {
    $url: (url?: { hash?: string }) => ({ pathname: '/events' as const, hash: url?.hash })
  },
  help: {
    $url: (url?: { hash?: string }) => ({ pathname: '/help' as const, hash: url?.hash })
  },
  join: {
    _referredBy: (referredBy: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/join/[referredBy]' as const, query: { referredBy }, hash: url?.hash })
    })
  },
  login: {
    $url: (url?: { hash?: string }) => ({ pathname: '/login' as const, hash: url?.hash })
  },
  media: {
    $url: (url?: { hash?: string }) => ({ pathname: '/media' as const, hash: url?.hash })
  },
  modal: {
    $url: (url?: { hash?: string }) => ({ pathname: '/modal' as const, hash: url?.hash })
  },
  onboarding: {
    preferences: {
      $url: (url?: { hash?: string }) => ({ pathname: '/onboarding/preferences' as const, hash: url?.hash })
    }
  },
  playground: {
    $url: (url?: { hash?: string }) => ({ pathname: '/playground' as const, hash: url?.hash })
  },
  policies: {
    _slug: (slug: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/policies/[slug]' as const, query: { slug }, hash: url?.hash })
    })
  },
  products: {
    artists: {
      $url: (url?: { hash?: string }) => ({ pathname: '/products/artists' as const, hash: url?.hash })
    },
    colleges: {
      $url: (url?: { hash?: string }) => ({ pathname: '/products/colleges' as const, hash: url?.hash })
    },
    venues: {
      $url: (url?: { hash?: string }) => ({ pathname: '/products/venues' as const, hash: url?.hash })
    }
  },
  search: {
    $url: (url?: { hash?: string }) => ({ pathname: '/search' as const, hash: url?.hash })
  },
  style: {
    $url: (url?: { hash?: string }) => ({ pathname: '/style' as const, hash: url?.hash })
  },
  test: {
    grid: {
      $url: (url?: { hash?: string }) => ({ pathname: '/test/grid' as const, hash: url?.hash })
    }
  },
  test_static: {
    $url: (url?: { hash?: string }) => ({ pathname: '/test-static' as const, hash: url?.hash })
  },
  venues: {
    _slug: (slug: string | number) => ({
      $url: (url: { query: Query5, hash?: string }) => ({ pathname: '/venues/[slug]' as const, query: { slug, ...url.query }, hash: url.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/venues' as const, hash: url?.hash })
  },
  webview: {
    remotion: {
      $url: (url?: { hash?: string }) => ({ pathname: '/webview/remotion' as const, hash: url?.hash })
    }
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

// prettier-ignore
export type PagesPath = typeof pagesPath
