import PhoneAuth from '@beatgig/auth-components/phone-auth'
import React from 'react'
import { View, Text } from 'dripsy'
import useAuth from '@beatgig/auth-hooks/use-auth'
import LoadingScreen from '@beatgig/components/loading-screen'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'
import SmartSignoutLink from '@beatgig/auth-components/smart-signout-link'
import { useAdminTypesenseKey } from '@beatgig/api-hooks/src/use-admin-typesense-key'

export default function AdminGate({ children }: { children: React.ReactNode }) {
  const { loading, user } = useAuth()
  const { data: account, error } = useMyAccount()
  const typesenseKey = useAdminTypesenseKey()

  if (loading) return <LoadingScreen />

  if (!user) {
    // has authenticated
    return (
      <View sx={{ flex: 1, bg: 'background' }}>
        <PhoneAuth />
      </View>
    )
  }

  if (!account || error) {
    // account is loading or doesn't exist
    return (
      <LoadingScreen childrenUnderLoader error={error}>
        <SmartSignoutLink color textSx={{ fontSize: 4 }} sx={{ mt: 3 }} />
      </LoadingScreen>
    )
  }

  if (!User.isAdmin(account)) {
    // this person exists, but isn't an admin...
    return (
      <View
        sx={{
          flex: 1,
          bg: 'background',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text sx={{ color: 'text', fontSize: [8, 10] }}>
          Only admins allowed here. 🐻
        </Text>
        <SmartSignoutLink color textSx={{ fontSize: 4 }} sx={{ mt: 3 }} />
      </View>
    )
  }
  if (!typesenseKey.data) {
    return (
      <LoadingScreen error={typesenseKey.error}>
        Loading search key...
      </LoadingScreen>
    )
  }
  return <>{children}</>
  // return (
  //   <AuthGate>
  //     {({ loading, user }) => {
  //       if (loading) return <ActivityIndicator />
  //       if (!user)
  //         return (
  //           <View sx={{ flex: 1, bg: 'background' }}>
  //             <PhoneAuth />
  //           </View>
  //         )
  //       return <>{children}</>
  //     }}
  //   </AuthGate>
  // )
}
