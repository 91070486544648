import { useMemo } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useResponsiveValue } from 'dripsy'

const webHeight = [60, 70]

export default function useWebHeaderHeight() {
  const { top } = useSafeAreaInsets()
  const height = useMemo(() => {
    let safeHeight = webHeight
    if (top) {
      safeHeight = safeHeight.map((thisHeight) => thisHeight + top)
    }
    return safeHeight
  }, [top])

  return {
    height,
    top,
    zIndex: 3,
    value: useResponsiveValue(height),
  }
}
