import React from 'react'
import { DoormanProvider, doorman } from 'react-doorman'
// import 'firebase/auth'
import { AuthApi } from '@beatgig/api/auth'
import Analytics from '@beatgig/analytics/typez'
import { Platform } from 'react-native'
import { User } from '@beatgig/api/user'
import { INTERCOM_ID } from '@beatgig/components/intercom/constants'
import globalSelectedVenueState from '@beatgig/helpers/global-selected-venue-state'
import vercelConstants from '@beatgig/constants/vercel-constants'
import NextRouter from 'next/router'
import { useAdminContext } from '../Admin/context'
import useTheme from '@beatgig/theme/use-theme'
import { NotificationApi } from '@beatgig/api/notification'

import { initFirebase, signInWithCustomToken } from './init'
import { identifyUxCam } from '@beatgig/native/uxcam/identify'

import { signInWithPhoneNumber, verifyPhoneCode } from './init'
import { shouldUseLogRocket } from '@beatgig/vendors/log-rocket/enabled'
import { setAuthTokenCookieFromFirebaseUser } from '@beatgig/auth-hooks/auth-cookies'
import { graphql } from '@beatgig/gql/headless'

initFirebase()

// rewrite Doorman to use short codes with our own server
doorman.signInWithPhoneNumber = async ({ phoneNumber }) => {
  try {
    if (
      process.env.NEXT_PUBLIC_BACKEND_ENV == 'sandbox' &&
      phoneNumber.startsWith('+1555')
    ) {
      await graphql.GetCode({ phoneNumber })
    } else {
      await signInWithPhoneNumber(phoneNumber)
    }

    return {
      success: true,
    }
  } catch (error) {
    return {
      success: false,
      error: error?.message?.includes?.('auth/invalid-phone-number')
        ? 'Invalid phone number.'
        : error?.message?.includes('auth/invalid-verification-code')
        ? 'Incorrect code. Double-check that this is the the code you received.'
        : error,
    }
  }
}

doorman.verifyCode = async ({ phoneNumber, code }) => {
  try {
    if (
      process.env.NEXT_PUBLIC_BACKEND_ENV == 'sandbox' &&
      phoneNumber.startsWith('+1555')
    ) {
      const token = await graphql.GetToken({
        code,
        phoneNumber,
      })
      if (token?.createCustomToken) {
        signInWithCustomToken(token.createCustomToken)
      } else {
        throw new Error('Invalid test token.')
      }
    } else {
      await verifyPhoneCode(code)
    }
    return {
      success: true,
      token: null, // no need for sign in with custom token, since firebase will sign this in for us
    }
  } catch (error) {
    return {
      success: false,
      error,
      token: null,
    }
  }
}

type Props = {
  children: React.ReactNode
}

export default function Doorman(props: Props) {
  const { children } = props

  const isAdminApp = useAdminContext()?.isAdminApp

  const { colors } = useTheme()

  return (
    <DoormanProvider
      initialPhoneNumber={Platform.select({ web: '', default: undefined })}
      onAuthStateChanged={React.useMemo(
        () => async (user) => {
          setAuthTokenCookieFromFirebaseUser(user)
          const SentryPromise = import('@beatgig/helpers/sentry')
          if (user) {
            AuthApi.setApiServicesToken(async () => user.getIdToken(false))
            SentryPromise.then(({ Sentry }) =>
              Sentry.setUser({
                // FIXME gql we use uid here...
                id: user.uid,
                name: user.displayName ?? '',
                phone_number: user.phoneNumber ?? '',
                email: user.email ?? '',
              })
            )
            Analytics.identify({
              uid: user.uid,
              name: user.displayName ?? '',
              phone_number: user.phoneNumber ?? '',
              email: user.email ?? '',
            })
            NotificationApi.getAndSendToken({ shouldCheckPermissions: true })

            if (Platform.OS === 'web') {
              const { pathname } = NextRouter
              const showIntercom =
                pathname && !pathname?.includes('embed') && !isAdminApp
              console.log('[doorman-provider]', { pathname, showIntercom })
              if (shouldUseLogRocket) {
                import('logrocket')
                  .then((rocket) => rocket.default)
                  .then((LogRocket) => {
                    // FIXME gql we use uid here...
                    LogRocket.identify(user.uid, {
                      name: user.displayName ?? '',
                      phone_number: user.phoneNumber ?? '',
                      email: user.email ?? '',
                      ...(vercelConstants() as {}),
                    })
                  })
              }

              if (showIntercom) {
                // TODO this is commented out for now, re-add later.
                window.Intercom?.('update', {
                  app_id: INTERCOM_ID,
                  // FIXME gql we use uid here...
                  user_id: user.uid,
                  // phone_number: user.phoneNumber ?? '',
                  name: user?.displayName ?? '',
                  email: user.email ?? '',
                  ...vercelConstants(),
                })
              }
            }
            identifyUxCam({
              userId: user.uid,
            })
            try {
              // if (!__DEV__) AuthApi.recordUserLogin()
            } catch (e) {}
          } else {
            User.removeFromAsyncStorage()
            AuthApi.setApiServicesToken('')
            SentryPromise.then(({ Sentry }) =>
              Sentry.configureScope((scope) => scope.setUser(null))
            )
            Analytics.reset()
            globalSelectedVenueState.setGlobalState('selectedVenueId', '')

            if (shouldUseLogRocket) {
              import('logrocket')
                .then((rocket) => rocket.default)
                .then((LogRocket) => {
                  LogRocket.startNewSession()
                })
            }
          }
        },
        [isAdminApp]
      )}
      theme={React.useMemo(
        () => ({
          tintColor: colors.primary,
          inputBackgroundColor: colors.muted2,
          inputTextColor: colors.text,
        }),
        [colors.muted2, colors.primary, colors.text]
      )}
      publicProjectId={AuthApi.doormanPublicProjectId}
    >
      {children}
    </DoormanProvider>
  )
}
