import { FacetName } from '@beatgig/api-services'

// FIXME gql what happens to these?
export const AlgoliaFacets = {
  category: FacetName.CATEGORY_DATA_CATEGORY,
  subgenre: FacetName.CATEGORY_DATA_SUBGENRES,
  rate_low: 'price.rate_low',
  user_type: 'metadata.user_type',
  user_role: 'user_role',
  bandConfigDuoPublished: 'band_configuration.duo.published',
  bandConfigFullBandPublished: 'band_configuration.full_band.published',
  bandConfigSoloPublished: 'band_configuration.solo.published',
  bandConfigTrioPublished: 'band_configuration.trio.published',
} as const

export type AlgoliaFacets = typeof AlgoliaFacets[keyof typeof AlgoliaFacets]

// FIXME gql what happens to these?
export const AlgoliaDefaultFacets = [
  FacetName.CATEGORY_DATA_CATEGORY,
  FacetName.CATEGORY_DATA_SUBGENRES,
  AlgoliaFacets.bandConfigDuoPublished,
  AlgoliaFacets.bandConfigTrioPublished,
  AlgoliaFacets.bandConfigSoloPublished,
  AlgoliaFacets.bandConfigFullBandPublished,
] as const
