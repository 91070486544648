import { useRef, useMemo } from 'react'
// import { useTimingTransition, mix } from 'react-native-redash'
import { useHover } from 'react-native-web-hooks'
import { Platform } from 'react-native'
// import { MutableRefObject } from 'react'

export default function useAnimatedHover<T = any>({
  // easing = Easing.bezier(0.2, 0.8, 0.2, 1),
  duration = 800,
  webStyleKeys = ['all'],
}: // ref: passedRef,
{
  duration?: number
  webStyleKeys?: string[]
  // ref?: any
} = {}) {
  const ref = useRef<T>()
  const isHovered = useHover(ref)
  // const transition = useTimingTransition(isHovered, {
  //   easing,
  //   duration,
  // })

  // const interpolate = <T extends string | number>({
  //   hovered,
  //   notHovered,
  // }: {
  //   hovered: T
  //   notHovered: T
  //   // @ts-ignore
  // }) => mix(transition, notHovered, hovered)

  const resetStyle = useMemo(
    () =>
      Platform.select({
        web: {
          // https://www.w3schools.com/css/css3_transitions.asp
          transitionProperty: webStyleKeys,
          transitionDuration: `${duration}ms`,
          transitionTimingFunction: `cubic-bezier(.2,.8,.2,1)`,
        },
        default: {},
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duration]
  )

  return {
    ref,
    isHovered,
    // transition,
    // interpolate,
    resetStyle,
  }
}
