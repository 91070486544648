import React from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import { StyleSheet } from 'react-native'
import { styled } from 'dripsy'
import useTheme from '@beatgig/theme/use-theme'
import { DripsyTheme } from '@beatgig/theme'

type Props = Omit<React.ComponentProps<typeof LinearGradient>, 'colors'> & {
  stretch?: boolean
  colors: ((string & {}) | keyof DripsyTheme['colors'])[]
}

const Grad = styled(function Gradient({ stretch, style, ...props }: Props) {
  const { colors } = useTheme()

  return (
    <LinearGradient
      {...props}
      colors={props.colors?.map((color) => colors?.[color] ?? color)}
      style={[stretch && StyleSheet.absoluteFill, style]}
    />
  )
})()
export default class Gradient extends React.Component<
  React.ComponentProps<typeof Grad>
> {
  shouldComponentUpdate(prev, next) {
    return JSON.stringify(prev) !== JSON.stringify(next)
  }
  render() {
    return <Grad {...this.props} />
  }
}
