import { useEffect, useRef, useCallback } from 'react'
import { useState } from 'react'
import { useShouldFreezeStateOnBlur } from './context'
import { UseFreezeSwrStateOnBlurReturns } from './swr.types'
import useWhyDidYouUpdate from '@beatgig/hooks/use-why-did-you-update'

const log = (...args: any[]) => {
  console.log('[useFreezeSwrStateOnBlur]', ...args)
}

export const useFreezeSwrStateOnBlur = <
  State
>(): UseFreezeSwrStateOnBlurReturns<State> => {
  // we might not be in a screen
  // so we get the `addListener` function from our context
  // this lets us use this in any component, even outside of navigation context
  const { addListener, shouldFreezeState } = useShouldFreezeStateOnBlur() || {}

  const [overrideState, setOverrideState] = useState<State>()
  const cachedState = useRef<State>()

  useWhyDidYouUpdate('useFreezeSwrStateOnBlur', {
    addListener,
    shouldFreezeState,
  })

  useEffect(
    function () {
      let unsubscribeFocus:
        | ReturnType<NonNullable<typeof addListener>>
        | undefined
      let unsubscribeBlur:
        | ReturnType<NonNullable<typeof addListener>>
        | undefined

      if (shouldFreezeState && addListener) {
        unsubscribeFocus = addListener('focus', () => {
          // log('focused | removing override state')
          setOverrideState(undefined)
        })
        unsubscribeBlur = addListener('blur', () => {
          // log('blurred | setting override state')
          setOverrideState(cachedState.current)
        })
      }

      return () => {
        unsubscribeFocus?.()
        unsubscribeBlur?.()
      }
    },
    [addListener, shouldFreezeState]
  )

  const setCachedState = useCallback((state: State) => {
    cachedState.current = state
  }, [])

  return {
    setCachedState,
    overrideState,
  }
}
