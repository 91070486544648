import Router from 'next/router'
const enabled = process.env.ENABLE_LOGROCKET

const getIsEmbed = () => {
  if (Router?.router) {
    return Router.pathname?.startsWith('/embed')
  }
}

export const shouldUseLogRocket =
  enabled ||
  (!__DEV__ &&
    process.env.NEXT_PUBLIC_BACKEND_ENV != 'sandbox' &&
    !getIsEmbed())
