import { ExistentUser, GetUser } from '@beatgig/api-services/user'
import { MeQuery, UserDoesNotExist, UserRole, UserType } from '@beatgig/gql'
import AsyncStorage from '@react-native-async-storage/async-storage'

export type ReplaceField<
  Obj,
  Field extends keyof Obj = keyof Obj,
  Value extends Obj[Field] = Obj[Field]
> = Omit<Obj, Field> & {
  [key in Field as Field]: Value
}

export class User {
  /**
   * Determine if this user is a buyer.
   *
   * This function adds TypeScript support to a generic user document from the backend.
   *
   * @param user The user object
   */
  static isBuyer(
    user?: GetUser | null
  ): user is ReplaceField<ExistentUser, 'user_role', UserRole.BUYER> {
    return !!(
      user &&
      user?.__typename == 'User' &&
      user.user_role === UserRole.BUYER
    )
  }
  /**
   * Determine if this user is a seller.
   *
   * This function adds TypeScript support to a generic user document from the backend.
   *
   * @param user The user object
   */
  static isSeller(
    user?: GetUser | null
  ): user is ReplaceField<ExistentUser, 'user_role', UserRole.SELLER> {
    return Boolean(
      user?.__typename === 'User' && user.user_role === UserRole.SELLER
    )
  }
  /**
   * Determine if this user is an admin.
   *
   * This function adds TypeScript support to a generic user document from the backend.
   *
   * @param user The user object
   */
  static isAdmin(
    user?: GetUser | null
  ): user is ReplaceField<ExistentUser, 'user_role', UserRole.ADMIN> {
    return Boolean(
      user?.__typename === 'User' && user.user_role === UserRole.ADMIN
    )
  }
  /**
   * Determine if this user is an owner. Owners have full permissions.
   *
   * This function adds TypeScript support to a generic user document from the backend.
   *
   * @param user The user object
   */
  static isOwner(
    user?: GetUser | null
  ): user is ReplaceField<ExistentUser, 'user_role', UserRole.ADMIN> {
    return User.isAdmin(user)
  }
  /**
   * TODO omit?
   */
  static isMultiArtistSeller(
    user?: GetUser | null
  ): user is ReplaceField<
    ReplaceField<ExistentUser, 'user_role', UserRole.SELLER>,
    'metadata',
    ReplaceField<
      ExistentUser['metadata'],
      'user_type',
      UserType.AGENT | UserType.MANAGER | UserType.OTHER_SELLER
    >
  > {
    if (user?.__typename == 'User' && user.user_role === UserRole.SELLER) {
      return [UserType.AGENT, UserType.MANAGER, UserType.OTHER_SELLER].includes(
        user.metadata.user_type
      )
    }
    return false
  }
  static isUPBBuyer(
    user?: GetUser | null
  ): user is ReplaceField<
    ReplaceField<ExistentUser, 'user_role', UserRole.BUYER>,
    'metadata',
    ReplaceField<
      ExistentUser['metadata'],
      'user_type',
      UserType.UNIVERSITY_PROGRAM_BOARD
    >
  > {
    if (user?.__typename === 'User' && user.user_role === UserRole.BUYER) {
      return user.metadata.user_type === UserType.UNIVERSITY_PROGRAM_BOARD
    }
    return false
  }
  static isMultiVenueBuyer(
    user?: GetUser | null
  ): user is ReplaceField<
    ReplaceField<ExistentUser, 'user_role', UserRole.BUYER>,
    'metadata',
    ReplaceField<
      ExistentUser['metadata'],
      'user_type',
      | UserType.NIGHTCLUB
      | UserType.RESTAURANT
      | UserType.BAR
      | UserType.BREWERY
      | UserType.COUNTRY_CLUB
      | UserType.MUNICIPALITY
      | UserType.THEATER
      | UserType.VINEYARD_WINERY
      | UserType.HOTEL_RESORT
    >
  > {
    if (user?.__typename == 'User' && user.user_role === UserRole.BUYER) {
      return (
        user.metadata.user_type === UserType.NIGHTCLUB ||
        user.metadata.user_type === UserType.RESTAURANT ||
        user.metadata.user_type === UserType.BAR ||
        user.metadata.user_type === UserType.BREWERY ||
        user.metadata.user_type === UserType.COUNTRY_CLUB ||
        user.metadata.user_type === UserType.MUNICIPALITY ||
        user.metadata.user_type === UserType.THEATER ||
        user.metadata.user_type === UserType.VINEYARD_WINERY ||
        user.metadata.user_type === UserType.HOTEL_RESORT
      )
    }
    return false
  }
  static hasOnboarded(user?: MeQuery['me'] | null): user is ExistentUser {
    if (!user) return false
    const key: keyof ExistentUser = 'id'

    return key in user && !User.needsToOnboard(user)
  }
  static isPendingApproval(
    user?: MeQuery['me'] | null
  ): user is ReplaceField<ExistentUser, 'approved', false> {
    return User.hasOnboarded(user) && !user.approved
  }
  static isApproved(
    user?: GetUser | null
  ): user is ReplaceField<ExistentUser, 'approved', false> {
    return User.hasOnboarded(user) && user.approved
  }
  static asyncStorageKey = 'user-account-gql'
  static async addToAsyncStorage(user: MeQuery['me']) {
    if (!user) return null
    return AsyncStorage.setItem(this.asyncStorageKey, JSON.stringify(user))
  }
  static async getFromAsyncStorage() {
    const user = await AsyncStorage.getItem(this.asyncStorageKey)
    if (user) {
      return JSON.parse(user) as MeQuery['me']
    }
    return null
  }
  static async removeFromAsyncStorage() {
    return AsyncStorage.removeItem(this.asyncStorageKey)
  }
  static needsToOnboard(user?: GetUser | null): user is UserDoesNotExist {
    return Boolean(
      user && user.__typename == 'UserDoesNotExist' && user.exists === false
    )
  }
}
