import { AUTH_COOKIE_NAME } from './auth-cookie-name'

// @ts-check
const { Platform } = require('react-native')

/**
 * @type {import('js-cookie')}
 */
const Cookie = require('js-cookie')

/**
 * @type {(token: string) => void}
 */
const setAuthTokenCookie = (token) => {
  if (Platform.OS !== 'web') return
  Cookie.set(AUTH_COOKIE_NAME, token)
}
const clearAuthTokenCookie = () => {
  if (Platform.OS !== 'web') return
  Cookie.remove(AUTH_COOKIE_NAME)
}

/**
 *
 * @type {(user: import('firebase/auth').User | undefined) => Promise<void>}
 */
const setAuthTokenCookieFromFirebaseUser = async (user) => {
  if (Platform.OS !== 'web') {
    return
  }

  if (user) {
    const { token, expirationTime } = await user.getIdTokenResult()
    document.cookie = `${AUTH_COOKIE_NAME}=${token}; expires=${expirationTime} path=/`
  } else {
    document.cookie = `${AUTH_COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
  }
  // if (user) {
  //   try {

  //     const json = user.toJSON()
  //     const token = await user.getIdToken(false)

  //     const isTokenSameAsJson = token === json.stsTokenManager.accessToken

  //     console.log('[firebase-json]', json)
  //     console.log('[firebase-token]', { isTokenSameAsJson })

  //     setAuthTokenCookie(token)
  //   } catch (e) {
  //     console.error('[firebase-error] setting auth token in cookie', e)
  //   }
  // } else {
  //   clearAuthTokenCookie()
  // }
}

// TODO does this break?
export {
  setAuthTokenCookie,
  clearAuthTokenCookie,
  setAuthTokenCookieFromFirebaseUser,
}
