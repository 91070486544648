import { createThemedComponent } from 'dripsy'
import { View } from 'react-native'

const FormRow = createThemedComponent(View, {
  defaultStyle: {
    mb: 3,
    p: 3,
    borderWidth: 1,
    borderRadius: 3,
    bg: 'muted',
  },
})

export default FormRow
