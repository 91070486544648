import React from 'react'
import { View, H3, Text } from 'dripsy'
import { AllUserAccountTypes } from '@beatgig/api-services/user'
import { User } from '@beatgig/api/user'
import APP_NAME from '@beatgig/constants/app-name'
import UserApprovalStatusBadge from '../user-approval-status-badge'
import Spacer from '@beatgig/design/components/spacer'
import Button from '@beatgig/components/button'
import { sellerInviteOnlyText } from '@beatgig/auth-components/sellers-invite-only/text'
import { MultiStepFormLayout } from '@beatgig/features/multi-step-form/layout'
import { OnboardingLayout } from '@beatgig/features/onboarding/layout'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import LoadingScreen from '@beatgig/components/loading-screen'

type Props = {
  user?: AllUserAccountTypes
  onGoBack: () => void
} & React.ComponentProps<typeof View>

export default function OnboardingPendingApproval(props: Props) {
  const { sx = {}, onGoBack } = props

  const user = useMyAccount().data

  if (!user) {
    return <LoadingScreen>Loading user...</LoadingScreen>
  }

  if (!User.hasOnboarded(user) || User.isApproved(user)) return null

  const isBuyer = User.isBuyer(user)
  const isSeller = User.isSeller(user)

  const firstName = user?.name?.split(' ')?.[0]

  const textSx: Props['sx'] = {
    textAlign: 'center',
    fontSize: 4,
    lineHeight: 5,
    color: 'muted6',
  }
  let message = ''
  if (isBuyer) {
    // buyerMessage = `If approved, you can see artist prices & submit booking offers.`
    message = `${APP_NAME} is currently invite-only. Our team will reach out to you shortly via phone/email. If approved, you'll get access to artist pricing & can start submitting booking offers.`
  } else if (isSeller) {
    message = sellerInviteOnlyText
  }
  return (
    <View
      sx={{
        px: [3, 4, 5],
        pt: [5, null],
        bg: 'background',
        flex: 1,
        justifyContent: 'space-between',
      }}
    >
      <View>
        <MultiStepFormLayout.Presence>
          {isBuyer && (
            <View sx={{ alignItems: 'center' }}>
              <UserApprovalStatusBadge user={user} type="outline" />
              <Spacer />
            </View>
          )}
          <H3 sx={{ color: 'text', textAlign: 'center', fontWeight: '500' }}>
            Welcome, {firstName}.
          </H3>
          <Text sx={textSx}>{message}</Text>
        </MultiStepFormLayout.Presence>
        <Spacer height={4} />
        {isBuyer && (
          <>
            <MultiStepFormLayout.Presence index={1}>
              <H3
                sx={{ textAlign: 'center', fontSize: '$5', fontWeight: '500' }}
              >
                What now?
              </H3>
              <Text sx={textSx}>
                For now, you can still build your user profile
                {isBuyer
                  ? ', search artists, & add artists to your favorites'
                  : ' and browse venues to apply to'}
                .
              </Text>
            </MultiStepFormLayout.Presence>
          </>
        )}
      </View>
      <Spacer height={[3, 4]} />
      <OnboardingLayout.Footer>
        <Button onPress={onGoBack}>Sounds good</Button>
      </OnboardingLayout.Footer>
    </View>
  )
}
