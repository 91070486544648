import { FormikConfig } from 'formik'
import { createContext } from 'react'
import { TextInput } from 'react-native'

/**
 * If you pass this a set of names (i.e. paths to a certain input), it will scroll to the first one that is registered
 */
export type ScrollToFirstInputFromFormNames = (names: string[]) => void

/**
 * Make this the third argument passed to `onSubmit` for a form if you want to access its scroll inputs
 */
export type ScrollableFormProps = {
  /**
   * If you pass this a set of names (i.e. paths to a certain input), it will scroll to the first one that is registered
   */
  scrollToFirstInputFromNamesWebOnly: ScrollToFirstInputFromFormNames
}

export type ScrollablesContext = {
  __inputRefs: React.RefObject<Record<string, TextInput>>
  registerRef: (name: string, type: 'input', ref: TextInput | null) => void
  __scrollToInputWebOnly: (name: string) => void
  /**
   * Pass a set of error paths to this function, and it will return the ref you should scroll to first.
   */
  __findFirstInputFromNames: (names: string[]) => string | undefined
  /**
   * If you pass this a set of names (i.e. paths to a certain input), it will scroll to the first one that is registered
   */
  scrollToFirstInputFromNamesWebOnly: ScrollToFirstInputFromFormNames
  /**
   * Use this in places when you aren't sure if the scrollables exist or not.
   */
  maybeScrollToFirstInputFromNamesWebOnly: ScrollToFirstInputFromFormNames
} & ScrollableFormProps

export type ScrollableFormikOnSubmitProp<Form, Returns = boolean> = (
  ...props: [...Parameters<FormikConfig<Form>['onSubmit']>, ScrollableFormProps]
) => Promise<Returns>

export const ScrollablesContext = createContext<ScrollablesContext>({
  __inputRefs: {} as any,
  registerRef: () => {
    // console.log('ScrollablesContext tried to register ref without a provider')
  },
  __scrollToInputWebOnly: () => {
    // console.log(
    //   'ScrollablesContext tried to scroll to input without a provider'
    // )
  },
  __findFirstInputFromNames: () => {
    // console.log(
    //   'ScrollablesContext tried to find first input from names without a provider'
    // )
    return undefined
  },
  scrollToFirstInputFromNamesWebOnly: () => {
    // console.log(
    //   'ScrollablesContext tried to scroll to first input from names without a provider'
    // )
  },
  maybeScrollToFirstInputFromNamesWebOnly: () => {
    // console.log(
    //   '[Form ScrollablesContext] Called maybeScrollToFirstInputFromNamesWebOnly() without a provider. This will error silently.'
    // )
  },
})
