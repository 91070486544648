import React, { useMemo } from 'react'

type Props = {
  children: React.ReactNode
}
import {
  NavigationContainer,
  getStateFromPath,
  DefaultTheme,
  LinkingOptions,
} from '@react-navigation/native'
import navigationRef from '@beatgig/navigation/service/ref'
import * as Linking from 'expo-linking'
import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'
import { APP_URL } from '@beatgig/constants'
import { NativeTabsParams } from '@beatgig/navigation/tabs/native-tabs/types'
import { NativeStackParams } from '@beatgig/navigation/stacks/native-stack/types'

import useTheme from '@beatgig/theme/use-theme'
import { NotificationApi } from '@beatgig/api/notification'
import { NewArtistStackParams } from '@beatgig/features/artist/new/native/types'
import { newArtistSteps } from '@beatgig/features/artist/new/use-trigger-step/context'
import { PushTrackingEvent } from '@beatgig/gql/headless-codegen'

const documentTitle = {
  enabled: false,
}

// add object type safety for tabs, paths, and objects
const getStackPath = <Path extends keyof NativeStackParams>(path: Path) => {
  return path
}
const getTabPath = <Path extends keyof NativeTabsParams>(path: Path) => {
  return path
}

// add object type safety for tabs, paths, and objects
const getNewArtistStackPath = <Path extends keyof NewArtistStackParams>(
  path: Path
) => {
  return path
}
function makeType<T>(t: T) {
  return t
}

const tabPaths = makeType({
  bookings: getTabPath('bookingsTab'),
  discover: getTabPath('discover'),
  myVenues: getTabPath('Venues'),
  myArtists: getTabPath('artists'),
  account: getTabPath('account'),
  home: getTabPath('homebase'),
  calendar: getTabPath('calendar'),
  sellerBookingRequests: getTabPath('bookingRequests'),
})

const stackPaths = makeType({
  booking: getStackPath('booking'),
  artist: getStackPath('Artist'),
  search: getStackPath('Search'),
  bookings: getStackPath('bookings'),
  myVenues: getStackPath('venues'),
  venue: getStackPath('venue'),
  discover: getStackPath('Discover'),
  calendar: getStackPath('Calendar'),
  bookingRequest: getStackPath('BookingRequestDetail'),
  likes: getStackPath('likes'),
  sellerBookingRequestDetail: getStackPath('BookingRequestDetailSeller'),
  sellerBookingRequests: getStackPath('ArtistBookingRequests'),
  homeBase: getStackPath('HomeBase'),
  promo: getStackPath('PromoStudio'),
  myArtists: getStackPath('Artists'),
  artistPayouts: getStackPath('artistPayouts'),
  artistMetrics: getStackPath('ArtistMetrics'),
})

const newArtistStackPaths = makeType({
  spotify: getNewArtistStackPath('spotify'),
  name: getNewArtistStackPath('name'),
  confirm: getNewArtistStackPath('confirm'),
  splash: getNewArtistStackPath('splash'),
})

const mockBuyerBookingRequestDetailUrl = (bookingRequestId: string) =>
  `dashboard/booking-requests/${bookingRequestId}`

const prefix = Linking.createURL('/')
console.log('[linking][prefix]', prefix)

const nativeLinking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: [prefix, `https://${APP_URL}/`, `https://*.${APP_URL}/`],
  subscribe(open) {
    const onReceiveURL = ({ url }: { url: string }) => {
      open(url)
    }
    const linking = Linking.addEventListener('url', onReceiveURL)

    const notificationResponseListener =
      Notifications.addNotificationResponseReceivedListener((response) => {
        const data: {
          url?: string
          trackingUrl?: string
        } = response.notification.request.content.data

        // https://docs.expo.dev/versions/latest/sdk/notifications/#notificationresponse
        const isTapped =
          response.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER

        const { url, trackingUrl } = data

        console.log('[received-notification]', {
          ...data,
          isTapped,
        })

        if (url && typeof url === 'string') {
          open(url)
        }
        console.log('[open-notification]', {
          trackingUrl,
          isTapped,
        })
        if (trackingUrl) {
          NotificationApi.viewNotification({
            trackingUrl,
            pushTrackingEvent: isTapped
              ? PushTrackingEvent.OPENED
              : PushTrackingEvent.DELIVERED,
          })
        }
      })

    return () => {
      console.log('[navigation-linking] cleaning up notifications listener')
      linking.remove()
      notificationResponseListener.remove()
    }
  },
  async getInitialURL() {
    const url = await Linking.getInitialURL()
    return url
  },
  getStateFromPath(path, config) {
    console.log('[navigation][get-state-from-path]', { path })
    const withRewrites = (unparsedPath: string): string => {
      if (unparsedPath.startsWith('/@')) {
        const slug = unparsedPath.replace('/@', '').split('?')[0].split('/')[0]

        const rest = unparsedPath.replace(`/@${slug}`, '')

        const final = `/artists/${slug}${rest}`

        return final
      }

      // dashboard/requests?bookingRequestId=:bookingRequestId => dashboard/requests/:bookingRequestId
      if (
        unparsedPath.startsWith('dashboard/requests?') &&
        unparsedPath.includes('bookingRequestId=')
      ) {
        const qs = require('querystring') as typeof import('querystring')
        const query = qs.parse(unparsedPath.split('?')[1])
        const { bookingRequestId } = query
        if (bookingRequestId && typeof bookingRequestId === 'string') {
          delete query.bookingRequestId
          return `/dashboard/requests/${bookingRequestId}?${qs.stringify(
            query
          )}`
        }
      }

      // dashboard/calendar?bookingRequestId=:bookingRequestId => dashboard/booking-requests/:bookingRequestId
      if (
        unparsedPath.startsWith('dashboard/calendar?') &&
        unparsedPath.includes('bookingRequestId=')
      ) {
        const qs = require('querystring') as typeof import('querystring')
        const query = qs.parse(unparsedPath.split('?')[1])
        const { bookingRequestId } = query
        if (bookingRequestId && typeof bookingRequestId === 'string') {
          delete query.bookingRequestId
          return `${mockBuyerBookingRequestDetailUrl(
            bookingRequestId
          )}?${qs.stringify(query)}`
        }
      }

      return unparsedPath
    }

    const finalPath = withRewrites(path)

    const state = getStateFromPath(finalPath, config)

    return state
  },
  config: {
    screens: {
      // dashboard
      bookingsTab: {
        initialRouteName: stackPaths.bookings,
        screens: {
          [stackPaths.bookings]: 'dashboard/bookings',
          // [stackPaths.booking]: 'dashboard/bookings/:id',
        },
      },
      [tabPaths.calendar]: {
        initialRouteName: stackPaths.calendar,
        screens: {
          // TODO add dashboard/calendar?venue=:venue&day=:day etc
          [stackPaths.calendar]: 'dashboard/calendar',
          // FIXME solito this should be a single tab with bookings above, call it calendarOrBookingsTab
          [stackPaths.booking]: 'dashboard/bookings/:id',
          [stackPaths.bookingRequest]:
            mockBuyerBookingRequestDetailUrl(':bookingRequestId'),
        },
      },
      [tabPaths.myVenues]: {
        initialRouteName: stackPaths.myVenues,
        path: 'dashboard/venues',
      },
      [tabPaths.myArtists]: {
        path: 'dashboard/artists',
        initialRouteName: stackPaths.myArtists,
        screens: {
          [stackPaths.artistPayouts]: 'payouts',
          [stackPaths.myArtists]: '',
          UpdateArtistModal: ':slug/edit/:step?',
          // TODO fix react-native-svg and try again
          // [stackPaths.artistMetrics]: 'analytics',
          NewArtistModal: {
            // TODO allow edit page in here, by making it a nested navigator once again
            path: 'new/:step?',
            parse: {
              step: (value) => {
                if (!value) {
                  return Object.keys(newArtistSteps)[0]
                }
                if (!newArtistSteps[value]) {
                  return Object.keys(newArtistSteps)[0]
                }
                return value
              },
            },
          },
        },
      },
      [tabPaths.account]: {
        path: 'dashboard/account',
      },
      [tabPaths.sellerBookingRequests]: {
        initialRouteName: stackPaths.sellerBookingRequests,
        screens: {
          [stackPaths.sellerBookingRequestDetail]:
            'dashboard/requests/:bookingRequestId',
          [stackPaths.sellerBookingRequests]: 'dashboard/requests',
        },
      },

      // anyone
      [tabPaths.discover]: {
        initialRouteName: stackPaths.discover,
        screens: {
          [stackPaths.search]: 'search',
          [stackPaths.artist]: `artists/:slug`,
          ArtistReviews: 'artists/:slug/reviews',
          ShareArtist: 'artists/:slug/share',
          CoverSongs: 'artists/:slug/cover-songs',
          EditArtistPerformanceData: 'artists/:slug/cover-songs/edit',
        },
      },
      [tabPaths.home]: {
        initialRouteName: stackPaths.homeBase,
        path: '',
        screens: {
          [stackPaths.likes]: 'dashboard/likes',
          [stackPaths.venue]: 'venues/:slug',
          [stackPaths.promo]: 'dashboard/promo',
          ArtistAvailabilityCheck: 'dashboard/avail-checks/:id',
          BookingConfirmations: 'dashboard/confirmations',
          BookingPreferences: 'onboarding/preferences',
        },
      },
    },
  },
}

export function NavigationProvider({ children }: Props) {
  const { colors } = useTheme()
  return (
    <NavigationContainer<ReactNavigation.RootParamList>
      documentTitle={documentTitle}
      linking={Platform.select({
        native: nativeLinking,
        default: undefined,
      })}
      theme={useMemo(
        () => ({
          ...DefaultTheme,
          dark: true,
          colors: {
            ...DefaultTheme.colors,
            primary: colors.primary,
            border: colors.border,
            background: colors.background,
            text: colors.text,
          },
        }),
        [colors.background, colors.border, colors.primary, colors.text]
      )}
      fallback={Platform.select({
        default: undefined,
        native: <></>,
      })}
      // @ts-expect-error oof we need solito
      ref={navigationRef}
    >
      {children}
    </NavigationContainer>
  )
}
