import React from 'react'
import { SWRConfig } from 'swr'
import { swr } from './cache'

const value = {
  cache: swr.cache,
}

export function SwrProvider({ children }: { children: React.ReactNode }) {
  return <SWRConfig value={value}>{children}</SWRConfig>
}
