import React from 'react'
import {
  initialWindowMetrics,
  SafeAreaProvider,
} from 'react-native-safe-area-context'
import Dripsy from './Dripsy'
import { NavigationProvider } from './Navigation'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import Doorman from './Doorman'
import SentryProvider from './Sentry'
import NextRouterProvider from './Router'
import { Platform } from 'react-native'
import { ResetNativeAppOnSignOut } from '@beatgig/native/reset-native-app'

import { NotifierWrapper } from 'react-native-notifier'
import Popups from './popups'
import QueryProvider from '@beatgig/queries/core/provider'
import { AlgoliaPendingTasksProvider } from '@beatgig/search/components/pending-algolia-tasks/provider'
import { SwrProvider } from './swr'
import { UrqlProvider } from './urql'
import { Solito } from './Solito'
import AuthPopup from '@beatgig/auth-components/auth-popup'
import { AutomaticallyOpenAuthPopup } from './open-auth'
import { LogRocketProvider } from './logrocket'
import { WebAnalyticsProvider } from '@beatgig/analytics/web-analytics'

type Props = {
  children: React.ReactNode
}

export default function Providers(props: Props) {
  const { children } = props

  return (
    <WebAnalyticsProvider>
      <SwrProvider>
        <ResetNativeAppOnSignOut>
          <AlgoliaPendingTasksProvider>
            <NextRouterProvider>
              <SentryProvider>
                <Dripsy>
                  <Doorman>
                    <UrqlProvider>
                      <QueryProvider>
                        <NavigationProvider>
                          <ActionSheetProvider useNativeDriver>
                            <SafeAreaProvider
                              initialMetrics={initialWindowMetrics}
                            >
                              <NotifierWrapper>
                                <Solito>
                                  <LogRocketProvider>
                                    {children}
                                  </LogRocketProvider>
                                  <AuthPopup />
                                  <AutomaticallyOpenAuthPopup />
                                </Solito>
                              </NotifierWrapper>
                              {Platform.OS === 'web' && <Popups />}
                            </SafeAreaProvider>
                          </ActionSheetProvider>
                        </NavigationProvider>
                      </QueryProvider>
                    </UrqlProvider>
                  </Doorman>
                </Dripsy>
              </SentryProvider>
            </NextRouterProvider>
          </AlgoliaPendingTasksProvider>
        </ResetNativeAppOnSignOut>
      </SwrProvider>
    </WebAnalyticsProvider>
  )
}
