import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { Container, View, Text, styled } from 'dripsy'
import * as yup from 'yup'
import OnboardingHeader from '../onboarding-header'
import { ServerError, UserRole } from '@beatgig/api-services'
import APP_NAME from '@beatgig/constants/app-name'
import APP_URL from '@beatgig/constants/app-url'
import unitedStatesAbbreviations from '@beatgig/constants/state-abbreviations'
import Link from '@beatgig/design/components/link'
import ErrorNote from '@beatgig/components/ErrorNote'
import CheckboxField from '@beatgig/forms/fields/checkbox-field'
import PickerField from '@beatgig/forms/fields/picker-field'
import A from '@beatgig/components/a'
import { Platform } from 'react-native'
import FormRow from '@beatgig/forms/components/row'
import FormTextField from '@beatgig/forms/fields/text-field'
import Subtitle from '@beatgig/forms/components/subtitle'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { useRegisterScroller, AnchorProvider } from '@nandorojo/anchor'
import { BuyerUserType, SellerUserType } from '@beatgig/gql'
import { UserOnboardingFormPersistence } from '@beatgig/features/user/onboarding/form-persist'
import { useIpAddressQuery } from '@beatgig/gql'
import { useFieldFast } from '@beatgig/forms/hooks/use-fast-field'
import useStable from '@beatgig/design/hooks/use-stable'
import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'
import { OnboardingLayout } from '@beatgig/features/onboarding/layout'
import { MultiStepFormLayout } from '@beatgig/features/multi-step-form/layout'
import Spacer from '@beatgig/design/components/spacer'

const ScrollView = styled(KeyboardAwareScrollView)({
  bg: 'background',
  flex: 1,
})

type GenericUserInfoResult = {
  name: string
  email: string
  signUpMessage: string
  defaultStateAbbr?: string
  otherStateResponse?: string
  acceptTermsConditions: boolean
}

type GenericUserInfo = GenericUserInfoResult & {
  demoUrl?: string
}

type Props = {
  onSubmit: (data: GenericUserInfoResult) => Promise<any>
  subtitle?: string
  userRole: UserRole
  networkError?: ServerError | null
  userType: SellerUserType | BuyerUserType
}

function LocationFromIP() {
  // FIXME why is this sending to the wrong place
  const [ipQuery] = useIpAddressQuery()

  const [country, , countryActions] = useFieldFast<
    GenericUserInfoResult['otherStateResponse'],
    GenericUserInfoResult
  >('otherStateResponse')
  const [state, , stateActions] = useFieldFast<
    GenericUserInfoResult['defaultStateAbbr'],
    GenericUserInfoResult
  >('defaultStateAbbr')

  const stableCountry = useStable(country.value)
  const stableState = useStable(state.value)

  const setCountry = useLatestCallback((value) => {
    countryActions.setValue(value, true)
  })
  const setState = useLatestCallback((value) => {
    stateActions.setValue(value, true)
  })

  useEffect(() => {
    if (ipQuery.data) {
      const { stateAbbr, countryAbbr } = ipQuery.data.ipAddress || {}
      if (!stableState.current && stateAbbr) {
        setState(stateAbbr)
      }
      if (!stableCountry.current && countryAbbr) {
        setCountry(countryAbbr)
      }
    }
  }, [ipQuery.data, setCountry, setState, stableCountry, stableState])

  return null
}

const { Presence } = MultiStepFormLayout

function GenericUserOnboardingInfoWrapped(props: Props) {
  const {
    onSubmit,
    subtitle,
    userRole,
    // referredBySlug = '',
    networkError,
    userType,
  } = props

  const [isOutsideOfUs, setIsOutsideOfUs] = useState(false)

  // TODO default this
  // useSWR(
  //   'my-ip-address',
  //   async () => {
  //     return null
  //   },
  //   {
  //     revalidateWhenStale: false,
  //   }
  // )

  const makePolicyUrl = (href: '/cookies' | '/terms' | '/privacy') => {
    return Platform.select({
      web: href,
      default: `https://${APP_URL}${href}`,
    })
  }

  const { registerScrollRef } = useRegisterScroller()

  const needsToSelectState =
    userRole === UserRole.BUYER &&
    ![
      BuyerUserType.UNIVERSITY_PROGRAM_BOARD,
      BuyerUserType.FRATERNITY_SORORITY,
      BuyerUserType.WEDDING,
      BuyerUserType.CORPORATE_EVENT,
    ].includes(userType as BuyerUserType)

  return (
    <Formik<GenericUserInfo>
      initialValues={{
        name: '',
        email: '',
        signUpMessage: '',
        defaultStateAbbr: '',
        otherStateResponse: '',
        // referredBySlug,
        acceptTermsConditions: false,
        demoUrl: '',
      }}
      onSubmit={async ({ demoUrl, ...data }) => {
        if (demoUrl) {
          data.signUpMessage += `\n\nDemo: ${demoUrl}`
        }
        return await onSubmit(data)
      }}
      validationSchema={yup.object().shape<GenericUserInfo>({
        email: yup
          .string()
          .trim()
          .email('Please enter a valid email.')
          .required('An email is required to receive important notifications.'),
        name: yup
          .string()
          .trim()
          .required('Please enter your full name.')
          .test(
            'full-name',
            'Please enter a first and last name.',
            (value) =>
              (value
                ?.split(' ')
                ?.map((v) => v?.trim())
                .filter(Boolean).length || 0) >= 2
          ),
        signUpMessage: yup.string().trim().required(' '),
        defaultStateAbbr:
          needsToSelectState && !isOutsideOfUs
            ? yup
                .string()
                .trim()
                .typeError('Please pick a state.')
                // .oneOf(unitedStatesAbbreviations)
                .required('Please select a state.')
            : undefined,
        otherStateResponse:
          needsToSelectState && isOutsideOfUs
            ? yup.string().trim().required('Please enter your location.')
            : undefined,
        // referredBySlug: yup.string(),
        acceptTermsConditions: yup
          .boolean()
          .nullable()
          .oneOf([true], 'Please agree to continue.')
          .required('Please agree to continue.'),
        demoUrl: yup
          .string()
          .optional()
          .url('Please enter a valid URL, starting with https://'),
      })}
      validateOnMount={false}
    >
      <View sx={{ flex: 1 }}>
        <ScrollView
          ref={(ref) => registerScrollRef(ref as any)}
          keyboardShouldPersistTaps="handled"
        >
          <Container
            sx={{
              maxWidth: [null, null, 500],
              alignSelf: [null, null, 'flex-start'],
              width: ['100%', '100%'],
              py: 3,
              bg: 'background',
            }}
          >
            <UserOnboardingFormPersistence name={`generic-${userRole}`} />
            <OnboardingHeader>{'Tell us about yourself.'}</OnboardingHeader>
            {!!subtitle && <Text>{subtitle}</Text>}
            <Presence index={1}>
              <FormRow>
                <FormTextField<GenericUserInfo>
                  name="name"
                  label="Your Full Name"
                  required
                  placeholder="Aubrey Graham"
                  autoComplete="name"
                  textContentType="name"
                />
                <Subtitle sx={{ pt: 2 }}>
                  {userRole === UserRole.SELLER
                    ? 'Please enter your real first and last name (not stage name)'
                    : 'Please enter your first and last name.'}
                </Subtitle>
              </FormRow>
            </Presence>
            <LocationFromIP />
            {needsToSelectState && (
              <Presence index={2}>
                <FormRow>
                  {!isOutsideOfUs && (
                    <>
                      <PickerField<GenericUserInfo>
                        name={'defaultStateAbbr'}
                        label="Location"
                        required
                        items={unitedStatesAbbreviations}
                      />
                      <Subtitle sx={{ mt: 2, color: 'text' }}>
                        Select the state where you plan to book most of your
                        shows.
                      </Subtitle>
                      <Text sx={{ color: 'mutedText', mt: 2 }}>
                        {`If you're mostly booking outside the United States, `}
                        <Link
                          color="callout"
                          onPress={() => setIsOutsideOfUs(true)}
                          // sx={{ mt: 2 }}
                          textSx={{
                            fontSize: 2,
                          }}
                        >
                          click here.
                        </Link>
                      </Text>
                    </>
                  )}
                  {isOutsideOfUs && (
                    <>
                      <FormTextField<GenericUserInfo>
                        name="otherStateResponse"
                        label="Location"
                        required
                        placeholder="Enter state or province"
                      />
                      <Subtitle sx={{ mt: 3, color: 'text' }}>
                        Enter the state or province where you plan to book most
                        of your shows.
                      </Subtitle>
                      <Text sx={{ color: 'mutedText', mt: 2 }}>
                        {`If you're mostly booking inside the United States, `}
                        <Text
                          onPress={() => setIsOutsideOfUs(false)}
                          sx={{
                            color: 'callout',
                          }}
                        >
                          click here.
                        </Text>
                      </Text>
                    </>
                  )}
                </FormRow>
              </Presence>
            )}
            <Presence index={2}>
              <FormRow>
                <FormTextField<GenericUserInfo>
                  name="email"
                  label="Your Email"
                  required
                  autoComplete="email"
                  keyboardType="email-address"
                  textContentType="emailAddress"
                />
                <Subtitle sx={{ pt: 2 }}>
                  Please enter your primary email.
                </Subtitle>
              </FormRow>
            </Presence>
            <Presence index={3}>
              <FormRow>
                <FormTextField<GenericUserInfo>
                  name="signUpMessage"
                  label="Message"
                  required
                  numberOfLines={3}
                  placeholder={`How will you use ${APP_NAME}?`}
                  multiline
                />
              </FormRow>
              {userRole === UserRole.SELLER && (
                <FormRow>
                  <FormTextField<GenericUserInfo>
                    name="demoUrl"
                    label="Demo Video URL"
                    placeholder="Enter URL"
                  />
                  <Subtitle sx={{ pt: 2 }}>
                    Please include a link to a video demo of your music{' '}
                    <A
                      href="https://www.youtube.com/watch?v=yG0k7g9Wza0"
                      target="_blank"
                      sx={{
                        color: 'primary',
                      }}
                    >
                      (example)
                    </A>
                    .
                  </Subtitle>
                </FormRow>
              )}
              <FormRow>
                <CheckboxField
                  schema={null as any as GenericUserInfo}
                  name="acceptTermsConditions"
                  label="I accept terms & conditions."
                  description={
                    <>
                      To continue, please agree to our{' '}
                      <A
                        sx={{ color: 'primary' }}
                        href={makePolicyUrl('/privacy')}
                        target="_blank"
                      >
                        Privacy Policy
                      </A>
                      ,{' '}
                      <A
                        sx={{ color: 'primary' }}
                        href={makePolicyUrl('/terms')}
                        target="_blank"
                      >
                        Terms of Service
                      </A>
                      , and{' '}
                      <A
                        sx={{ color: 'primary' }}
                        href={makePolicyUrl('/cookies')}
                        target="_blank"
                      >
                        Cookie Policy
                      </A>
                      .
                    </>
                  }
                />
              </FormRow>
            </Presence>
            <ErrorNote sx={{ mb: 3 }} error={networkError} />
          </Container>
          <Spacer y="$6" />
        </ScrollView>
        {true && (
          <OnboardingLayout.Footer>
            <OnboardingLayout.Submit
              formName="Generic User Info"
              networkError={!!networkError}
            >
              Continue
            </OnboardingLayout.Submit>
          </OnboardingLayout.Footer>
        )}
      </View>
    </Formik>
  )
}

export default function GenericUserOnboardingInfo(props: Props) {
  return (
    <AnchorProvider horizontal={false}>
      <GenericUserOnboardingInfoWrapped {...props} />
    </AnchorProvider>
  )
}
