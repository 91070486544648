import { Box } from '@beatgig/design/components/box'
import Gradient from '@beatgig/design/components/gradient'
import {
  Dimensions,
  useWindowDimensions,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
} from 'react-native'
import { H1 as Heading1, H2 as Heading2, Sx } from 'dripsy'
import { DripsyTheme } from '@beatgig/theme'
import React, {
  createContext,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { TextInput, ScrollView, useSx } from 'dripsy'
import { MotiView, useDynamicAnimation } from 'moti'
import Entity from '@beatgig/design/components/entity'
import { MotiPressable } from 'moti/interactions'
import { MotiLink } from 'solito/moti'
import Button from '@beatgig/components/button'
import Press from '@beatgig/components/press'
import { Text } from '@beatgig/design/components/text'
import { LayoutView } from '@beatgig/design/components/layout-view'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import useTheme from '@beatgig/theme/use-theme'

import Ionicons, { IconProps } from '@beatgig/design/components/ionicons'
import { useLink } from 'solito/link'
import useAreYouSure from '@beatgig/hooks/use-are-you-sure'
import { parseNextPath, useRouter } from 'solito/router'
import { DropdownMenu } from '@beatgig/design/components/dropdown-menu/dropdown-menu'
import { ServerError } from '@beatgig/api-services'
import Avatar from '@beatgig/design/components/avatar'

const getIsRow = (width: number) => width > 744

function useIsRow() {
  const [isRow, setIsRow] = useState(() =>
    getIsRow(Dimensions.get('window').width)
  )

  useEffect(function updateRow() {
    const onChange = ({ window }) => {
      setIsRow(getIsRow(window.width))
    }
    const result = Dimensions.addEventListener('change', onChange)

    return () => {
      result?.remove?.()
    }
  }, [])

  return isRow
}

function Root({ children }: { children: React.ReactNode }) {
  return (
    <SidebarNavigationProvider>
      <KeyboardAvoidingView
        style={Platform.select({
          // web: StyleSheet.absoluteFill,
          default: { flex: 1 },
          web: {
            position: 'absolute',
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
          },
        })}
        behavior={Platform.select({
          ios: 'padding',
        })}
        // keyboardVerticalOffset={Platform.select({
        //   ios: headerHeight + 30,
        //   default: headerHeight,
        // })}
      >
        {children}
      </KeyboardAvoidingView>
    </SidebarNavigationProvider>
  )
}

function Grid({ children }: { children: React.ReactNode }) {
  const isRow = useIsRow()
  const headerHeight = useHeaderHeight()

  return (
    <Box flex={1} flexDirection={isRow ? 'row' : 'column'}>
      {children}
    </Box>
  )

  return (
    <Box flex={1}>
      <ScrollView
        sx={{
          flexDirection: isRow ? 'row' : 'column',
        }}
        contentContainerSx={{
          flex: 1,
          flexDirection: isRow ? 'row' : 'column',
        }}
        // contentInsetAdjustmentBehavior makes it shift below the header
        // however, automatic makes it awlso jump when the keyboard closes
        // so instead, we're using padding above for the header height
        // contentInsetAdjustmentBehavior="automatic"
        scrollToOverflowEnabled
        keyboardShouldPersistTaps="handled"
        bounces={false}
        overScrollMode="never"
        // automaticallyAdjustKeyboardInsets
        keyboardDismissMode="on-drag"
      >
        <Box height={headerHeight} />
        {children}
      </ScrollView>
    </Box>
  )
}

const mainPaddingX = ['$3', null, '$4', 48]

function Main({ children }: { children: React.ReactNode }) {
  const isRow = useIsRow()

  return (
    <Box
      flex={1}
      // minHeight={isRow ? undefined : '75%'}
      // width={!isRow ? '100%' : undefined}
      // maxHeight={isRow ? undefined : '75%'}

      // borderTopRightRadius={!isRow ? 12.01 : 0}
      // borderTopLeftRadius={!isRow ? 12.01 : 0}
      overflow="hidden"
    >
      {children}
    </Box>
  )
}

// TODO shouldn't this be scrollable?
function MainContent({ children }: { children: React.ReactNode }) {
  const isRow = useIsRow()
  const headerHeight = useHeaderHeight()

  return (
    <ScrollView
      contentContainerSx={Platform.select({
        web: {
          flexGrow: 1,
          justifyContent: 'center',
        },
      })}
      centerContent={true}
      // contentInsetAdjustmentBehavior makes it shift below the header
      // however, automatic makes it awlso jump when the keyboard closes
      // so instead, we're using padding above for the header height
      // contentInsetAdjustmentBehavior="automatic"
      scrollToOverflowEnabled
      keyboardShouldPersistTaps="handled"
      bounces={true || false} // TODO is true ok here?
      overScrollMode="never"
      keyboardDismissMode="on-drag"
    >
      <Box
        width="100%"
        alignSelf="center"
        maxWidth={624}
        py={isRow ? '$4' : '$3'}
        px={mainPaddingX}
      >
        {children}
      </Box>
    </ScrollView>
  )

  return (
    <Box
      flex={1}
      justifyContent={isRow ? 'center' : undefined}
      width="100%"
      px={mainPaddingX}
      alignSelf="center"
      maxWidth={624}
      pt={!isRow ? '$4' : undefined}
    >
      {children}
    </Box>
  )
}

const SidebarContext = createContext<{
  onHoverIn: (index: number) => void
  activeIndex: number
}>({
  onHoverIn() {
    // no-op
  },
  activeIndex: -1,
})

type SidebarNavigationContext = {
  addListener: (
    callback: (event: {
      preventDefault: () => void
      action: () => void
      defaultPrevented: boolean
    }) => void
  ) => {
    remove: () => void
  }
  dispatch: (callback: () => void) => void
}

const SidebarNavigationContext = createContext<SidebarNavigationContext>({
  addListener() {
    console.error('[sidebar-navigation-context] addListener has no provider')
    return {
      remove() {
        // no-op
      },
    }
  },
  dispatch(action) {
    // no-op
    action()
  },
})

const useSidebarNavigationContext = () => useContext(SidebarNavigationContext)

// this is a pretty unfortunate-but-necessary code to prevent navigation via sidebar with a dirty form. it's only applicable on native.
function SidebarNavigationProvider({ children }) {
  const mounted = useRef(false)
  useEffect(function mount() {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  const id = useRef(0)
  const listeners = useRef<
    Record<number, Parameters<SidebarNavigationContext['addListener']>[0]>
  >({})

  const addListener: SidebarNavigationContext['addListener'] = useCallback(
    (callback) => {
      id.current = id.current + 1
      const myId = id.current
      listeners.current[myId] = callback

      console.log('[sidebar-navigation-context] addListener', myId)

      return {
        remove() {
          console.log('[sidebar-navigation-context] removeListener', myId)
          delete listeners.current[myId]
        },
      }
    },
    []
  )

  const dispatch: SidebarNavigationContext['dispatch'] = useCallback(
    (_action) => {
      let defaultPrevented = false

      console.log('[sidebar-navigation-context] dispatch', _action)

      const action = () => {
        if (mounted.current) {
          _action()
        }
      }
      console.log(
        '[sidebar-navigation-context][dispatch][listeners]',
        listeners.current
      )
      for (const listenerId in listeners.current) {
        const callback = listeners.current[listenerId]

        callback({
          preventDefault() {
            console.log('[navigation-sidebar] preventDefault()')
            defaultPrevented = true
          },
          action,
          get defaultPrevented() {
            return defaultPrevented
          },
        })
      }

      requestAnimationFrame(() => {
        console.log(
          '[sidebar-navigation-context] action prevented:',
          defaultPrevented ? 'yes' : 'no'
        )

        // is raf necessary?
        if (!defaultPrevented && mounted.current) {
          action()
        }
      })
    },
    []
  )

  return (
    <SidebarNavigationContext.Provider
      value={{
        addListener,
        dispatch,
      }}
    >
      {children}
    </SidebarNavigationContext.Provider>
  )
}

function SidebarMenu({
  activeIndex,
  items,
}: {
  activeIndex: number
  items: Array<
    Pick<
      React.ComponentProps<typeof SidebarItem>,
      'href' | 'label' | 'icon' | 'imageUri'
    >
  >
}) {
  const isRow = useIsRow()
  const headerHeight = useHeaderHeight()

  const sidebarNavigationContext = useSidebarNavigationContext()

  const [hoverState, setHoverState] = useState({
    index: -1,
    hovered: false,
  })

  const sx = useSx()
  const router = useRouter()

  if (!isRow) {
    const activeItem = items[activeIndex]
    const height = 44
    return (
      <Box>
        <Box height={headerHeight} />
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Box
              height={height}
              bg="backgroundLight"
              px="$3"
              flexDirection="row"
              alignItems="center"
            >
              <Ionicons icon={activeItem.icon} />
              <Box flex={1} ml="$3">
                <Text lineHeight={height}>{activeItem.label}</Text>
              </Box>
              <Ionicons icon="list" />
            </Box>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            side="bottom"
            align="end"
            alignOffset={8}
            sideOffset={8}
          >
            {items.map(({ href, label }, index) => {
              const isActive = index === activeIndex
              const onSelect = () => {
                const action = () => router.push(href)
                sidebarNavigationContext.dispatch(action)
              }

              return (
                <DropdownMenu.CheckboxItem
                  value={isActive ? 'on' : 'off'}
                  onValueChange={onSelect}
                  key={parseNextPath(href)}
                >
                  <DropdownMenu.ItemTitle>{label}</DropdownMenu.ItemTitle>
                </DropdownMenu.CheckboxItem>
              )
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </Box>
    )
  }

  return (
    <SidebarContext.Provider
      value={{
        onHoverIn: (index) => setHoverState({ hovered: true, index }),
        activeIndex,
      }}
    >
      <Box
        height={isRow ? '100%' : undefined}
        width={isRow ? 300 : undefined}
        bg="background"
        borderColor="border"
        borderRightWidth={1}
      >
        <Box height={headerHeight} />
        <ScrollView>
          <MotiView
            style={sx({
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              borderRadius: '$3',
              bg: 'muted',
              height: sidebarItemHeight,
              mx: '$3',
              zIndex: -1,
            })}
            animate={{
              translateY: sidebarItemHeight * hoverState.index,
              opacity: hoverState.hovered ? 1 : 0,
              scale: hoverState.hovered ? 1 : 0.95,
            }}
            transition={{
              type: 'timing',
              duration: 150,
            }}
            pointerEvents="none"
          />
          <Box
            onMouseLeave={() => {
              setHoverState((state) => ({ ...state, hovered: false }))
            }}
          >
            {items.map(({ label, href, icon, imageUri }, index) => (
              <SidebarItem
                isActive={index === activeIndex}
                key={label}
                label={label}
                href={href}
                icon={icon}
                index={index}
                imageUri={imageUri}
              />
            ))}
          </Box>
        </ScrollView>
      </Box>
    </SidebarContext.Provider>
  )
}

const sidebarItemHeight = 44

const SidebarItem = memo(function SidebarItem({
  index,
  label,
  isActive,
  href,
  icon,
  imageUri,
}: {
  index: number
  label: string
  isActive?: boolean
  href: React.ComponentProps<typeof MotiLink>['href']
  icon: IconProps['icon']
  imageUri?: string
}) {
  const { onHoverIn } = useContext(SidebarContext)

  const link = useLink({
    href,
  })

  const width = 3

  return (
    <Press {...link} onHoverIn={() => onHoverIn(index)}>
      <MotiView
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
        }}
        animate={{ translateX: isActive ? 0 : -width }}
        transition={{
          type: 'timing',
          duration: 150,
        }}
        delay={isActive ? 125 : 0}
      >
        <Box
          width={width}
          borderTopRightRadius="$2"
          borderBottomRightRadius="$2"
          // borderRadius="$3"
          bg="text"
          height="100%"
          // my="$2"
        />
      </MotiView>
      <Box
        alignItems="center"
        flexDirection="row"
        px="$3"
        height={sidebarItemHeight}
        mx="$3"
      >
        <Box width={22} alignItems="center">
          {imageUri ? (
            <Avatar uri={imageUri} size={22} />
          ) : (
            <Ionicons
              icon={icon}
              color={isActive ? 'text' : 'muted4'}
              size={19}
            />
          )}
        </Box>

        <Box flex={1} ml="$3" pl="$2">
          <Text
            fontWeight={isActive ? '500' : undefined}
            color={isActive ? 'text' : 'mutedText'}
          >
            {label}
          </Text>
        </Box>
      </Box>
    </Press>
  )
})

function Aside({ children }: { children: React.ReactNode }) {
  const isRow = useIsRow()
  return (
    <LayoutView
      // flexGrow={isRow ? undefined : 1}
      // minHeight={isRow ? undefined : '30%'}
      // height={isRow ? '100%' : undefined}
      // justifyContent={isRow ? 'center' : 'flex-end'}
      // flex={isRow ? 1 : undefined}
      layoutId="multi-step-form-aside"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: isRow ? undefined : 1,
        minHeight: isRow ? undefined : '30%',
        height: isRow ? '100%' : undefined,
        justifyContent: isRow ? 'center' : 'flex-end',
        flex: isRow ? 1 : undefined,
      }}
    >
      {children}
    </LayoutView>
  )
}

function AsideContent({ children }: { children: React.ReactNode }) {
  const isRow = useIsRow()
  const screenWidth = useWindowDimensions().width
  const containerWidth = isRow ? screenWidth : screenWidth / 2

  return (
    <Box
      ml={isRow ? Math.min(containerWidth * 0.1, 56) : undefined}
      mx={24}
      mb={!isRow ? '$4' : undefined}
    >
      {children}
    </Box>
  )
}

const h1Animation = {
  from: {
    opacity: 0,
    translateY: 10,
  },
  animate: { opacity: 1, translateY: 0 },
  // ...Platform.select({
  //   web: {
  exit: { opacity: 0, translateY: 0 },
  //   },
  // }),
}

const H1 = memo(function H1({ children }: { children: string }) {
  const fontSize = ['$7', '$8', '$9', '$10']
  return (
    <MotiView {...h1Animation}>
      <Heading1 sx={{ color: 'white', fontWeight: 600, fontSize, mb: 0 }}>
        {children}
      </Heading1>
    </MotiView>
  )
})

const h2Animation = {
  from: {
    opacity: 0,
    translateY: 5,
  },
  animate: {
    opacity: 1,
    translateY: 0,
  },
  // ...Platform.select({
  //   web: {
  exit: {
    opacity: 0,
    translateY: 0,
  },
  //   },
  // }),
}

const H2 = memo(function H2({
  children,
  mb = '$3',
  textAlign,
}: {
  children: string
  mb?: Sx['mb']
  textAlign?: 'center' | 'left'
}) {
  const fontSize = ['$5', '$6', '$7']
  return (
    <MotiView {...h2Animation}>
      <Heading2 sx={{ fontWeight: '500', fontSize, mb, textAlign }}>
        {children}
      </Heading2>
    </MotiView>
  )
})

const H3 = memo(function H2({ children }: { children: string }) {
  const fontSize = ['$4', '$5']
  return (
    <MotiView {...h2Animation}>
      <Heading2 sx={{ fontWeight: '500', fontSize, mb: '$3' }}>
        {children}
      </Heading2>
    </MotiView>
  )
})

function Background({ children }: { children: React.ReactNode }) {
  return <Box stretch>{children}</Box>
}

function ColorBackground({
  color = 'background',
}: {
  color?: keyof DripsyTheme['colors']
}) {
  return <Box stretch bg={color} zIndex={-1} />
}

function GradientBackground({
  colors = ['blue', 'success'].reverse(),
}: Partial<Pick<React.ComponentProps<typeof Gradient>, 'colors'>>) {
  return <Gradient stretch colors={colors} sx={{ zIndex: -1 }} />
}

function Presence({
  children,
  index = 0,
  style,
}: {
  children: React.ReactNode
  index?: number
  style?: React.ComponentProps<typeof MotiView>['style']
}) {
  return (
    <MotiView style={style} {...presenceAnimation} delay={index * 40}>
      {children}
    </MotiView>
  )
}

const presenceAnimation: React.ComponentProps<typeof MotiView> = {
  from: {
    opacity: 0,
    translateY: 4,
  },
  animate: {
    opacity: 1,
    translateY: 0,
  },
  // ...Platform.select({
  //   web: {
  exit: {
    opacity: 0,
    translateY: 0,
  },
  //   },
  // }),
}

const Input = memo(
  forwardRef(function Input(
    props: React.ComponentProps<typeof TextInput>,
    ref
  ) {
    const theme = useTheme()

    return (
      <MotiView {...presenceAnimation}>
        <TextInput
          {...props}
          ref={ref as any}
          sx={{
            p: '$3',
            bg: 'muted',
            borderRadius: 16,
            fontSize: ['$4', '$5'],
            color: 'text',
            width: '100%',
          }}
          selectionColor={theme.colors.primary}
          placeholderTextColor={theme.colors.mutedText}
          keyboardAppearance="dark"
        />
      </MotiView>
    )
  })
)

Input.displayName = 'Input'

function ActionItem({
  index = 0,
  onPress,
  isSelected,
  href,
  disablePresenceAnimation = false,
  sx,
  ...props
}: {
  index?: number
  children: React.ReactNode
  isSelected?: boolean
  disablePresenceAnimation?: boolean
  sx?: Sx
} & (
  | {
      onPress: () => void
      href?: undefined
    }
  | {
      href: React.ComponentProps<typeof MotiLink>['href']
      onPress?: (e?: any) => void
    }
) &
  React.ComponentProps<typeof Entity>) {
  const Container = href ? MotiLink : MotiPressable

  return (
    <Container
      from={{
        opacity: disablePresenceAnimation ? 1 : 0,
        translateY: disablePresenceAnimation ? 0 : 5,
        scale: 1,
      }}
      animate={({ hovered, pressed }) => {
        'worklet'

        return {
          opacity: 1,
          translateY: 0,
          scale: isSelected ? 1 : pressed ? 0.97 : hovered ? 0.99 : 1,
        }
      }}
      exit={
        !disablePresenceAnimation && {
          opacity: 0,
          translateY: 0,
          scale: 1,
        }
      }
      // @ts-expect-error it's fine
      href={href}
      transition={{
        delay: 40 * index,
        scale: {
          delay: 0,
          type: 'timing',
          duration: 125,
        },
        // faster spring transition
        opacity: {
          type: 'timing',
          duration: 150,
        },
        translateY: {
          type: 'spring',
          // damping: 0.5,
          stiffness: 200,
          // mass: 0.5,
        },
      }}
      onPress={onPress}
    >
      <Entity
        {...props}
        sx={{
          bg: isSelected ? 'muted2' : 'muted',
          p: (theme) => theme.space.$3 + theme.space.$2,
          borderRadius: 16,
          borderWidth: 2,
          borderColor: isSelected ? 'text' : 'transparent',
          ...sx,
        }}
        alignFirstRow="center"
      >
        {props.children}
      </Entity>
    </Container>
  )
}

const useFooterHeight = () => {
  const { bottom = 0 } = useSafeAreaInsets()
  return bottom + Platform.select({ web: 100, default: 70 })
}

function useHeaderHeight() {
  const theme = useTheme()
  const { top } = useSafeAreaInsets()

  return top ? top + 50 : theme.space.$3 + 40
}

function Header({
  onCloseHref,
  areYouSure = '',
}: {
  onCloseHref: React.ComponentProps<typeof MotiLink>['href']
  areYouSure?: string
}) {
  const { top = 0 } = useSafeAreaInsets()
  const height = useHeaderHeight()

  const link = useLink({
    href: onCloseHref,
  })

  const { replace, push, back } = useRouter()

  const areYouSureAction = useAreYouSure({
    action: areYouSure,
  })

  const onClose = (e?: any) => {
    e?.preventDefault()
    const run = () => {
      if (Platform.OS == 'web') {
        push(onCloseHref)
      } else {
        // replace(onCloseHref)
        back()
      }
    }
    if (areYouSure) {
      areYouSureAction(run)
    } else {
      run()
    }
  }
  return (
    <Box
      px="$3"
      height={height}
      position="absolute"
      top={0}
      left={0}
      right={0}
      pointerEvents="box-none"
      zIndex={3}
      pt={top}
    >
      <Box
        flex={1}
        justifyContent="center"
        alignItems="flex-start"
        pointerEvents="box-none"
      >
        <Button
          onPress={onClose}
          sx={{
            borderRadius: '$3',
          }}
          variant="small"
          color="muted"
        >
          Done
        </Button>
        {null && (
          <MotiPressable
            animate={({ pressed, hovered }) => {
              'worklet'

              return {
                scale: pressed ? 0.9 : hovered ? 0.95 : 1,
              }
            }}
            transition={{
              // fast spring transition for touching
              type: 'spring',
              damping: 0.5,
              stiffness: 200,
              mass: 0.5,
            }}
            accessibilityLabel="Close"
            accessibilityRole={link.accessibilityRole}
            onPress={onClose}
          >
            <Box
              borderRadius="$3"
              px="$3"
              alignItems="center"
              justifyContent="center"
              height={34}
              bg="backgroundLight"
            >
              <Text lineHeight={34} fontSize="$3">
                Done
              </Text>
            </Box>
            {null && (
              <Box
                size={36}
                borderRadius="rounded"
                bg="text"
                alignItems="center"
                justifyContent="center"
                shadowColor="white"
                shadowOpacity={0.5}
                shadowRadius={5}
                shadowOffset={{ width: 0, height: 0 }}
              >
                <Ionicons
                  selectable={false}
                  name="close-outline"
                  size={23}
                  color="background"
                />
              </Box>
            )}
          </MotiPressable>
        )}
      </Box>
    </Box>
  )
}

function Footer({ children }: { children: React.ReactNode }) {
  const footerHeight = useFooterHeight()
  const { bottom } = useSafeAreaInsets()

  const safeAreaStyle = useDynamicAnimation(() => ({
    height: bottom,
  }))

  useEffect(
    function safeKeyboard() {
      if (Platform.OS !== 'web') {
        const show = Keyboard.addListener('keyboardWillShow', () => {
          safeAreaStyle.animateTo({ height: 0 })
        })
        const hide = Keyboard.addListener('keyboardWillHide', () => {
          safeAreaStyle.animateTo({ height: bottom })
        })

        return () => {
          hide?.remove?.()
          show?.remove?.()
        }
      }
    },
    [bottom, safeAreaStyle]
  )

  return (
    <Box borderTopWidth={1} borderColor="border">
      <Box
        px={['$3', null, '$5']}
        py={['$2', null, '$3']}
        // my="$1"
        bg="background"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {children}
      </Box>
      <MotiView
        state={safeAreaStyle}
        transition={{ type: 'timing', duration: 120 }}
      />
    </Box>
  )
}

function PrimaryAction({
  children = 'Continue',
  disabled,
  loading,
  onSubmit,
  error,
}: {
  children?: React.ReactNode
  disabled: boolean
  loading: boolean
  onSubmit: () => void
  error?: ServerError | boolean
}) {
  return (
    <Button
      disabled={disabled}
      loading={loading}
      onPress={onSubmit}
      sx={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        height: 50,
        px: '$4',
        fontSize: '$3',
      }}
      variant={Platform.select({ web: undefined, default: 'small' })}
      color={error ? 'error' : 'text'}
    >
      {children}
    </Button>
  )
}

function BackAction({ onGoBack }: { onGoBack: () => void }) {
  return (
    <Press onPress={onGoBack}>
      <Text fontSize="$4" selectable={false}>
        Back
      </Text>
    </Press>
  )
}

export const MultiStepFormLayout = {
  Root,
  Grid,
  Main,
  Aside,
  Background,
  GradientBackground,
  MainContent,
  H1,
  H2,
  H3,
  AsideContent,
  ColorBackground,
  Input,
  ActionItem,
  Footer,
  PrimaryAction,
  BackAction,
  Presence,
  Header,
  SidebarItem,
  SidebarMenu,
  useSidebarNavigationContext,
}
