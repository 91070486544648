import React from 'react'
import { View } from 'dripsy'
import { Menu } from '../menu'

type Props = {
  menuItems?: React.ReactNode
}

export default function EntityMenu(props: Props) {
  const { menuItems } = props

  if (!menuItems) return null

  return (
    <View sx={{ ml: 3 }}>
      <Menu
        placement="right top"
        trigger={<Menu.IconButton icon="ellipsis-vertical" shape="none" />}
      >
        {menuItems}
      </Menu>
    </View>
  )
}
