import { useEffect, useRef } from 'react'

export default function useWhyDidYouUpdate<Props extends {}>(
  name: string,
  props: Props,
  { keysOnly = false }: { keysOnly?: boolean } = {}
) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef<Props | undefined>()

  useEffect(() => {
    if (!__DEV__) {
      if (previousProps.current as Props) {
        // Get all keys from previous and current props
        const allKeys = Object.keys({
          ...(previousProps.current as Props),
          ...props,
        })
        // Use this object to keep track of changed props
        const changesObj: {
          [key in keyof Props]?: { to: unknown; from: unknown }
        } = {}
        // Iterate through keys
        allKeys.forEach((key) => {
          // If previous is different from current
          if (
            (previousProps.current as Props)[key as keyof Props] !==
            props[key as keyof Props]
          ) {
            // Add to changesObj
            const from = (previousProps.current as Props)[key as keyof Props]
            const to = props[key as keyof Props]
            changesObj[key as keyof Props] = {
              from: keysOnly ? 'keys only' : from,
              to: keysOnly ? 'keys only' : to,
            }
          }
        })

        // If changesObj not empty then output to console
        if (Object.keys(changesObj).length) {
          console.log('[why-did-you-update]', name, changesObj)
        }
      }

      // Finally update previousProps with current props for next hook call
      previousProps.current = props
    }
  })
}
