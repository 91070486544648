import React, { useMemo } from 'react'
import type { Function } from 'ts-toolbelt'
import { useFieldFast } from '../hooks/use-fast-field'
import { View } from 'dripsy'
import Checkbox from '@beatgig/design/components/checkbox'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import Label from './field-label'
import { useFormikContext } from 'formik'
import ErrorText from './error'
import { ScrollToField } from './scroll-to-field'

type CheckboxFieldProps<Schema, Path extends string> = {
  name: Function.AutoPath<Schema, Path>
  /**
   * This field is a no-op. It's used as a terrible way to specify the schema.
   *
   * Usage is like so:
   *
   * ```tsx
   * type Schema = {
   *   user: { name: string }
   * }
   *
   * <CheckboxField schema={null as Schema} name="user.name" />
   * ```
   *
   * This is due to a terrible limitation of TS, as shown in this issue: https://github.com/millsp/ts-toolbelt/issues/154#issuecomment-772097890
   */
  schema: Schema
  label?: string
  tooltip?: React.ReactNode
  description: React.ReactNode
} & Omit<React.ComponentProps<typeof Checkbox>, 'checked' | 'onChange'>

export default function CheckboxField<Schema, Path extends string>({
  name,
  tooltip,
  description,
  label,
  ...rest
}: CheckboxFieldProps<Schema, Path>) {
  const [
    { value },
    { error, touched },
    { setTouched, setValue },
  ] = useFieldFast<boolean | undefined>(name)
  const { submitCount } = useFormikContext()

  const hasErrored = !!((submitCount || touched) && error?.trim())

  const { onChange } = useMemo(
    () => ({
      onChange: (next) => {
        setTouched(true)
        setValue(next)
      },
    }),
    [setTouched, setValue]
  )

  return (
    <View>
      <ScrollToField name={name as string} />
      {!!label && <Label hasErrored={hasErrored}>{label}</Label>}
      <HoverTooltip placement="right" anchor="top" bg="text" text={tooltip}>
        <Checkbox {...rest} onChange={onChange} checked={value}>
          {description}
        </Checkbox>
      </HoverTooltip>
      {hasErrored && <ErrorText>{error}</ErrorText>}
    </View>
  )
}
