import { AnalyticsBrowser } from '@segment/analytics-next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { segmentWeb, setSegmentWeb } from './segment-web'

const useInitializenitializeSegmentWeb = () => {
  const router = useRouter()
  if (
    !segmentWeb &&
    !router.pathname.startsWith('/embed') &&
    typeof window != 'undefined'
  ) {
    setSegmentWeb(
      AnalyticsBrowser.load({
        writeKey:
          process.env.NEXT_PUBLIC_BACKEND_ENV == 'sandbox'
            ? 'GR8z9vbYhpLbFGymPgDn8kxzSLq1wBOs'
            : '5UUrEAy3Eg488eElRgoKmgp9Y6LpW0jp',
      })
    )
  }
}

const useSegmentWeb = () => {
  useInitializenitializeSegmentWeb()

  const { events } = useRouter()

  useEffect(() => {
    if (segmentWeb) {
      const handler = () => {
        if (segmentWeb) {
          const urlSearchParams = new URLSearchParams(window.location.search)
          // @ts-expect-error
          const params = Object.fromEntries(urlSearchParams?.entries?.())
          segmentWeb.page(params)
        }
      }
      events.on('routeChangeComplete', handler)
      return () => {
        events.off('routeChangeComplete', handler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export const WebAnalyticsProvider = ({ children }) => {
  useSegmentWeb()
  return <>{children}</>
}
