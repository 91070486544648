import Picker from '@beatgig/components/picker'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import { View } from 'dripsy'
import React from 'react'
import { useFieldFast } from '../hooks/use-fast-field'
import ErrorText from './error'
import FieldLabel from './field-label'
// import Input from './text-input'
import Input from '@beatgig/design/components/input'
import { FieldName } from './types'
import { ScrollToField } from './scroll-to-field'
import { useFormikContext } from 'formik'

export type PickerFieldProps<T extends object> = {
  name: FieldName<T>
  sx?: React.ComponentProps<typeof View>['sx']
  items: string[]
  label?: string
  placeholder?: {
    label?: string
    value?: string
  }
  required?: boolean
  allowsCustomValue?: boolean
  onChange?: (value: string) => void
}

function FastPicker({ ...props }: React.ComponentProps<typeof Picker>) {
  return <Picker {...props} />
}

const CUSTOM_VALUE = 'Other'

export default function PickerField<T extends object>(
  props: PickerFieldProps<T>
) {
  const {
    items,
    label,
    placeholder = { label: 'Select an item...', value: undefined },
    allowsCustomValue = false,
    required = false,
    onChange,
  } = props

  const [
    customTextFieldIsVisible,
    setCustomTextFieldIsVisible,
  ] = React.useState(false)
  const [customText, setCustomText] = React.useState('')
  const { ref, isHovered } = useAnimatedHover()
  const { submitCount } = useFormikContext()

  const name = Array.isArray(props.name) ? props.name.join('.') : props.name

  const [
    { value },
    { error, touched },
    { handleBlur, setValue, setTouched },
  ] = useFieldFast(name as string)

  // console.log('[picker]', { value })

  const handleClose = React.useCallback(() => {
    setTouched(true)
  }, [setTouched])

  const handleValueChange = React.useCallback<
    React.ComponentProps<typeof Picker>['onValueChange']
  >(
    (value: string, index) => {
      console.log('[picker] did change', { value, index })
      setValue(value)
      onChange?.(value)
      setTouched(true)
      // console.log('[picker] did change', { value })
      // if (value === CUSTOM_VALUE || value === null) {
      //   setCustomTextFieldIsVisible(true)
      //   setValue(customText)
      // } else {
      //   setCustomTextFieldIsVisible(false)
      //   setValue(value)
      // }
    },
    [setValue, onChange, setTouched]
  )

  const itemDicts = React.useMemo<
    React.ComponentProps<typeof Picker>['items']
  >(() => {
    const final = items.map((value) => ({ label: value, value }))
    // if (allowsCustomValue) {
    //   final.push({ label: 'Other', value: CUSTOM_VALUE })
    // }
    return final
  }, [items])

  const hasErrored = !!error && !!(touched || submitCount)

  // console.log('[form.picker]', { customTextFieldIsVisible })

  return (
    <View ref={ref}>
      <ScrollToField name={name as string} />
      {!!label && (
        <FieldLabel
          hasErrored={hasErrored}
          required={required}
          isHovered={isHovered}
        >
          {label}
        </FieldLabel>
      )}
      <FastPicker
        // onOpen={handleOpen}
        onValueChange={handleValueChange}
        // value={value}
        value={customTextFieldIsVisible ? CUSTOM_VALUE : value}
        items={itemDicts}
        onClose={handleClose}
        // placeholder={
        //   {
        //     // label: 'Select an organization...',
        //     // value: null,
        //   }
        // }
        placeholder={placeholder}
      />
      {customTextFieldIsVisible && (
        <Input
          value={customText}
          onChangeText={(text) => {
            setCustomText(text)
            setValue(text)
          }}
          placeholder="Other"
          hasErrored={hasErrored}
          onBlur={handleBlur}
          isHovered={isHovered}
          // onFocus={handleFocus}
        />
      )}
      {hasErrored && !!error?.trim() && <ErrorText>{error}</ErrorText>}
    </View>
  )
}
