import { unstable_createElement } from 'react-native-web'
import { styled } from 'dripsy'
import { motion } from 'framer-motion'
import type { LayoutViewProps } from './types'

/**
 * @deprecated wrong import
 */
const LayoutView = styled(({ layout = true, ...props }: LayoutViewProps) => {
  return unstable_createElement(motion.div, {
    layout: typeof layout == 'string' ? layout : Boolean(layout),
    ...props,
  })
})()

LayoutView.displayName = 'LayoutView'

export { LayoutView }
