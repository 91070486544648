import React from 'react'
import { ThemeProvider } from '@theme-ui/core'
import theme from '@beatgig/theme/theme'
import { DripsyProvider } from 'dripsy'

type Props = {
  children: React.ReactNode
}

export default function Dripsy({ children }: Props) {
  return (
    <ThemeProvider theme={theme as any}>
      <DripsyProvider theme={theme}>{children}</DripsyProvider>
    </ThemeProvider>
  )
}
