import React from 'react'
import { View } from 'dripsy'
import useAuthPopupState from '@beatgig/auth-hooks/use-auth-popup-state'
import { Modal, Platform } from 'react-native'
import WebModal from '@beatgig/components/web-modal'
import AuthFlow from '../auth-flow'
import { NavigationContainer } from '@react-navigation/native'
import useAmISignedIn from '@beatgig/auth-hooks/use-am-i-signed-in'
import { useRouter } from 'next/router'

import { NotifierWrapper } from 'react-native-notifier'
// import dynamic from 'next/dynamic'
// const AuthFlow = dynamic(() => import('../auth-flow'))

export default function WebAuthPopup() {
  const [isOpen, setIsOpen] = useAuthPopupState()
  const goBack = React.useCallback(() => setIsOpen(false), [setIsOpen])

  const { push, pathname = '' } = useRouter() || {}

  const redirect = React.useCallback(() => {
    if (pathname.startsWith('/join') && Platform.OS === 'web') {
      push('/')
    }
  }, [push, pathname])

  const onDidAuthenticate = React.useCallback(() => {
    goBack()

    // if we're on the referral page, redirect to home
    redirect()
  }, [goBack, redirect])

  const { amISignedIn } = useAmISignedIn()

  const content = (
    <NavigationContainer
      independent
      documentTitle={{
        enabled: false,
      }}
    >
      <NotifierWrapper>
        <WebModal
          scrollable={false}
          dangerouslyDisableNativeScroll={true}
          animated={false}
          padded={false}
          onRequestClose={goBack}
          header={amISignedIn}
          headerIcon="close"
        >
          <View sx={{ flex: 1, bg: 'background' }}>
            {isOpen && (
              <AuthFlow
                onDidAuthenticatedSuccessfully={onDidAuthenticate}
                onAcceptPendingApproval={redirect}
                onClose={goBack}
              />
            )}
          </View>
        </WebModal>
      </NotifierWrapper>
    </NavigationContainer>
  )

  return (
    <Modal
      transparent
      visible={isOpen}
      onRequestClose={goBack}
      animationType={Platform.select({ native: 'slide' })}
    >
      {/* independent is needed here, since this navigator lives on its own  */}
      {content}
    </Modal>
  )
}
