import { createCache } from 'swr'

const map = new Map()

const { mutate, cache } = createCache(map)

export const swr = {
  mutate,
  cache,
  clear() {
    // const keys = [...map.keys()]
    map.clear()
    // for (const key of keys) {
    //   mutate(key)
    // }
  },
  // serialize: unstable_serialize,
}
