import {
  MeQuery,
  SellerUserType,
  BuyerUserType,
  User,
  UserFromAdminQuery,
} from '@beatgig/gql'

export type AllUserAccountTypes = ExistentUser

type ExcludeUser<T> = T extends User['__typename'] ? never : T

type UserDoesNotExistTypename = ExcludeUser<
  keyof Record<Pick<MeQuery['me'], '__typename'>['__typename'], true>
>

type WithoutNotExistingUser<Obj extends object> = Obj extends {
  __typename: UserDoesNotExistTypename
}
  ? never
  : Obj

export type ExistentUser =
  | NonNullable<WithoutNotExistingUser<MeQuery['me']>>
  | UserFromAdminQuery['user']

export type GetUser = MeQuery['me'] | UserFromAdminQuery['user']

export {
  UserActionsService,
  UsersService,
  AuthService,
} from '@beatgig/backend-types'

export {
  SellerUserType,
  BuyerUserType,
  UserRole,
  PublicOrPrivate,
  AdminUserType,
  WhatShouldIDoAlert,
} from '@beatgig/gql'

export type UserDisplay = ExistentUser

export type { Like, UserExists } from '@beatgig/backend-types'

const makeUserTypeNames = <
  Type extends BuyerUserType | SellerUserType,
  O extends Record<Type, string> = Record<Type, string>
>(
  obj: O
): O => {
  return obj
}

export const BuyerUserTypeNames: Record<BuyerUserType, string> = {
  Bar: 'Bar',
  Brewery: 'Brewery',
  Corporate_Event: 'Corporate Event',
  Country_Club: 'Country Club',
  Fraternity_Sorority: 'Fraternity / Sorority',
  Hotel_Resort: 'Hotel / Resort',
  Municipality: 'Municipality',
  Nightclub: 'Nightclub',
  Restaurant: 'Restaurant',
  Theater: 'Theater',
  University_Program_Board: 'University Program Board',
  Vineyard_Winery: 'Vineyard / Winery',
  Wedding: 'Wedding',
  Promoter: "I'm a Promoter",
  Other_Buyer: 'Other',
}

export const displayifyBuyerUserType = (type: BuyerUserType) => {
  return BuyerUserTypeNames[type]
}

export const parseBuyerUserTypeFromDisplay = (type: string) => {
  const index = Object.values(BuyerUserTypeNames).indexOf(type)
  if (index === -1) {
    console.error(`Unknown BuyerUserType: ${type}`)
    return
  }
  return Object.keys(BuyerUserTypeNames)[
    index
  ] as keyof typeof BuyerUserTypeNames
}
