import { useCallback, useMemo, useRef, useState, useContext } from 'react'
import { useRouting } from 'expo-next-react-navigation'
import { Platform } from 'react-native'
import Router from 'next/router'
import useStable from '@beatgig/design/hooks/use-stable'
import {
  NavigationContext,
  NavigationRouteContext,
} from '@react-navigation/native'

type Config<
  Props extends Record<string, unknown>,
  Required extends boolean,
  ParsedType,
  InitialValue
> = (Required extends false
  ? {
      parse?: (value?: string) => ParsedType
    }
  : {
      parse: (value?: string) => ParsedType
    }) & {
  stringify?: (value: ParsedType) => string
  initial: InitialValue
  paramsToClearOnSetState?: (keyof Props)[]
}

type Params<
  Props extends Record<string, unknown> = Record<string, string>,
  Name extends keyof Props = keyof Props,
  NullableUnparsedParsedType extends Props[Name] | undefined =
    | Props[Name]
    | undefined,
  ParseFunction extends
    | undefined
    | ((value?: string) => NonNullable<NullableUnparsedParsedType>) = (
    value?: string
  ) => NonNullable<NullableUnparsedParsedType>,
  InitialValue = NullableUnparsedParsedType | undefined,
  ParsedType = InitialValue extends undefined
    ? NullableUnparsedParsedType
    : ParseFunction extends undefined
    ? NullableUnparsedParsedType
    : NonNullable<NullableUnparsedParsedType>
> = NonNullable<ParsedType> extends string
  ?
      | [name: Name, config: Config<Props, false, ParsedType, InitialValue>]
      | [name: Name]
  : [name: Name, config: Config<Props, true, ParsedType, InitialValue>]

type Returns<
  Props extends Record<string, unknown> = Record<string, string>,
  Name extends keyof Props = keyof Props,
  NullableUnparsedParsedType extends Props[Name] | undefined =
    | Props[Name]
    | undefined,
  ParseFunction extends
    | undefined
    | ((value?: string) => NonNullable<NullableUnparsedParsedType>) = (
    value?: string
  ) => NonNullable<NullableUnparsedParsedType>,
  InitialValue = NullableUnparsedParsedType | undefined,
  ParsedType = InitialValue extends undefined
    ? NullableUnparsedParsedType
    : ParseFunction extends undefined
    ? NullableUnparsedParsedType
    : NonNullable<NullableUnparsedParsedType>
> = readonly [
  state: ParsedType | InitialValue,
  setState: (value: ParsedType) => void
]

export function createParam<
  Props extends Record<string, unknown> = Record<string, string>
>() {
  function useParam<
    Name extends keyof Props,
    NullableUnparsedParsedType extends Props[Name] | undefined =
      | Props[Name]
      | undefined,
    ParseFunction extends
      | undefined
      | ((value?: string) => NonNullable<NullableUnparsedParsedType>) = (
      value?: string
    ) => NonNullable<NullableUnparsedParsedType>,
    InitialValue = NullableUnparsedParsedType | undefined,
    ParsedType = InitialValue extends undefined
      ? NullableUnparsedParsedType
      : ParseFunction extends undefined
      ? NullableUnparsedParsedType
      : NonNullable<NullableUnparsedParsedType>
  >(
    ...[name, maybeConfig]: Params<
      Props,
      Name,
      NullableUnparsedParsedType,
      ParseFunction,
      InitialValue,
      ParsedType
    >
  ): Returns<
    Props,
    Name,
    NullableUnparsedParsedType,
    ParseFunction,
    InitialValue,
    ParsedType
  > {
    const {
      parse,
      initial,
      stringify = (value: ParsedType) => `${value}`,
      paramsToClearOnSetState,
    } = maybeConfig || {}
    const router = useRouting()

    const hasSetState = useRef(false)

    // if you're using this hook outside of a react-navigation screen,
    // it should still work. so we have a fallback to normal react state
    const nativeRoute = useContext(NavigationRouteContext)
    const nativeNavigation = useContext(NavigationContext)

    const [nativeStateFromReact, setNativeStateFromReact] = useState<
      ParsedType | InitialValue
    >(() => router.getParam(name as string) ?? (initial as InitialValue))

    const nativeStateFromRoute =
      // value from the params
      (nativeRoute?.params?.[name as string] as ParsedType) ??
      // otherwise, if it's nullish, and we haven't overriden it, use initial value
      (!hasSetState.current
        ? (initial as InitialValue)
        : (nativeRoute?.params?.[name as string] as ParsedType))

    const setNativeStateFromNavigation = useCallback(
      (value: ParsedType) => {
        hasSetState.current = true
        nativeNavigation?.setParams({
          [name]: value,
        })
      },
      [name, nativeNavigation]
    )

    const nativeState = nativeRoute
      ? nativeStateFromRoute
      : nativeStateFromReact
    const setNativeState = nativeRoute
      ? setNativeStateFromNavigation
      : setNativeStateFromReact

    const stableStringify = useStable(stringify)
    const stableParse = useStable(parse)
    const stableParamsToClear = useStable(paramsToClearOnSetState)

    const initialValue = useRef(initial)

    const setState = useCallback(
      (value: ParsedType) => {
        hasSetState.current = true
        const { pathname, query } = Router
        const newQuery = { ...query }
        if (value != null) {
          newQuery[name as string] = stableStringify.current(value)
        } else {
          delete newQuery[name as string]
        }

        if (stableParamsToClear.current) {
          for (const paramKey of stableParamsToClear.current) {
            delete newQuery[paramKey as string]
          }
        }

        const willChangeExistingParam =
          query[name as string] && newQuery[name as string]

        const action = willChangeExistingParam ? Router.replace : Router.push

        action(
          {
            pathname,
            query: newQuery,
          },
          undefined,
          {
            shallow: true,
          }
        )
      },
      [name, stableStringify, stableParamsToClear]
    )
    // @ts-expect-error internal field
    setState.__name = name
    // @ts-expect-error internal field
    setState.__stringify = stableStringify

    const webParam: string | undefined = router.getParam(name as string)

    const state = useMemo<ParsedType>(() => {
      let state: ParsedType
      if (webParam === undefined && !hasSetState.current) {
        state = initialValue.current as any
      } else if (stableParse.current) {
        state = stableParse.current?.(webParam)
      } else {
        state = webParam as any
      }
      return state
    }, [stableParse, webParam])

    if (Platform.OS !== 'web') {
      return [nativeState, setNativeState]
    }

    return [state, setState]
  }

  function useBatch() {
    const nativeNavigation = useContext(NavigationContext)

    const batch = useCallback(
      function batch(factory: BatchFactory | Array<[Function, unknown]>) {
        const runBatchForArray = (
          arrayOfFactories: Array<[Function, unknown]>
        ) => {
          if (Platform.OS === 'web') {
            if (typeof window == 'undefined') {
              console.error(
                'You called batch() for useParam() on the web, but there is no window. This is not supported, and indicates a bug in your code.'
              )
            }
            // on Web, we want to edit all the query params in one pass
            // we passed a secret __name field to the setState function

            const query = { ...Router.query }
            arrayOfFactories.forEach(([setState, value]) => {
              // @ts-expect-error internal field
              const name = setState.__name
              const stringify =
                // @ts-expect-error internal field
                setState?.__stringify?.current || ((value) => value)

              if (value != null) {
                query[name] = stringify(value)
              } else {
                delete query[name]
              }
            })
            Router.replace(
              {
                pathname: Router.pathname,
                query,
              },
              undefined,
              {
                shallow: true,
              }
            )
          } else {
            const params = {}
            // on native, we're using normal setState, so we can just call each one
            arrayOfFactories.forEach(([setState, value]) => {
              // @ts-expect-error
              const name = setState.__name
              params[name] = value
            })
            nativeNavigation?.setParams(params)
          }
        }
        if (Array.isArray(factory)) {
          runBatchForArray(factory)
        } else {
          // this option exists only so that people can pass type-safe functions
          const dispatch = (
            factory: Function,
            value: unknown
          ): [Function, unknown] => [factory, value]

          const arrayOfFactories = factory(dispatch)
          runBatchForArray(arrayOfFactories)
        }
      },
      [nativeNavigation]
    )

    return batch
  }

  return {
    useParam,
    useBatch,
  }
}

type Batcher = <Dispatch extends (...a: any[]) => any>(
  dispatch: Dispatch,
  props: Parameters<Dispatch>[0]
) => [Function, unknown]

type BatchFactory = (batcher: Batcher) => Array<[Function, unknown]>
