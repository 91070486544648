import { Text } from 'dripsy'
import React from 'react'

type Props = {
  children: React.ReactNode | string
  isFocused?: boolean
  hasErrored?: boolean
  isHovered?: boolean
  required?: boolean
  as?: React.ComponentType<any>
} & React.ComponentProps<typeof Text>

export default function Label({
  sx = {},
  isFocused,
  hasErrored,
  isHovered,
  required = false,
  children,
  as: As,
  ...props
}: Props) {
  let color = 'muted6'
  if (hasErrored) color = 'danger'
  else if (isFocused) color = 'primary'
  else if (isHovered) color = 'text'
  const Component = As || Text

  return (
    <Component
      sx={{
        color,
        fontSize: As ? undefined : 3,
        fontWeight: '600',
        mb: 2,
        transitionDuration: '0.25s',
        transitionProperty: 'color',
        justifyContent: 'flex-start',
        ...sx,
      }}
      {...props}
    >
      {children}
      {!!required && (
        <Text
          variant="inherit"
          sx={{
            // fontSize: 3,
            color: 'cyan',
            ml: 1,
            justifyContent: 'flex-start',
            height: '100%',
          }}
        >
          *
        </Text>
      )}
    </Component>
  )
}
