import React from 'react'
import GenericUserOnboardingInfo from '../generic-user-info'
import { useRoute } from '@react-navigation/native'
import useCreateUser from '@beatgig/auth-hooks/use-create-user'
import { SellerGenericInfoRoute } from '@beatgig/navigation/stacks/seller-onboarding-stack/types'
import { UserRole } from '@beatgig/api-services'
import { UserType } from '@beatgig/gql'

type Props = {}

export default function GenericSellerInfo() {
  const {
    params: { userType },
  } = useRoute<SellerGenericInfoRoute>()

  const { execute, error } = useCreateUser()
  // const { navigate: redirectToMyArtists } = useNavigateToMyArtists()

  return (
    <GenericUserOnboardingInfo
      networkError={error}
      userType={userType}
      onSubmit={async ({ signUpMessage, ...user }) => {
        console.log('[seller-onboarding] will execute', {
          signUpMessage,
          user,
          userType,
        })
        await execute({
          input: {
            metadata: { userType: userType as unknown as UserType },
            ...user,
            userRole: UserRole.SELLER,
          },
          signUpMessage,
        })
        // redirectToMyArtists()
        return false
      }}
      userRole={UserRole.SELLER}
    />
  )
}
