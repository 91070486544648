import { User } from '@beatgig/api/user'
import { Sentry } from '@beatgig/helpers/sentry'
import Analytics from '@beatgig/analytics/typez'
import { shouldUseLogRocket } from '@beatgig/vendors/log-rocket/enabled'
import { useCallback, useEffect } from 'react'
import { useMeQuery } from '@beatgig/gql'
import useAuth from './use-auth'

// dont call identify() more than necessary
let identifiedUid = ''

const useMyAccount = (
  config?: Pick<NonNullable<Parameters<typeof useMeQuery>[0]>, 'requestPolicy'>
) => {
  const auth = useAuth()
  const [me, revalidate] = useMeQuery({
    requestPolicy: config?.requestPolicy,
    pause: !auth.user,
  })

  const pullToRefresh = useCallback(() => {
    revalidate({
      requestPolicy: 'cache-and-network',
    })
  }, [revalidate])

  useEffect(
    function identify() {
      const user = me.data?.me
      if (User.hasOnboarded(user) && user.id !== identifiedUid) {
        identifiedUid = user.id

        User.addToAsyncStorage(user)
        Sentry.setUser({
          id: user.firebaseId,
          name: user.name ?? '',
          phone_number: user.phone_number ?? '',
          email: user.email ?? '',
        })
        Analytics.identify({
          uid: user.firebaseId,
          name: user.name ?? '',
          phone_number: user.phone_number ?? '',
          email: user.email ?? '',
          user_role: user.user_role,
          user_type: user.metadata.user_type,
        })

        if (shouldUseLogRocket) {
          import('logrocket')
            .then((rocket) => rocket.default)
            .then((LogRocket) => {
              LogRocket.identify(user.firebaseId, {
                name: user.name,
                email: user.email,
                phone_number: user.phone_number ?? '',
                user_role: user.user_role,
                user_type: user.metadata.user_type,
              })
            })
        }
      }
    },
    [me.data]
  )

  return {
    data: me.data?.me,
    isValidating: me.stale,
    revalidate,
    error: me.error,
    pullToRefresh,
    isLoadingInitial: Boolean(auth.user) && !me.data,
    isPullingToRefresh: false, // FIXME stale flickers here for some reason
  }
}

export default useMyAccount
