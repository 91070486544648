import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { View, useSx } from 'dripsy'
import { FlatList, Platform, Keyboard, StyleSheet } from 'react-native'
import { ScrollView, useAnchors } from '@nandorojo/anchor'
import type { TextInput } from 'react-native'
import Input from '../input'
import type { Sx as SxStyleProp } from 'dripsy'
import AutoCompleteItem from './auto-complete-item'
import { pickChild } from '../../helpers/collections'
import AutoCompleteSearching from './auto-complete-searching'
import AutoCompleteEmpty from './auto-complete-empty'
import useCurrentState from '../../hooks/use-current-state'
import AutoCompleteDropdown from './auto-complete-dropdown'
import { AutoCompleteContext } from './auto-complete-context'
import useStable from '../../hooks/use-stable'
import { AutoCompleteOption } from './types'
import Press from '@beatgig/components/press'
// import { BottomSheetFlatList } from '@gorhom/bottom-sheet'
import Combobox from './combo-box'
import useConsoleLog from '@beatgig/hooks/use-log'
import { BottomSheetFlatList } from './list'

type BaseOption = AutoCompleteOption

type SelectedInputProps = 'value' | 'onChangeText' | 'editable' | 'placeholder'

type BaseOptionOrNode = BaseOption

type FilterFunction<Option> = (
  data: Option,
  index: number,
  value: string
) => boolean

type Props<Option extends BaseOptionOrNode> = {
  options?: Option[]
  sx?: SxStyleProp
  dropdownSx?: SxStyleProp
  searching?: boolean
  children?: React.ReactNode
  defaultValue?: string
  onSearch?: (value: string) => void
  onSelect?: (value: string) => void
  /**
   * @default true
   */
  hideOptionsWhileSearching?: boolean
  /**
   * @default true
   */
  showEmpty?: boolean
  keyExtractor: (item: Option, index: number) => string
  /**
   * If `true`, then you can type a value other than those in the `options` array.
   * @default true
   */
  allowsCustomValue?: boolean
  CustomInput?: React.ComponentType<
    Pick<React.ComponentProps<typeof Input>, SelectedInputProps> & {}
  >
  onFocus?: () => void
  onBlur?: () => void
  /**
   * Optional: a custom funtion to filter through items.
   *
   * If set to `false` (or unset), then there will be no filtering.
   *
   * If set to `true`, it will run a custom function to check if the label or value matches the string.
   *
   * If set to a pure function, it must return a boolean indicating whether or not the item should be visible.
   */
  filter?: boolean | FilterFunction<Option>
  inputProps?: {} & Omit<React.ComponentProps<typeof Input>, SelectedInputProps>
  hasErrored?: boolean
  /**
   * If `false`, pressing an item from the dropdown will not set the text state to match that value.
   *
   * If `label`, it will set the `label` as the text value when you press.
   *
   * Default `true`
   */
  shouldSetControlledStateOnPress?: boolean | 'label'
  /**
   * @default false
   *
   * If you want blurring the input to empty the text if you haven't selected a value, make `true`.
   *
   * This is useful if you are requiring values, and want it to be clear it isn't set. Useful with `allowsCustomValue: false`.
   */
  shouldClearInputOnEmptyBlur?: boolean
  /**
   * Called when the input is fully cleared. Useful with `shouldClearInputOnEmptyBlur`
   */
  onClear?: (value: string) => void
  /**
   * Called once when the scroll position gets within onEndReachedThreshold of the rendered content.
   */
  onEndReached?: React.ComponentProps<typeof FlatList>['onEndReached']
  /**
   * The alignment of the text.
   *
   * By default, aligns `left` if there is no `subtitle`, and `center` otherwise.
   */
  align?: 'left' | 'center'
  // disabled?: boolean
} & Pick<React.ComponentProps<typeof Input>, SelectedInputProps> &
  Pick<React.ComponentProps<typeof Combobox>, 'onPressContinue'>

const Separator = <View sx={{ height: 1, bg: 'border' }} />
export default function AutoComplete<Option extends BaseOptionOrNode>(
  props: Props<Option>
) {
  const {
    children,
    sx,
    options: _options,
    searching = false,
    defaultValue,
    onSearch,
    onSelect: _onSelect,
    onChangeText,
    value,
    hideOptionsWhileSearching = false,
    showEmpty = true,
    keyExtractor,
    dropdownSx,
    editable = true,
    allowsCustomValue = true,
    CustomInput,
    filter: _filter,
    placeholder,
    hasErrored,
    inputProps = {},
    shouldSetControlledStateOnPress = true,
    onFocus,
    onBlur,
    shouldClearInputOnEmptyBlur = false,
    onClear: _onClear,
    onEndReached: _onEndReached,
    align,
    onPressContinue,
    // disabled = false
  } = props

  const [state, setState, stateRef] = useCurrentState(defaultValue ?? '')
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const [selectedValue, setSelectedValue] = useState(defaultValue ?? '')

  const [_focusedIndex, setFocusedIndex] = useState(0)

  const focusedIndex = dropdownVisible
    ? Platform.select({ web: _focusedIndex, default: -1 })
    : 0

  const [, searchChild] = pickChild(children, AutoCompleteSearching)
  const [, emptyChild] = pickChild(children, AutoCompleteEmpty)

  const getKey = useStable(keyExtractor)

  const filter = useStable(_filter)

  const makeSx = useSx()

  const options = useMemo(() => {
    if (!filter.current) return _options

    if (filter.current === true) {
      return _options?.filter((option, index) => {
        const includes = (text?: string) =>
          text?.toLowerCase().trim().includes?.(state?.toLowerCase().trim())

        return (
          includes((option as BaseOption)?.value) ||
          includes((option as BaseOption)?.label)
        )
      })
    }
    return _options?.filter((option, index) =>
      (filter.current as FilterFunction<Option>)(option, index, state)
    )
  }, [_options, filter, state])

  const hasSelectedItems = useMemo(() => {
    if (Platform.OS === 'web') {
      return false
    }
    return !!_options?.some((option) => {
      return (option as BaseOption).selected
    })
  }, [_options])

  const selectedLabel = (options?.find((option) => {
    return option.value === selectedValue
  }) as BaseOption | undefined)?.label

  const onEndReached = useStable(_onEndReached)

  const onSelect = useStable(_onSelect)

  const webScrollAnchors = useAnchors()

  const autoCompleteItems = useMemo(() => {
    const hasSearchChild = searchChild && React.Children.count(searchChild) > 0
    const hasEmptyChild = emptyChild && React.Children.count(emptyChild) > 0

    if (searching) {
      if (hideOptionsWhileSearching || !options?.length) {
        return hasSearchChild ? (
          searchChild
        ) : (
          <AutoCompleteSearching>{'Searching...'}</AutoCompleteSearching>
        )
      }
    }
    if (options?.length === 0) {
      if (!state) return null
      if (hasEmptyChild) return emptyChild
      if (showEmpty)
        return (
          <AutoCompleteEmpty allowsCustomValue={allowsCustomValue}>
            {'No results...'}
          </AutoCompleteEmpty>
        )
      return null
    }

    const renderItem = ({ item, index }: { item: Option; index: number }) => {
      const isLast = index + 1 === options?.length
      const isFirst = !index
      const { label, ...itemProps } = item

      return (
        <AutoCompleteItem
          label={label}
          isFirst={isFirst}
          isLast={isLast}
          key={item.value}
          showSeparator={!isFirst}
          isText
          align={align}
          isFocused={focusedIndex === index}
          {...itemProps}
        >
          {label}
        </AutoCompleteItem>
      )
    }

    if (Platform.OS === 'web') {
      return (
        <ScrollView anchors={webScrollAnchors}>
          {options?.map((option, index) => {
            return renderItem({ item: option, index })
          })}
        </ScrollView>
      )
    }

    return (
      <BottomSheetFlatList
        data={options}
        keyExtractor={getKey.current}
        onEndReached={onEndReached.current}
        onEndReachedThreshold={2}
        keyboardShouldPersistTaps="handled"
        nestedScrollEnabled
        keyboardDismissMode="none"
        contentContainerStyle={Platform.select({
          native: makeSx({
            pb: 6,
          }),
          default: undefined,
        })}
        maxToRenderPerBatch={Platform.select({
          default: undefined,
          native: 10,
        })}
        renderItem={renderItem}
      />
    )
  }, [
    searchChild,
    emptyChild,
    searching,
    options,
    getKey,
    onEndReached,
    makeSx,
    hideOptionsWhileSearching,
    state,
    showEmpty,
    allowsCustomValue,
    align,
    focusedIndex,
    webScrollAnchors,
  ])

  const onChooseValue = useCallback<
    Required<AutoCompleteContext>['updateValue']
  >(
    ({ value, label }) => {
      if (!editable) return
      onSelect.current?.(value)
      if (Platform.OS !== 'web') {
        Keyboard.dismiss()
      }
      if (shouldSetControlledStateOnPress) {
        setSelectedValue(value)

        if (shouldSetControlledStateOnPress === 'label') {
          setState(label)
        } else {
          setState(value)
        }
      }
    },
    [editable, onSelect, setState, shouldSetControlledStateOnPress]
  )

  const onChangeStable = useStable(onChangeText)

  useEffect(() => {
    onChangeStable.current?.(state)
  }, [state, onChangeStable])

  // if the item is controlled, use that
  useEffect(() => {
    setState((state) => {
      if (value !== state && value != undefined) {
        return value
      }
      return state
    })
  }, [setState, value])

  const onClear = useStable(_onClear)
  const maybeResetInputOnBlur = () => {
    if (allowsCustomValue) return
    if (!state) {
      return
    }
    console.log('[maybe reset input]', { shouldClearInputOnEmptyBlur })
    if (shouldClearInputOnEmptyBlur) {
      setState((state) => {
        let nextState = state
        // if we've typed the text of the label when we blur
        // and we are controlling the label, not value
        // oof lol
        if (shouldSetControlledStateOnPress === 'label') {
          if (state !== selectedLabel) nextState = ''
          // ok, we care about the value instead of label
          // so now check if that should get cleared
        } else if (state !== selectedValue) {
          // } else if (shouldSetControlledStateOnPress) {
          nextState = ''
        }
        // tell the parent that we've cleared if needed
        if (!nextState) {
          onClear.current?.('')
        }
        return nextState
        // return state
      })
      // onClear.current?.('')
      return
    }
    // setState((state) => {
    //   if (state !== selectedValue) return selectedValue
    //   return state
    // })
  }

  const onFocusChange = (next: boolean) => () => {
    if (next) {
      setDropdownVisible(next)
      onSearch?.(stateRef.current)
      onFocus?.()
    } else {
      setTimeout(() => {
        setDropdownVisible(false)
        maybeResetInputOnBlur()
        onBlur?.()
      }, Platform.select({ web: 150, default: 30 }))
    }
  }

  const context = useMemo<AutoCompleteContext>(
    () => ({
      value: state,
      updateValue: onChooseValue,
      visible: dropdownVisible,
      updateVisible: setDropdownVisible,
      updateTextState: setState,
    }),
    [state, onChooseValue, dropdownVisible, setState]
  )

  const InputComponent = CustomInput || Input

  const handleClear = () => {}

  const openBottomSheetOnNative = Platform.select({
    web: undefined,
    default: () => {
      Keyboard.dismiss()
      setDropdownVisible(true)
    },
  })

  useConsoleLog('[auto-complete]', {
    selectedValue,
    hasSelectedItems,
  })

  const focusedValue =
    focusedIndex != null ? options?.[focusedIndex]?.value : null

  useEffect(
    function scrollToFocusedItem() {
      if (focusedValue && Platform.OS == 'web') {
        webScrollAnchors.current?.scrollTo(focusedValue, {
          offset: 0,
        })
      }
    },
    [focusedValue, webScrollAnchors]
  )

  const previousState = useRef(state)
  useEffect(
    function onValueChange() {
      if (state === previousState.current) return
      previousState.current = state
      setFocusedIndex(0)
    },
    [state]
  )

  const inputRef = useRef<TextInput>(null)

  return (
    <AutoCompleteContext.Provider value={context}>
      <View sx={{ ...sx, zIndex: 1000 }}>
        <InputComponent
          {...inputProps}
          ref={inputRef}
          onKeyPress={(e) => {
            if (e.nativeEvent.key === 'ArrowDown') {
              setFocusedIndex((focusedIndex) => {
                const index = Math.min(
                  options?.length ? options.length - 1 : 0,
                  focusedIndex + 1
                )

                return index
              })
            } else if (e.nativeEvent.key === 'ArrowUp') {
              setFocusedIndex((focusedIndex) => {
                const nextIndex = Math.max(0, focusedIndex - 1)

                return nextIndex
              })
            } else if (e.nativeEvent.key === 'Enter') {
              const item = options?.[focusedIndex]
              if (item) {
                e.preventDefault()
                onChooseValue({
                  value: item.value,
                  label: item.label,
                })
                // instead of setting visible to false,
                // let's blur it altogether
                // because otherwise it gets hidden but no longer searches
                // setDropdownVisible(false)
                inputRef.current?.blur()
              }
            }
          }}
          hasErrored={hasErrored}
          onFocus={onFocusChange(true)}
          onBlur={onFocusChange(false)}
          onClear={onClear.current}
          value={state}
          onChangeText={setState}
          // on native, we don't type here
          editable={Platform.OS === 'web' && editable}
          clearable
          placeholder={placeholder}
          loading={searching}
        />
        {Platform.OS !== 'web' && (
          <Press
            onPress={openBottomSheetOnNative}
            style={StyleSheet.absoluteFillObject}
            sx={{ zIndex: 1, right: 40 }}
          />
        )}
        {Platform.select({
          web: (
            <AutoCompleteDropdown
              sx={{ zIndex: 1000, ...dropdownSx }}
              visible={dropdownVisible}
            >
              {autoCompleteItems}
            </AutoCompleteDropdown>
          ),
          default: (
            <Combobox
              showButton={!!(selectedValue && state) || hasSelectedItems}
              searching={searching}
              onClear={onClear.current}
              visible={dropdownVisible}
              hasErrored={hasErrored}
              onPressContinue={onPressContinue}
            >
              {autoCompleteItems}
            </Combobox>
          ),
        })}
      </View>
    </AutoCompleteContext.Provider>
  )
}

AutoComplete.Item = AutoCompleteItem
AutoComplete.Searching = AutoCompleteSearching
AutoComplete.Empty = AutoCompleteEmpty
