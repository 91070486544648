import React, { ComponentProps } from 'react'
import Input from './index'
import { TextInputMask, TextInputMaskProps } from 'react-native-masked-text'

type Props = Omit<
  TextInputMaskProps &
    Omit<ComponentProps<typeof Input>, keyof TextInputMaskProps>,
  'customTextInputProps'
> & {
  customTextInputProps?: ComponentProps<typeof Input>
}

const MaskedInput = React.forwardRef<TextInputMask, Props>(function MaskedInput(
  props,
  ref
) {
  const {
    sx = {},
    hasErrored,
    isHovered,
    isFocused,
    borderColor,
    containerSx,
    label,
    labelRight,
    onChangeText,
    ...maskProps
  } = props

  return (
    <TextInputMask
      ref={ref as any}
      customTextInput={Input}
      {...maskProps}
      includeRawValueInChangeText
      onChangeText={(_, raw: string) => onChangeText?.(raw)}
      customTextInputProps={{
        sx,
        hasErrored,
        isHovered,
        isFocused,
        borderColor,
        containerSx,
        label,
        labelRight,
        ...maskProps.customTextInputProps,
      }}
    />
  )
})

export default MaskedInput
