import { BandConfigurationOption } from '@beatgig/gql'

export type SearchableBandConfigurationOption = Exclude<
  BandConfigurationOption,
  BandConfigurationOption.UNSPECIFIED
>

const DefaultBandConfigurations: Array<SearchableBandConfigurationOption> = []

export default DefaultBandConfigurations
