import React from 'react'
import { View, remToPixels } from 'dripsy'
import { DropdownProps } from './types'

export default function AutoCompleteDropdown(props: DropdownProps) {
  const { children, visible } = props

  const isShown = !!visible && !!children

  return (
    <View
      // state={animation}
      // transition={transition}
      pointerEvents={isShown ? undefined : 'none'}
      sx={{
        opacity: isShown ? 1 : 0,
        transform: [
          {
            translateY: isShown ? 0 : -5,
          },
        ],
        willChange: 'opacity, transform',
        transitionProperty: 'opacity, transform',
        transitionDuration: '200ms',
      }}
    >
      <View
        style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
        }}
      >
        <View
          sx={{
            mt: 2,
            zIndex: 10,
            maxHeight: remToPixels('15rem'),
            borderColor: 'border',
            borderWidth: 1,
            borderRadius: 3,
            bg: 'background',
            overflow: 'hidden',
          }}
        >
          {children}
        </View>
      </View>
    </View>
  )
}
