import { User } from '@beatgig/api/user'
import useMyAccount from './use-my-account'
import { useRef, useEffect } from 'react'
import useAuthPopupState from './use-auth-popup-state'
import useOpenAuthPopup from './use-open-auth-popup'
import { useBuyerPreferences } from '@beatgig/features/buyer-preferences/screen/list/use-preferences'
import { useRouter } from 'next/router'

/**
 * Call this on the home page, for instance. Use it sparingly, if at all.
 */
export default function useAutomaticallyOpenOnboardingPopup() {
  const open = useOpenAuthPopup()
  const hasOpened = useRef(false)
  const { data } = useMyAccount()

  const { pathname = '' } = useRouter() || {}

  const disabled = pathname?.startsWith('/embed')

  const [isOpen] = useAuthPopupState()

  const { preferences } = useBuyerPreferences()

  useEffect(
    function markOpenedBasedOnState() {
      if (isOpen) {
        hasOpened.current = true
      }
    },
    [isOpen]
  )

  const needsToOnboard = User.needsToOnboard(data)

  useEffect(
    function automaticallyOpenIfNeedsOnboarding() {
      if (!hasOpened.current && needsToOnboard && !disabled) {
        hasOpened.current = true
        open()
      }
    },
    [needsToOnboard, open, disabled]
  )

  const needsToCompleteBuyerPreferences = Boolean(
    preferences?.some((preference) => !preference.response)
  )
  useEffect(
    function automaticallyOpenIfNeedsBuyerPreferences() {
      if (!hasOpened.current && !disabled) {
        const featureReleaseDate = 1661728566050
        const userCreatedAt = User.hasOnboarded(data)
          ? new Date(data.created_at)
          : 0
        const wasCreatedAfterFeatureReleaseDate =
          userCreatedAt > featureReleaseDate

        if (
          needsToCompleteBuyerPreferences &&
          wasCreatedAfterFeatureReleaseDate &&
          !User.isApproved(data)
        ) {
          hasOpened.current = true
          open()
        }
      }
    },
    [data, disabled, needsToCompleteBuyerPreferences, open]
  )
}
