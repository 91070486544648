import { View } from 'dripsy'
import React from 'react'

type Props = {
  children: React.ReactNode
} & React.ComponentProps<typeof View>

export default function Card(props: Props) {
  const { children, sx = {}, ...viewProps } = props

  return (
    <View
      {...viewProps}
      sx={{ borderRadius: 3, bg: 'muted', overflow: 'hidden', p: [3], ...sx }}
    >
      {children}
    </View>
  )
}
