import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { graphql } from '@beatgig/gql/headless'
import useGet from './use-get'

export function useAdminTypesenseKey() {
  const { data: me } = useMyAccount()

  return useGet(
    User.isAdmin(me) ? 'typesense-admin-key' : null,
    async () => {
      const key = (await graphql.AdminSearchKey()).adminSearchKey
      return key
    },
    {
      refreshInterval: 0,
      dedupingInterval: 60 * 1000,
    }
  )
}
