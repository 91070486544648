import { Text, View, useDripsyTheme } from 'dripsy'
import React from 'react'
import Ionicons from '@beatgig/design/components/ionicons'

type Props = {
  children: React.ReactNode
  sx?: React.ComponentProps<typeof View>['sx']
}

export default function ErrorText(props: Props) {
  const { children, sx } = props

  const { colors } = useDripsyTheme().theme

  return (
    <View
      sx={{
        // p: 2,
        mt: 2,
        // borderWidth: 1,
        // borderColor: 'danger',
        // borderRadius: 3,
        flexDirection: 'row',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Ionicons color="error" name="alert-circle-outline" size={25} />
      <View sx={{ flex: 1, ml: 2 }}>
        <Text sx={{ color: 'danger' }}>{children}</Text>
      </View>
    </View>
  )
}
