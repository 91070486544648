import React from 'react'
import Icon from '@expo/vector-icons/build/Ionicons'
import { styled, useResponsiveValue } from 'dripsy'
import useTheme from '@beatgig/theme/use-theme'

const StyledIcon = styled(Icon)({})

type Color = (string & {}) | keyof ReturnType<typeof useTheme>['colors']
type Name = React.ComponentProps<typeof StyledIcon>['name']

type Icon =
  | {
      name: Name
      size?: number | number[]
      color?: Color
    }
  | React.ReactElement
  | Name

type BaseProps = {
  size?: number | number[]
  color?: Color
  name: Name
}
export type IconProps = {
  icon: Icon
}

export type IconsBaseProps = BaseProps

export type AllIconProps = (IconProps | BaseProps) & {
  sx?: React.ComponentProps<typeof StyledIcon>['sx']
  selectable?: boolean
}

function isIconProps(props: AllIconProps): props is IconProps {
  return !!(props as IconProps).icon
}

export default function Ionicons(preProps: AllIconProps) {
  const props = { ...preProps }
  const { colors } = useTheme()

  let icon: Icon | undefined
  let color: Color = colors.text

  if (isIconProps(props)) {
    icon = props.icon
  } else {
    icon = {
      name: props.name,
      color: props.color,
      size: props.size,
    }
  }

  let name: React.ComponentProps<typeof StyledIcon>['name'] | null = null
  let size: number | number[] = 22
  if (!React.isValidElement(icon)) {
    // double logic to appease TS
    if (typeof icon === 'string') {
      name = icon as React.ComponentProps<typeof StyledIcon>['name']
      const propsColor = (props as BaseProps).color
      const propsSize = (props as BaseProps).size
      if (propsColor) {
        color = colors?.[propsColor] ?? propsColor
      }
      if (propsSize) {
        size = propsSize
      }
    } else if (icon?.name) {
      name = icon.name
      if (icon.size) size = icon.size
      if (icon.color) {
        color = colors?.[icon.color] ?? icon.color
      }
    }
  }

  const responsiveSize = useResponsiveValue(Array.isArray(size) ? size : [size])

  if (React.isValidElement(icon)) {
    return icon
  }
  // this exists for conditional props
  if (typeof icon === 'boolean') return null

  if (!name) return null

  return (
    <StyledIcon
      selectable={false}
      {...props}
      color={color}
      size={responsiveSize}
      name={name}
      sx={props.sx}
    />
  )
}
