import Gradient from '@beatgig/design/components/gradient'
import { Box } from '@beatgig/design/components/box'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import SubmitButton from '@beatgig/forms/fields/submit'
import { Keyboard } from 'react-native'
import { useAnimationState } from 'moti'
import useTheme from '@beatgig/theme/use-theme'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import { MotiView } from 'moti'

const Submit: typeof SubmitButton = (props) => {
  return <SubmitButton {...props} />
}

function Footer({ children }) {
  const insets = useSafeAreaInsets()

  const { space } = useTheme()

  const ctaAnimation = useAnimationState(
    {
      keyboardOpen: {
        paddingBottom: space.$3,
      },
      keyboardClosed: {
        paddingBottom: insets.bottom || space.$3,
      },
    },
    {
      from: 'keyboardClosed',
    }
  )

  useEffect(
    function padCtaOnKeyboardOpen() {
      if (Platform.OS != 'web') {
        const onOpen = () => {
          ctaAnimation.transitionTo('keyboardOpen')
        }
        const onHide = () => {
          ctaAnimation.transitionTo('keyboardClosed')
        }
        const keyboardshow = Keyboard.addListener('keyboardWillShow', onOpen)
        const keyboardhide = Keyboard.addListener('keyboardWillHide', onHide)

        return () => {
          keyboardshow.remove()
          keyboardhide.remove()
        }
      }
    },
    [ctaAnimation]
  )

  return (
    <Box
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      pt="$3"
      px="$3"
      pb={0}
    >
      <Gradient
        stretch
        sx={{ top: -40, bottom: -20 }}
        colors={['background', 'transparent'].reverse()}
        pointerEvents="none"
      />
      <MotiView
        transition={{
          type: 'timing',
          duration: 120,
        }}
        state={ctaAnimation}
      >
        <Box alignSelf="center" width="100%" maxWidth={450}>
          {children}
        </Box>
      </MotiView>
    </Box>
  )
}

export const OnboardingLayout = {
  Footer,
  Submit,
}
