import React from 'react'

import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import { shouldUseLogRocket } from '@beatgig/vendors/log-rocket/enabled'
import vercelConstants from '@beatgig/constants/vercel-constants'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const integrations: RewriteFrames[] = []
  if (
    process.env.NEXT_IS_SERVER === 'true' &&
    process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
  ) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace(
            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR as string,
            'app:///'
          )
          frame.filename = frame.filename?.replace('.next', '_next')
          return frame
        },
      })
    )
  }

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    environment: process.env.NEXT_PUBLIC_BACKEND_ENV,
    async beforeSend(event) {
      const ignoredMessages = [
        'cancelled',
        'NetworkError when attempting to fetch resource',
        'canceled',
        'NetworkError',
        "Cannot read properties of null (reading 'style')", // stupid error from firebase captcha
      ]
      const message = (
        event?.extra as
          | undefined
          | {
              error?: { message?: string }
            }
      )?.error?.message?.toLowerCase()

      if (message && ignoredMessages.includes(message)) {
        return null
      }
      if (typeof window === 'undefined') {
        return event
      }
      if (shouldUseLogRocket) {
        const LogRocket = (await import('logrocket')).default
        await new Promise<void>((resolve) =>
          LogRocket.getSessionURL((url) => {
            event.extra = event.extra || {}
            if (url !== null) {
              event.extra['LogRocket'] = url
            }
            resolve()
          })
        )
      }
      event.extra = {
        ...event.extra,
        ...vercelConstants(),
      }
      return event
    },
    ignoreErrors: [
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: cancelled',
      'TypeError: Cancelled',
      'message: cancelled',
      'cancelled',
    ],
  })
}

type Props = {
  children: React.ReactNode
}

export default function SentryProvider(props: Props) {
  const { children } = props

  return <>{children}</>
}
