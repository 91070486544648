import { IconsBaseProps } from '@beatgig/design/components/ionicons'
import { pagesPath } from '@beatgig/features/routing/pathpida/$path'
import { AuthedRoutes } from '../../routes/authed-routes'

// export class AdminRoutes {
//   static readonly Shows = `/`
//   static readonly BookingUpdates = AuthedRoutes.bookings().web.path
//   static readonly Artists = `/artists`
//   static readonly Applicants = `/applicants`
//   static readonly BuyerApplicants = `${AdminRoutes.Applicants}/buyers`
//   static readonly SellerApplicants = `${AdminRoutes.Applicants}/sellers`
//   static readonly Admins = `${AdminRoutes.Applicants}/admins`
//   static readonly RecommendedBookings = '/recs'
//   static readonly Calendar = '/recs'
// }

const Applicants = '/applicants'

export const AdminRoutes = {
  Shows: '/',
  BookingUpdates: AuthedRoutes.bookings().web.path,
  Artists: '/artists',
  Applicants: '/applicants',
  BuyerApplicants: `${Applicants}/buyers`,
  SellerApplicants: `${Applicants}/sellers`,
  Admins: `${Applicants}/admins`,
  RecommendedBookings: '/recs',
  Calendar: '/calendar',
  Venues: '/venues',
  BookingRequests: '/booking-requests',
  BookingRequestsAsSeller: AuthedRoutes.bookingRequests().web.path,
  Promo: AuthedRoutes.promoStudio().web.path,
  PromoArtist: '/dashboard/artist-promo',
  Invoices: AuthedRoutes.invoices().web.path,
  VenueTheme: '/dashboard/venue-theme',
  ArtistAnalytics: pagesPath.dashboard.artists.analytics.$url().pathname,
} as const

type MenuItem = {
  label: string
  path: string
  isActive: (asPath: string) => boolean
  dropdownItems?: Omit<MenuItem, 'dropdownItems'>[]
  iconName: IconsBaseProps['name']
}

const isActive: MenuItem['isActive'] = function (asPath) {
  return (
    asPath.startsWith(this.path as string) ||
    asPath.startsWith(('/dashboard' + this.path) as string)
  )
}

export function useAdminTabs() {
  const tabs: MenuItem[] = [
    {
      label: 'Shows',
      path: '/',
      isActive: function (asPath) {
        return asPath === this.path
      },
      iconName: 'tv-outline',
    },
    {
      label: 'Booking Updates',
      path: '/dashboard/bookings',
      isActive,
      iconName: 'book-outline',
    },
    {
      label: 'Cancellation Requests',
      path: '/cancellation-requests',
      isActive,
      iconName: 'sad-outline',
    },
    {
      label: 'Artist Confirmations',
      path: '/confirmations',
      isActive,
      iconName: 'checkmark-outline',
    },
    {
      label: 'Artist Updates',
      path: AdminRoutes.Artists,
      isActive(asPath) {
        return asPath.startsWith(this.path) || asPath.startsWith('/@')
      },
      iconName: 'musical-note-outline',
    },
    {
      label: 'Search Artists',
      path: '/search',
      isActive,
      iconName: 'search',
    },
    {
      label: 'Applicants',
      path: '/applicants',
      isActive,
      iconName: 'people-outline',
      dropdownItems: [
        {
          label: 'Buyers',
          path: AdminRoutes.BuyerApplicants,
          isActive,
          iconName: 'cash-outline',
        },
        {
          label: 'Sellers',
          path: AdminRoutes.SellerApplicants,
          isActive,
          iconName: 'person-add-outline',
        },
        {
          label: 'Admins',
          path: AdminRoutes.Admins,
          isActive,
          iconName: 'lock-closed-outline',
        },
      ],
    },
    // {
    //   label: 'Recommended Bookings',
    //   path: AdminRoutes.RecommendedBookings,
    //   isActive,
    //   iconName: 'color-wand-outline',
    // },
    {
      label: 'Booking Requests',
      path: AdminRoutes.BookingRequests,
      isActive(path) {
        return (
          path.startsWith(this.path) ||
          path.startsWith(AdminRoutes.BookingRequestsAsSeller)
        )
      },
      iconName: 'flash-outline',
      dropdownItems: [
        {
          label: 'All',
          path: AdminRoutes.BookingRequests,
          isActive,
          iconName: 'list',
        },
        {
          label: 'By Artist',
          path: AdminRoutes.BookingRequestsAsSeller,
          isActive,
          iconName: 'musical-note',
        },
      ],
    },
    {
      label: 'Venues',
      path: AdminRoutes.Venues,
      isActive,
      iconName: 'business-outline',
    },
    {
      label: 'Calendar',
      path: AdminRoutes.Calendar,
      isActive,
      iconName: 'calendar-outline',
    },
    {
      label: 'Invoices',
      path: AdminRoutes.Invoices,
      isActive,
      iconName: 'logo-usd',
    },
    {
      label: 'Promo',
      path: AdminRoutes.Promo,
      isActive(asPath) {
        return (
          asPath.startsWith(this.path) ||
          asPath.startsWith(AdminRoutes.PromoArtist)
        )
      },
      iconName: 'color-wand-outline',
      dropdownItems: [
        {
          label: 'View As Buyer',
          isActive,
          path: AdminRoutes.Promo,
          iconName: 'business-outline',
        },
        {
          label: 'View As Seller',
          isActive,
          path: AdminRoutes.PromoArtist,
          iconName: 'musical-note',
        },
      ],
    },
    {
      label: 'Venue Embed',
      path: AdminRoutes.VenueTheme,
      isActive,
      iconName: 'link-outline',
    },
    {
      label: 'Artist Analytics',
      path: AdminRoutes.ArtistAnalytics,
      isActive,
      iconName: 'bar-chart-outline',
    },
  ]
  return {
    tabs,
  }
}
