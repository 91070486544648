import { Platform } from 'react-native'
import { APP_VERSION } from './app-version'
import Constants from 'expo-constants'

type VercelConstants = {
  // isVercel?: string
  vercelUrl?: string
  vercelGitCommitRef?: string
  vercelGitCommitSha?: string
  vercelGitCommitAuthorName?: string
  vercelGitCommitMessage?: string
  version: string
  runtimeVersion?: string
  releaseId?: string
  isDev?: boolean
  platform: string
  appName?: string
  bundleUrl?: string
  scheme?: string
  slug?: string
}

export default function vercelConstants(): VercelConstants {
  if (Platform.OS !== 'web') {
    return {
      version: APP_VERSION,
      releaseId: Constants.manifest?.releaseId,
      runtimeVersion: Constants.manifest2?.runtimeVersion,
      isDev: __DEV__,
      platform: Platform.OS,
      appName: Constants.manifest?.name,
      bundleUrl: Constants.manifest?.bundleUrl,
      scheme: Constants.manifest?.scheme,
      slug: Constants.manifest?.slug,
    }
  }
  if (process.env.NODE_ENV !== 'production') {
    return {
      version: APP_VERSION,
      platform: Platform.OS,
    }
  }

  return {
    // isVercel: process.env.NEXT_PUBLIC_VERCEL,
    vercelUrl: process.env.NEXT_PUBLIC_VERCEL_URL,
    vercelGitCommitRef: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
    vercelGitCommitSha: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    vercelGitCommitAuthorName:
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME,
    vercelGitCommitMessage: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE,
    version: APP_VERSION,
    platform: Platform.OS,
    isDev: __DEV__,
  }
}
