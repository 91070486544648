import { graphql } from '@beatgig/gql/headless'
import Constants from 'expo-constants'
import * as Notifications from 'expo-notifications'
import { Platform } from 'react-native'

const { deviceName = 'missing-device-name' } = Constants

export class NotificationApi {
  static setExpoDevice({ notificationToken }: { notificationToken: string }) {
    return graphql.SetExpoDevice({
      deviceName,
      token: notificationToken,
    })
  }
  static signOut() {
    if (Platform.OS != 'web') {
      NotificationApi.unsetExpoDevice()
      Notifications.setBadgeCountAsync(0)
    }
  }
  static unsetExpoDevice = () => {
    if (Platform.OS != 'web') {
      return graphql.UnsetExpoDevice({
        deviceName,
      })
    }
  }
  static get viewNotification() {
    return graphql.ViewNotification
  }

  static async getToken(shouldCheckPermissions = false) {
    if (Platform.OS === 'web') return

    if (shouldCheckPermissions) {
      const isPermitted = await NotificationApi.allowsNotificationsAsync()

      if (!isPermitted) return
    }

    const slug = Constants.manifest?.slug || process.env.EXPO_APP_SLUG
    const owner = Constants.manifest?.owner || process.env.EXPO_APP_OWNER
    return await Notifications.getExpoPushTokenAsync({
      // https://docs.expo.io/versions/latest/sdk/notifications/#getexpopushtokenasyncoptions-expotokenoptions-expopushtoken
      experienceId: `@${owner}/${slug}`,
    }).catch((e) => console.error('[notifications-api] get-token', e))
  }

  static async sendToken({ token, type }: { token: string; type: string }) {
    console.log('[notification-token!]', { token, type })
    if (Platform.OS !== 'web' && typeof token === 'string' && type === 'expo') {
      console.log('[notifications][send-token]', token)
      NotificationApi.setExpoDevice({
        notificationToken: token,
      })
    }
  }

  static async allowsNotificationsAsync() {
    const settings = await Notifications.getPermissionsAsync()
    console.log('[notifications-api] allows', settings.granted)
    return (
      settings.granted ||
      settings.ios?.status === Notifications.IosAuthorizationStatus.PROVISIONAL
    )
  }

  static async getAndSendToken({
    shouldCheckPermissions = false,
  }: { shouldCheckPermissions?: boolean } = {}) {
    if (Platform.OS === 'web') return

    console.log('[get-and-send-token] will get token')
    const system = await NotificationApi.getToken(shouldCheckPermissions)
    console.log('[get-and-send-token] got token', system)
    if (!system) return

    const { data, type } = system
    NotificationApi.sendToken({ token: data as string, type })
  }
}
