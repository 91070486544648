import { Text } from 'dripsy'
import React from 'react'
// const Subtitle = createThemedComponent(Text, {
//   defaultStyle: { color: 'mutedText', mb: 2 },
//   defaultVariant: 'body',
//   themeKey: 'text',
// })

export default function Subtitle({
  sx = {},
  mb = 2,
  ...props
}: React.ComponentProps<typeof Text> & { mb?: number }) {
  return (
    <Text
      {...props}
      sx={{
        color: 'mutedText',
        mb,
        ...sx,
      }}
    />
  )
}
