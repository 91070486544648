import React, { useCallback } from 'react'
import { View, Text } from 'dripsy'
import Ionicons, { IconsBaseProps } from '../ionicons'
import { StyleSheet, Platform } from 'react-native'
import type { Sx as SxStyleProp } from 'dripsy'
import Press from '@beatgig/components/press'
import { MotiView, useAnimationState } from 'moti'
import useTheme from '@beatgig/theme/use-theme'

type Props = {
  children: React.ReactNode
  icon?: boolean | 'hover'
  color?: boolean | string
  iconColor?: string
  underline?: boolean
  block?: boolean
  onPress?: () => void
  textSx?: SxStyleProp
  iconName?: IconsBaseProps['name']
  href?: string
  isText?: boolean
  iconSize?: number
  iconMargin?: number
  iconSide?: 'left' | 'right'
} & Omit<React.ComponentProps<typeof Press>, 'onPress'>

const Link = React.forwardRef(function Link(props: Props, outerRef) {
  const {
    iconSide = 'right',
    sx = {},
    children,
    color = false,
    iconName = 'open-outline',
    icon = !!props.iconName,
    textSx = {},
    block,
    onPress,
    disabled = !onPress && !icon,
    isText = true,
    iconColor: iconColorProp,
    iconSize = 20,
    iconMargin = 1,
    as: Container = Press,
    ...rest
  } = props

  const blockAnimationState = useAnimationState({
    from: {
      opacity: 0,
    },
    active: {
      opacity: 0.3,
    },
  })
  const iconAnimationState = useAnimationState(
    {
      from: {
        translateX: 0,
        opacity: 1,
      },
      active: {
        translateX: 0,
        opacity: 1,
      },
    },
    {
      from: icon === 'hover' ? 'from' : 'active',
    }
  )

  const updateInteractionState = useCallback(
    (active: boolean) => () => {
      blockAnimationState.transitionTo(active ? 'active' : 'from')
      if (icon === 'hover') {
        iconAnimationState.transitionTo(active ? 'active' : 'from')
      }
    },
    [blockAnimationState, iconAnimationState, icon]
  )

  const { colors } = useTheme()
  let mainColor: string
  if (typeof color === 'boolean') {
    mainColor = color ? 'primary' : 'text'
  } else {
    mainColor = color
  }
  const iconColor = iconColorProp
    ? colors?.[iconColorProp] ?? iconColorProp
    : colors?.[mainColor]

  const BlockBackground = (
    <MotiView
      state={blockAnimationState}
      style={[
        StyleSheet.absoluteFillObject,
        { backgroundColor: colors?.[mainColor] },
      ]}
      transition={{ type: 'timing', duration: 250 }}
    />
  )
  let blockStyle = {}
  if (block) {
    blockStyle = {
      px: 2,
      py: 2,
      borderRadius: 2,
      overflow: 'hidden',
    }
  }

  return (
    <Container
      ref={outerRef as any}
      {...rest}
      onPress={onPress}
      disabled={disabled}
      onPressIn={updateInteractionState(true)}
      onPressOut={updateInteractionState(false)}
      onHoverIn={updateInteractionState(true)}
      onHoverOut={updateInteractionState(false)}
    >
      <View
        sx={{
          alignItems: 'center',
          flexDirection: iconSide === 'right' ? 'row' : 'row-reverse',
          // TODO change to moti
          ...blockStyle,
          ...sx,
        }}
      >
        {!!block && BlockBackground}
        {isText ? (
          <Text
            selectable={false}
            sx={{ color: mainColor, fontSize: 3, ...textSx }}
          >
            {children}
          </Text>
        ) : (
          children
        )}
        {!!icon && (
          <MotiView
            transition={Platform.select({
              web: { type: 'timing', duration: 250 },
              default: undefined,
            })}
            state={iconAnimationState}
          >
            <Ionicons
              name={iconName}
              selectable={false}
              size={iconSize}
              color={iconColor as string}
              sx={{
                [iconSide === 'left' ? 'mr' : 'ml']: iconMargin,
              }}
            />
          </MotiView>
        )}
      </View>
    </Container>
  )
})

export default Link
