import React, { useEffect } from 'react'
import { View, StyleSheet } from 'react-native'
import { LinearGradient as NativeGradient } from 'expo-linear-gradient'
import Animated, {
  Easing,
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  interpolate,
  withRepeat,
} from 'react-native-reanimated'
import { styled, useDripsyTheme, Text } from 'dripsy'
import type { Sx as SxStyleProp } from 'dripsy'
import { Sizes, Variants } from '../types'

const {
  Value,
  useCode,
  Clock,
  set,
  block,
  startClock,
  cond,
  stopClock,
  timing,
} = Animated

const Dots = styled(View)({
  flexDirection: 'row',
})
const Container = styled(View)({
  flexDirection: 'row',
  alignItems: 'center',
})
const LinearGradient = styled(NativeGradient)({})

const Loading = function Loading(props: Props) {
  const {
    variant = 'default',
    animationConfig,
    delay = 500,
    count = 3,
    children,
    sx = {},
    size = 'mini',
  } = props

  const { theme } = useDripsyTheme()

  // const animatedValue = React.useRef(new Value(0)).current
  const value = useSharedValue(0)

  // const config = {
  //   duration: 2500,
  //   toValue: new Value(count + 1),
  //   easing: Easing.inOut(Easing.linear),
  //   ...animationConfig,
  // }

  useEffect(() => {
    value.value = withRepeat(
      withTiming(count + 1, {
        easing: Easing.inOut(Easing.linear),
        duration: 2500,
      }),
      -1,
      false
    )
  }, [count, value])

  // useCode(() => set(animatedValue, runLoop(config)), [])

  let dotSize = size === 'mini' ? 5 : 6
  if (size === 'medium') {
    dotSize = 7
  }
  if (size === 'large') {
    dotSize = 8
  }
  if (size === 'xl') {
    dotSize = 9
  }

  return React.useMemo(() => {
    const { colors } = theme
    const getColor = (color: string | Variants): string => {
      if (color === 'default') {
        return colors?.text as string
      }
      return (colors?.[color] ?? color) as string
    }
    const color = [getColor(variant), getColor(variant)]
    const empty = color.map((color) => `${color}40`)
    return (
      <Container sx={sx}>
        {!!children && <Text sx={{ color: color[0], mr: 2 }}>{children}</Text>}
        <Dots>
          {new Array(count).fill('').map((_, i) => {
            const index = i + 1
            return (
              <Dot
                key={index}
                index={index}
                color={color}
                empty={empty}
                value={value}
                dotSize={dotSize}
              />
            )
          })}
        </Dots>
      </Container>
    )
  }, [children, count, sx, theme, variant, value, dotSize])
}

function Dot({
  index,
  value,
  empty,
  color,
  dotSize,
}: {
  index: number
  value: Animated.SharedValue<number>
  color: Array<string>
  empty: Array<string>
  dotSize: number
}) {
  const style = useAnimatedStyle(() => {
    const opacity = interpolate(
      value.value,
      [index - 1, index, index + 1],
      [0, 1, 0]
    )

    return {
      opacity,
    }
  }, [value, index])
  return (
    <View
      style={{
        height: dotSize,
        width: dotSize,
        borderRadius: 9999,
        // backgroundColor: 'white',
        marginHorizontal: 2,
        overflow: 'hidden',
      }}
    >
      <LinearGradient
        {...{
          colors: empty,
          start: [0.9, 0.9],
          end: [0.1, 0.1],
        }}
        style={{ flex: 1 }}
      />
      <Animated.View style={[style, StyleSheet.absoluteFill]}>
        <LinearGradient
          {...{
            colors: color,
            start: [0.1, 0.1],
            end: [0.9, 0.9],
          }}
          style={{ flex: 1 }}
        />
      </Animated.View>
    </View>
  )
}

export default Loading

export interface Props {
  backgroundColors?: string[]
  dotColors?: string[]
  dotEmptyColors?: string[]
  variant?: Variants
  animationConfig?: {
    duration: number
    toValue: Animated.Value<number>
    easing: Animated.EasingNodeFunction
  }
  /**
   * If defined, the time between animations.
   */
  delay?: number
  /**
   * Number of dots. Defaults to 3.
   */
  count?: number
  children?: React.ReactNode
  sx?: SxStyleProp
  size?: Sizes
}

// const dotSize = 9
// const padding = dotSize / 4
// const paddingVertical = padding * 5
// const totalHeight = dotSize + paddingVertical * 2

// function runLoop(config: Animated.TimingConfig) {
//   const clock = new Clock()

//   const state = {
//     finished: new Value(0),
//     position: new Value(0),
//     time: new Value(0),
//     frameTime: new Value(0),
//   }

//   return block([
//     // start right away
//     startClock(clock),

//     // process your state
//     timing(clock, state, config),

//     // when over (processed by timing at the end)
//     cond(state.finished, [
//       // we stop
//       stopClock(clock),

//       // set flag ready to be restarted
//       set(state.finished, 0),
//       // same value as the initial defined in the state creation
//       set(state.position, 0),

//       // very important to reset this ones !!! as mentioned in the doc about timing is saying
//       set(state.time, 0),
//       set(state.frameTime, 0),

//       // and we restart
//       startClock(clock),
//     ]),

//     state.position,
//   ])
// }

// export function loop({
//   duration,
//   toValue,
//   easing,
// }: {
//   duration: number
//   toValue?: number
//   easing?: Animated.EasingNodeFunction
// }) {
//   const clock = new Clock()

//   const state = {
//     finished: new Value(0),
//     position: new Value(0),
//     time: new Value(0),
//     frameTime: new Value(0),
//   }

//   const config = {
//     duration: new Value(duration),
//     toValue: new Value(toValue || 1),
//     easing: easing || Easing.inOut(Easing.linear),
//   }

//   return block([
//     startClock(clock),
//     timing(clock, state, config),
//     cond(state.finished, [
//       stopClock(clock),
//       set(state.finished, 0),
//       set(state.position, 0),
//       set(state.time, 0),
//       set(state.frameTime, 0),
//       startClock(clock),
//     ]),
//     state.position,
//   ])
// }
// // https://gist.github.com/Thram/e9ca9de3e4b47b9877f5bd089b5059ea
// export function runDelay(node: Animated.Node<any>, delayTime: number) {
//   const clock = new Clock()

//   const state = {
//     finished: new Value(0),
//     position: new Value(0),
//     time: new Value(0),
//     frameTime: new Value(0),
//   }

//   const config = {
//     duration: new Value(delayTime),
//     toValue: new Value(1),
//     easing: Easing.linear,
//   }

//   return block([
//     // start right away
//     startClock(clock),
//     // process your state
//     timing(clock, state, config),
//     // when over (processed by timing at the end)
//     cond(state.finished, node),
//   ])
// }
