import React, { ReactNode, ComponentProps } from 'react'
import { styled } from 'dripsy'
import {
  Pressable as NativePressable,
  PressableStateCallbackType,
  ViewStyle,
  StyleSheet,
} from 'react-native'

// react-native-web/overrides.ts
declare module 'react-native' {
  interface PressableStateCallbackType {
    hovered?: boolean
    focused?: boolean
    interacted?: boolean
  }
  interface ViewStyle {
    transitionProperty?: string
    transitionDuration?: string
    transitionDelay?: string
  }
  interface TextProps {
    accessibilityComponentType?: never
    accessibilityTraits?: never
  }
  interface PressableProps {
    onHoverIn?: (e: MouseEvent) => void
    onHoverOut?: (e: MouseEvent) => void
  }
  interface ViewProps {
    accessibilityRole?: string
    href?: string
    hrefAttrs?: {
      rel: 'noreferrer'
      target?: '_blank'
    }
    // children?: React.ReactNode
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  }
}

type CallbackWithHovered = PressableStateCallbackType
const StyledPressable = styled(NativePressable)({})

type Props = {
  style?: ((prop: CallbackWithHovered) => ViewStyle) | ViewStyle
  children?: ReactNode | ((prop: CallbackWithHovered) => ReactNode)
  stretch?: boolean
} & Omit<ComponentProps<typeof StyledPressable>, 'children' | 'style'>

const Press = React.forwardRef(function Pressable(
  { sx = {}, disabled, hitSlop = 10, children, stretch, ...props }: Props,
  ref: ComponentProps<typeof NativePressable>['ref']
) {
  return (
    <StyledPressable
      {...props}
      style={(interaction) => [
        stretch && StyleSheet.absoluteFill,
        typeof props.style == 'function'
          ? props.style(interaction)
          : props.style,
      ]}
      ref={ref as any}
      disabled={disabled}
      hitSlop={hitSlop}
      sx={{
        cursor:
          props.onPress || props.accessibilityRole === 'link' || !disabled
            ? 'pointer'
            : 'default',
        ...sx,
      }}
    >
      {typeof children === 'function'
        ? (interactions) => {
            const { hovered, pressed } = interactions
            return children({
              ...interactions,
              interacted: hovered || pressed,
            })
          }
        : children}
    </StyledPressable>
  )
})

export default Press
