import React from 'react'
import { Text, View, useDripsyTheme } from 'dripsy'
import { Sizes, Variants } from '../types'
import type { Sx as SxStyleProp } from 'dripsy'
import { Platform } from 'react-native'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import useGetColors from '../../hooks/use-variant-colors'

type Icon =
  | {
      name: React.ComponentProps<typeof Ionicons>['name']
      size?: number
      // color?: string
    }
  | React.ReactElement
  | React.ComponentProps<typeof Ionicons>['name']

type Props = {
  children?: React.ReactNode
  variant?: Variants
  size?: Sizes
  dot?: boolean
  sx?: SxStyleProp
  textSx?: SxStyleProp
  uppercase?: boolean
  /**
   * @deprecated use `filling` instead
   * Default: `filled`. Can also be `faded`, or `outline`
   */
  type?: 'filled' | 'faded' | 'outline'
  /**
   * Default: `filled`
   */
  filling?: 'filled' | 'faded' | 'outlined'
  iconLeft?: Icon | boolean
  iconRight?: Icon | boolean
} & Omit<React.ComponentProps<typeof Text>, 'variant'>

export default function Badge(props: Props) {
  const {
    sx = {},
    variant = 'primary',
    children,
    uppercase,
    size = 'small',
    dot,
    type = 'filled',
    filling = type === 'outline' ? 'outlined' : type,
    iconLeft,
    iconRight,
    textSx = {},
  } = props

  const { theme } = useDripsyTheme()

  const { bg, color, borderColor } = useGetColors({
    variant,
    filling,
  })

  const maybeRenderIcon = (
    icon: Props['iconLeft'],
    side: 'left' | 'right' = 'left'
  ) => {
    if (React.isValidElement(icon)) {
      return icon
    }
    // this exists for conditional props
    if (typeof icon === 'boolean') return null

    let name: React.ComponentProps<typeof Ionicons>['name'] | null = null
    let size = 22
    if (typeof icon === 'string') {
      name = icon
    } else if (icon?.name) {
      name = icon.name
      if (icon.size) size = icon.size
    }

    if (name) {
      return (
        <View
          sx={Platform.select({
            web: { height: '100%' },
            default: { height: size, my: -1 },
          })}
        >
          <Ionicons
            selectable={false}
            style={{
              marginRight:
                side === 'left' && children ? theme.space?.[1] * 1.5 : 0,
              marginLeft:
                side === 'right' && children ? theme.space?.[1] * 1.5 : 0,
              alignItems: 'center',
              height: Platform.select({ web: size * 0.7, default: undefined }),
              display: 'flex',
              justifyContent: 'center',
              // position: 'absolute',
              // top: 0,
            }}
            color={color}
            size={size}
            name={name}
          />
        </View>
      )
    }
    return null
  }

  return (
    <View
      sx={{
        borderRadius: dot ? 'rounded' : 4,
        bg,
        borderColor,
        borderWidth: 1,
        px: dot ? undefined : 2,
        py: dot ? undefined : 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: dot ? 12 : undefined,
        width: dot ? 12 : undefined,
        ...sx,
      }}
    >
      {maybeRenderIcon(iconLeft, 'left')}
      <Text
        sx={{
          textTransform: uppercase ? 'uppercase' : undefined,
          color,
          fontSize: 1,
          fontWeight: '600',
          ...textSx,
        }}
        selectable={false}
      >
        {!dot && children}
      </Text>
      {maybeRenderIcon(iconRight, 'right')}
    </View>
  )
}
