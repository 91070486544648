import { useEffect } from 'react'
import Analytics, { SimpleScreenType } from '../typez'

/**
 * @deprecated use analytics.screen directly
 */
export default function useTrackScreen({ name }: { name: SimpleScreenType }) {
  useEffect(() => {
    console.log('[use-track-screen]', { name })
    Analytics.screen(name)
  }, [name])
}
