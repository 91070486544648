import useActionSheet from './use-action-sheet'
import { useCallback } from 'react'
import { useSx } from 'dripsy'

type Props = {
  /**
   * Are you sure you want to `<action>`?
   *
   * If you pass a function, it should return a string that overrides the entire message, not just the action.
   */
  action: string | (() => string)
  /**
   * Shown under the title
   */
  message?: string
  /**
   * Default: `Yes, I'm sure`.
   */
  dangerousText?: string
  /**
   * Default: `Cancel`
   */
  cancelText?: string
}

type Callback = () => void | Promise<void>

export default function useAreYouSure(props: Props) {
  // const {
  //   action,
  //   message,
  //   cancelText = 'Cancel',
  //   dangerousText = `Yes, I'm sure.`,
  // } = props
  const { showActionSheetWithOptions } = useActionSheet()
  const sx = useSx()

  // const _callback = useStable(callback)

  return useCallback(
    async (
      callback: Callback,
      {
        action = props.action,
        message = props.message,
        cancelText = props.cancelText ?? 'Cancel',
        dangerousText = props.dangerousText ?? `Yes, I'm sure.`,
      }: Props = props
    ) => {
      const options = [cancelText, dangerousText]
      let title: string
      if (typeof action === 'function') title = action()
      else {
        title = `Are you sure you want to ${action}?`
      }

      const cancelButtonIndex = 0
      const destructiveButtonIndex = 1
      return new Promise((resolve) => {
        showActionSheetWithOptions(
          {
            title,
            message,
            options,
            cancelButtonIndex,
            destructiveButtonIndex,
            messageTextStyle: sx({
              fontFamily: 'root',
              fontSize: '$3',
            }),
          },
          (index) => {
            if (index === cancelButtonIndex) {
            } else if (index === destructiveButtonIndex) {
              callback()
            }
            resolve(index)
          }
        )
      })
    },
    [props, showActionSheetWithOptions, sx]
  )
}
