/** @jsxRuntime classic */
/** @jsx jsx */
import viewResetStyle from '@beatgig/helpers/view-reset-style'
import { ComponentProps } from 'react'
import { jsx } from '@theme-ui/core'
import { forwardRef } from 'react'
import type { View } from 'dripsy'

const NativeView = forwardRef(function NativeView(
  { sx = {}, ...props }: ComponentProps<typeof View>,
  ref
) {
  return (
    <div
      {...(props as any)}
      sx={{ ...viewResetStyle, ...sx }}
      ref={ref as any}
    />
  )
})

export default NativeView
