import React from 'react'
import { AlgoliaPendingLoader } from './algolia'

export function AlgoliaPendingTasksProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <AlgoliaPendingLoader.PendingLoaderProvider>
      {children}
    </AlgoliaPendingLoader.PendingLoaderProvider>
  )
}
