import type { ResizeMode, Priority } from 'react-native-fast-image'
export const resizeMode: Record<ResizeMode, ResizeMode> = {
  contain: 'contain',
  cover: 'cover',
  stretch: 'stretch',
  center: 'center',
}
export const priority: Record<Priority, Priority> = {
  low: 'low',
  normal: 'normal',
  high: 'high',
}
type Cache = 'immutable' | 'web' | 'cacheOnly'
export const cacheControl: Record<Cache, Cache> = {
  immutable: 'immutable',
  web: 'web',
  cacheOnly: 'cacheOnly',
}
