import type { GraphQLClient } from 'graphql-request';
import type * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date (isoformat) */
  Date: any;
  /** Date with time (isoformat) */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  UUID: any;
};

export enum AdminUserType {
  EMPLOYEE = 'employee',
  OWNER = 'owner',
  READ_ONLY = 'read_only'
}

export type Approval = {
  __typename: 'Approval';
  admin?: Maybe<User>;
  adminId: Scalars['String'];
  timestamp?: Maybe<Scalars['DateTime']>;
};

export type Artist = {
  __typename: 'Artist';
  adminDetails?: Maybe<Scalars['String']>;
  artistReviews: Array<ArtistReview>;
  avgRating: Scalars['Float'];
  bandConfiguration?: Maybe<BandConfig>;
  billing: ConnectAccountDisplay;
  bio?: Maybe<Scalars['String']>;
  bookings: Array<Booking>;
  category?: Maybe<Category>;
  contactInformation?: Maybe<ContactInformation>;
  coverImage?: Maybe<Image>;
  createdAt: Scalars['DateTime'];
  defaultPriceRange?: Maybe<PriceRange>;
  groupedReviews: Array<ArtistReview>;
  iLiked: Scalars['Boolean'];
  id: Scalars['String'];
  lastUpdated: Scalars['DateTime'];
  likeCount: Scalars['Int'];
  location?: Maybe<Location>;
  media?: Maybe<Media>;
  name: Scalars['String'];
  numberOfReviews: Scalars['Int'];
  pendingUserPhoneNumbers: Array<Scalars['String']>;
  performanceData?: Maybe<ArtistPerformanceData>;
  profileImage?: Maybe<Image>;
  published: Scalars['Boolean'];
  relatedArtists: Array<Artist>;
  sellers: Array<User>;
  slug: Scalars['String'];
  socialMedia?: Maybe<SocialMedia>;
  spotifyId?: Maybe<Scalars['String']>;
  subgenres?: Maybe<Array<Scalars['String']>>;
  topReviews: Array<ArtistReview>;
  transfers: Array<TransferStripe>;
  venueTakePercentages?: Maybe<Scalars['JSON']>;
  viewCount: Scalars['Int'];
};


export type ArtistArtistReviewsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type ArtistBillingArgs = {
  redirectUrl: Scalars['String'];
};


export type ArtistBookingsArgs = {
  bookingFlows?: InputMaybe<Array<BookingFlow>>;
  direction?: InputMaybe<Dir>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BookingOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
};


export type ArtistGroupedReviewsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type ArtistRelatedArtistsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type ArtistArtistNotFoundResponse = Artist | ArtistNotFoundResponse;

export type ArtistAvailability = {
  __typename: 'ArtistAvailability';
  booking?: Maybe<Booking>;
  buyerDisplayName?: Maybe<Scalars['String']>;
  /** Admin only. */
  checks: Array<ArtistAvailabilityCheck>;
  createdAt: Scalars['DateTime'];
  /** Admin only. */
  createdBy?: Maybe<User>;
  id: Scalars['UUID'];
  lastUpdated: Scalars['DateTime'];
  location: Location;
  performanceLengthMins?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  status: ArtistAvailabilityStatus;
};

export type ArtistAvailabilityCheck = {
  __typename: 'ArtistAvailabilityCheck';
  artist: Artist;
  artistAvailability: ArtistAvailability;
  /** Admin only. */
  courierMessages: Array<CourierMessage>;
  courierNotificationIds: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Admin only. */
  createdBy?: Maybe<User>;
  id: Scalars['UUID'];
  lastUpdated: Scalars['DateTime'];
  notifiedAt: Scalars['DateTime'];
  respondedAt?: Maybe<Scalars['DateTime']>;
  respondedBy?: Maybe<User>;
  response?: Maybe<ArtistAvailabilityResponse>;
  responseMessage?: Maybe<Scalars['String']>;
};

export type ArtistAvailabilityInput = {
  buyerDisplayName?: InputMaybe<Scalars['String']>;
  location: LocationInput;
  performanceLengthMins?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
};

export enum ArtistAvailabilityResponse {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable'
}

export enum ArtistAvailabilityStatus {
  DONE = 'done',
  PENDING = 'pending'
}

export type ArtistLike = {
  __typename: 'ArtistLike';
  artist: Artist;
  timestamp: Scalars['DateTime'];
  user: User;
};

export type ArtistNotFoundResponse = {
  __typename: 'ArtistNotFoundResponse';
  message: Scalars['String'];
};

export enum ArtistOrderBy {
  CREATED_AT = 'created_at',
  LAST_UPDATED = 'last_updated'
}

export type ArtistPatch = {
  bandConfiguration?: InputMaybe<BandConfigInput>;
  bio?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Category>;
  contactInformation?: InputMaybe<ContactInformationInput>;
  coverImage?: InputMaybe<ImageInput>;
  location?: InputMaybe<LocationInput>;
  media?: InputMaybe<MediaInput>;
  name?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<ImageInput>;
  slug?: InputMaybe<Scalars['String']>;
  socialMedia?: InputMaybe<SocialMediaInput>;
  subgenres?: InputMaybe<Array<Scalars['String']>>;
};

export type ArtistPerformanceData = {
  __typename: 'ArtistPerformanceData';
  createdAt: Scalars['DateTime'];
  firebaseId: Scalars['String'];
  id: Scalars['UUID'];
  lastUpdated: Scalars['DateTime'];
  songs?: Maybe<Array<Scalars['String']>>;
  songsTxt?: Maybe<Scalars['String']>;
  states?: Maybe<Array<Scalars['String']>>;
};

export type ArtistReview = {
  __typename: 'ArtistReview';
  artist: Artist;
  author?: Maybe<User>;
  authorFirstName?: Maybe<Scalars['String']>;
  authorLocStr?: Maybe<Scalars['String']>;
  authorOrgStr?: Maybe<Scalars['String']>;
  authorProfileImage?: Maybe<SmallImage>;
  avgRating?: Maybe<Scalars['Float']>;
  booking?: Maybe<Booking>;
  comment?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  fromVenue: Scalars['Boolean'];
  id: Scalars['String'];
  published: Scalars['Boolean'];
  rating: Scalars['Int'];
  timestamp: Scalars['DateTime'];
  venue?: Maybe<Venue>;
};

export type ArtistReviewInput = {
  comment?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<ImageInput>>;
  rating: Scalars['Int'];
};

export type ArtistReviewPatch = {
  comment?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<ImageInput>>;
  rating: Scalars['Int'];
};

export type ArtistSpotify = {
  __typename: 'ArtistSpotify';
  externalUrls?: Maybe<ExternalUrls>;
  followers: Followers;
  genres: Array<Scalars['String']>;
  href: Scalars['String'];
  id: Scalars['String'];
  images: Array<ImageSpotify>;
  name: Scalars['String'];
  popularity: Scalars['Int'];
};

export type ArtistStats = {
  __typename: 'ArtistStats';
  cancellationRate?: Maybe<Scalars['Float']>;
  hasVerifiedBadge: Scalars['Boolean'];
  numberOfBookings: Scalars['Int'];
  numberOfCancellations: Scalars['Int'];
  responseRate?: Maybe<Scalars['Float']>;
  responseTimeMins?: Maybe<Scalars['Float']>;
};

export type ArtistTypesense = {
  __typename: 'ArtistTypesense';
  _geoloc?: Maybe<GeoLoc>;
  avgRating: Scalars['Float'];
  bandConfiguration: BandConfig;
  bandConfigurationOptions?: Maybe<Array<Scalars['String']>>;
  category?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Image>;
  createdAt: Scalars['Int'];
  id: Scalars['String'];
  lastTriggeredToTypesenseAt: Scalars['Int'];
  lastUpdated: Scalars['Int'];
  locationGeopoint?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  numberOfReviews: Scalars['Int'];
  objectID: Scalars['String'];
  privateDefaultPriceHigh?: Maybe<Scalars['Int']>;
  privateDefaultPriceLow?: Maybe<Scalars['Int']>;
  privateDuoPriceHigh?: Maybe<Scalars['Int']>;
  privateDuoPriceLow?: Maybe<Scalars['Int']>;
  privateFourPlusPriceHigh?: Maybe<Scalars['Int']>;
  privateFourPlusPriceLow?: Maybe<Scalars['Int']>;
  privateSoloPriceHigh?: Maybe<Scalars['Int']>;
  privateSoloPriceLow?: Maybe<Scalars['Int']>;
  privateTrioPriceHigh?: Maybe<Scalars['Int']>;
  privateTrioPriceLow?: Maybe<Scalars['Int']>;
  profileImage?: Maybe<Image>;
  publicDefaultPriceHigh?: Maybe<Scalars['Int']>;
  publicDefaultPriceLow?: Maybe<Scalars['Int']>;
  publicDuoPriceHigh?: Maybe<Scalars['Int']>;
  publicDuoPriceLow?: Maybe<Scalars['Int']>;
  publicFourPlusPriceHigh?: Maybe<Scalars['Int']>;
  publicFourPlusPriceLow?: Maybe<Scalars['Int']>;
  publicSoloPriceHigh?: Maybe<Scalars['Int']>;
  publicSoloPriceLow?: Maybe<Scalars['Int']>;
  publicTrioPriceHigh?: Maybe<Scalars['Int']>;
  publicTrioPriceLow?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
  spotifyPopularity?: Maybe<Scalars['Int']>;
  spotifyPopularityAndReviews: Scalars['Int'];
  subgenres?: Maybe<Array<Scalars['String']>>;
};

export type ArtistTypesensePublic = {
  __typename: 'ArtistTypesensePublic';
  _geoloc?: Maybe<GeoLoc>;
  avgRating: Scalars['Float'];
  category?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Image>;
  createdAt: Scalars['Int'];
  id: Scalars['String'];
  lastTriggeredToTypesenseAt: Scalars['Int'];
  lastUpdated: Scalars['Int'];
  locationGeopoint?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  numberOfReviews: Scalars['Int'];
  objectID: Scalars['String'];
  profileImage?: Maybe<Image>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
  spotifyPopularity?: Maybe<Scalars['Int']>;
  spotifyPopularityAndReviews: Scalars['Int'];
  subgenres?: Maybe<Array<Scalars['String']>>;
};

export type ArtistVenueApplicationStraw = {
  __typename: 'ArtistVenueApplicationStraw';
  id: Scalars['String'];
  message: Scalars['String'];
};

export type BandConfig = {
  __typename: 'BandConfig';
  bandConfigOptionTypes: BandConfigOptionFrontend;
  duo?: Maybe<ConfigInfo>;
  fallback?: Maybe<ConfigInfo>;
  fourPlus?: Maybe<ConfigInfo>;
  solo?: Maybe<ConfigInfo>;
  trio?: Maybe<ConfigInfo>;
};

export type BandConfigInput = {
  duo?: InputMaybe<ConfigInfoInput>;
  fourPlus?: InputMaybe<ConfigInfoInput>;
  solo?: InputMaybe<ConfigInfoInput>;
  trio?: InputMaybe<ConfigInfoInput>;
  unspecified?: InputMaybe<ConfigInfoInput>;
};

export enum BandConfigOptionFrontend {
  DUO = 'duo',
  FALLBACK = 'fallback',
  FOUR_PLUS = 'four_plus',
  SOLO = 'solo',
  TRIO = 'trio'
}

export enum BandConfigurationOption {
  DUO = 'duo',
  FOUR_PLUS = 'four_plus',
  SOLO = 'solo',
  TRIO = 'trio',
  UNSPECIFIED = 'unspecified'
}

export type BarChart = {
  __typename: 'BarChart';
  data: Array<Scalars['Float']>;
  firstFutureIndex?: Maybe<Scalars['Int']>;
  labels: Array<Scalars['String']>;
};

export type BarChartEdge = {
  __typename: 'BarChartEdge';
  monthly?: Maybe<BarChart>;
  yearly?: Maybe<BarChart>;
};

export type Bid = {
  __typename: 'Bid';
  artist: Artist;
  bandConfiguration?: Maybe<BandConfigurationOption>;
  bookingRequest: BookingRequest;
  buyerHasSeen?: Maybe<Scalars['Boolean']>;
  buyerPrice: Scalars['Float'];
  buyerSawAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  id: Scalars['String'];
  lastUpdated: Scalars['DateTime'];
  notes?: Maybe<Scalars['String']>;
  rejectionMessage?: Maybe<Scalars['String']>;
  sellerPrice: Scalars['Float'];
  status: BidStatus;
  withdrawalMessage?: Maybe<Scalars['String']>;
};

export type BidInput = {
  bandConfiguration?: InputMaybe<BandConfigurationOption>;
  notes?: InputMaybe<Scalars['String']>;
};

export type BidPatch = {
  bandConfiguration?: InputMaybe<BandConfigurationOption>;
  notes?: InputMaybe<Scalars['String']>;
};

export enum BidStatus {
  ACCEPTED = 'accepted',
  ARTIST_UNAVAILABLE = 'artist_unavailable',
  PENDING = 'pending',
  REJECTED = 'rejected',
  WITHDRAWN = 'withdrawn'
}

export enum Billing {
  CO_HEADLINER = 'Co_Headliner',
  HEADLINER = 'Headliner',
  OPENER = 'Opener'
}

export type Booking = {
  __typename: 'Booking';
  accountExec?: Maybe<User>;
  adminCancellationMessage?: Maybe<Scalars['String']>;
  artist: Artist;
  artistAvailability?: Maybe<ArtistAvailability>;
  artistId: Scalars['String'];
  artistName: Scalars['String'];
  artistReviews: Array<ArtistReview>;
  artistSlug: Scalars['String'];
  autoPublishMessage: Scalars['String'];
  bandConfiguration?: Maybe<BandConfigurationOption>;
  billing: Billing;
  bookingBilling?: Maybe<BookingBilling>;
  bookingDispute?: Maybe<BookingDispute>;
  bookingFlow: BookingFlow;
  buyer: User;
  buyerHasReviewed?: Maybe<Scalars['Boolean']>;
  buyerId: Scalars['String'];
  buyerMetadata: Metadata;
  buyerName: Scalars['String'];
  buyerSlug: Scalars['String'];
  canIAdvanceBooking: Scalars['Boolean'];
  canIAutoPublish: Scalars['Boolean'];
  canIEdit: Scalars['Boolean'];
  canIEditEventDescription: Scalars['Boolean'];
  canIPublish: Scalars['Boolean'];
  canSellerSee: Scalars['Boolean'];
  cancellationMessage?: Maybe<Scalars['String']>;
  cancellationRequested?: Maybe<Scalars['Boolean']>;
  cancellationRequests: Array<CancellationRequest>;
  capacity?: Maybe<Scalars['Int']>;
  charge?: Maybe<Charge>;
  comments?: Maybe<Scalars['String']>;
  confirmation?: Maybe<BookingConfirmation>;
  createdAt: Scalars['DateTime'];
  displayPrices: DisplayPrices;
  doINeedToReview: Scalars['Boolean'];
  doINeedToReviewMessage?: Maybe<Scalars['String']>;
  firebaseId: Scalars['String'];
  id: Scalars['String'];
  indoorsOrOutdoors: IndoorsOrOutdoors;
  isPublished: Scalars['Boolean'];
  /** Buyer only. */
  lastOfferAmount: Scalars['Float'];
  /** Seller only. */
  lastSellerOfferAmount: Scalars['Float'];
  lastUpdated: Scalars['DateTime'];
  location: Location;
  negotiationSteps: Array<NegotiationStep>;
  oldStartTime?: Maybe<Scalars['DateTime']>;
  payout?: Maybe<Payout>;
  performanceLengthMins: Scalars['Int'];
  possibleActions: Array<NegotiationAction>;
  productionAndVenueSpecs?: Maybe<Scalars['String']>;
  publicCoverImage?: Maybe<Image>;
  publicEventDescription?: Maybe<Scalars['String']>;
  publicProfileImage?: Maybe<Image>;
  publishedMessage: Scalars['String'];
  refundedInCredits?: Maybe<Scalars['Boolean']>;
  rescheduleMessage?: Maybe<Scalars['String']>;
  shouldAutoPublish?: Maybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: BookingStatus;
  statusInformation?: Maybe<StatusInformation>;
  statusV2: BookingStatusV2;
  venue?: Maybe<Venue>;
  venueId?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
  venueSlug?: Maybe<Scalars['String']>;
  whatShouldIDoAlerts?: Maybe<WhatShouldIDoAlerts>;
};

export type BookingBadgesResponse = {
  __typename: 'BookingBadgesResponse';
  badgeCount: Scalars['Int'];
  bookings: Array<Booking>;
};

export type BookingBilling = {
  __typename: 'BookingBilling';
  buyerBilling?: Maybe<BuyerBilling>;
  buyerNeedsBankAccountCard?: Maybe<Card>;
  connectAccount?: Maybe<ConnectAccountDisplay>;
  frontendDirections: FrontendDirections;
  receiptItems?: Maybe<Array<ReceiptItem>>;
  sellerNeedsPayoutMethodCard?: Maybe<Card>;
};

export type BookingByBuyerType = {
  __typename: 'BookingByBuyerType';
  bookingsCount: Scalars['Int'];
  buyerUserType: UserType;
  revenue: Scalars['Float'];
};

export type BookingByCity = {
  __typename: 'BookingByCity';
  bookingsCount: Scalars['Int'];
  cityName: Scalars['String'];
  revenue: Scalars['Float'];
  sampleLocation: Location;
};

export type BookingByVenue = {
  __typename: 'BookingByVenue';
  bookingsCount: Scalars['Int'];
  location: Location;
  revenue: Scalars['Float'];
  venueId?: Maybe<Scalars['String']>;
  venueName: Scalars['String'];
  venueType: VenueType;
};

export type BookingConfirmation = {
  __typename: 'BookingConfirmation';
  booking: Booking;
  confirmedAt: Scalars['DateTime'];
  confirmedBy: User;
  id: Scalars['UUID'];
  response: BookingConfirmationResponse;
};

export enum BookingConfirmationResponse {
  CANCEL = 'cancel',
  CONFIRM = 'confirm'
}

export type BookingDispute = {
  __typename: 'BookingDispute';
  booking: Booking;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lastUpdated: Scalars['DateTime'];
  message: Scalars['String'];
  resolved: Scalars['Boolean'];
  resolvedAt?: Maybe<Scalars['DateTime']>;
  resolvedBy?: Maybe<User>;
  user: User;
};

export enum BookingFlow {
  PRIVATE = 'private',
  UPB = 'upb',
  VENUE = 'venue'
}

export type BookingInput = {
  bandConfiguration?: InputMaybe<BandConfigurationOption>;
  billing: Billing;
  capacity?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['String']>;
  indoorsOrOutdoors: IndoorsOrOutdoors;
  performanceLengthMins: Scalars['Int'];
  productionAndVenueSpecs?: InputMaybe<Scalars['String']>;
  publicCoverImage?: InputMaybe<ImageInput>;
  publicEventDescription?: InputMaybe<Scalars['String']>;
  publicProfileImage?: InputMaybe<ImageInput>;
  shouldAutoPublish?: InputMaybe<Scalars['Boolean']>;
};

export enum BookingOrderBy {
  CREATED_AT = 'created_at',
  LAST_UPDATED = 'last_updated',
  START_TIME = 'start_time'
}

export type BookingPatch = {
  bandConfiguration?: InputMaybe<BandConfigurationOption>;
  billing?: InputMaybe<Billing>;
  capacity?: InputMaybe<Scalars['Int']>;
  comments?: InputMaybe<Scalars['String']>;
  indoorsOrOutdoors?: InputMaybe<IndoorsOrOutdoors>;
  location?: InputMaybe<LocationInput>;
  performanceLengthMins?: InputMaybe<Scalars['Int']>;
  productionAndVenueSpecs?: InputMaybe<Scalars['String']>;
  publicCoverImage?: InputMaybe<ImageInput>;
  publicEventDescription?: InputMaybe<Scalars['String']>;
  publicProfileImage?: InputMaybe<ImageInput>;
  shouldAutoPublish?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type BookingRequest = {
  __typename: 'BookingRequest';
  accountExec?: Maybe<User>;
  adminInstructions?: Maybe<Scalars['String']>;
  adminInstructionsBuyer?: Maybe<Scalars['String']>;
  areAllMyBidsRejected?: Maybe<Scalars['Boolean']>;
  artistInstructions?: Maybe<Scalars['String']>;
  artists: Array<Artist>;
  artistsBuyer: Array<Artist>;
  bandConfigurationOptions?: Maybe<Array<BandConfigurationOption>>;
  bids: Array<Bid>;
  billing?: Maybe<Billing>;
  booking?: Maybe<Booking>;
  budget?: Maybe<PriceRangeOptional>;
  budgetBuyer?: Maybe<PriceRangeOptional>;
  buyerFrontendCopy?: Maybe<FrontendDirections>;
  canIEdit: Scalars['Boolean'];
  capacity?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  declines: Array<BookingRequestDecline>;
  didSomeoneElsesBidGetChosen: Scalars['Boolean'];
  extraArtistInfos: Array<ExtraArtistInfo>;
  firebaseId: Scalars['String'];
  genres?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  indoorsOrOutdoors?: Maybe<IndoorsOrOutdoors>;
  isActionRequired: Scalars['Boolean'];
  isPast: Scalars['Boolean'];
  lastUpdated: Scalars['DateTime'];
  myAcceptedBid?: Maybe<Bid>;
  myArtistsThatCanBid: Array<Artist>;
  otherBookingRequestsInGroup: Array<BookingRequest>;
  performanceLengthMins: Scalars['Int'];
  publicEventDescription?: Maybe<Scalars['String']>;
  shouldAutoPublish?: Maybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: BookingRequestStatus;
  venue: Venue;
  venueId?: Maybe<Scalars['String']>;
  venueLocation: Location;
  venueName?: Maybe<Scalars['String']>;
  venueSlug?: Maybe<Scalars['String']>;
  whatShouldIDoAlerts?: Maybe<WhatShouldIDoAlerts>;
};

export type BookingRequestBadgesResponse = {
  __typename: 'BookingRequestBadgesResponse';
  badgeCount: Scalars['Int'];
  bookingRequests: Array<BookingRequest>;
};

export type BookingRequestDecline = {
  __typename: 'BookingRequestDecline';
  artist: Artist;
  bookingRequest: BookingRequest;
  declinedBy: User;
  id: Scalars['String'];
  reasonForDecline?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
};

export type BookingRequestInput = {
  adminInstructions?: InputMaybe<Scalars['String']>;
  artistInstructions?: InputMaybe<Scalars['String']>;
  bandConfigurationOptions?: InputMaybe<Array<BandConfigurationOption>>;
  billing?: InputMaybe<Billing>;
  budget?: InputMaybe<PriceRangeOptionalInput>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  indoorsOrOutdoors?: InputMaybe<IndoorsOrOutdoors>;
  performanceLengthMins: Scalars['Int'];
  publicEventDescription?: InputMaybe<Scalars['String']>;
  shouldAutoPublish?: InputMaybe<Scalars['Boolean']>;
};

export enum BookingRequestOrderBy {
  CREATED_AT = 'created_at',
  LAST_UPDATED = 'last_updated',
  START_TIME = 'start_time'
}

export type BookingRequestPatch = {
  adminInstructions?: InputMaybe<Scalars['String']>;
  artistInstructions?: InputMaybe<Scalars['String']>;
  bandConfigurationOptions?: InputMaybe<Array<BandConfigurationOption>>;
  billing?: InputMaybe<Billing>;
  budget?: InputMaybe<PriceRangeOptionalInput>;
  genres?: InputMaybe<Array<Scalars['String']>>;
  indoorsOrOutdoors?: InputMaybe<IndoorsOrOutdoors>;
  performanceLengthMins?: InputMaybe<Scalars['Int']>;
  publicEventDescription?: InputMaybe<Scalars['String']>;
  shouldAutoPublish?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export enum BookingRequestStatus {
  ACCEPTED = 'accepted',
  CANCELLED = 'cancelled',
  PENDING = 'pending'
}

export enum BookingStatus {
  ADVANCING = 'advancing',
  CANCELED = 'canceled',
  CONTRACTING = 'contracting',
  CREATED = 'created',
  DECLINED = 'declined',
  NEGOTIATING = 'negotiating',
  PERFORMANCE = 'performance',
  RESCHEDULING = 'rescheduling'
}

export enum BookingStatusV2 {
  CANCELED = 'canceled',
  CONFIRMED = 'confirmed',
  CONTRACTING = 'contracting',
  CREATED = 'created',
  DECLINED = 'declined',
  NEGOTIATING = 'negotiating',
  RESCHEDULING = 'rescheduling'
}

export type BookingTypesense = {
  __typename: 'BookingTypesense';
  artistCategory?: Maybe<Scalars['String']>;
  artistId: Scalars['String'];
  artistName: Scalars['String'];
  artistProfileImage?: Maybe<Image>;
  artistSubgenres?: Maybe<Array<Scalars['String']>>;
  buyerMetadataLocation?: Maybe<Scalars['String']>;
  buyerMetadataOrganization?: Maybe<Scalars['String']>;
  buyerName: Scalars['String'];
  createdAt: Scalars['Int'];
  firebaseId: Scalars['String'];
  id: Scalars['String'];
  isPublished: Scalars['Boolean'];
  lastTriggeredToTypesenseAt: Scalars['Int'];
  lastUpdated: Scalars['Int'];
  latLngStr: Scalars['String'];
  location: Location;
  locationGeopoint: Array<Scalars['Float']>;
  startTime: Scalars['Int'];
  startTimeQueryableStr: Scalars['String'];
  statusV2: BookingStatusV2;
  venueId?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
  venueProfileImage?: Maybe<Image>;
  yearMonthStr: Scalars['String'];
};

export type Button = {
  __typename: 'Button';
  subtext: Scalars['String'];
  text: Scalars['String'];
  url: Scalars['String'];
};

export type BuyerBilling = {
  __typename: 'BuyerBilling';
  bankAccount?: Maybe<StripeBankAccount>;
  needsBankAccountCard?: Maybe<Card>;
};

export enum BuyerUserType {
  BAR = 'Bar',
  BREWERY = 'Brewery',
  CORPORATE_EVENT = 'Corporate_Event',
  COUNTRY_CLUB = 'Country_Club',
  FRATERNITY_SORORITY = 'Fraternity_Sorority',
  HOTEL_RESORT = 'Hotel_Resort',
  MUNICIPALITY = 'Municipality',
  NIGHTCLUB = 'Nightclub',
  OTHER_BUYER = 'Other_Buyer',
  PROMOTER = 'Promoter',
  RESTAURANT = 'Restaurant',
  THEATER = 'Theater',
  UNIVERSITY_PROGRAM_BOARD = 'University_Program_Board',
  VINEYARD_WINERY = 'Vineyard_Winery',
  WEDDING = 'Wedding'
}

export type CalendarBooking = {
  __typename: 'CalendarBooking';
  artistBio?: Maybe<Scalars['String']>;
  artistCategory?: Maybe<Category>;
  artistCoverImage?: Maybe<Image>;
  artistName: Scalars['String'];
  artistProfileImage?: Maybe<Image>;
  artistSlug?: Maybe<Scalars['String']>;
  bandConfiguration?: Maybe<BandConfigurationOption>;
  beatgigId: Scalars['String'];
  bookingType: CalendarBookingType;
  id: Scalars['String'];
  performanceLengthMins: Scalars['Int'];
  publicCoverImage?: Maybe<Image>;
  publicEventDescription?: Maybe<Scalars['String']>;
  publicProfileImage?: Maybe<Image>;
  startTime: Scalars['DateTime'];
};

export enum CalendarBookingType {
  EXTERNAL = 'external',
  INTERNAL = 'internal'
}

export type CalendarBookingsResponse = {
  __typename: 'CalendarBookingsResponse';
  calendarBookings: Array<CalendarBooking>;
  canFetchMore: Scalars['Boolean'];
  venueId: Scalars['String'];
  venueLocation: Location;
};

export type CancellationRequest = {
  __typename: 'CancellationRequest';
  booking: Booking;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lastUpdated: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
  resolved: Scalars['Boolean'];
  user: User;
};

export type Card = {
  __typename: 'Card';
  buttonText?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  header: Scalars['String'];
  message: Scalars['String'];
};

export enum Category {
  COMEDY = 'Comedy',
  COUNTRY = 'Country',
  DJ = 'DJ',
  HIP_HOP = 'Hip_Hop',
  JAZZ = 'Jazz',
  POP = 'Pop',
  REGGAE = 'Reggae',
  ROCK = 'Rock',
  TRIBUTE = 'Tribute'
}

export type Charge = {
  __typename: 'Charge';
  cancelledInvoiceId?: Maybe<Scalars['String']>;
  chargedBuyer: Scalars['Boolean'];
  invoiceId?: Maybe<Scalars['String']>;
  invoiceItemId?: Maybe<Scalars['String']>;
  manuallyChargedBuyer: Scalars['Boolean'];
  manuallyChargedBuyerBy?: Maybe<Scalars['String']>;
};

export enum Collection {
  ARTISTS = 'ARTISTS',
  BOOKINGS = 'BOOKINGS',
  USERS = 'USERS'
}

export enum ColorMode {
  DARK = 'DARK',
  LIGHT = 'LIGHT'
}

export type Colors = {
  __typename: 'Colors';
  colorMode: ColorMode;
  primary: Scalars['String'];
};

export type ColorsInput = {
  colorMode: ColorMode;
  primary: Scalars['String'];
};

export type ConfigInfo = {
  __typename: 'ConfigInfo';
  notes?: Maybe<Scalars['String']>;
  prices: Prices;
};

export type ConfigInfoInput = {
  notes?: InputMaybe<Scalars['String']>;
  prices: PricesInput;
};

export type ConnectAccountDisplay = {
  __typename: 'ConnectAccountDisplay';
  button: Button;
  canTransfer: Scalars['Boolean'];
  dashboardLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  needsToAddInformation: Scalars['Boolean'];
  needsToOnboard: Scalars['Boolean'];
  onboardingLink?: Maybe<Scalars['String']>;
  payoutMethod?: Maybe<PayoutMethod>;
};

export type ContactInformation = {
  __typename: 'ContactInformation';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type ContactInformationInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CourierChannel = {
  __typename: 'CourierChannel';
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
};

export type CourierMessage = {
  __typename: 'CourierMessage';
  channel?: Maybe<CourierChannel>;
  clicked?: Maybe<Scalars['DateTime']>;
  courierUrl: Scalars['String'];
  delivered?: Maybe<Scalars['DateTime']>;
  enqueued?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  id: Scalars['String'];
  notification?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['DateTime']>;
  providers?: Maybe<Array<CourierProvider>>;
  reason?: Maybe<Scalars['String']>;
  recipient: Scalars['String'];
  recipientId: Scalars['String'];
  sent?: Maybe<Scalars['DateTime']>;
  status: Status;
};

export type CourierProvider = {
  __typename: 'CourierProvider';
  clicked?: Maybe<Scalars['DateTime']>;
  delivered?: Maybe<Scalars['DateTime']>;
  error?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['DateTime']>;
  provider: Scalars['String'];
  sent?: Maybe<Scalars['DateTime']>;
  status: Status;
};

export enum Dir {
  ASC = 'asc',
  DESC = 'desc'
}

export enum DirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type DiscoverArtist = {
  __typename: 'DiscoverArtist';
  avgRating: Scalars['Float'];
  displayPriceRange?: Maybe<PriceRange>;
  id: Scalars['String'];
  name: Scalars['String'];
  numberOfReviews: Scalars['Int'];
  profileImage?: Maybe<Image>;
  slug: Scalars['String'];
};

export type DisplayImages = {
  __typename: 'DisplayImages';
  coverImage?: Maybe<Image>;
  profileImage?: Maybe<Image>;
};

export type DisplayImagesInput = {
  coverImage?: InputMaybe<ImageInput>;
  profileImage?: InputMaybe<ImageInput>;
};

export type DisplayPrices = {
  __typename: 'DisplayPrices';
  buyer: Scalars['Float'];
  seller: Scalars['Float'];
};

export enum EventStatus {
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  TENTATIVE = 'TENTATIVE'
}

export type ExternalBooking = {
  __typename: 'ExternalBooking';
  artistName: Scalars['String'];
  canIEdit: Scalars['Boolean'];
  coverImage?: Maybe<Image>;
  firebaseId: Scalars['String'];
  id: Scalars['String'];
  indoorsOrOutdoors?: Maybe<IndoorsOrOutdoors>;
  isPublished: Scalars['Boolean'];
  performanceLengthMins: Scalars['Int'];
  publicEventDescription?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  venue: Venue;
};

export type ExternalBookingInput = {
  artistName?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<ImageInput>;
  indoorsOrOutdoors?: InputMaybe<IndoorsOrOutdoors>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  performanceLengthMins: Scalars['Int'];
  publicEventDescription?: InputMaybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
};

export enum ExternalBookingOrderBy {
  CREATED_AT = 'created_at',
  LAST_UPDATED = 'last_updated',
  START_TIME = 'start_time'
}

export type ExternalBookingPatch = {
  artistName?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<ImageInput>;
  indoorsOrOutdoors?: InputMaybe<IndoorsOrOutdoors>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  performanceLengthMins?: InputMaybe<Scalars['Int']>;
  publicEventDescription?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type ExternalCalendarEvent = {
  __typename: 'ExternalCalendarEvent';
  busy: Scalars['Boolean'];
  calendarId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  participants: Array<Participant>;
  status: EventStatus;
  title: Scalars['String'];
  when: WhenTimeWhenTimespanWhenDateWhenDatespan;
};

export type ExternalUrls = {
  __typename: 'ExternalUrls';
  spotify?: Maybe<Scalars['String']>;
};

export type ExtraArtistInfo = {
  __typename: 'ExtraArtistInfo';
  artist: Artist;
  artistDetailsForBuyerByAdmin?: Maybe<Scalars['String']>;
  bookingRequest: BookingRequest;
  id: Scalars['String'];
  recommendedBy: User;
  recommendedPrice?: Maybe<Scalars['Float']>;
};

export type ExtraArtistInfoInput = {
  artistDetailsForBuyerByAdmin?: InputMaybe<Scalars['String']>;
  recommendedPrice?: InputMaybe<Scalars['Float']>;
};

export type ExtraArtistInfoPatch = {
  artistDetailsForBuyerByAdmin?: InputMaybe<Scalars['String']>;
  recommendedPrice?: InputMaybe<Scalars['Float']>;
};

export enum Filling {
  FADED = 'faded',
  FILLED = 'filled',
  OUTLINED = 'outlined'
}

export type FilterArtistAvailabilityResponse = {
  eq?: InputMaybe<ArtistAvailabilityResponse>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<ArtistAvailabilityResponse>;
  gte?: InputMaybe<ArtistAvailabilityResponse>;
  in?: InputMaybe<Array<ArtistAvailabilityResponse>>;
  lt?: InputMaybe<ArtistAvailabilityResponse>;
  lte?: InputMaybe<ArtistAvailabilityResponse>;
  neq?: InputMaybe<ArtistAvailabilityResponse>;
};

export type FilterArtistAvailabilityStatus = {
  eq?: InputMaybe<ArtistAvailabilityStatus>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<ArtistAvailabilityStatus>;
  gte?: InputMaybe<ArtistAvailabilityStatus>;
  in?: InputMaybe<Array<ArtistAvailabilityStatus>>;
  lt?: InputMaybe<ArtistAvailabilityStatus>;
  lte?: InputMaybe<ArtistAvailabilityStatus>;
  neq?: InputMaybe<ArtistAvailabilityStatus>;
};

export type FilterBandConfigurationOption = {
  eq?: InputMaybe<BandConfigurationOption>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<BandConfigurationOption>;
  gte?: InputMaybe<BandConfigurationOption>;
  in?: InputMaybe<Array<BandConfigurationOption>>;
  lt?: InputMaybe<BandConfigurationOption>;
  lte?: InputMaybe<BandConfigurationOption>;
  neq?: InputMaybe<BandConfigurationOption>;
};

export type FilterBidStatus = {
  eq?: InputMaybe<BidStatus>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<BidStatus>;
  gte?: InputMaybe<BidStatus>;
  in?: InputMaybe<Array<BidStatus>>;
  lt?: InputMaybe<BidStatus>;
  lte?: InputMaybe<BidStatus>;
  neq?: InputMaybe<BidStatus>;
};

export type FilterBilling = {
  eq?: InputMaybe<Billing>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Billing>;
  gte?: InputMaybe<Billing>;
  in?: InputMaybe<Array<Billing>>;
  lt?: InputMaybe<Billing>;
  lte?: InputMaybe<Billing>;
  neq?: InputMaybe<Billing>;
};

export type FilterBooking = {
  accountExec?: InputMaybe<NestedFilterUser>;
  adminCancellationMessage?: InputMaybe<FilterString>;
  and?: InputMaybe<Array<FilterBooking>>;
  artist?: InputMaybe<NestedFilterArtist>;
  artistAvailability?: InputMaybe<NestedFilterArtistAvailability>;
  artistId?: InputMaybe<FilterId>;
  artistName?: InputMaybe<FilterString>;
  artistSlug?: InputMaybe<FilterString>;
  bandConfiguration?: InputMaybe<FilterBandConfigurationOption>;
  billing?: InputMaybe<FilterBilling>;
  bookingDispute?: InputMaybe<NestedFilterBookingDispute>;
  bookingFlow?: InputMaybe<FilterBookingFlow>;
  bookingGroup?: InputMaybe<FilterString>;
  bookingRequest?: InputMaybe<NestedFilterBookingRequest>;
  buyer?: InputMaybe<NestedFilterUser>;
  buyerHasReviewed?: InputMaybe<FilterBoolean>;
  buyerId?: InputMaybe<FilterId>;
  buyerMetadata?: InputMaybe<FilterString>;
  buyerName?: InputMaybe<FilterString>;
  buyerSlug?: InputMaybe<FilterString>;
  buyersWithAccess?: InputMaybe<FilterString>;
  canSellerSee?: InputMaybe<FilterBoolean>;
  cancellationMessage?: InputMaybe<FilterString>;
  cancellationRequested?: InputMaybe<FilterBoolean>;
  cancellationRequests?: InputMaybe<NestedFilterCancellationRequest>;
  capacity?: InputMaybe<FilterInt16>;
  charge?: InputMaybe<FilterString>;
  chargeLink?: InputMaybe<NestedFilterCharge>;
  chargedBuyer?: InputMaybe<FilterBoolean>;
  comments?: InputMaybe<FilterString>;
  confirmation?: InputMaybe<NestedFilterBookingConfirmation>;
  createdAt?: InputMaybe<FilterDateTime>;
  customerBalanceTransactionId?: InputMaybe<FilterString>;
  deposit?: InputMaybe<FilterString>;
  firebaseId?: InputMaybe<FilterString>;
  hasBookingDispute?: InputMaybe<FilterBoolean>;
  hasCancellationRequest?: InputMaybe<FilterBoolean>;
  hasConfirmation?: InputMaybe<FilterBoolean>;
  hasExtraCharge?: InputMaybe<FilterBoolean>;
  hasExtraPayout?: InputMaybe<FilterBoolean>;
  hasPendingCancellationRequest?: InputMaybe<FilterBoolean>;
  id?: InputMaybe<FilterId>;
  indoorsOrOutdoors?: InputMaybe<FilterIndoorsOrOutdoors>;
  isPublished?: InputMaybe<FilterBoolean>;
  lastAction?: InputMaybe<FilterNegotiationAction>;
  lastCancellationRequest?: InputMaybe<NestedFilterCancellationRequest>;
  lastOfferAmount?: InputMaybe<FilterFloat32>;
  lastOnBehalfOfRole?: InputMaybe<FilterOnBehalfOfRole>;
  lastSellerOfferAmount?: InputMaybe<FilterFloat32>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  legacyId?: InputMaybe<FilterString>;
  location?: InputMaybe<FilterString>;
  negotiationStepModels?: InputMaybe<NestedFilterNegotiationStep>;
  negotiationSteps?: InputMaybe<FilterString>;
  not?: InputMaybe<FilterBooking>;
  oldBooking?: InputMaybe<NestedFilterBooking>;
  oldStartTime?: InputMaybe<FilterDateTime>;
  or?: InputMaybe<Array<FilterBooking>>;
  originalBookingFromReplaceArtist?: InputMaybe<NestedFilterBooking>;
  paidSeller?: InputMaybe<FilterBoolean>;
  payout?: InputMaybe<FilterString>;
  payoutLink?: InputMaybe<NestedFilterPayout>;
  performanceLengthMins?: InputMaybe<FilterInt16>;
  productionAndVenueSpecs?: InputMaybe<FilterString>;
  publicCoverImage?: InputMaybe<FilterString>;
  publicEventDescription?: InputMaybe<FilterString>;
  publicProfileImage?: InputMaybe<FilterString>;
  refundedInCredits?: InputMaybe<FilterBoolean>;
  replaceArtistOnBooking?: InputMaybe<NestedFilterBooking>;
  rescheduleMessage?: InputMaybe<FilterString>;
  revenue?: InputMaybe<FilterFloat32>;
  reviewsOfArtist?: InputMaybe<NestedFilterArtistReview>;
  sellersWithAccess?: InputMaybe<FilterString>;
  shouldAutoPublish?: InputMaybe<FilterBoolean>;
  startTime?: InputMaybe<FilterDateTime>;
  status?: InputMaybe<FilterBookingStatus>;
  statusV2?: InputMaybe<FilterBookingStatusV2>;
  usersWithAccess?: InputMaybe<FilterString>;
  venue?: InputMaybe<NestedFilterVenue>;
  venueId?: InputMaybe<FilterId>;
  venueName?: InputMaybe<FilterString>;
  venueSlug?: InputMaybe<FilterString>;
  version?: InputMaybe<FilterString>;
  weekOutNotification?: InputMaybe<FilterBoolean>;
  weekOutNotificationAt?: InputMaybe<FilterDateTime>;
};

export type FilterBookingConfirmation = {
  and?: InputMaybe<Array<FilterBookingConfirmation>>;
  booking?: InputMaybe<NestedFilterBooking>;
  confirmedAt?: InputMaybe<FilterDateTime>;
  confirmedBy?: InputMaybe<NestedFilterUser>;
  id?: InputMaybe<FilterId>;
  not?: InputMaybe<FilterBookingConfirmation>;
  or?: InputMaybe<Array<FilterBookingConfirmation>>;
  response?: InputMaybe<FilterBookingConfirmationResponse>;
  sellersWithAccess?: InputMaybe<FilterString>;
};

export type FilterBookingConfirmationResponse = {
  eq?: InputMaybe<BookingConfirmationResponse>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<BookingConfirmationResponse>;
  gte?: InputMaybe<BookingConfirmationResponse>;
  in?: InputMaybe<Array<BookingConfirmationResponse>>;
  lt?: InputMaybe<BookingConfirmationResponse>;
  lte?: InputMaybe<BookingConfirmationResponse>;
  neq?: InputMaybe<BookingConfirmationResponse>;
};

export type FilterBookingFlow = {
  eq?: InputMaybe<BookingFlow>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<BookingFlow>;
  gte?: InputMaybe<BookingFlow>;
  in?: InputMaybe<Array<BookingFlow>>;
  lt?: InputMaybe<BookingFlow>;
  lte?: InputMaybe<BookingFlow>;
  neq?: InputMaybe<BookingFlow>;
};

export type FilterBookingRequestStatus = {
  eq?: InputMaybe<BookingRequestStatus>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<BookingRequestStatus>;
  gte?: InputMaybe<BookingRequestStatus>;
  in?: InputMaybe<Array<BookingRequestStatus>>;
  lt?: InputMaybe<BookingRequestStatus>;
  lte?: InputMaybe<BookingRequestStatus>;
  neq?: InputMaybe<BookingRequestStatus>;
};

export type FilterBookingStatus = {
  eq?: InputMaybe<BookingStatus>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<BookingStatus>;
  gte?: InputMaybe<BookingStatus>;
  in?: InputMaybe<Array<BookingStatus>>;
  lt?: InputMaybe<BookingStatus>;
  lte?: InputMaybe<BookingStatus>;
  neq?: InputMaybe<BookingStatus>;
};

export type FilterBookingStatusV2 = {
  eq?: InputMaybe<BookingStatusV2>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<BookingStatusV2>;
  gte?: InputMaybe<BookingStatusV2>;
  in?: InputMaybe<Array<BookingStatusV2>>;
  lt?: InputMaybe<BookingStatusV2>;
  lte?: InputMaybe<BookingStatusV2>;
  neq?: InputMaybe<BookingStatusV2>;
};

export type FilterBoolean = {
  eq?: InputMaybe<Scalars['Boolean']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type FilterCancellationRequest = {
  and?: InputMaybe<Array<FilterCancellationRequest>>;
  booking?: InputMaybe<NestedFilterBooking>;
  createdAt?: InputMaybe<FilterDateTime>;
  id?: InputMaybe<FilterId>;
  key?: InputMaybe<FilterString>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  message?: InputMaybe<FilterString>;
  not?: InputMaybe<FilterCancellationRequest>;
  or?: InputMaybe<Array<FilterCancellationRequest>>;
  resolved?: InputMaybe<FilterBoolean>;
  user?: InputMaybe<NestedFilterUser>;
};

export type FilterCategory = {
  eq?: InputMaybe<Category>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Category>;
  gte?: InputMaybe<Category>;
  in?: InputMaybe<Array<Category>>;
  lt?: InputMaybe<Category>;
  lte?: InputMaybe<Category>;
  neq?: InputMaybe<Category>;
};

export type FilterDateTime = {
  eq?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  ilike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
};

export type FilterFloat32 = {
  eq?: InputMaybe<Scalars['Float']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
};

export type FilterFloat64 = {
  eq?: InputMaybe<Scalars['Float']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
};

export type FilterId = {
  eq?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  neq?: InputMaybe<Scalars['String']>;
};

export type FilterIndoorsOrOutdoors = {
  eq?: InputMaybe<IndoorsOrOutdoors>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<IndoorsOrOutdoors>;
  gte?: InputMaybe<IndoorsOrOutdoors>;
  in?: InputMaybe<Array<IndoorsOrOutdoors>>;
  lt?: InputMaybe<IndoorsOrOutdoors>;
  lte?: InputMaybe<IndoorsOrOutdoors>;
  neq?: InputMaybe<IndoorsOrOutdoors>;
};

export type FilterInt16 = {
  eq?: InputMaybe<Scalars['Int']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
};

export type FilterInt32 = {
  eq?: InputMaybe<Scalars['Int']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
};

export type FilterInt64 = {
  eq?: InputMaybe<Scalars['Int']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
};

export type FilterNegotiationAction = {
  eq?: InputMaybe<NegotiationAction>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<NegotiationAction>;
  gte?: InputMaybe<NegotiationAction>;
  in?: InputMaybe<Array<NegotiationAction>>;
  lt?: InputMaybe<NegotiationAction>;
  lte?: InputMaybe<NegotiationAction>;
  neq?: InputMaybe<NegotiationAction>;
};

export type FilterOnBehalfOfRole = {
  eq?: InputMaybe<OnBehalfOfRole>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<OnBehalfOfRole>;
  gte?: InputMaybe<OnBehalfOfRole>;
  in?: InputMaybe<Array<OnBehalfOfRole>>;
  lt?: InputMaybe<OnBehalfOfRole>;
  lte?: InputMaybe<OnBehalfOfRole>;
  neq?: InputMaybe<OnBehalfOfRole>;
};

export type FilterPlaidStatus = {
  eq?: InputMaybe<PlaidStatus>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<PlaidStatus>;
  gte?: InputMaybe<PlaidStatus>;
  in?: InputMaybe<Array<PlaidStatus>>;
  lt?: InputMaybe<PlaidStatus>;
  lte?: InputMaybe<PlaidStatus>;
  neq?: InputMaybe<PlaidStatus>;
};

export type FilterString = {
  eq?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  ilike?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  like?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
};

export type FilterUserRole = {
  eq?: InputMaybe<UserRole>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<UserRole>;
  gte?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  lt?: InputMaybe<UserRole>;
  lte?: InputMaybe<UserRole>;
  neq?: InputMaybe<UserRole>;
};

export type FilterUserType = {
  eq?: InputMaybe<UserType>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<UserType>;
  gte?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  lt?: InputMaybe<UserType>;
  lte?: InputMaybe<UserType>;
  neq?: InputMaybe<UserType>;
};

export type FilterVenueType = {
  eq?: InputMaybe<VenueType>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<VenueType>;
  gte?: InputMaybe<VenueType>;
  in?: InputMaybe<Array<VenueType>>;
  lt?: InputMaybe<VenueType>;
  lte?: InputMaybe<VenueType>;
  neq?: InputMaybe<VenueType>;
};

export type Followers = {
  __typename: 'Followers';
  total?: Maybe<Scalars['Int']>;
};

export type FrontendDirections = {
  __typename: 'FrontendDirections';
  notes?: Maybe<Array<Note>>;
  shortMessage?: Maybe<ShortMessage>;
  tooltip?: Maybe<Tooltip>;
};

export type GamificationMetric = {
  __typename: 'GamificationMetric';
  quality: Quality;
  score: Scalars['Int'];
  value: Scalars['Float'];
};

export type GamificationMetrics = {
  __typename: 'GamificationMetrics';
  avgRating: GamificationMetric;
  bookingsCount: GamificationMetric;
  cancellationRate: GamificationMetric;
  cancellationsCount: GamificationMetric;
  likeCount: GamificationMetric;
  numberOfReviews: GamificationMetric;
  responseRate: GamificationMetric;
  responseTimeMins: GamificationMetric;
  viewCount: GamificationMetric;
};

export type GeoLoc = {
  __typename: 'GeoLoc';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type Image = {
  __typename: 'Image';
  height: Scalars['Int'];
  publicCloudinaryId: Scalars['String'];
  url: Scalars['String'];
  verticalAlign?: Maybe<VerticalAlign>;
  width: Scalars['Int'];
};

export type ImageInput = {
  height: Scalars['Int'];
  publicCloudinaryId: Scalars['String'];
  url: Scalars['String'];
  verticalAlign?: InputMaybe<VerticalAlign>;
  width: Scalars['Int'];
};

export type ImageSpotify = {
  __typename: 'ImageSpotify';
  height: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type Images = {
  __typename: 'Images';
  allSources: Array<Image>;
  gallery: Array<Image>;
};

export type ImagesInput = {
  allSources?: Array<ImageInput>;
  gallery?: Array<ImageInput>;
};

export enum IndoorsOrOutdoors {
  INDOORS = 'Indoors',
  OUTDOORS = 'Outdoors'
}

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID'
}

export type IpResponse = {
  __typename: 'IpResponse';
  countryAbbr: Scalars['String'];
  stateAbbr: Scalars['String'];
};

export type LastMoved = {
  __typename: 'LastMoved';
  buyer?: Maybe<Array<NegotiationAction>>;
  seller?: Maybe<Array<NegotiationAction>>;
};

export type Location = {
  __typename: 'Location';
  city?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  placeId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  stateAbbr?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  city?: InputMaybe<Scalars['String']>;
  countryId?: InputMaybe<Scalars['Int']>;
  countryName?: InputMaybe<Scalars['String']>;
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  placeId?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  stateAbbr?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
  unit?: InputMaybe<Scalars['String']>;
};

export type Media = {
  __typename: 'Media';
  images: Images;
  songs: Songs;
  videos: Videos;
};

export type MediaInput = {
  images: ImagesInput;
  songs: SongsInput;
  videos: VideosInput;
};

export type Metadata = {
  __typename: 'Metadata';
  location?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  userType: UserType;
};

export type MetadataInput = {
  location?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  userType: UserType;
};

export type Metric = {
  __typename: 'Metric';
  barCharts: BarChartEdge;
  overview?: Maybe<Overview>;
};

export type Metrics = {
  __typename: 'Metrics';
  bookingsCount: Metric;
  revenue: Metric;
};

export type Mutation = {
  __typename: 'Mutation';
  acceptBidAdmin: Bid;
  acceptBidBuyer: Bid;
  acceptBookingAdmin: Booking;
  acceptBookingBuyer: Booking;
  acceptBookingSeller: Booking;
  addArtistsToBookingRequests: Array<BookingRequest>;
  advanceBooking: Booking;
  /** admin only */
  approve: User;
  assignAccountExecToBooking: Booking;
  assignAccountExecToBookingRequest: BookingRequest;
  cancelBooking: Booking;
  cancelBookingAdmin: Booking;
  cancelBookingRequests: Array<BookingRequest>;
  changePlaceId: Venue;
  /** admin only */
  changeUserRole: User;
  chargeBooking: Booking;
  confirmBooking: BookingConfirmation;
  counterBookingAdmin: Booking;
  counterBookingBuyer: Booking;
  counterBookingSeller: Booking;
  createArtist: Artist;
  createArtistAvailability: ArtistAvailability;
  createArtistAvailabilityCheck: ArtistAvailabilityCheck;
  createArtistReview: ArtistReview;
  createArtistVenueApplication: ArtistVenueApplicationStraw;
  createBidAdmin: Bid;
  createBidSeller: Bid;
  createBookingRequestsAdmin: Array<BookingRequest>;
  createBookingRequestsBuyer: Array<BookingRequest>;
  createBookingsAdmin: Array<Booking>;
  createBookingsBuyer: Array<Booking>;
  createCustomToken: Scalars['String'];
  createExternalBooking: ExternalBooking;
  createExtraArtistInfo: ExtraArtistInfo;
  createLinkToArtist: Artist;
  createLinkToVenue: Venue;
  createUser: User;
  createVenueFromPlaceId: Venue;
  declineBooking: Booking;
  declineBookingAdmin: Booking;
  declineBookingRequest: BookingRequestDecline;
  deleteArtist: Artist;
  deleteArtistReview: ArtistReview;
  deleteBankAccount?: Maybe<StripeBankAccount>;
  deleteExternalBooking: ExternalBooking;
  deleteExtraArtistInfo: ExtraArtistInfo;
  deleteUser: User;
  /** admin only */
  deleteUserAdmin: User;
  deleteUserForTesting: Scalars['Boolean'];
  deleteVenue: Venue;
  disputeBooking: BookingDispute;
  duplicateBooking: Booking;
  exchangeAuthCodeGoogle: Scalars['Boolean'];
  getCodeTestUser: Scalars['Boolean'];
  giveArtistAvailability: ArtistAvailabilityCheck;
  helloMutation: Scalars['String'];
  likeArtist: Scalars['Boolean'];
  linkAccountExec: Venue;
  login: Scalars['Boolean'];
  markBookingAsCharged: Booking;
  markBookingAsPaidOut: Booking;
  payoutBooking: Booking;
  /** queue_only */
  propagateArtist: Scalars['JSON'];
  /** queue_only */
  propagateBooking: Scalars['JSON'];
  /** queue_only */
  propagateUser: Scalars['JSON'];
  /** queue_only */
  propagateVenue: Scalars['JSON'];
  publishArtist: Artist;
  publishArtistReview: ArtistReview;
  publishBooking: Booking;
  refreshDiscoverPage: Scalars['Boolean'];
  rejectBid: Bid;
  removeArtistsFromBookingRequests: Array<BookingRequest>;
  removeCalendar: Scalars['Boolean'];
  replaceArtistOnBooking: Booking;
  replaceVenue: Booking;
  requestArtistAvailabilityForBooking: ArtistAvailability;
  resendCheck: ArtistAvailabilityCheck;
  resolveBookingDispute: BookingDispute;
  resolveCancellationRequest: CancellationRequest;
  sendPromoImageToEmail: SendImageToEmailResponse;
  sendPromoImageToPhone: SendImageToPhoneResponse;
  setArtistAvailabilityStatus: ArtistAvailability;
  setCustomBuyerPriceOnBid: Bid;
  setDefaultBankAccount?: Maybe<StripeBankAccount>;
  setDevice: Scalars['Boolean'];
  setPlaidAccessToken?: Maybe<SetPlaidAccessTokenResponse>;
  switchBuyerToSeller: User;
  switchSellerToBuyer: User;
  textUserLink: Scalars['Boolean'];
  trackPromoExport: Scalars['Boolean'];
  trackPushNotificationEvent: Scalars['Boolean'];
  undoDeclineBookingRequest: BookingRequestDecline;
  unsetDevice: Scalars['Boolean'];
  updateAdminDetails: Artist;
  updateArtist: Artist;
  updateArtistImages: Artist;
  updateArtistPerformanceDataSongs: ArtistPerformanceData;
  updateArtistReview: ArtistReview;
  updateArtistSongs: Artist;
  updateArtistVideos: Artist;
  updateBid: Bid;
  updateBookingPreference: Array<Question>;
  updateBookingPreferences: Array<Question>;
  updateBookingRequests: Array<BookingRequest>;
  updateBookings: Array<Booking>;
  updateBuyerPrice: Booking;
  updateExternalBooking: ExternalBooking;
  updateExtraArtistInfo: ExtraArtistInfo;
  updateLastOfferAmount: Booking;
  updateSellerPrice: Booking;
  updateSellerPriceOnBid: Bid;
  updateSpotifyId: Artist;
  updateUser: User;
  /** admin only */
  updateUserAdmin: User;
  updateVenue: Venue;
  uploadImage: Image;
  viewArtist: Scalars['Int'];
  withdrawBid: Bid;
};


export type MutationAcceptBidAdminArgs = {
  bidId: Scalars['String'];
  buyerFirebaseId: Scalars['String'];
  notifyAcceptedSellers: Scalars['Boolean'];
  notifyRejectedSellers: Scalars['Boolean'];
};


export type MutationAcceptBidBuyerArgs = {
  bidId: Scalars['String'];
};


export type MutationAcceptBookingAdminArgs = {
  bookingId: Scalars['String'];
  notifyBuyers: Scalars['Boolean'];
  notifySellers: Scalars['Boolean'];
  offerAmount?: InputMaybe<Scalars['Float']>;
  onBehalfOfRole: OnBehalfOfRole;
  sellerOfferAmount?: InputMaybe<Scalars['Float']>;
};


export type MutationAcceptBookingBuyerArgs = {
  bookingId: Scalars['String'];
  offerAmount: Scalars['Float'];
};


export type MutationAcceptBookingSellerArgs = {
  bookingId: Scalars['String'];
  sellerOfferAmount: Scalars['Float'];
};


export type MutationAddArtistsToBookingRequestsArgs = {
  artistIds: Array<Scalars['String']>;
  bookingRequestIds: Array<Scalars['String']>;
  extraArtistInfoInputs: Array<InputMaybe<ExtraArtistInfoInput>>;
  notifySellers?: InputMaybe<Scalars['Boolean']>;
};


export type MutationAdvanceBookingArgs = {
  bookingId: Scalars['String'];
  notifyBuyers: Scalars['Boolean'];
  notifySellers: Scalars['Boolean'];
};


export type MutationApproveArgs = {
  disapprove?: Scalars['Boolean'];
  userId: Scalars['String'];
};


export type MutationAssignAccountExecToBookingArgs = {
  accountExecId: Scalars['String'];
  bookingId: Scalars['String'];
};


export type MutationAssignAccountExecToBookingRequestArgs = {
  accountExecId: Scalars['String'];
  bookingRequestId: Scalars['String'];
};


export type MutationCancelBookingArgs = {
  bookingId: Scalars['String'];
  cancellationMessage: Scalars['String'];
};


export type MutationCancelBookingAdminArgs = {
  adminCancellationMessage?: InputMaybe<Scalars['String']>;
  bookingId: Scalars['String'];
  cancellationMessage: Scalars['String'];
  notifyBuyers: Scalars['Boolean'];
  notifySellers: Scalars['Boolean'];
  onBehalfOfRole: OnBehalfOfRole;
};


export type MutationCancelBookingRequestsArgs = {
  bookingRequestIds: Array<Scalars['String']>;
  cancellationMessage: Scalars['String'];
  notifySellers?: InputMaybe<Scalars['Boolean']>;
};


export type MutationChangePlaceIdArgs = {
  newPlaceId: Scalars['String'];
  venueId: Scalars['String'];
};


export type MutationChangeUserRoleArgs = {
  newMetadata: MetadataInput;
  newUserRole: UserRole;
  userId: Scalars['String'];
};


export type MutationChargeBookingArgs = {
  bookingId: Scalars['String'];
};


export type MutationConfirmBookingArgs = {
  bookingId: Scalars['UUID'];
  response: BookingConfirmationResponse;
};


export type MutationCounterBookingAdminArgs = {
  bookingId: Scalars['String'];
  notifyBuyers: Scalars['Boolean'];
  notifySellers: Scalars['Boolean'];
  offerAmount?: InputMaybe<Scalars['Float']>;
  onBehalfOfRole: OnBehalfOfRole;
  sellerOfferAmount?: InputMaybe<Scalars['Float']>;
};


export type MutationCounterBookingBuyerArgs = {
  bookingId: Scalars['String'];
  offerAmount: Scalars['Float'];
};


export type MutationCounterBookingSellerArgs = {
  bookingId: Scalars['String'];
  sellerOfferAmount: Scalars['Float'];
};


export type MutationCreateArtistArgs = {
  name: Scalars['String'];
  spotifyId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateArtistAvailabilityArgs = {
  bookingId?: InputMaybe<Scalars['UUID']>;
  input?: InputMaybe<ArtistAvailabilityInput>;
};


export type MutationCreateArtistAvailabilityCheckArgs = {
  artistAvailabilityId: Scalars['UUID'];
  artistId: Scalars['UUID'];
};


export type MutationCreateArtistReviewArgs = {
  bookingId: Scalars['String'];
  input: ArtistReviewInput;
};


export type MutationCreateArtistVenueApplicationArgs = {
  artistId: Scalars['String'];
  message: Scalars['String'];
  venueId: Scalars['String'];
};


export type MutationCreateBidAdminArgs = {
  artistDetailsForBuyerByAdmin?: InputMaybe<Scalars['String']>;
  artistId: Scalars['String'];
  bookingRequestId: Scalars['String'];
  customBuyerPrice?: InputMaybe<Scalars['Float']>;
  input: BidInput;
  notifyBuyers: Scalars['Boolean'];
  sellerPrice: Scalars['Float'];
};


export type MutationCreateBidSellerArgs = {
  artistId: Scalars['String'];
  bookingRequestId: Scalars['String'];
  input: BidInput;
  sellerPrice: Scalars['Float'];
};


export type MutationCreateBookingRequestsAdminArgs = {
  accountExecId?: InputMaybe<Scalars['String']>;
  artistIds: Array<Scalars['String']>;
  input: BookingRequestInput;
  notifySellers?: InputMaybe<Scalars['Boolean']>;
  startTimes: Array<Scalars['DateTime']>;
  venueId: Scalars['String'];
};


export type MutationCreateBookingRequestsBuyerArgs = {
  artistIds: Array<Scalars['String']>;
  input: BookingRequestInput;
  startTimes: Array<Scalars['DateTime']>;
  venueId: Scalars['String'];
};


export type MutationCreateBookingsAdminArgs = {
  artistId: Scalars['String'];
  bookingFlow: BookingFlow;
  buyerFirebaseId?: InputMaybe<Scalars['String']>;
  ignoreConflictingBookings?: Scalars['Boolean'];
  input: BookingInput;
  notifyBuyers?: Scalars['Boolean'];
  notifySellers?: Scalars['Boolean'];
  offerAmount: Scalars['Float'];
  sellerAutoAccepts?: InputMaybe<Scalars['Boolean']>;
  sellerOfferAmount?: InputMaybe<Scalars['Float']>;
  startTimes: Array<Scalars['DateTime']>;
  venueId?: InputMaybe<Scalars['String']>;
  venueLocationInput?: InputMaybe<LocationInput>;
};


export type MutationCreateBookingsBuyerArgs = {
  artistId: Scalars['String'];
  bookingFlow: BookingFlow;
  input: BookingInput;
  offerAmount: Scalars['Float'];
  startTimes: Array<Scalars['DateTime']>;
  venueId?: InputMaybe<Scalars['String']>;
  venueLocationInput?: InputMaybe<LocationInput>;
};


export type MutationCreateCustomTokenArgs = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationCreateExternalBookingArgs = {
  input: ExternalBookingInput;
  venueId: Scalars['String'];
};


export type MutationCreateExtraArtistInfoArgs = {
  artistId: Scalars['String'];
  bookingRequestId: Scalars['String'];
  input: ExtraArtistInfoInput;
};


export type MutationCreateLinkToArtistArgs = {
  artistId?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  remove?: Scalars['Boolean'];
  slug?: InputMaybe<Scalars['String']>;
};


export type MutationCreateLinkToVenueArgs = {
  phoneNumber: Scalars['String'];
  remove?: Scalars['Boolean'];
  venueId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
  signUpMessage?: InputMaybe<Scalars['String']>;
};


export type MutationCreateVenueFromPlaceIdArgs = {
  accountExecId?: InputMaybe<Scalars['String']>;
  ignoreDuplicatePlaceIds?: InputMaybe<Scalars['Boolean']>;
  placeId: Scalars['String'];
  venueName?: InputMaybe<Scalars['String']>;
  venueType: VenueType;
};


export type MutationDeclineBookingArgs = {
  bookingId: Scalars['String'];
};


export type MutationDeclineBookingAdminArgs = {
  bookingId: Scalars['String'];
  notifyBuyers: Scalars['Boolean'];
  notifySellers: Scalars['Boolean'];
  onBehalfOfRole: OnBehalfOfRole;
};


export type MutationDeclineBookingRequestArgs = {
  artistId: Scalars['String'];
  bookingRequestId: Scalars['String'];
  reasonForDecline: Scalars['String'];
};


export type MutationDeleteArtistArgs = {
  artistId?: InputMaybe<Scalars['String']>;
  artistSlug?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteArtistReviewArgs = {
  artistReviewId: Scalars['String'];
};


export type MutationDeleteBankAccountArgs = {
  bankAccountId: Scalars['String'];
  venueId: Scalars['String'];
};


export type MutationDeleteExternalBookingArgs = {
  externalBookingId: Scalars['String'];
};


export type MutationDeleteExtraArtistInfoArgs = {
  extraArtistInfoId: Scalars['String'];
};


export type MutationDeleteUserAdminArgs = {
  userId?: InputMaybe<Scalars['String']>;
  userSlug?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteUserForTestingArgs = {
  key: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationDeleteVenueArgs = {
  venueId?: InputMaybe<Scalars['String']>;
  venueSlug?: InputMaybe<Scalars['String']>;
};


export type MutationDisputeBookingArgs = {
  bookingId: Scalars['String'];
  message: Scalars['String'];
};


export type MutationDuplicateBookingArgs = {
  bookingId: Scalars['String'];
  newArtistBandConfiguration: BandConfigurationOption;
  newArtistId: Scalars['String'];
  newOfferAmount: Scalars['Float'];
  notifyBuyers: Scalars['Boolean'];
  notifySellers: Scalars['Boolean'];
};


export type MutationExchangeAuthCodeGoogleArgs = {
  authCode: Scalars['String'];
  redirectUri: Scalars['String'];
};


export type MutationGetCodeTestUserArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationGiveArtistAvailabilityArgs = {
  artistAvailabilityCheckId: Scalars['UUID'];
  response: ArtistAvailabilityResponse;
  responseMessage?: InputMaybe<Scalars['String']>;
};


export type MutationLikeArtistArgs = {
  artistId?: InputMaybe<Scalars['String']>;
  artistSlug?: InputMaybe<Scalars['String']>;
  unlike?: Scalars['Boolean'];
};


export type MutationLinkAccountExecArgs = {
  accountExecId: Scalars['String'];
  remove?: Scalars['Boolean'];
  venueId: Scalars['String'];
};


export type MutationMarkBookingAsChargedArgs = {
  bookingId: Scalars['String'];
  undo?: Scalars['Boolean'];
};


export type MutationMarkBookingAsPaidOutArgs = {
  bookingId: Scalars['String'];
  undo?: Scalars['Boolean'];
};


export type MutationPayoutBookingArgs = {
  bookingId: Scalars['String'];
};


export type MutationPropagateArtistArgs = {
  modelId: Scalars['String'];
};


export type MutationPropagateBookingArgs = {
  modelId: Scalars['String'];
};


export type MutationPropagateUserArgs = {
  modelId: Scalars['String'];
};


export type MutationPropagateVenueArgs = {
  modelId: Scalars['String'];
};


export type MutationPublishArtistArgs = {
  artistId?: InputMaybe<Scalars['String']>;
  unpublish?: InputMaybe<Scalars['Boolean']>;
};


export type MutationPublishArtistReviewArgs = {
  artistReviewId: Scalars['String'];
  unpublish?: Scalars['Boolean'];
};


export type MutationPublishBookingArgs = {
  bookingId: Scalars['String'];
  unpublish?: Scalars['Boolean'];
};


export type MutationRefreshDiscoverPageArgs = {
  key: Scalars['String'];
};


export type MutationRejectBidArgs = {
  bidId: Scalars['String'];
  notifySellers?: InputMaybe<Scalars['Boolean']>;
  rejectionMessage: Scalars['String'];
};


export type MutationRemoveArtistsFromBookingRequestsArgs = {
  artistIds: Array<Scalars['String']>;
  bookingRequestIds: Array<Scalars['String']>;
};


export type MutationReplaceArtistOnBookingArgs = {
  adminCancellationMessage?: InputMaybe<Scalars['String']>;
  bookingId: Scalars['String'];
  cancelOnBehalfOfRole: OnBehalfOfRole;
  cancellationMessage: Scalars['String'];
  newArtistId: Scalars['String'];
  newBandConfiguration?: InputMaybe<BandConfigurationOption>;
  newOfferAmount?: InputMaybe<Scalars['Float']>;
  notifyBuyers: Scalars['Boolean'];
  notifyOldSellers: Scalars['Boolean'];
  notifySellers: Scalars['Boolean'];
};


export type MutationReplaceVenueArgs = {
  bookingId: Scalars['UUID'];
  newBuyerId?: InputMaybe<Scalars['UUID']>;
  newVenueId: Scalars['UUID'];
};


export type MutationRequestArtistAvailabilityForBookingArgs = {
  artistIds: Array<Scalars['UUID']>;
  bookingId: Scalars['UUID'];
};


export type MutationResendCheckArgs = {
  artistAvailabilityCheckId: Scalars['UUID'];
};


export type MutationResolveBookingDisputeArgs = {
  bookingDisputeId: Scalars['String'];
};


export type MutationResolveCancellationRequestArgs = {
  id: Scalars['String'];
};


export type MutationSendPromoImageToEmailArgs = {
  imgUrl: Scalars['String'];
  message: Scalars['String'];
  redirectUrl: Scalars['String'];
  subject: Scalars['String'];
};


export type MutationSendPromoImageToPhoneArgs = {
  imgUrl: Scalars['String'];
  message: Scalars['String'];
  redirectUrl: Scalars['String'];
};


export type MutationSetArtistAvailabilityStatusArgs = {
  artistAvailabilityId: Scalars['UUID'];
  status: ArtistAvailabilityStatus;
};


export type MutationSetCustomBuyerPriceOnBidArgs = {
  bidId: Scalars['String'];
  customBuyerPrice: Scalars['Float'];
};


export type MutationSetDefaultBankAccountArgs = {
  bankAccountId: Scalars['String'];
  venueId: Scalars['String'];
};


export type MutationSetDeviceArgs = {
  deviceName: Scalars['String'];
  notificationToken: Scalars['String'];
};


export type MutationSetPlaidAccessTokenArgs = {
  metadataStringified: Scalars['String'];
  publicToken: Scalars['String'];
  venueId: Scalars['String'];
};


export type MutationSwitchBuyerToSellerArgs = {
  sellerUserType: SellerUserType;
  userId: Scalars['UUID'];
};


export type MutationSwitchSellerToBuyerArgs = {
  buyerUserType: BuyerUserType;
  location?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};


export type MutationTextUserLinkArgs = {
  captchaToken: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type MutationTrackPromoExportArgs = {
  imgUrl: Scalars['String'];
  redirectUrl: Scalars['String'];
  via: Scalars['String'];
};


export type MutationTrackPushNotificationEventArgs = {
  pushTrackingEvent: PushTrackingEvent;
  trackingUrl: Scalars['String'];
};


export type MutationUndoDeclineBookingRequestArgs = {
  declineId: Scalars['String'];
};


export type MutationUnsetDeviceArgs = {
  deviceName: Scalars['String'];
};


export type MutationUpdateAdminDetailsArgs = {
  artistId: Scalars['String'];
  details?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateArtistArgs = {
  artistId: Scalars['String'];
  patch: ArtistPatch;
};


export type MutationUpdateArtistImagesArgs = {
  artistId: Scalars['String'];
  images: Array<ImageInput>;
};


export type MutationUpdateArtistPerformanceDataSongsArgs = {
  artistId: Scalars['String'];
  songsTxt: Scalars['String'];
};


export type MutationUpdateArtistReviewArgs = {
  artistReviewId: Scalars['String'];
  patch: ArtistReviewPatch;
};


export type MutationUpdateArtistSongsArgs = {
  artistId: Scalars['String'];
  songs: SongsInput;
};


export type MutationUpdateArtistVideosArgs = {
  artistId: Scalars['String'];
  videos: VideosInput;
};


export type MutationUpdateBidArgs = {
  bidId: Scalars['String'];
  notifyBuyers?: InputMaybe<Scalars['Boolean']>;
  patch: BidPatch;
};


export type MutationUpdateBookingPreferenceArgs = {
  response: ResponseInput;
};


export type MutationUpdateBookingPreferencesArgs = {
  responses: Array<ResponseInput>;
};


export type MutationUpdateBookingRequestsArgs = {
  bookingRequestIds: Array<Scalars['String']>;
  notifySellers?: InputMaybe<Scalars['Boolean']>;
  patch: BookingRequestPatch;
  startTimeDeltaMins?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateBookingsArgs = {
  bookingIds: Array<Scalars['String']>;
  notifySellers?: Scalars['Boolean'];
  patch: BookingPatch;
  startTimeDeltaMins?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateBuyerPriceArgs = {
  bookingId: Scalars['String'];
  newPrice: Scalars['Float'];
};


export type MutationUpdateExternalBookingArgs = {
  externalBookingId: Scalars['String'];
  patch: ExternalBookingPatch;
};


export type MutationUpdateExtraArtistInfoArgs = {
  extraArtistInfoId: Scalars['String'];
  patch: ExtraArtistInfoPatch;
};


export type MutationUpdateLastOfferAmountArgs = {
  bookingId: Scalars['String'];
  newBuyerPrice: Scalars['Float'];
  takePercentage?: InputMaybe<Scalars['Float']>;
};


export type MutationUpdateSellerPriceArgs = {
  bookingId: Scalars['String'];
  newPrice: Scalars['Float'];
};


export type MutationUpdateSellerPriceOnBidArgs = {
  bidId: Scalars['String'];
  newSellerPrice: Scalars['Float'];
  notifyBuyers?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateSpotifyIdArgs = {
  artistId: Scalars['String'];
  newSpotifyId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  patch: UserPatch;
};


export type MutationUpdateUserAdminArgs = {
  patch: UserPatch;
  userId: Scalars['String'];
};


export type MutationUpdateVenueArgs = {
  patch: VenuePatch;
  venueId: Scalars['String'];
};


export type MutationUploadImageArgs = {
  imageBytes: Scalars['String'];
};


export type MutationViewArtistArgs = {
  artistId: Scalars['String'];
};


export type MutationWithdrawBidArgs = {
  bidId: Scalars['String'];
  notifyBuyers?: InputMaybe<Scalars['Boolean']>;
  withdrawalMessage?: InputMaybe<Scalars['String']>;
};

export type MyBadgeResponse = {
  __typename: 'MyBadgeResponse';
  artistAvailChecks: Scalars['Int'];
  bookingRequests: Scalars['Int'];
  bookings: Scalars['Int'];
  total: Scalars['Int'];
};

export enum MyBookingsFilter {
  ARTIST_BOOKINGS_ONLY = 'ARTIST_BOOKINGS_ONLY',
  BUYER_BOOKINGS_ONLY = 'BUYER_BOOKINGS_ONLY',
  PRIVATE_BUYER_BOOKINGS_ONLY = 'PRIVATE_BUYER_BOOKINGS_ONLY',
  VENUE_BOOKINGS_ONLY = 'VENUE_BOOKINGS_ONLY'
}

export enum NegotiationAction {
  ACCEPT = 'accept',
  CANCEL = 'cancel',
  COUNTER = 'counter',
  DECLINE = 'decline',
  OFFER = 'offer',
  RESCHEDULE = 'reschedule',
  SCHEDULE = 'schedule'
}

export type NegotiationStep = {
  __typename: 'NegotiationStep';
  action: NegotiationAction;
  description: NegotiationStepDescription;
  offerAmount: Scalars['Float'];
  onBehalfOfRole: OnBehalfOfRole;
  sellerOfferAmount: Scalars['Float'];
  timestamp: Scalars['DateTime'];
  userId: Scalars['String'];
  userRole: UserRole;
};

export type NegotiationStepDescription = {
  __typename: 'NegotiationStepDescription';
  admin: Scalars['String'];
  buyer: Scalars['String'];
  seller: Scalars['String'];
};

export type NestedFilterArtist = {
  adminDetails?: InputMaybe<FilterString>;
  artistPerformanceData?: InputMaybe<NestedFilterArtistPerformanceData>;
  artistReviews?: InputMaybe<NestedFilterArtistReview>;
  avgRating?: InputMaybe<FilterFloat64>;
  bandConfiguration?: InputMaybe<FilterString>;
  bio?: InputMaybe<FilterString>;
  bookingRequests?: InputMaybe<NestedFilterBookingRequest>;
  bookings?: InputMaybe<NestedFilterBooking>;
  category?: InputMaybe<FilterCategory>;
  confirmedBookings?: InputMaybe<NestedFilterBooking>;
  connectAccountId?: InputMaybe<FilterString>;
  contactInformation?: InputMaybe<FilterString>;
  coverImage?: InputMaybe<FilterString>;
  createdAt?: InputMaybe<FilterDateTime>;
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  lastBackendUpdated?: InputMaybe<FilterDateTime>;
  lastRedisRefreshed?: InputMaybe<FilterDateTime>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  likeCount?: InputMaybe<FilterInt64>;
  likes?: InputMaybe<NestedFilterArtistLike>;
  linkedPhoneNumbers?: InputMaybe<NestedFilterPhoneNumberToArtist>;
  location?: InputMaybe<FilterString>;
  media?: InputMaybe<FilterString>;
  migrationVersion?: InputMaybe<FilterInt16>;
  name?: InputMaybe<FilterString>;
  numberOfReviews?: InputMaybe<FilterInt64>;
  payoutsManually?: InputMaybe<FilterBoolean>;
  profileImage?: InputMaybe<FilterString>;
  published?: InputMaybe<FilterBoolean>;
  publishedArtistReviews?: InputMaybe<NestedFilterArtistReview>;
  relatedArtists?: InputMaybe<NestedFilterArtist>;
  sellerFirebaseIds?: InputMaybe<FilterString>;
  sellers?: InputMaybe<NestedFilterUser>;
  slug?: InputMaybe<FilterString>;
  socialMedia?: InputMaybe<FilterString>;
  spotifyData?: InputMaybe<NestedFilterArtistSpotifyData>;
  spotifyFollowers?: InputMaybe<FilterInt32>;
  spotifyId?: InputMaybe<FilterString>;
  spotifyPopularity?: InputMaybe<FilterInt16>;
  topReviews?: InputMaybe<NestedFilterArtistReview>;
  venueTakePercentages?: InputMaybe<FilterString>;
  version?: InputMaybe<FilterInt16>;
  viewCount?: InputMaybe<FilterInt64>;
  views?: InputMaybe<NestedFilterArtistView>;
};

export type NestedFilterArtistAvailability = {
  booking?: InputMaybe<NestedFilterBooking>;
  buyerDisplayName?: InputMaybe<FilterString>;
  checks?: InputMaybe<NestedFilterArtistAvailabilityCheck>;
  createdAt?: InputMaybe<FilterDateTime>;
  createdBy?: InputMaybe<NestedFilterUser>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  location?: InputMaybe<FilterString>;
  performanceLengthMins?: InputMaybe<FilterInt16>;
  startTime?: InputMaybe<FilterDateTime>;
  status?: InputMaybe<FilterArtistAvailabilityStatus>;
};

export type NestedFilterArtistAvailabilityCheck = {
  artist?: InputMaybe<NestedFilterArtist>;
  artistAvailability?: InputMaybe<NestedFilterArtistAvailability>;
  artistAvailabilityId?: InputMaybe<FilterId>;
  artistId?: InputMaybe<FilterId>;
  courierNotificationIds?: InputMaybe<FilterString>;
  createdAt?: InputMaybe<FilterDateTime>;
  createdBy?: InputMaybe<NestedFilterUser>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  notifiedAt?: InputMaybe<FilterDateTime>;
  respondedAt?: InputMaybe<FilterDateTime>;
  respondedBy?: InputMaybe<NestedFilterUser>;
  response?: InputMaybe<FilterArtistAvailabilityResponse>;
  responseMessage?: InputMaybe<FilterString>;
  sellersWithAccess?: InputMaybe<FilterString>;
  startTime?: InputMaybe<FilterDateTime>;
};

export type NestedFilterArtistLike = {
  artist?: InputMaybe<NestedFilterArtist>;
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  timestamp?: InputMaybe<FilterDateTime>;
  user?: InputMaybe<NestedFilterUser>;
};

export type NestedFilterArtistPerformanceData = {
  artist?: InputMaybe<NestedFilterArtist>;
  createdAt?: InputMaybe<FilterDateTime>;
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  songsTxt?: InputMaybe<FilterString>;
};

export type NestedFilterArtistReview = {
  artist?: InputMaybe<NestedFilterArtist>;
  author?: InputMaybe<NestedFilterUser>;
  authorLocStr?: InputMaybe<FilterString>;
  authorName?: InputMaybe<FilterString>;
  authorOrgStr?: InputMaybe<FilterString>;
  authorProfileImage?: InputMaybe<FilterString>;
  booking?: InputMaybe<NestedFilterBooking>;
  comment?: InputMaybe<FilterString>;
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  images?: InputMaybe<FilterString>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  legacyId?: InputMaybe<FilterString>;
  published?: InputMaybe<FilterBoolean>;
  rating?: InputMaybe<FilterInt16>;
  timestamp?: InputMaybe<FilterDateTime>;
  venue?: InputMaybe<NestedFilterVenue>;
};

export type NestedFilterArtistSpotifyData = {
  artist?: InputMaybe<NestedFilterArtist>;
  artistId?: InputMaybe<FilterId>;
  exists?: InputMaybe<Scalars['Boolean']>;
  followers?: InputMaybe<FilterInt32>;
  id?: InputMaybe<FilterId>;
  images?: InputMaybe<FilterString>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  popularity?: InputMaybe<FilterInt16>;
  spotifyId?: InputMaybe<FilterString>;
  url?: InputMaybe<FilterString>;
};

export type NestedFilterArtistView = {
  artist?: InputMaybe<NestedFilterArtist>;
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  timestamp?: InputMaybe<FilterDateTime>;
  totalViews?: InputMaybe<FilterInt32>;
  user?: InputMaybe<NestedFilterUser>;
};

export type NestedFilterBid = {
  artist?: InputMaybe<NestedFilterArtist>;
  artistId?: InputMaybe<FilterId>;
  bandConfiguration?: InputMaybe<FilterBandConfigurationOption>;
  bookingRequest?: InputMaybe<NestedFilterBookingRequest>;
  bookingRequestId?: InputMaybe<FilterId>;
  buyerHasSeen?: InputMaybe<FilterBoolean>;
  buyerPrice?: InputMaybe<FilterFloat32>;
  buyerSawAt?: InputMaybe<FilterDateTime>;
  buyersWithAccess?: InputMaybe<FilterString>;
  createdAt?: InputMaybe<FilterDateTime>;
  createdBy?: InputMaybe<NestedFilterUser>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  notes?: InputMaybe<FilterString>;
  rejectionMessage?: InputMaybe<FilterString>;
  sellerPrice?: InputMaybe<FilterFloat32>;
  sellersWithAccess?: InputMaybe<FilterString>;
  status?: InputMaybe<FilterBidStatus>;
  usersWithAccess?: InputMaybe<FilterString>;
  withdrawalMessage?: InputMaybe<FilterString>;
};

export type NestedFilterBooking = {
  accountExec?: InputMaybe<NestedFilterUser>;
  adminCancellationMessage?: InputMaybe<FilterString>;
  artist?: InputMaybe<NestedFilterArtist>;
  artistAvailability?: InputMaybe<NestedFilterArtistAvailability>;
  artistId?: InputMaybe<FilterId>;
  artistName?: InputMaybe<FilterString>;
  artistSlug?: InputMaybe<FilterString>;
  bandConfiguration?: InputMaybe<FilterBandConfigurationOption>;
  billing?: InputMaybe<FilterBilling>;
  bookingDispute?: InputMaybe<NestedFilterBookingDispute>;
  bookingFlow?: InputMaybe<FilterBookingFlow>;
  bookingGroup?: InputMaybe<FilterString>;
  bookingRequest?: InputMaybe<NestedFilterBookingRequest>;
  buyer?: InputMaybe<NestedFilterUser>;
  buyerHasReviewed?: InputMaybe<FilterBoolean>;
  buyerId?: InputMaybe<FilterId>;
  buyerMetadata?: InputMaybe<FilterString>;
  buyerName?: InputMaybe<FilterString>;
  buyerSlug?: InputMaybe<FilterString>;
  buyersWithAccess?: InputMaybe<FilterString>;
  canSellerSee?: InputMaybe<FilterBoolean>;
  cancellationMessage?: InputMaybe<FilterString>;
  cancellationRequested?: InputMaybe<FilterBoolean>;
  cancellationRequests?: InputMaybe<NestedFilterCancellationRequest>;
  capacity?: InputMaybe<FilterInt16>;
  charge?: InputMaybe<FilterString>;
  chargeLink?: InputMaybe<NestedFilterCharge>;
  chargedBuyer?: InputMaybe<FilterBoolean>;
  comments?: InputMaybe<FilterString>;
  confirmation?: InputMaybe<NestedFilterBookingConfirmation>;
  createdAt?: InputMaybe<FilterDateTime>;
  customerBalanceTransactionId?: InputMaybe<FilterString>;
  deposit?: InputMaybe<FilterString>;
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  hasBookingDispute?: InputMaybe<FilterBoolean>;
  hasCancellationRequest?: InputMaybe<FilterBoolean>;
  hasConfirmation?: InputMaybe<FilterBoolean>;
  hasExtraCharge?: InputMaybe<FilterBoolean>;
  hasExtraPayout?: InputMaybe<FilterBoolean>;
  hasPendingCancellationRequest?: InputMaybe<FilterBoolean>;
  id?: InputMaybe<FilterId>;
  indoorsOrOutdoors?: InputMaybe<FilterIndoorsOrOutdoors>;
  isPublished?: InputMaybe<FilterBoolean>;
  lastAction?: InputMaybe<FilterNegotiationAction>;
  lastCancellationRequest?: InputMaybe<NestedFilterCancellationRequest>;
  lastOfferAmount?: InputMaybe<FilterFloat32>;
  lastOnBehalfOfRole?: InputMaybe<FilterOnBehalfOfRole>;
  lastSellerOfferAmount?: InputMaybe<FilterFloat32>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  legacyId?: InputMaybe<FilterString>;
  location?: InputMaybe<FilterString>;
  negotiationStepModels?: InputMaybe<NestedFilterNegotiationStep>;
  negotiationSteps?: InputMaybe<FilterString>;
  oldBooking?: InputMaybe<NestedFilterBooking>;
  oldStartTime?: InputMaybe<FilterDateTime>;
  originalBookingFromReplaceArtist?: InputMaybe<NestedFilterBooking>;
  paidSeller?: InputMaybe<FilterBoolean>;
  payout?: InputMaybe<FilterString>;
  payoutLink?: InputMaybe<NestedFilterPayout>;
  performanceLengthMins?: InputMaybe<FilterInt16>;
  productionAndVenueSpecs?: InputMaybe<FilterString>;
  publicCoverImage?: InputMaybe<FilterString>;
  publicEventDescription?: InputMaybe<FilterString>;
  publicProfileImage?: InputMaybe<FilterString>;
  refundedInCredits?: InputMaybe<FilterBoolean>;
  replaceArtistOnBooking?: InputMaybe<NestedFilterBooking>;
  rescheduleMessage?: InputMaybe<FilterString>;
  revenue?: InputMaybe<FilterFloat32>;
  reviewsOfArtist?: InputMaybe<NestedFilterArtistReview>;
  sellersWithAccess?: InputMaybe<FilterString>;
  shouldAutoPublish?: InputMaybe<FilterBoolean>;
  startTime?: InputMaybe<FilterDateTime>;
  status?: InputMaybe<FilterBookingStatus>;
  statusV2?: InputMaybe<FilterBookingStatusV2>;
  usersWithAccess?: InputMaybe<FilterString>;
  venue?: InputMaybe<NestedFilterVenue>;
  venueId?: InputMaybe<FilterId>;
  venueName?: InputMaybe<FilterString>;
  venueSlug?: InputMaybe<FilterString>;
  version?: InputMaybe<FilterString>;
  weekOutNotification?: InputMaybe<FilterBoolean>;
  weekOutNotificationAt?: InputMaybe<FilterDateTime>;
};

export type NestedFilterBookingConfirmation = {
  booking?: InputMaybe<NestedFilterBooking>;
  confirmedAt?: InputMaybe<FilterDateTime>;
  confirmedBy?: InputMaybe<NestedFilterUser>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  response?: InputMaybe<FilterBookingConfirmationResponse>;
  sellersWithAccess?: InputMaybe<FilterString>;
};

export type NestedFilterBookingDispute = {
  booking?: InputMaybe<NestedFilterBooking>;
  createdAt?: InputMaybe<FilterDateTime>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  message?: InputMaybe<FilterString>;
  resolved?: InputMaybe<FilterBoolean>;
  resolvedAt?: InputMaybe<FilterDateTime>;
  resolvedBy?: InputMaybe<NestedFilterUser>;
  user?: InputMaybe<NestedFilterUser>;
  usersWithAccess?: InputMaybe<FilterString>;
};

export type NestedFilterBookingPreferenceResponses = {
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  responses?: InputMaybe<FilterString>;
  user?: InputMaybe<NestedFilterUser>;
};

export type NestedFilterBookingRequest = {
  accountExec?: InputMaybe<NestedFilterUser>;
  accountExecId?: InputMaybe<FilterId>;
  adminInstructions?: InputMaybe<FilterString>;
  artistIds?: InputMaybe<FilterId>;
  artistInstructions?: InputMaybe<FilterString>;
  artistSlugs?: InputMaybe<FilterString>;
  artists?: InputMaybe<NestedFilterArtist>;
  artistsWithActiveBidsFirebaseIds?: InputMaybe<FilterString>;
  bids?: InputMaybe<NestedFilterBid>;
  billing?: InputMaybe<FilterBilling>;
  booking?: InputMaybe<NestedFilterBooking>;
  bookingGroup?: InputMaybe<FilterString>;
  budget?: InputMaybe<FilterString>;
  buyersWithAccess?: InputMaybe<FilterString>;
  cancellationMessage?: InputMaybe<FilterString>;
  capacity?: InputMaybe<FilterInt16>;
  createdAt?: InputMaybe<FilterDateTime>;
  createdBy?: InputMaybe<NestedFilterUser>;
  declines?: InputMaybe<NestedFilterDecline>;
  exists?: InputMaybe<Scalars['Boolean']>;
  extraArtistInfos?: InputMaybe<FilterString>;
  extraArtistInfosV2?: InputMaybe<NestedFilterExtraArtistInfo>;
  firebaseId?: InputMaybe<FilterString>;
  hasPendingBids?: InputMaybe<FilterBoolean>;
  id?: InputMaybe<FilterId>;
  indoorsOrOutdoors?: InputMaybe<FilterIndoorsOrOutdoors>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  pendingBids?: InputMaybe<NestedFilterBid>;
  performanceLengthMins?: InputMaybe<FilterInt16>;
  productionAndVenueSpecs?: InputMaybe<FilterString>;
  publicEventDescription?: InputMaybe<FilterString>;
  sellersWithAccess?: InputMaybe<FilterString>;
  shouldAutoPublish?: InputMaybe<FilterBoolean>;
  startTime?: InputMaybe<FilterDateTime>;
  status?: InputMaybe<FilterBookingRequestStatus>;
  usersWithAccess?: InputMaybe<FilterString>;
  venue?: InputMaybe<NestedFilterVenue>;
  venueId?: InputMaybe<FilterId>;
  venueLocation?: InputMaybe<FilterString>;
  venueName?: InputMaybe<FilterString>;
  venueSlug?: InputMaybe<FilterString>;
  version?: InputMaybe<FilterString>;
};

export type NestedFilterCancellationRequest = {
  booking?: InputMaybe<NestedFilterBooking>;
  createdAt?: InputMaybe<FilterDateTime>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  key?: InputMaybe<FilterString>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  message?: InputMaybe<FilterString>;
  resolved?: InputMaybe<FilterBoolean>;
  user?: InputMaybe<NestedFilterUser>;
};

export type NestedFilterCharge = {
  booking?: InputMaybe<NestedFilterBooking>;
  cancelledInvoiceId?: InputMaybe<FilterString>;
  chargedBuyer?: InputMaybe<FilterBoolean>;
  chargedBuyerBy?: InputMaybe<NestedFilterUser>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  invoiceId?: InputMaybe<FilterString>;
  invoiceItemId?: InputMaybe<FilterString>;
  manuallyChargedBuyer?: InputMaybe<FilterBoolean>;
};

export type NestedFilterDecline = {
  artist?: InputMaybe<NestedFilterArtist>;
  bookingRequest?: InputMaybe<NestedFilterBookingRequest>;
  buyersWithAccessDecline?: InputMaybe<FilterString>;
  declinedBy?: InputMaybe<NestedFilterUser>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  reasonForDecline?: InputMaybe<FilterString>;
  sellersWithAccessDecline?: InputMaybe<FilterString>;
  timestamp?: InputMaybe<FilterDateTime>;
};

export type NestedFilterDevice = {
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  ipAddress?: InputMaybe<FilterString>;
  lastSeenAt?: InputMaybe<FilterDateTime>;
  name?: InputMaybe<FilterString>;
  notificationToken?: InputMaybe<FilterString>;
  uid?: InputMaybe<FilterString>;
  user?: InputMaybe<NestedFilterUser>;
};

export type NestedFilterExternalBooking = {
  artistName?: InputMaybe<FilterString>;
  billing?: InputMaybe<FilterBilling>;
  capacity?: InputMaybe<FilterInt16>;
  coverImage?: InputMaybe<FilterString>;
  createdAt?: InputMaybe<FilterDateTime>;
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  indoorsOrOutdoors?: InputMaybe<FilterIndoorsOrOutdoors>;
  isPublished?: InputMaybe<FilterBoolean>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  ownerFirebaseIds?: InputMaybe<FilterString>;
  performanceLengthMins?: InputMaybe<FilterInt16>;
  productionAndVenueSpecs?: InputMaybe<FilterString>;
  publicEventDescription?: InputMaybe<FilterString>;
  shouldAutoPublish?: InputMaybe<FilterBoolean>;
  startTime?: InputMaybe<FilterDateTime>;
  venue?: InputMaybe<NestedFilterVenue>;
  venueId?: InputMaybe<FilterId>;
  venueName?: InputMaybe<FilterString>;
  venueSlug?: InputMaybe<FilterString>;
  version?: InputMaybe<FilterString>;
};

export type NestedFilterExtraArtistInfo = {
  artist?: InputMaybe<NestedFilterArtist>;
  artistDetailsForBuyerByAdmin?: InputMaybe<FilterString>;
  bookingRequest?: InputMaybe<NestedFilterBookingRequest>;
  buyersWithAccessExtra?: InputMaybe<FilterString>;
  createdAt?: InputMaybe<FilterDateTime>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  recommendedBy?: InputMaybe<NestedFilterUser>;
  recommendedPrice?: InputMaybe<FilterFloat32>;
  sellersWithAccessExtra?: InputMaybe<FilterString>;
};

export type NestedFilterMetaBooking = {
  billing?: InputMaybe<FilterBilling>;
  capacity?: InputMaybe<FilterInt16>;
  createdAt?: InputMaybe<FilterDateTime>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  indoorsOrOutdoors?: InputMaybe<FilterIndoorsOrOutdoors>;
  isPublished?: InputMaybe<FilterBoolean>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  performanceLengthMins?: InputMaybe<FilterInt16>;
  productionAndVenueSpecs?: InputMaybe<FilterString>;
  publicEventDescription?: InputMaybe<FilterString>;
  shouldAutoPublish?: InputMaybe<FilterBoolean>;
  startTime?: InputMaybe<FilterDateTime>;
  venue?: InputMaybe<NestedFilterVenue>;
  version?: InputMaybe<FilterString>;
};

export type NestedFilterNegotiationStep = {
  action?: InputMaybe<FilterNegotiationAction>;
  booking?: InputMaybe<NestedFilterBooking>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  offerAmount?: InputMaybe<FilterFloat32>;
  onBehalfOfRole?: InputMaybe<FilterOnBehalfOfRole>;
  sellerOfferAmount?: InputMaybe<FilterFloat32>;
  timestamp?: InputMaybe<FilterDateTime>;
  user?: InputMaybe<NestedFilterUser>;
  userRole?: InputMaybe<FilterUserRole>;
};

export type NestedFilterPayout = {
  booking?: InputMaybe<NestedFilterBooking>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  manuallyPaidSeller?: InputMaybe<FilterBoolean>;
  manuallyPaidSellerBy?: InputMaybe<NestedFilterUser>;
  paidSeller?: InputMaybe<FilterBoolean>;
  transferId?: InputMaybe<FilterString>;
};

export type NestedFilterPhoneNumberToArtist = {
  artists?: InputMaybe<NestedFilterArtist>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  isLegacy?: InputMaybe<FilterBoolean>;
  linkedArtistIds?: InputMaybe<FilterId>;
  phoneNumber?: InputMaybe<FilterString>;
};

export type NestedFilterPhoneNumberToVenue = {
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  isLegacy?: InputMaybe<FilterBoolean>;
  phoneNumber?: InputMaybe<FilterString>;
  venueIds?: InputMaybe<FilterId>;
  venues?: InputMaybe<NestedFilterVenue>;
};

export type NestedFilterUser = {
  acceptTermsConditions?: InputMaybe<FilterBoolean>;
  accountExecBookingRequests?: InputMaybe<NestedFilterBookingRequest>;
  accountExecBookings?: InputMaybe<NestedFilterBooking>;
  adminType?: InputMaybe<FilterUserType>;
  allMyVenues?: InputMaybe<NestedFilterVenue>;
  approval?: InputMaybe<FilterString>;
  approved?: InputMaybe<FilterBoolean>;
  approvedBy?: InputMaybe<NestedFilterUser>;
  artistFirebaseIds?: InputMaybe<FilterString>;
  artistIds?: InputMaybe<FilterId>;
  artistNames?: InputMaybe<FilterString>;
  artistReviews?: InputMaybe<NestedFilterArtistReview>;
  artistSlugs?: InputMaybe<FilterString>;
  artists?: InputMaybe<NestedFilterArtist>;
  bookingPreferenceResponses?: InputMaybe<NestedFilterBookingPreferenceResponses>;
  bookings?: InputMaybe<NestedFilterBooking>;
  buyerBookingRequests?: InputMaybe<NestedFilterBookingRequest>;
  buyerBookings?: InputMaybe<NestedFilterBooking>;
  buyerSellerBookingRequests?: InputMaybe<NestedFilterBookingRequest>;
  buyerSellerBookings?: InputMaybe<NestedFilterBooking>;
  createdAt?: InputMaybe<FilterDateTime>;
  createdByEdgedb?: InputMaybe<FilterBoolean>;
  defaultStateAbbr?: InputMaybe<FilterString>;
  deletedAt?: InputMaybe<FilterDateTime>;
  devices?: InputMaybe<NestedFilterDevice>;
  email?: InputMaybe<FilterString>;
  exists?: InputMaybe<Scalars['Boolean']>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  legacyId?: InputMaybe<FilterString>;
  likeCount?: InputMaybe<FilterInt64>;
  likes?: InputMaybe<NestedFilterArtistLike>;
  metadata?: InputMaybe<FilterString>;
  name?: InputMaybe<FilterString>;
  notificationTokens?: InputMaybe<FilterString>;
  numberOfArtists?: InputMaybe<FilterInt64>;
  numberOfVenues?: InputMaybe<FilterInt64>;
  nylasAccessToken?: InputMaybe<FilterString>;
  otherStateResponse?: InputMaybe<FilterString>;
  phoneNumber?: InputMaybe<FilterString>;
  profileImage?: InputMaybe<FilterString>;
  referredById?: InputMaybe<FilterString>;
  sellerBookingRequests?: InputMaybe<NestedFilterBookingRequest>;
  sellerBookings?: InputMaybe<NestedFilterBooking>;
  signUpMessage?: InputMaybe<FilterString>;
  slackUserId?: InputMaybe<FilterString>;
  slug?: InputMaybe<FilterString>;
  userRole?: InputMaybe<FilterUserRole>;
  userType?: InputMaybe<FilterUserType>;
  venueBookings?: InputMaybe<NestedFilterBooking>;
  venueIds?: InputMaybe<FilterId>;
  venueNames?: InputMaybe<FilterString>;
  venues?: InputMaybe<NestedFilterVenue>;
  venuesAssignedTo?: InputMaybe<NestedFilterVenue>;
  viewCount?: InputMaybe<FilterInt64>;
  views?: InputMaybe<NestedFilterArtistView>;
};

export type NestedFilterVenue = {
  accountExec?: InputMaybe<NestedFilterUser>;
  accountExecId?: InputMaybe<FilterId>;
  artistReviews?: InputMaybe<NestedFilterArtistReview>;
  bookingRequests?: InputMaybe<NestedFilterBookingRequest>;
  bookings?: InputMaybe<NestedFilterBooking>;
  budget?: InputMaybe<FilterString>;
  capacity?: InputMaybe<FilterInt16>;
  colors?: InputMaybe<FilterString>;
  createdAt?: InputMaybe<FilterDateTime>;
  createdBy?: InputMaybe<NestedFilterUser>;
  createdByEdgedb?: InputMaybe<FilterBoolean>;
  customerId?: InputMaybe<FilterString>;
  description?: InputMaybe<FilterString>;
  displayImages?: InputMaybe<FilterString>;
  exists?: InputMaybe<Scalars['Boolean']>;
  externalBookings?: InputMaybe<NestedFilterExternalBooking>;
  firebaseId?: InputMaybe<FilterString>;
  id?: InputMaybe<FilterId>;
  images?: InputMaybe<FilterString>;
  lastTriggeredAt?: InputMaybe<FilterDateTime>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  linkedPhoneNumbers?: InputMaybe<NestedFilterPhoneNumberToVenue>;
  location?: InputMaybe<FilterString>;
  logo?: InputMaybe<FilterString>;
  metaBookings?: InputMaybe<NestedFilterMetaBooking>;
  name?: InputMaybe<FilterString>;
  numStages?: InputMaybe<FilterInt16>;
  ownerFirebaseIds?: InputMaybe<FilterString>;
  ownerIds?: InputMaybe<FilterId>;
  owners?: InputMaybe<NestedFilterUser>;
  parkingAndLoadingAccess?: InputMaybe<FilterString>;
  paysManually?: InputMaybe<FilterBoolean>;
  phoneNumber?: InputMaybe<FilterString>;
  placeId?: InputMaybe<FilterString>;
  plaidInfo?: InputMaybe<NestedFilterVenuePlaidInfo>;
  productionAndVenueSpecs?: InputMaybe<FilterString>;
  slug?: InputMaybe<FilterString>;
  socialMedia?: InputMaybe<FilterString>;
  venueType?: InputMaybe<FilterVenueType>;
  website?: InputMaybe<FilterString>;
};

export type NestedFilterVenuePlaidInfo = {
  accessToken?: InputMaybe<FilterString>;
  accountId?: InputMaybe<FilterString>;
  createdAt?: InputMaybe<FilterDateTime>;
  createdBy?: InputMaybe<NestedFilterUser>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<FilterId>;
  lastUpdated?: InputMaybe<FilterDateTime>;
  setAccessTokenBy?: InputMaybe<NestedFilterUser>;
  status?: InputMaybe<FilterPlaidStatus>;
  venue?: InputMaybe<NestedFilterVenue>;
};

export type Note = {
  __typename: 'Note';
  filling?: Maybe<Filling>;
  label?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  size?: Maybe<Size>;
  variant?: Maybe<Variant>;
};

export enum NullsOrderingEnum {
  BIGGEST = 'BIGGEST',
  SMALLEST = 'SMALLEST'
}

export enum OnBehalfOfRole {
  BUYER = 'buyer',
  SELLER = 'seller'
}

export type OrderArtist = {
  adminDetails?: InputMaybe<Ordering>;
  artistPerformanceData?: InputMaybe<OrderArtistPerformanceData>;
  artistReviews?: InputMaybe<OrderArtistReview>;
  avgRating?: InputMaybe<Ordering>;
  bandConfiguration?: InputMaybe<Ordering>;
  bio?: InputMaybe<Ordering>;
  bookingRequests?: InputMaybe<OrderBookingRequest>;
  bookings?: InputMaybe<OrderBooking>;
  category?: InputMaybe<Ordering>;
  confirmedBookings?: InputMaybe<OrderBooking>;
  connectAccountId?: InputMaybe<Ordering>;
  contactInformation?: InputMaybe<Ordering>;
  coverImage?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  lastBackendUpdated?: InputMaybe<Ordering>;
  lastRedisRefreshed?: InputMaybe<Ordering>;
  lastTriggeredAt?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  likeCount?: InputMaybe<Ordering>;
  likes?: InputMaybe<OrderArtistLike>;
  linkedPhoneNumbers?: InputMaybe<OrderPhoneNumberToArtist>;
  location?: InputMaybe<Ordering>;
  media?: InputMaybe<Ordering>;
  migrationVersion?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  numberOfReviews?: InputMaybe<Ordering>;
  payoutsManually?: InputMaybe<Ordering>;
  profileImage?: InputMaybe<Ordering>;
  published?: InputMaybe<Ordering>;
  publishedArtistReviews?: InputMaybe<OrderArtistReview>;
  relatedArtists?: InputMaybe<OrderArtist>;
  sellerFirebaseIds?: InputMaybe<Ordering>;
  sellers?: InputMaybe<OrderUser>;
  slug?: InputMaybe<Ordering>;
  socialMedia?: InputMaybe<Ordering>;
  spotifyData?: InputMaybe<OrderArtistSpotifyData>;
  spotifyFollowers?: InputMaybe<Ordering>;
  spotifyId?: InputMaybe<Ordering>;
  spotifyPopularity?: InputMaybe<Ordering>;
  topReviews?: InputMaybe<OrderArtistReview>;
  venueTakePercentages?: InputMaybe<Ordering>;
  version?: InputMaybe<Ordering>;
  viewCount?: InputMaybe<Ordering>;
  views?: InputMaybe<OrderArtistView>;
};

export type OrderArtistAvailability = {
  booking?: InputMaybe<OrderBooking>;
  buyerDisplayName?: InputMaybe<Ordering>;
  checks?: InputMaybe<OrderArtistAvailabilityCheck>;
  createdAt?: InputMaybe<Ordering>;
  createdBy?: InputMaybe<OrderUser>;
  id?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  location?: InputMaybe<Ordering>;
  performanceLengthMins?: InputMaybe<Ordering>;
  startTime?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
};

export type OrderArtistAvailabilityCheck = {
  artist?: InputMaybe<OrderArtist>;
  artistAvailability?: InputMaybe<OrderArtistAvailability>;
  artistAvailabilityId?: InputMaybe<Ordering>;
  artistId?: InputMaybe<Ordering>;
  courierNotificationIds?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  createdBy?: InputMaybe<OrderUser>;
  id?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  notifiedAt?: InputMaybe<Ordering>;
  respondedAt?: InputMaybe<Ordering>;
  respondedBy?: InputMaybe<OrderUser>;
  response?: InputMaybe<Ordering>;
  responseMessage?: InputMaybe<Ordering>;
  sellersWithAccess?: InputMaybe<Ordering>;
  startTime?: InputMaybe<Ordering>;
};

export type OrderArtistLike = {
  artist?: InputMaybe<OrderArtist>;
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  timestamp?: InputMaybe<Ordering>;
  user?: InputMaybe<OrderUser>;
};

export type OrderArtistPerformanceData = {
  artist?: InputMaybe<OrderArtist>;
  createdAt?: InputMaybe<Ordering>;
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  songsTxt?: InputMaybe<Ordering>;
};

export type OrderArtistReview = {
  artist?: InputMaybe<OrderArtist>;
  author?: InputMaybe<OrderUser>;
  authorLocStr?: InputMaybe<Ordering>;
  authorName?: InputMaybe<Ordering>;
  authorOrgStr?: InputMaybe<Ordering>;
  authorProfileImage?: InputMaybe<Ordering>;
  booking?: InputMaybe<OrderBooking>;
  comment?: InputMaybe<Ordering>;
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  images?: InputMaybe<Ordering>;
  lastTriggeredAt?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  legacyId?: InputMaybe<Ordering>;
  published?: InputMaybe<Ordering>;
  rating?: InputMaybe<Ordering>;
  timestamp?: InputMaybe<Ordering>;
  venue?: InputMaybe<OrderVenue>;
};

export type OrderArtistSpotifyData = {
  artist?: InputMaybe<OrderArtist>;
  artistId?: InputMaybe<Ordering>;
  followers?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  images?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  popularity?: InputMaybe<Ordering>;
  spotifyId?: InputMaybe<Ordering>;
  url?: InputMaybe<Ordering>;
};

export type OrderArtistView = {
  artist?: InputMaybe<OrderArtist>;
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  timestamp?: InputMaybe<Ordering>;
  totalViews?: InputMaybe<Ordering>;
  user?: InputMaybe<OrderUser>;
};

export type OrderBid = {
  artist?: InputMaybe<OrderArtist>;
  artistId?: InputMaybe<Ordering>;
  bandConfiguration?: InputMaybe<Ordering>;
  bookingRequest?: InputMaybe<OrderBookingRequest>;
  bookingRequestId?: InputMaybe<Ordering>;
  buyerHasSeen?: InputMaybe<Ordering>;
  buyerPrice?: InputMaybe<Ordering>;
  buyerSawAt?: InputMaybe<Ordering>;
  buyersWithAccess?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  createdBy?: InputMaybe<OrderUser>;
  id?: InputMaybe<Ordering>;
  lastTriggeredAt?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  notes?: InputMaybe<Ordering>;
  rejectionMessage?: InputMaybe<Ordering>;
  sellerPrice?: InputMaybe<Ordering>;
  sellersWithAccess?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
  usersWithAccess?: InputMaybe<Ordering>;
  withdrawalMessage?: InputMaybe<Ordering>;
};

export type OrderBooking = {
  accountExec?: InputMaybe<OrderUser>;
  adminCancellationMessage?: InputMaybe<Ordering>;
  artist?: InputMaybe<OrderArtist>;
  artistAvailability?: InputMaybe<OrderArtistAvailability>;
  artistId?: InputMaybe<Ordering>;
  artistName?: InputMaybe<Ordering>;
  artistSlug?: InputMaybe<Ordering>;
  bandConfiguration?: InputMaybe<Ordering>;
  billing?: InputMaybe<Ordering>;
  bookingDispute?: InputMaybe<OrderBookingDispute>;
  bookingFlow?: InputMaybe<Ordering>;
  bookingGroup?: InputMaybe<Ordering>;
  bookingRequest?: InputMaybe<OrderBookingRequest>;
  buyer?: InputMaybe<OrderUser>;
  buyerHasReviewed?: InputMaybe<Ordering>;
  buyerId?: InputMaybe<Ordering>;
  buyerMetadata?: InputMaybe<Ordering>;
  buyerName?: InputMaybe<Ordering>;
  buyerSlug?: InputMaybe<Ordering>;
  buyersWithAccess?: InputMaybe<Ordering>;
  canSellerSee?: InputMaybe<Ordering>;
  cancellationMessage?: InputMaybe<Ordering>;
  cancellationRequested?: InputMaybe<Ordering>;
  cancellationRequests?: InputMaybe<OrderCancellationRequest>;
  capacity?: InputMaybe<Ordering>;
  charge?: InputMaybe<Ordering>;
  chargeLink?: InputMaybe<OrderCharge>;
  chargedBuyer?: InputMaybe<Ordering>;
  comments?: InputMaybe<Ordering>;
  confirmation?: InputMaybe<OrderBookingConfirmation>;
  createdAt?: InputMaybe<Ordering>;
  customerBalanceTransactionId?: InputMaybe<Ordering>;
  deposit?: InputMaybe<Ordering>;
  firebaseId?: InputMaybe<Ordering>;
  hasBookingDispute?: InputMaybe<Ordering>;
  hasCancellationRequest?: InputMaybe<Ordering>;
  hasConfirmation?: InputMaybe<Ordering>;
  hasExtraCharge?: InputMaybe<Ordering>;
  hasExtraPayout?: InputMaybe<Ordering>;
  hasPendingCancellationRequest?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  indoorsOrOutdoors?: InputMaybe<Ordering>;
  isPublished?: InputMaybe<Ordering>;
  lastAction?: InputMaybe<Ordering>;
  lastCancellationRequest?: InputMaybe<OrderCancellationRequest>;
  lastOfferAmount?: InputMaybe<Ordering>;
  lastOnBehalfOfRole?: InputMaybe<Ordering>;
  lastSellerOfferAmount?: InputMaybe<Ordering>;
  lastTriggeredAt?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  legacyId?: InputMaybe<Ordering>;
  location?: InputMaybe<Ordering>;
  negotiationStepModels?: InputMaybe<OrderNegotiationStep>;
  negotiationSteps?: InputMaybe<Ordering>;
  oldBooking?: InputMaybe<OrderBooking>;
  oldStartTime?: InputMaybe<Ordering>;
  originalBookingFromReplaceArtist?: InputMaybe<OrderBooking>;
  paidSeller?: InputMaybe<Ordering>;
  payout?: InputMaybe<Ordering>;
  payoutLink?: InputMaybe<OrderPayout>;
  performanceLengthMins?: InputMaybe<Ordering>;
  productionAndVenueSpecs?: InputMaybe<Ordering>;
  publicCoverImage?: InputMaybe<Ordering>;
  publicEventDescription?: InputMaybe<Ordering>;
  publicProfileImage?: InputMaybe<Ordering>;
  refundedInCredits?: InputMaybe<Ordering>;
  replaceArtistOnBooking?: InputMaybe<OrderBooking>;
  rescheduleMessage?: InputMaybe<Ordering>;
  revenue?: InputMaybe<Ordering>;
  reviewsOfArtist?: InputMaybe<OrderArtistReview>;
  sellersWithAccess?: InputMaybe<Ordering>;
  shouldAutoPublish?: InputMaybe<Ordering>;
  startTime?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
  statusV2?: InputMaybe<Ordering>;
  usersWithAccess?: InputMaybe<Ordering>;
  venue?: InputMaybe<OrderVenue>;
  venueId?: InputMaybe<Ordering>;
  venueName?: InputMaybe<Ordering>;
  venueSlug?: InputMaybe<Ordering>;
  version?: InputMaybe<Ordering>;
  weekOutNotification?: InputMaybe<Ordering>;
  weekOutNotificationAt?: InputMaybe<Ordering>;
};

export type OrderBookingConfirmation = {
  booking?: InputMaybe<OrderBooking>;
  confirmedAt?: InputMaybe<Ordering>;
  confirmedBy?: InputMaybe<OrderUser>;
  id?: InputMaybe<Ordering>;
  response?: InputMaybe<Ordering>;
  sellersWithAccess?: InputMaybe<Ordering>;
};

export type OrderBookingDispute = {
  booking?: InputMaybe<OrderBooking>;
  createdAt?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  message?: InputMaybe<Ordering>;
  resolved?: InputMaybe<Ordering>;
  resolvedAt?: InputMaybe<Ordering>;
  resolvedBy?: InputMaybe<OrderUser>;
  user?: InputMaybe<OrderUser>;
  usersWithAccess?: InputMaybe<Ordering>;
};

export type OrderBookingPreferenceResponses = {
  id?: InputMaybe<Ordering>;
  responses?: InputMaybe<Ordering>;
  user?: InputMaybe<OrderUser>;
};

export type OrderBookingRequest = {
  accountExec?: InputMaybe<OrderUser>;
  accountExecId?: InputMaybe<Ordering>;
  adminInstructions?: InputMaybe<Ordering>;
  artistIds?: InputMaybe<Ordering>;
  artistInstructions?: InputMaybe<Ordering>;
  artistSlugs?: InputMaybe<Ordering>;
  artists?: InputMaybe<OrderArtist>;
  artistsWithActiveBidsFirebaseIds?: InputMaybe<Ordering>;
  bids?: InputMaybe<OrderBid>;
  billing?: InputMaybe<Ordering>;
  booking?: InputMaybe<OrderBooking>;
  bookingGroup?: InputMaybe<Ordering>;
  budget?: InputMaybe<Ordering>;
  buyersWithAccess?: InputMaybe<Ordering>;
  cancellationMessage?: InputMaybe<Ordering>;
  capacity?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  createdBy?: InputMaybe<OrderUser>;
  declines?: InputMaybe<OrderDecline>;
  extraArtistInfos?: InputMaybe<Ordering>;
  extraArtistInfosV2?: InputMaybe<OrderExtraArtistInfo>;
  firebaseId?: InputMaybe<Ordering>;
  hasPendingBids?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  indoorsOrOutdoors?: InputMaybe<Ordering>;
  lastTriggeredAt?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  pendingBids?: InputMaybe<OrderBid>;
  performanceLengthMins?: InputMaybe<Ordering>;
  productionAndVenueSpecs?: InputMaybe<Ordering>;
  publicEventDescription?: InputMaybe<Ordering>;
  sellersWithAccess?: InputMaybe<Ordering>;
  shouldAutoPublish?: InputMaybe<Ordering>;
  startTime?: InputMaybe<Ordering>;
  status?: InputMaybe<Ordering>;
  usersWithAccess?: InputMaybe<Ordering>;
  venue?: InputMaybe<OrderVenue>;
  venueId?: InputMaybe<Ordering>;
  venueLocation?: InputMaybe<Ordering>;
  venueName?: InputMaybe<Ordering>;
  venueSlug?: InputMaybe<Ordering>;
  version?: InputMaybe<Ordering>;
};

export type OrderCancellationRequest = {
  booking?: InputMaybe<OrderBooking>;
  createdAt?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  key?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  message?: InputMaybe<Ordering>;
  resolved?: InputMaybe<Ordering>;
  user?: InputMaybe<OrderUser>;
};

export type OrderCharge = {
  booking?: InputMaybe<OrderBooking>;
  cancelledInvoiceId?: InputMaybe<Ordering>;
  chargedBuyer?: InputMaybe<Ordering>;
  chargedBuyerBy?: InputMaybe<OrderUser>;
  id?: InputMaybe<Ordering>;
  invoiceId?: InputMaybe<Ordering>;
  invoiceItemId?: InputMaybe<Ordering>;
  manuallyChargedBuyer?: InputMaybe<Ordering>;
};

export type OrderDecline = {
  artist?: InputMaybe<OrderArtist>;
  bookingRequest?: InputMaybe<OrderBookingRequest>;
  buyersWithAccessDecline?: InputMaybe<Ordering>;
  declinedBy?: InputMaybe<OrderUser>;
  id?: InputMaybe<Ordering>;
  reasonForDecline?: InputMaybe<Ordering>;
  sellersWithAccessDecline?: InputMaybe<Ordering>;
  timestamp?: InputMaybe<Ordering>;
};

export type OrderDevice = {
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  ipAddress?: InputMaybe<Ordering>;
  lastSeenAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  notificationToken?: InputMaybe<Ordering>;
  uid?: InputMaybe<Ordering>;
  user?: InputMaybe<OrderUser>;
};

export type OrderExternalBooking = {
  artistName?: InputMaybe<Ordering>;
  billing?: InputMaybe<Ordering>;
  capacity?: InputMaybe<Ordering>;
  coverImage?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  indoorsOrOutdoors?: InputMaybe<Ordering>;
  isPublished?: InputMaybe<Ordering>;
  lastTriggeredAt?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  ownerFirebaseIds?: InputMaybe<Ordering>;
  performanceLengthMins?: InputMaybe<Ordering>;
  productionAndVenueSpecs?: InputMaybe<Ordering>;
  publicEventDescription?: InputMaybe<Ordering>;
  shouldAutoPublish?: InputMaybe<Ordering>;
  startTime?: InputMaybe<Ordering>;
  venue?: InputMaybe<OrderVenue>;
  venueId?: InputMaybe<Ordering>;
  venueName?: InputMaybe<Ordering>;
  venueSlug?: InputMaybe<Ordering>;
  version?: InputMaybe<Ordering>;
};

export type OrderExtraArtistInfo = {
  artist?: InputMaybe<OrderArtist>;
  artistDetailsForBuyerByAdmin?: InputMaybe<Ordering>;
  bookingRequest?: InputMaybe<OrderBookingRequest>;
  buyersWithAccessExtra?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  recommendedBy?: InputMaybe<OrderUser>;
  recommendedPrice?: InputMaybe<Ordering>;
  sellersWithAccessExtra?: InputMaybe<Ordering>;
};

export type OrderMetaBooking = {
  billing?: InputMaybe<Ordering>;
  capacity?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  indoorsOrOutdoors?: InputMaybe<Ordering>;
  isPublished?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  performanceLengthMins?: InputMaybe<Ordering>;
  productionAndVenueSpecs?: InputMaybe<Ordering>;
  publicEventDescription?: InputMaybe<Ordering>;
  shouldAutoPublish?: InputMaybe<Ordering>;
  startTime?: InputMaybe<Ordering>;
  venue?: InputMaybe<OrderVenue>;
  version?: InputMaybe<Ordering>;
};

export type OrderNegotiationStep = {
  action?: InputMaybe<Ordering>;
  booking?: InputMaybe<OrderBooking>;
  id?: InputMaybe<Ordering>;
  offerAmount?: InputMaybe<Ordering>;
  onBehalfOfRole?: InputMaybe<Ordering>;
  sellerOfferAmount?: InputMaybe<Ordering>;
  timestamp?: InputMaybe<Ordering>;
  user?: InputMaybe<OrderUser>;
  userRole?: InputMaybe<Ordering>;
};

export type OrderPayout = {
  booking?: InputMaybe<OrderBooking>;
  id?: InputMaybe<Ordering>;
  manuallyPaidSeller?: InputMaybe<Ordering>;
  manuallyPaidSellerBy?: InputMaybe<OrderUser>;
  paidSeller?: InputMaybe<Ordering>;
  transferId?: InputMaybe<Ordering>;
};

export type OrderPhoneNumberToArtist = {
  artists?: InputMaybe<OrderArtist>;
  id?: InputMaybe<Ordering>;
  isLegacy?: InputMaybe<Ordering>;
  linkedArtistIds?: InputMaybe<Ordering>;
  phoneNumber?: InputMaybe<Ordering>;
};

export type OrderPhoneNumberToVenue = {
  id?: InputMaybe<Ordering>;
  isLegacy?: InputMaybe<Ordering>;
  phoneNumber?: InputMaybe<Ordering>;
  venueIds?: InputMaybe<Ordering>;
  venues?: InputMaybe<OrderVenue>;
};

export type OrderUser = {
  acceptTermsConditions?: InputMaybe<Ordering>;
  accountExecBookingRequests?: InputMaybe<OrderBookingRequest>;
  accountExecBookings?: InputMaybe<OrderBooking>;
  adminType?: InputMaybe<Ordering>;
  allMyVenues?: InputMaybe<OrderVenue>;
  approval?: InputMaybe<Ordering>;
  approved?: InputMaybe<Ordering>;
  approvedBy?: InputMaybe<OrderUser>;
  artistFirebaseIds?: InputMaybe<Ordering>;
  artistIds?: InputMaybe<Ordering>;
  artistNames?: InputMaybe<Ordering>;
  artistReviews?: InputMaybe<OrderArtistReview>;
  artistSlugs?: InputMaybe<Ordering>;
  artists?: InputMaybe<OrderArtist>;
  bookingPreferenceResponses?: InputMaybe<OrderBookingPreferenceResponses>;
  bookings?: InputMaybe<OrderBooking>;
  buyerBookingRequests?: InputMaybe<OrderBookingRequest>;
  buyerBookings?: InputMaybe<OrderBooking>;
  buyerSellerBookingRequests?: InputMaybe<OrderBookingRequest>;
  buyerSellerBookings?: InputMaybe<OrderBooking>;
  createdAt?: InputMaybe<Ordering>;
  createdByEdgedb?: InputMaybe<Ordering>;
  defaultStateAbbr?: InputMaybe<Ordering>;
  deletedAt?: InputMaybe<Ordering>;
  devices?: InputMaybe<OrderDevice>;
  email?: InputMaybe<Ordering>;
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  lastTriggeredAt?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  legacyId?: InputMaybe<Ordering>;
  likeCount?: InputMaybe<Ordering>;
  likes?: InputMaybe<OrderArtistLike>;
  metadata?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  notificationTokens?: InputMaybe<Ordering>;
  numberOfArtists?: InputMaybe<Ordering>;
  numberOfVenues?: InputMaybe<Ordering>;
  nylasAccessToken?: InputMaybe<Ordering>;
  otherStateResponse?: InputMaybe<Ordering>;
  phoneNumber?: InputMaybe<Ordering>;
  profileImage?: InputMaybe<Ordering>;
  referredById?: InputMaybe<Ordering>;
  sellerBookingRequests?: InputMaybe<OrderBookingRequest>;
  sellerBookings?: InputMaybe<OrderBooking>;
  signUpMessage?: InputMaybe<Ordering>;
  slackUserId?: InputMaybe<Ordering>;
  slug?: InputMaybe<Ordering>;
  userRole?: InputMaybe<Ordering>;
  userType?: InputMaybe<Ordering>;
  venueBookings?: InputMaybe<OrderBooking>;
  venueIds?: InputMaybe<Ordering>;
  venueNames?: InputMaybe<Ordering>;
  venues?: InputMaybe<OrderVenue>;
  venuesAssignedTo?: InputMaybe<OrderVenue>;
  viewCount?: InputMaybe<Ordering>;
  views?: InputMaybe<OrderArtistView>;
};

export type OrderVenue = {
  accountExec?: InputMaybe<OrderUser>;
  accountExecId?: InputMaybe<Ordering>;
  artistReviews?: InputMaybe<OrderArtistReview>;
  bookingRequests?: InputMaybe<OrderBookingRequest>;
  bookings?: InputMaybe<OrderBooking>;
  budget?: InputMaybe<Ordering>;
  capacity?: InputMaybe<Ordering>;
  colors?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  createdBy?: InputMaybe<OrderUser>;
  createdByEdgedb?: InputMaybe<Ordering>;
  customerId?: InputMaybe<Ordering>;
  description?: InputMaybe<Ordering>;
  displayImages?: InputMaybe<Ordering>;
  externalBookings?: InputMaybe<OrderExternalBooking>;
  firebaseId?: InputMaybe<Ordering>;
  id?: InputMaybe<Ordering>;
  images?: InputMaybe<Ordering>;
  lastTriggeredAt?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  linkedPhoneNumbers?: InputMaybe<OrderPhoneNumberToVenue>;
  location?: InputMaybe<Ordering>;
  logo?: InputMaybe<Ordering>;
  metaBookings?: InputMaybe<OrderMetaBooking>;
  name?: InputMaybe<Ordering>;
  numStages?: InputMaybe<Ordering>;
  ownerFirebaseIds?: InputMaybe<Ordering>;
  ownerIds?: InputMaybe<Ordering>;
  owners?: InputMaybe<OrderUser>;
  parkingAndLoadingAccess?: InputMaybe<Ordering>;
  paysManually?: InputMaybe<Ordering>;
  phoneNumber?: InputMaybe<Ordering>;
  placeId?: InputMaybe<Ordering>;
  plaidInfo?: InputMaybe<OrderVenuePlaidInfo>;
  productionAndVenueSpecs?: InputMaybe<Ordering>;
  slug?: InputMaybe<Ordering>;
  socialMedia?: InputMaybe<Ordering>;
  venueType?: InputMaybe<Ordering>;
  website?: InputMaybe<Ordering>;
};

export type OrderVenuePlaidInfo = {
  accessToken?: InputMaybe<Ordering>;
  accountId?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  createdBy?: InputMaybe<OrderUser>;
  id?: InputMaybe<Ordering>;
  lastUpdated?: InputMaybe<Ordering>;
  setAccessTokenBy?: InputMaybe<OrderUser>;
  status?: InputMaybe<Ordering>;
  venue?: InputMaybe<OrderVenue>;
};

export type Ordering = {
  dir: DirectionEnum;
  nulls?: InputMaybe<NullsOrderingEnum>;
};

export type Overview = {
  __typename: 'Overview';
  allTimeValue: Scalars['Float'];
  growth?: Maybe<Scalars['Float']>;
};

export type Participant = {
  __typename: 'Participant';
  comment?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status: ParticipantStatus;
};

export enum ParticipantStatus {
  MAYBE = 'MAYBE',
  NO = 'NO',
  NOREPLY = 'NOREPLY',
  YES = 'YES'
}

export type Payout = {
  __typename: 'Payout';
  manuallyPaidSeller: Scalars['Boolean'];
  manuallyPaidSellerBy?: Maybe<Scalars['String']>;
  paidSeller: Scalars['Boolean'];
  transferId?: Maybe<Scalars['String']>;
};

export type PayoutMethod = {
  __typename: 'PayoutMethod';
  availablePayoutMethods?: Maybe<Array<Scalars['String']>>;
  bankName?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  funding?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
};

export type Pipeline = {
  __typename: 'Pipeline';
  confirmed: PipelineMetrics;
  pending: PipelineMetrics;
};

export type PipelineMetrics = {
  __typename: 'PipelineMetrics';
  bookingsCount: Scalars['Int'];
  revenue: Scalars['Float'];
};

export enum PlaidStatus {
  ERRORED = 'errored',
  PENDING = 'pending',
  SUCCESS = 'success',
  WAITING_FOR_MICRO_DEPOSITS = 'waiting_for_micro_deposits'
}

export type PossibleActions = {
  __typename: 'PossibleActions';
  buyer: LastMoved;
  seller: LastMoved;
};

export enum PreferenceType {
  ARTISTS = 'Artists',
  BUDGET = 'Budget',
  DATES = 'Dates',
  GENRES = 'Genres',
  LOCATION = 'Location',
  TEXT = 'Text',
  VENUE = 'Venue'
}

export type PriceRange = {
  __typename: 'PriceRange';
  high: Scalars['Float'];
  low: Scalars['Float'];
};

export type PriceRangeInput = {
  high: Scalars['Float'];
  low: Scalars['Float'];
};

export type PriceRangeOptional = {
  __typename: 'PriceRangeOptional';
  high?: Maybe<Scalars['Float']>;
  low?: Maybe<Scalars['Float']>;
};

export type PriceRangeOptionalInput = {
  high?: InputMaybe<Scalars['Float']>;
  low?: InputMaybe<Scalars['Float']>;
};

export type Prices = {
  __typename: 'Prices';
  private?: Maybe<PriceRange>;
  public?: Maybe<PriceRange>;
};

export type PricesInput = {
  private: PriceRangeInput;
  public: PriceRangeInput;
};

export type PublicInvoice = {
  __typename: 'PublicInvoice';
  amountDue: Scalars['Int'];
  created: Scalars['DateTime'];
  customerName: Scalars['String'];
  hostedInvoiceUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoicePdf?: Maybe<Scalars['String']>;
  paid: Scalars['Boolean'];
  status: InvoiceStatus;
};

export enum PublicOrPrivate {
  PRIVATE = 'private',
  PUBLIC = 'public'
}

export type PublicUser = {
  __typename: 'PublicUser';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type PublicUserUserDoesNotExist = PublicUser | UserDoesNotExist;

export enum PushTrackingEvent {
  CLICKED = 'CLICKED',
  DELIVERED = 'DELIVERED',
  OPENED = 'OPENED',
  READ = 'READ'
}

export enum Quality {
  BAD = 'bad',
  GOOD = 'good'
}

export type Query = {
  __typename: 'Query';
  adminSearchKey: Scalars['String'];
  artist: Artist;
  artistAvailability: ArtistAvailability;
  /** Seller with access or admin only. */
  artistAvailabilityCheck: ArtistAvailabilityCheck;
  artistBookingsByBuyerType: Array<BookingByBuyerType>;
  artistBookingsByCity: Array<BookingByCity>;
  artistBookingsByVenue: Array<BookingByVenue>;
  artistBookingsUpcoming: Pipeline;
  artistGamificationMetrics: GamificationMetrics;
  artistMetrics: Metrics;
  artistReview: ArtistReview;
  artistSearchKey: Scalars['String'];
  artistServerSide: ArtistArtistNotFoundResponse;
  artistStats: ArtistStats;
  artists: Array<Artist>;
  booking: Booking;
  bookingConfirmations: Array<BookingConfirmation>;
  bookingPreferences: Array<Question>;
  bookingRequest: BookingRequest;
  bookingRequests: Array<BookingRequest>;
  bookings: Array<Booking>;
  bookingsSearchKey: Scalars['String'];
  bookingsViaFilter: Array<Booking>;
  buyerToSellerAmount: Scalars['Float'];
  cancellationRequests: Array<CancellationRequest>;
  didILikeArtist: Scalars['Boolean'];
  discoverPage: Array<Row>;
  edgedbToFirebase: Scalars['String'];
  externalBooking: ExternalBooking;
  externalBookings: Array<ExternalBooking>;
  firebaseToEdgedb?: Maybe<Scalars['String']>;
  getIpInfo?: Maybe<IpResponse>;
  googleOauthUrl: Scalars['String'];
  hello: Scalars['String'];
  isArtistSlugAvailable: Scalars['Boolean'];
  isVenueSlugAvailable: Scalars['Boolean'];
  locationFromPlaceId: Location;
  me: UserUserDoesNotExist;
  myArtistReviews: Array<ArtistReview>;
  myArtists: Array<Artist>;
  /** @deprecated use my_badges */
  myBadgeCount: Scalars['Int'];
  myBadges: MyBadgeResponse;
  myBookingRequests: Array<BookingRequest>;
  myBookingRequestsWhereActionIsRequired: Array<BookingRequest>;
  myBookingRequestsWhereActionIsRequiredCount: Scalars['Int'];
  myBookings: Array<Booking>;
  myBookingsThatNeedReviews: Array<Booking>;
  myBookingsThatNeedReviewsCount: Scalars['Int'];
  myBookingsToConfirm: Array<Booking>;
  myBookingsWhereActionIsRequired: Array<Booking>;
  myBookingsWhereActionIsRequiredCount: Scalars['Int'];
  myExternalBookings: Array<ExternalBooking>;
  myExternalCalendarEvents: Array<ExternalCalendarEvent>;
  myLikedArtists: Array<ArtistLike>;
  myLikedArtistsSlugs: Array<Scalars['String']>;
  /** @deprecated use booking_requests instead */
  myVenueBookingRequests: Array<BookingRequest>;
  /** authed only */
  myVenues: Array<Venue>;
  plaidLinkToken: Scalars['String'];
  publicUser: PublicUserUserDoesNotExist;
  sellerToBuyerAmount: Scalars['Float'];
  spotifyArtistFromId: ArtistSpotify;
  spotifyArtistsFromName: Array<ArtistSpotify>;
  typesenseTypes: UserTypesenseArtistTypesenseArtistTypesensePublicBookingTypesenseVenueTypesense;
  user: User;
  userTypesSchema: Scalars['Boolean'];
  users: Array<User>;
  venue: Venue;
  venues: Array<Venue>;
};


export type QueryArtistArgs = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryArtistAvailabilityArgs = {
  id: Scalars['UUID'];
};


export type QueryArtistAvailabilityCheckArgs = {
  id: Scalars['UUID'];
};


export type QueryArtistBookingsByBuyerTypeArgs = {
  artistSlug: Scalars['String'];
  sortBy?: SortBy;
};


export type QueryArtistBookingsByCityArgs = {
  artistSlug: Scalars['String'];
  sortBy?: SortBy;
};


export type QueryArtistBookingsByVenueArgs = {
  artistSlug: Scalars['String'];
  sortBy?: SortBy;
};


export type QueryArtistBookingsUpcomingArgs = {
  artistSlug: Scalars['String'];
};


export type QueryArtistGamificationMetricsArgs = {
  artistSlug: Scalars['String'];
};


export type QueryArtistMetricsArgs = {
  artistSlug: Scalars['String'];
};


export type QueryArtistReviewArgs = {
  id: Scalars['String'];
};


export type QueryArtistSearchKeyArgs = {
  userFirebaseId?: InputMaybe<Scalars['String']>;
};


export type QueryArtistServerSideArgs = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryArtistStatsArgs = {
  artistSlug: Scalars['String'];
};


export type QueryArtistsArgs = {
  direction?: InputMaybe<Dir>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ArtistOrderBy>;
  published?: InputMaybe<Scalars['Boolean']>;
  slugs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBookingArgs = {
  id: Scalars['String'];
};


export type QueryBookingConfirmationsArgs = {
  filter?: InputMaybe<FilterBookingConfirmation>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderBookingConfirmation>;
};


export type QueryBookingRequestArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryBookingRequestsArgs = {
  accountExecFirebaseIds?: InputMaybe<Array<Scalars['String']>>;
  accountExecSlugs?: InputMaybe<Array<Scalars['String']>>;
  direction?: InputMaybe<Dir>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BookingRequestOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<BookingRequestStatus>>;
  venueQueryName?: InputMaybe<Scalars['String']>;
  venueSlugs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBookingsArgs = {
  accountExecFirebaseIds?: InputMaybe<Array<Scalars['String']>>;
  accountExecSlugs?: InputMaybe<Array<Scalars['String']>>;
  artistQueryName?: InputMaybe<Scalars['String']>;
  artistSlugs?: InputMaybe<Array<Scalars['String']>>;
  bookingFlows?: InputMaybe<Array<BookingFlow>>;
  cancellationRequestUserRole?: InputMaybe<UserRole>;
  cancellationRequestUserRoles?: InputMaybe<Array<UserRole>>;
  cancellationRequested?: InputMaybe<Scalars['Boolean']>;
  direction?: InputMaybe<Dir>;
  hasCancellationRequest?: InputMaybe<Scalars['Boolean']>;
  hasCharge?: InputMaybe<Scalars['Boolean']>;
  hasPayout?: InputMaybe<Scalars['Boolean']>;
  hasPendingCancellationRequest?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  notStatusesV2?: InputMaybe<Array<BookingStatusV2>>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BookingOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<BookingStatus>>;
  statusesV2?: InputMaybe<Array<BookingStatusV2>>;
  venueQueryName?: InputMaybe<Scalars['String']>;
  venueSlugs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryBookingsViaFilterArgs = {
  filter?: InputMaybe<FilterBooking>;
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
  order?: InputMaybe<OrderBooking>;
};


export type QueryBuyerToSellerAmountArgs = {
  bookingFlow: BookingFlow;
  buyerAmount: Scalars['Float'];
};


export type QueryCancellationRequestsArgs = {
  filter?: InputMaybe<FilterCancellationRequest>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderCancellationRequest>;
};


export type QueryDidILikeArtistArgs = {
  artistId: Scalars['String'];
};


export type QueryEdgedbToFirebaseArgs = {
  collection: Collection;
  edgedbId: Scalars['String'];
};


export type QueryExternalBookingArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryExternalBookingsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<Scalars['String']>;
};


export type QueryFirebaseToEdgedbArgs = {
  collection: Collection;
  firebaseId: Scalars['String'];
};


export type QueryGoogleOauthUrlArgs = {
  redirectUri: Scalars['String'];
};


export type QueryIsArtistSlugAvailableArgs = {
  slug: Scalars['String'];
};


export type QueryIsVenueSlugAvailableArgs = {
  slug: Scalars['String'];
};


export type QueryLocationFromPlaceIdArgs = {
  placeId: Scalars['String'];
};


export type QueryMyArtistReviewsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryMyArtistsArgs = {
  direction?: InputMaybe<Dir>;
  orderBy?: InputMaybe<ArtistOrderBy>;
};


export type QueryMyBookingRequestsArgs = {
  direction?: InputMaybe<Dir>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  notStatuses?: InputMaybe<Array<BookingRequestStatus>>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BookingRequestOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<BookingRequestStatus>>;
  venueSlugs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryMyBookingRequestsWhereActionIsRequiredArgs = {
  direction?: Dir;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: BookingRequestOrderBy;
};


export type QueryMyBookingsArgs = {
  artistQueryName?: InputMaybe<Scalars['String']>;
  artistSlugs?: InputMaybe<Array<Scalars['String']>>;
  bookingFlows?: InputMaybe<Array<BookingFlow>>;
  direction?: InputMaybe<Dir>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  myBookingsFilter?: InputMaybe<MyBookingsFilter>;
  notStatusesV2?: InputMaybe<Array<BookingStatusV2>>;
  offset: Scalars['Int'];
  orderBy?: InputMaybe<BookingOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
  statusesV2?: InputMaybe<Array<BookingStatusV2>>;
  venueSlugs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryMyBookingsThatNeedReviewsArgs = {
  direction?: Dir;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: BookingOrderBy;
};


export type QueryMyBookingsToConfirmArgs = {
  includeConfirmed?: InputMaybe<Scalars['Boolean']>;
};


export type QueryMyBookingsWhereActionIsRequiredArgs = {
  direction?: Dir;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: BookingOrderBy;
};


export type QueryMyExternalBookingsArgs = {
  direction?: InputMaybe<Dir>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalBookingOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
  venueSlugs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryMyExternalCalendarEventsArgs = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};


export type QueryMyLikedArtistsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryMyVenueBookingRequestsArgs = {
  direction?: InputMaybe<Dir>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BookingRequestOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<BookingStatus>>;
  venueSlugs?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryMyVenuesArgs = {
  direction?: InputMaybe<Dir>;
  orderBy?: InputMaybe<VenueOrderBy>;
};


export type QueryPlaidLinkTokenArgs = {
  venueId: Scalars['String'];
};


export type QueryPublicUserArgs = {
  slug: Scalars['String'];
};


export type QuerySellerToBuyerAmountArgs = {
  bookingFlow: BookingFlow;
  sellerAmount: Scalars['Float'];
};


export type QuerySpotifyArtistFromIdArgs = {
  id: Scalars['String'];
};


export type QuerySpotifyArtistsFromNameArgs = {
  name: Scalars['String'];
};


export type QueryTypesenseTypesArgs = {
  collectionName: TypesenseCollectionName;
};


export type QueryUserArgs = {
  firebaseId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryUserTypesSchemaArgs = {
  adminUserType: AdminUserType;
  buyerUserType: BuyerUserType;
  sellerUserType: SellerUserType;
};


export type QueryUsersArgs = {
  approved?: InputMaybe<Scalars['Boolean']>;
  direction?: InputMaybe<Dir>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserOrderBy>;
  publicOrPrivate?: InputMaybe<PublicOrPrivate>;
  slugs?: InputMaybe<Array<Scalars['String']>>;
  userRoles?: InputMaybe<Array<UserRole>>;
  userTypes?: InputMaybe<Array<UserType>>;
};


export type QueryVenueArgs = {
  frontendCacheIgnorer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};


export type QueryVenuesArgs = {
  direction?: InputMaybe<Dir>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VenueOrderBy>;
  slugs?: InputMaybe<Array<Scalars['String']>>;
};

export type Question = {
  __typename: 'Question';
  body: Scalars['String'];
  id: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  type?: Maybe<PreferenceType>;
};

export type ReceiptItem = {
  __typename: 'ReceiptItem';
  description: Scalars['String'];
  dollarAmount: Scalars['Float'];
  percentage?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type ResponseInput = {
  body: Scalars['String'];
  questionId: Scalars['String'];
};

export type Row = {
  __typename: 'Row';
  artists: Array<DiscoverArtist>;
  category?: Maybe<Category>;
  name: Scalars['String'];
  subgenres?: Maybe<Array<Scalars['String']>>;
};

export enum SellerUserType {
  AGENT = 'Agent',
  ARTIST = 'Artist',
  MANAGER = 'Manager',
  OTHER_SELLER = 'Other_Seller'
}

export type SendImageToEmailResponse = {
  __typename: 'SendImageToEmailResponse';
  email: Scalars['String'];
};

export type SendImageToPhoneResponse = {
  __typename: 'SendImageToPhoneResponse';
  phoneNumber: Scalars['String'];
};

export type SetPlaidAccessTokenResponse = {
  __typename: 'SetPlaidAccessTokenResponse';
  allBankAccounts: Array<StripeBankAccount>;
  createdBankAccount: StripeBankAccount;
};

export type ShortMessage = {
  __typename: 'ShortMessage';
  body?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum Size {
  LARGE = 'large',
  MEDIUM = 'medium',
  MINI = 'mini',
  SMALL = 'small',
  XL = 'xl'
}

export type SmallImage = {
  __typename: 'SmallImage';
  height?: Maybe<Scalars['Int']>;
  publicCloudinaryId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type SocialMedia = {
  __typename: 'SocialMedia';
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  soundcloud?: Maybe<Scalars['String']>;
  spotify?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

export type SocialMediaInput = {
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  soundcloud?: InputMaybe<Scalars['String']>;
  spotify?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type Songs = {
  __typename: 'Songs';
  soundcloud: Array<Scalars['String']>;
  spotify: Array<Scalars['String']>;
};

export type SongsInput = {
  soundcloud?: Array<Scalars['String']>;
  spotify?: Array<Scalars['String']>;
};

export enum SortBy {
  BOOKINGS_COUNT = 'bookings_count',
  REVENUE = 'revenue'
}

export enum Status {
  CLICKED = 'CLICKED',
  DELIVERED = 'DELIVERED',
  ENQUEUED = 'ENQUEUED',
  OPENED = 'OPENED',
  SENT = 'SENT',
  UNDELIVERABLE = 'UNDELIVERABLE',
  UNMAPPED = 'UNMAPPED',
  UNROUTABLE = 'UNROUTABLE'
}

export type StatusInformation = {
  __typename: 'StatusInformation';
  currentStep: StatusNode;
  currentStepIndex: Scalars['Int'];
  statusBar: Array<StatusNode>;
  totalStepCount: Scalars['Int'];
};

export type StatusNode = {
  __typename: 'StatusNode';
  buyerDescription: Scalars['String'];
  possibleActions: PossibleActions;
  sellerDescription: Scalars['String'];
  status: BookingStatusV2;
};

export type StripeBankAccount = {
  __typename: 'StripeBankAccount';
  accountHolderName?: Maybe<Scalars['String']>;
  accountHolderType?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDefault?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  object?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type Tooltip = {
  __typename: 'Tooltip';
  tempField?: Maybe<Scalars['String']>;
};

export type TransferMetadata = {
  __typename: 'TransferMetadata';
  bookingId?: Maybe<Scalars['String']>;
};

export type TransferStripe = {
  __typename: 'TransferStripe';
  amount: Scalars['Int'];
  amountReversed: Scalars['Int'];
  balanceTransaction: Scalars['String'];
  created: Scalars['Int'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  metadata: TransferMetadata;
  object: Scalars['String'];
  reversed: Scalars['Boolean'];
  sourceTransaction?: Maybe<Scalars['String']>;
  sourceType?: Maybe<Scalars['String']>;
  transferGroup?: Maybe<Scalars['String']>;
};

export enum TypesenseCollectionName {
  ARTISTS = 'artists',
  BOOKINGS = 'bookings',
  USERS = 'users',
  VENUES = 'venues'
}

export type User = {
  __typename: 'User';
  accountExecBookings: Array<Booking>;
  allMyVenues: Array<Venue>;
  approval?: Maybe<Approval>;
  approved: Scalars['Boolean'];
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<User>;
  artists: Array<Artist>;
  badgeCount: Scalars['Int'];
  bookingBadges: BookingBadgesResponse;
  bookingPreferences: Array<Question>;
  bookingRequestBadges: BookingRequestBadgesResponse;
  bookings: Array<Booking>;
  createdAt: Scalars['DateTime'];
  defaultStateAbbr?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  externalCalendarEvents: Array<ExternalCalendarEvent>;
  firebaseId: Scalars['String'];
  hasSyncedCalendar: Scalars['Boolean'];
  id: Scalars['String'];
  isApproved: Scalars['Boolean'];
  lastUpdated: Scalars['DateTime'];
  likes: Array<ArtistLike>;
  metadata: Metadata;
  name: Scalars['String'];
  numberOfArtists: Scalars['Int'];
  numberOfVenues: Scalars['Int'];
  otherStateResponse?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  profileImage?: Maybe<Image>;
  publicOrPrivate: PublicOrPrivate;
  signUpMessage?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  userRole: UserRole;
  userType: UserType;
  venues: Array<Venue>;
  venuesAssignedTo: Array<Venue>;
};


export type UserAccountExecBookingsArgs = {
  direction?: InputMaybe<Dir>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BookingOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
};


export type UserAllMyVenuesArgs = {
  direction?: InputMaybe<Dir>;
  orderBy?: InputMaybe<VenueOrderBy>;
};


export type UserArtistsArgs = {
  direction?: InputMaybe<Dir>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ArtistOrderBy>;
};


export type UserBookingsArgs = {
  direction?: InputMaybe<Dir>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BookingOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
};


export type UserExternalCalendarEventsArgs = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};


export type UserLikesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UserVenuesArgs = {
  direction?: InputMaybe<Dir>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<VenueOrderBy>;
  slugs?: InputMaybe<Array<Scalars['String']>>;
};


export type UserVenuesAssignedToArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type UserDoesNotExist = {
  __typename: 'UserDoesNotExist';
  exists: Scalars['Boolean'];
};

export type UserInput = {
  acceptTermsConditions: Scalars['Boolean'];
  defaultStateAbbr?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  metadata: MetadataInput;
  name: Scalars['String'];
  otherStateResponse?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<ImageInput>;
  userRole: UserRole;
};

export enum UserOrderBy {
  CREATED_AT = 'created_at',
  LAST_UPDATED = 'last_updated'
}

export type UserPatch = {
  email?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<MetadataInput>;
  name?: InputMaybe<Scalars['String']>;
  profileImage?: InputMaybe<ImageInput>;
  slug?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  ADMIN = 'admin',
  BUYER = 'buyer',
  SELLER = 'seller'
}

export enum UserType {
  AGENT = 'Agent',
  ARTIST = 'Artist',
  BAR = 'Bar',
  BREWERY = 'Brewery',
  CORPORATE_EVENT = 'Corporate_Event',
  COUNTRY_CLUB = 'Country_Club',
  FRATERNITY_SORORITY = 'Fraternity_Sorority',
  HOTEL_RESORT = 'Hotel_Resort',
  MANAGER = 'Manager',
  MUNICIPALITY = 'Municipality',
  NIGHTCLUB = 'Nightclub',
  OTHER_BUYER = 'Other_Buyer',
  OTHER_SELLER = 'Other_Seller',
  PROMOTER = 'Promoter',
  RESTAURANT = 'Restaurant',
  THEATER = 'Theater',
  UNIVERSITY_PROGRAM_BOARD = 'University_Program_Board',
  VINEYARD_WINERY = 'Vineyard_Winery',
  WEDDING = 'Wedding',
  EMPLOYEE = 'employee',
  OWNER = 'owner',
  READ_ONLY = 'read_only'
}

export type UserTypesense = {
  __typename: 'UserTypesense';
  approved: Scalars['Boolean'];
  artistNamesStr?: Maybe<Scalars['String']>;
  createdAt: Scalars['Int'];
  email: Scalars['String'];
  firebaseId: Scalars['String'];
  id: Scalars['String'];
  lastTriggeredToTypesenseAt: Scalars['Int'];
  lastUpdated: Scalars['Int'];
  metadata: Metadata;
  metadataLocation?: Maybe<Scalars['String']>;
  metadataOrganization?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objectID: Scalars['String'];
  phoneNumber: Scalars['String'];
  profileImage?: Maybe<Image>;
  publicOrPrivate: PublicOrPrivate;
  slug: Scalars['String'];
  userRole: UserRole;
  userType: UserType;
  venueNamesStr?: Maybe<Scalars['String']>;
};

export type UserTypesenseArtistTypesenseArtistTypesensePublicBookingTypesenseVenueTypesense = ArtistTypesense | ArtistTypesensePublic | BookingTypesense | UserTypesense | VenueTypesense;

export type UserUserDoesNotExist = User | UserDoesNotExist;

export enum Variant {
  ACCENT = 'accent',
  CALLOUT = 'callout',
  DEFAULT = 'default',
  ERROR = 'error',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  WARNING = 'warning'
}

export type Venue = {
  __typename: 'Venue';
  accountExec?: Maybe<User>;
  bankAccounts: Array<StripeBankAccount>;
  bookingRequests: Array<BookingRequest>;
  bookings: Array<Booking>;
  budget?: Maybe<PriceRangeOptional>;
  calendarBookings: CalendarBookingsResponse;
  capacity?: Maybe<Scalars['Int']>;
  colors?: Maybe<Colors>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  defaultBankAccount?: Maybe<StripeBankAccount>;
  description?: Maybe<Scalars['String']>;
  displayImages?: Maybe<DisplayImages>;
  externalBookings: Array<ExternalBooking>;
  firebaseId: Scalars['String'];
  genresBooked?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  images?: Maybe<Array<Image>>;
  invoices: Array<PublicInvoice>;
  lastUpdated: Scalars['DateTime'];
  location: Location;
  logo?: Maybe<Image>;
  name: Scalars['String'];
  numStages: Scalars['Int'];
  owners: Array<User>;
  parkingAndLoadingAccess?: Maybe<Scalars['String']>;
  pendingUserPhoneNumbers: Array<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  placeId: Scalars['String'];
  productionAndVenueSpecs?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  socialMedia?: Maybe<SocialMedia>;
  venueType: VenueType;
  website?: Maybe<Scalars['String']>;
};


export type VenueBookingRequestsArgs = {
  direction?: InputMaybe<Dir>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BookingRequestOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
  statuses?: InputMaybe<Array<BookingRequestStatus>>;
};


export type VenueBookingsArgs = {
  direction?: InputMaybe<Dir>;
  limit?: InputMaybe<Scalars['Int']>;
  notStatusesV2?: InputMaybe<Array<BookingStatusV2>>;
  orderBy?: InputMaybe<BookingOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
  statusesV2?: InputMaybe<Array<BookingStatusV2>>;
};


export type VenueCalendarBookingsArgs = {
  end?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['DateTime']>;
};


export type VenueExternalBookingsArgs = {
  direction?: InputMaybe<Dir>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ExternalBookingOrderBy>;
  startTimeMax?: InputMaybe<Scalars['DateTime']>;
  startTimeMin?: InputMaybe<Scalars['DateTime']>;
};


export type VenueInvoicesArgs = {
  limit?: Scalars['Int'];
};

export enum VenueOrderBy {
  CREATED_AT = 'created_at',
  LAST_UPDATED = 'last_updated'
}

export type VenuePatch = {
  budget?: InputMaybe<PriceRangeOptionalInput>;
  capacity?: InputMaybe<Scalars['Int']>;
  colors?: InputMaybe<ColorsInput>;
  description?: InputMaybe<Scalars['String']>;
  displayImages?: InputMaybe<DisplayImagesInput>;
  genresBooked?: InputMaybe<Array<Scalars['String']>>;
  images?: InputMaybe<Array<ImageInput>>;
  logo?: InputMaybe<ImageInput>;
  name?: InputMaybe<Scalars['String']>;
  numStages?: InputMaybe<Scalars['Int']>;
  parkingAndLoadingAccess?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  placeId?: InputMaybe<Scalars['String']>;
  productionAndVenueSpecs?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  socialMedia?: InputMaybe<SocialMediaInput>;
  venueType?: InputMaybe<VenueType>;
  website?: InputMaybe<Scalars['String']>;
};

export enum VenueType {
  BAR = 'Bar',
  BREWERY = 'Brewery',
  COUNTRY_CLUB = 'Country_Club',
  HOTEL = 'Hotel',
  MUNICIPALITY = 'Municipality',
  NIGHTCLUB = 'Nightclub',
  OTHER_VENUE = 'Other_Venue',
  RESTAURANT = 'Restaurant',
  THEATER = 'Theater',
  VINEYARD_WINERY = 'Vineyard_Winery'
}

export type VenueTypesense = {
  __typename: 'VenueTypesense';
  createdAt: Scalars['Int'];
  firebaseId: Scalars['String'];
  id: Scalars['String'];
  lastTriggeredToTypesenseAt: Scalars['Int'];
  lastUpdated: Scalars['Int'];
  location: Location;
  locationGeopoint: Array<Scalars['Float']>;
  name: Scalars['String'];
  objectID: Scalars['String'];
  slug: Scalars['String'];
  venueType: VenueType;
};

export enum VerticalAlign {
  BOTTOM = 'BOTTOM',
  CENTER = 'CENTER',
  TOP = 'TOP'
}

export type Videos = {
  __typename: 'Videos';
  youtube: Array<Scalars['String']>;
};

export type VideosInput = {
  youtube?: Array<Scalars['String']>;
};

export enum WhatShouldIDoAlert {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  AWAITING_RESPONSE = 'AWAITING_RESPONSE'
}

export type WhatShouldIDoAlerts = {
  __typename: 'WhatShouldIDoAlerts';
  buyer?: Maybe<WhatShouldIDoAlert>;
  seller?: Maybe<WhatShouldIDoAlert>;
};

export type WhenDate = {
  __typename: 'WhenDate';
  date: Scalars['Date'];
};

export type WhenDatespan = {
  __typename: 'WhenDatespan';
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type WhenTime = {
  __typename: 'WhenTime';
  time: Scalars['DateTime'];
};

export type WhenTimeWhenTimespanWhenDateWhenDatespan = WhenDate | WhenDatespan | WhenTime | WhenTimespan;

export type WhenTimespan = {
  __typename: 'WhenTimespan';
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type AdminSearchKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminSearchKeyQuery = { __typename: 'Query', adminSearchKey: string };

export type DiscoverPageQueryVariables = Exact<{ [key: string]: never; }>;


export type DiscoverPageQuery = { __typename: 'Query', discoverPage: Array<{ __typename: 'Row', name: string, category?: Category | undefined, subgenres?: Array<string> | undefined, artists: Array<{ __typename: 'DiscoverArtist', id: string, slug: string, name: string, numberOfReviews: number, avgRating: number, profileImage?: { __typename: 'Image', publicCloudinaryId: string, height: number, width: number } | undefined, displayPriceRange?: { __typename: 'PriceRange', high: number, low: number } | undefined }> }> };

export type UploadImageMutationVariables = Exact<{
  imageBytes: Scalars['String'];
}>;


export type UploadImageMutation = { __typename: 'Mutation', uploadImage: { __typename: 'Image', url: string, height: number, width: number, public_cloudinary_id: string } };

export type ViewNotificationMutationVariables = Exact<{
  trackingUrl: Scalars['String'];
  pushTrackingEvent: PushTrackingEvent;
}>;


export type ViewNotificationMutation = { __typename: 'Mutation', trackPushNotificationEvent: boolean };

export type SetExpoDeviceMutationVariables = Exact<{
  deviceName: Scalars['String'];
  token: Scalars['String'];
}>;


export type SetExpoDeviceMutation = { __typename: 'Mutation', setDevice: boolean };

export type UnsetExpoDeviceMutationVariables = Exact<{
  deviceName: Scalars['String'];
}>;


export type UnsetExpoDeviceMutation = { __typename: 'Mutation', unsetDevice: boolean };

export type BuyerToSellerPriceQueryVariables = Exact<{
  buyerOfferAmount: Scalars['Float'];
  bookingFlow: BookingFlow;
}>;


export type BuyerToSellerPriceQuery = { __typename: 'Query', buyerToSellerAmount: number };

export type StaticallyGeneratedArtistQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type StaticallyGeneratedArtistQuery = { __typename: 'Query', artistServerSide: { __typename: 'Artist', id: string, name: string, slug: string, published: boolean, cover_image?: { __typename: 'Image', height: number, width: number, url: string, verticalAlign?: VerticalAlign | undefined, public_cloudinary_id: string } | undefined, profile_image?: { __typename: 'Image', height: number, width: number, url: string, public_cloudinary_id: string } | undefined, media?: { __typename: 'Media', songs: { __typename: 'Songs', soundcloud: Array<string>, spotify: Array<string> }, videos: { __typename: 'Videos', youtube: Array<string> }, images: { __typename: 'Images', gallery: Array<{ __typename: 'Image', height: number, width: number, url: string, public_cloudinary_id: string }> } } | undefined } | { __typename: 'ArtistNotFoundResponse', message: string } };

export type PromoApiBookingQueryVariables = Exact<{
  bookingId: Scalars['String'];
}>;


export type PromoApiBookingQuery = { __typename: 'Query', booking: { __typename: 'Booking', id: string, artistId: string, startTime: any, artist: { __typename: 'Artist', profileImage?: { __typename: 'Image', publicCloudinaryId: string } | undefined } } };

export type GetCodeMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type GetCodeMutation = { __typename: 'Mutation', getCodeTestUser: boolean };

export type GetTokenMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  code: Scalars['String'];
}>;


export type GetTokenMutation = { __typename: 'Mutation', createCustomToken: string };

export type BookingSearchKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingSearchKeyQuery = { __typename: 'Query', bookingsSearchKey: string };

export type TypesenseArtistApiKeyQueryVariables = Exact<{
  userFirebaseId?: InputMaybe<Scalars['String']>;
}>;


export type TypesenseArtistApiKeyQuery = { __typename: 'Query', artistSearchKey: string };


export const AdminSearchKeyDocument = gql`
    query AdminSearchKey {
  adminSearchKey
}
    `;
export const DiscoverPageDocument = gql`
    query DiscoverPage {
  discoverPage {
    name
    category
    subgenres
    artists {
      id
      slug
      name
      profileImage {
        publicCloudinaryId
        height
        width
      }
      numberOfReviews
      avgRating
      displayPriceRange {
        high
        low
      }
    }
  }
}
    `;
export const UploadImageDocument = gql`
    mutation UploadImage($imageBytes: String!) {
  uploadImage(imageBytes: $imageBytes) {
    url
    height
    width
    public_cloudinary_id: publicCloudinaryId
  }
}
    `;
export const ViewNotificationDocument = gql`
    mutation ViewNotification($trackingUrl: String!, $pushTrackingEvent: PushTrackingEvent!) {
  trackPushNotificationEvent(
    trackingUrl: $trackingUrl
    pushTrackingEvent: $pushTrackingEvent
  )
}
    `;
export const SetExpoDeviceDocument = gql`
    mutation SetExpoDevice($deviceName: String!, $token: String!) {
  setDevice(deviceName: $deviceName, notificationToken: $token)
}
    `;
export const UnsetExpoDeviceDocument = gql`
    mutation UnsetExpoDevice($deviceName: String!) {
  unsetDevice(deviceName: $deviceName)
}
    `;
export const BuyerToSellerPriceDocument = gql`
    query BuyerToSellerPrice($buyerOfferAmount: Float!, $bookingFlow: BookingFlow!) {
  buyerToSellerAmount(buyerAmount: $buyerOfferAmount, bookingFlow: $bookingFlow)
}
    `;
export const StaticallyGeneratedArtistDocument = gql`
    query StaticallyGeneratedArtist($slug: String!) {
  artistServerSide(slug: $slug) {
    ... on ArtistNotFoundResponse {
      message
      __typename
    }
    ... on Artist {
      id
      name
      slug
      published
      __typename
      cover_image: coverImage {
        public_cloudinary_id: publicCloudinaryId
        height
        width
        url
        verticalAlign
      }
      profile_image: profileImage {
        public_cloudinary_id: publicCloudinaryId
        height
        width
        url
      }
      media {
        songs {
          soundcloud
          spotify
        }
        videos {
          youtube
        }
        images {
          gallery {
            height
            width
            url
            public_cloudinary_id: publicCloudinaryId
          }
        }
      }
    }
  }
}
    `;
export const PromoApiBookingDocument = gql`
    query PromoAPIBooking($bookingId: String!) {
  booking(id: $bookingId) {
    id
    artistId
    startTime
    artist {
      profileImage {
        publicCloudinaryId
      }
    }
  }
}
    `;
export const GetCodeDocument = gql`
    mutation GetCode($phoneNumber: String!) {
  getCodeTestUser(phoneNumber: $phoneNumber)
}
    `;
export const GetTokenDocument = gql`
    mutation GetToken($phoneNumber: String!, $code: String!) {
  createCustomToken(phoneNumber: $phoneNumber, code: $code)
}
    `;
export const BookingSearchKeyDocument = gql`
    query BookingSearchKey {
  bookingsSearchKey
}
    `;
export const TypesenseArtistApiKeyDocument = gql`
    query TypesenseArtistApiKey($userFirebaseId: String) {
  artistSearchKey(userFirebaseId: $userFirebaseId)
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AdminSearchKey(variables?: AdminSearchKeyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AdminSearchKeyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AdminSearchKeyQuery>(AdminSearchKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AdminSearchKey', 'query');
    },
    DiscoverPage(variables?: DiscoverPageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DiscoverPageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DiscoverPageQuery>(DiscoverPageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DiscoverPage', 'query');
    },
    UploadImage(variables: UploadImageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadImageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadImageMutation>(UploadImageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadImage', 'mutation');
    },
    ViewNotification(variables: ViewNotificationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ViewNotificationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ViewNotificationMutation>(ViewNotificationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ViewNotification', 'mutation');
    },
    SetExpoDevice(variables: SetExpoDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetExpoDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetExpoDeviceMutation>(SetExpoDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetExpoDevice', 'mutation');
    },
    UnsetExpoDevice(variables: UnsetExpoDeviceMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UnsetExpoDeviceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UnsetExpoDeviceMutation>(UnsetExpoDeviceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UnsetExpoDevice', 'mutation');
    },
    BuyerToSellerPrice(variables: BuyerToSellerPriceQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BuyerToSellerPriceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BuyerToSellerPriceQuery>(BuyerToSellerPriceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BuyerToSellerPrice', 'query');
    },
    StaticallyGeneratedArtist(variables: StaticallyGeneratedArtistQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StaticallyGeneratedArtistQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StaticallyGeneratedArtistQuery>(StaticallyGeneratedArtistDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'StaticallyGeneratedArtist', 'query');
    },
    PromoAPIBooking(variables: PromoApiBookingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PromoApiBookingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PromoApiBookingQuery>(PromoApiBookingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PromoAPIBooking', 'query');
    },
    GetCode(variables: GetCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCodeMutation>(GetCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCode', 'mutation');
    },
    GetToken(variables: GetTokenMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetTokenMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTokenMutation>(GetTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetToken', 'mutation');
    },
    BookingSearchKey(variables?: BookingSearchKeyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BookingSearchKeyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<BookingSearchKeyQuery>(BookingSearchKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'BookingSearchKey', 'query');
    },
    TypesenseArtistApiKey(variables?: TypesenseArtistApiKeyQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TypesenseArtistApiKeyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TypesenseArtistApiKeyQuery>(TypesenseArtistApiKeyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TypesenseArtistApiKey', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;