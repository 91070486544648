import { APP_URL } from '@beatgig/constants'
import { shouldUseLogRocket } from '@beatgig/vendors/log-rocket/enabled'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { Platform } from 'react-native'
import LogRocket from 'logrocket'
import useAuth from '@beatgig/auth-hooks/use-auth'

export function LogRocketProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter()

  const isEmbed = router?.pathname?.startsWith('/embed')
  const isReactNativeWebViewPage = !!router?.pathname?.startsWith?.('/webview')
  const auth = useAuth()

  const disableForAnonymousUser = Platform.select({
    // web is okay for anonymous users
    web: false,
    default: !auth.user,
  })

  useEffect(() => {
    const isCorrectUrl =
      Platform.OS !== 'web' || window.location.host.endsWith(APP_URL)
    if (
      !disableForAnonymousUser &&
      shouldUseLogRocket &&
      isCorrectUrl &&
      !isEmbed &&
      !isReactNativeWebViewPage
    ) {
      LogRocket.init('rnpdid/beatgig', {
        network: {
          requestSanitizer: (request) => {
            if (request.headers['authorization']) {
              request.headers['authorization'] = '<hidden-auth>'
            }

            return request
          },
        },
      })
    }
  }, [disableForAnonymousUser, isEmbed, isReactNativeWebViewPage])

  return <>{children}</>
}
