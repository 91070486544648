import React from 'react'
import { View, Text } from 'dripsy'
import { useResponsive } from '@beatgig/hooks/use-responsive'
import Loading from '@beatgig/design/components/loading'
import { ServerError } from '@beatgig/api-services'
import type { Sx as SxStyleProp } from 'dripsy'

type Props = {
  error?: ServerError
  childrenUnderLoader?: boolean
  showChildrenWhenError?: boolean
  containerSx?: SxStyleProp
  /**
   * Used when the server error exists, but it has no `body.message`. Default: `Trying again...`
   */
  errorMessageFallback?: string
  /**
   * Percentage of screen height. To control directly as a style prop, use `containerSx.minHeight`
   */
  minHeight?: number
} & React.ComponentProps<typeof Loading>

export default function LoadingScreen(props: Props) {
  const {
    children = <Text sx={{ color: 'text' }}>Loading...</Text>,
    error,
    variant = error ? 'error' : 'default',
    childrenUnderLoader = false,
    showChildrenWhenError = false,
    containerSx = {},
    errorMessageFallback = 'Trying again...',
    minHeight = 50,
    ...loading
  } = props

  const { vh } = useResponsive()

  let shouldChildrenShow = true
  if (error && !showChildrenWhenError) shouldChildrenShow = false

  let message = ''

  if (error && 'body' in error && error.body?.message) {
    message = error.body.message
  }
  if (error && 'graphQLErrors' in error) {
    error.graphQLErrors.forEach((item, i) => {
      if (i > 0) {
        if (!message.endsWith('. ')) {
          message += '. '
        } else if (message.endsWith('.')) {
          message += ' '
        }
      }
      if (!message.includes(item.message)) {
        message += `${item.message}`
      }
    })
  }

  return (
    <View
      sx={{
        minHeight: vh(minHeight),
        alignItems: 'center',
        justifyContent: 'center',
        bg: 'background',
        p: 3,
        flex: 1,
        ...containerSx,
      }}
    >
      <Loading variant={variant} {...loading}>
        <Text>
          {!!error &&
            `${message || `${errorMessageFallback}${error?.message ?? ''}`}`}
        </Text>
        {shouldChildrenShow && !childrenUnderLoader && children}
      </Loading>
      {shouldChildrenShow && childrenUnderLoader && children}
    </View>
  )
}
