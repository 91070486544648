import React from 'react'
import { H1, Text, View } from 'dripsy'
import APP_NAME from '@beatgig/constants/app-name'
import { UserRole } from '@beatgig/api-services'
import Button from '@beatgig/components/button'

type Props = {
  userRole: UserRole | null
  onContinue?: () => void
  name?: string
}

const benefits = {
  [UserRole.BUYER]: [
    {
      label: 'Instant access to thousands of artists',
    },
    {
      label: 'Transparent pricing',
    },
    {
      label: 'Production made easy',
    },
  ],
  [UserRole.SELLER]: [
    {
      label: 'Get more bookings',
    },
    {
      label: 'Reach more fans',
    },
    {
      label: 'Manage shows with ease',
    },
  ],
}

const fadeInUp = {
  from: {
    opacity: 0,
    transform: [
      {
        translateY: 12,
      },
    ],
  },
  to: {
    opacity: 1,
    transform: [
      {
        translateY: 0,
      },
    ],
  },
}

const fadeInDown = {
  from: {
    opacity: 0,
    transform: [
      {
        translateY: -5,
      },
    ],
  },
  to: {
    opacity: 1,
    transform: [
      {
        translateY: 0,
      },
    ],
  },
}
class Animations {
  static title = {
    duration: 600,
    delay: 100,
  }
  static subtitle = {
    duration: 600,
    delay: Animations.title.duration + Animations.title.delay + 1000,
  }
  static benefit = {
    duration: 600,
    delay: Animations.subtitle.duration + Animations.subtitle.delay + 1500,
    itemDelay: 1000,
  }
  static button = (userRole: UserRole | null) => ({
    duration: 700,
    delay:
      //   Animations.benefit.duration +
      Animations.benefit.delay +
      Animations.benefit.itemDelay *
        (userRole ? benefits[userRole]?.length : 0 ?? 0),
  })
}

const OnboardingWelcome = React.memo(function OnboardingWelcome(props: Props) {
  const { userRole, onContinue, name } = props

  const renderBenefits = React.useCallback(() => {
    return (
      <View sx={{ my: 3, alignItems: 'center' }}>
        {!!userRole &&
          benefits[userRole]?.map(({ label }, index) => {
            return (
              <View key={label} sx={{ my: 2 }}>
                <Text sx={{ color: 'text', fontWeight: 'bold', fontSize: 4 }}>
                  {label}
                </Text>
              </View>
            )
            // return (
            //   <Animatable
            //     animation={fadeInUp}
            //     duration={Animations.benefit.duration}
            //     delay={
            //       index * Animations.benefit.itemDelay +
            //       Animations.benefit.delay
            //     }
            //     key={label}
            //   >
            //     <View sx={{ my: 2 }}>
            //       <Text sx={{ color: 'text', fontWeight: 'bold', fontSize: 4 }}>
            //         {label}
            //       </Text>
            //     </View>
            //   </Animatable>
            // )
          })}
      </View>
    )
  }, [userRole])

  const renderTitle = React.useCallback(() => {
    return (
      <H1
        sx={{
          color: 'text',
          fontSize: 7,
          textAlign: 'center',
          mb: 3,
          fontWeight: '900',
        }}
      >
        Welcome to{' '}
        <Text sx={{ color: 'primary', fontWeight: '900', fontSize: 7 }}>
          {APP_NAME}
        </Text>
        {!!name?.split(' ')?.[0] && `, ${name.split(' ')[0]}`}
      </H1>
    )
  }, [name])

  const renderSubtitle = React.useCallback(() => {
    return (
      <Text sx={{ color: 'mutedText', textAlign: 'center', fontSize: 4 }}>
        {`You're moments away from the future of booking concerts.`}
      </Text>
    )
  }, [])

  const renderButton = React.useCallback(() => {
    return (
      <View sx={{ alignItems: 'center' }}>
        <Button onPress={onContinue}>Continue</Button>
      </View>
    )
  }, [onContinue])

  return (
    <View
      sx={{ px: 3, py: 3, bg: 'background', flex: 1, justifyContent: 'center' }}
    >
      {renderTitle()}
      {renderSubtitle()}
      {renderBenefits()}
      {renderButton()}
    </View>
  )
})

export default OnboardingWelcome
