import * as Urql from '@urql/exchange-graphcache'
import { Query } from './index'

export const invalidateBadges = <
  Resolver extends Urql.UpdateResolver = Urql.UpdateResolver
>(
  resolver?: Resolver
): Resolver => {
  const response = (...[parent, args, cache, info]: Parameters<Resolver>) => {
    const key: keyof Query = 'myBadges'
    // cache.invalidate({ __typename}) seems to break if you use it without an id
    // no need to inspect fields since myBadges has no arguments
    cache.invalidate('Query', key)

    return resolver?.(parent, args, cache, info)
  }

  return response as Resolver
}
