import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import useAmISignedIn from '@beatgig/auth-hooks/use-am-i-signed-in'
import React, { useState } from 'react'
import { Platform } from 'react-native'
import useSignOut from '@beatgig/auth-hooks/use-sign-out'
import PhoneAuth from '../phone-auth'
import { UserRole } from '@beatgig/api-services'
import BuyerOnboardingStack from '@beatgig/navigation/stacks/buyer-onboarding-stack'
import Button from '@beatgig/components/button'
import Card from '@beatgig/components/card'
import {
  useDripsyTheme,
  Row,
  Text,
  View,
  H3,
  ScrollView,
  Container,
} from 'dripsy'
import SellerOnboardingStack from '@beatgig/navigation/stacks/seller-onboarding-stack'
import OnboardingWelcome from '@beatgig/ui/onboarding-welcome'
import APP_NAME from '@beatgig/constants/app-name'
import { User } from '@beatgig/api/user'
import OnboardingPendingApproval from '@beatgig/ui/onboarding-pending-approval'
import LoadingScreen from '@beatgig/components/loading-screen'
import useTrackScreen from '@beatgig/analytics/hooks/use-track-screen'
import Sizer from '@beatgig/components/sizer'
import WebModal from '@beatgig/components/web-modal'

import { MotiView } from '@beatgig/design/components/moti-view'
import { Box } from '@beatgig/design/components/box'
import dynamic from 'next/dynamic'
const OnboardingStack = dynamic(() =>
  import('@beatgig/features/onboarding/stack').then((m) => m.OnboardingStack)
)

type Props = {
  userRole?: UserRole
  onClose?: () => void
  onDidAuthenticatedSuccessfully?: () => void
  phoneAuthProps?: React.ComponentProps<typeof PhoneAuth>
  /**
   * Set it to 1 outside of modals, such as for the dashboard page, so that it contains the scrollview
   */
  onAcceptPendingApproval?: () => void
}

const AuthFlow = function AuthFlow(props: Props) {
  const {
    onClose,
    onDidAuthenticatedSuccessfully,
    phoneAuthProps = {},
    onAcceptPendingApproval,
  } = props

  useTrackScreen({ name: 'Auth Flow' })

  const [userRole, setUserRole] = useState(props.userRole)

  const { loading, amISignedIn } = useAmISignedIn()
  const signOut = useSignOut()
  const { colors, space } = useDripsyTheme().theme
  const { data, error, isLoadingInitial } = useMyAccount()

  console.log('[auth-flow] USER', { isLoadingInitial })

  const renderHeader = React.useCallback<
    NonNullable<React.ComponentProps<typeof PhoneAuth>['renderHeader']>
  >(
    ({ screen, goBack }) => {
      return (
        <WebModal.Header
          onClose={() => {
            if (screen === 'code') goBack()
            else onClose?.()
          }}
          title={screen === 'code' ? 'Confirm' : 'Get Started'}
          icon={screen === 'code' ? 'chevron-back' : onClose ? 'close' : null}
        />
      )
    },
    [onClose]
  )

  console.log('[auth-flow] onboarding', {
    onboarded: User.hasOnboarded(data),
  })

  if (loading) return <LoadingScreen />
  if (amISignedIn) {
    if (!data || isLoadingInitial) {
      return (
        <LoadingScreen minHeight={50} error={error}>
          Loading your account...
        </LoadingScreen>
      )
    }
    const useNewOnboardingStack = true
    if (!useNewOnboardingStack) {
      if (User.isApproved(data)) {
        return (
          <OnboardingWelcome
            name={data?.name}
            userRole={data?.user_role ?? null}
            onContinue={onDidAuthenticatedSuccessfully}
          />
        )
      }
      if (User.hasOnboarded(data)) {
        console.log('[auth-flow] should show pending')
        return (
          <OnboardingPendingApproval
            onGoBack={() => {
              onClose?.()
              onAcceptPendingApproval?.()
            }}
            user={data}
          />
        )
      }
    }

    if (!userRole && !User.hasOnboarded(data)) {
      return <UserRolePicker setUserRole={setUserRole} />
    }

    const finalUserRole = User.hasOnboarded(data) ? data.user_role : userRole

    return (
      <Box flex={1}>
        {!useNewOnboardingStack && (
          <>
            {userRole === UserRole.BUYER && <BuyerOnboardingStack />}
            {userRole === UserRole.SELLER && <SellerOnboardingStack />}
          </>
        )}
        {!!finalUserRole && (
          <OnboardingStack
            onAcceptPendingApproval={onAcceptPendingApproval || null}
            onClose={onClose || null}
            onDidAuthenticateSuccessfully={
              onDidAuthenticatedSuccessfully || null
            }
            userRole={finalUserRole}
          />
        )}
        {userRole === UserRole.ADMIN && (
          <Button onPress={signOut}>Sign Out</Button>
        )}
      </Box>
    )
  }

  return (
    <PhoneAuth
      renderHeader={renderHeader}
      backgroundColor={colors?.background}
      textColor={colors?.text}
      {...phoneAuthProps}
    />
  )
}

export default AuthFlow

function UserRolePicker({
  setUserRole,
}: {
  setUserRole: (userRole: UserRole) => void
}) {
  return (
    <ScrollView>
      <Container>
        <MotiView animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Sizer pt={Platform.select({ native: 4 })} my={[4, 5]}>
            <H3 sx={{ mx: 3, textAlign: 'center' }}>
              What brings you to {APP_NAME}?
            </H3>
          </Sizer>
        </MotiView>
        <View sx={{ mx: 1 }}>
          <Sizer {...{ flex: [null, 1] }}>
            {/* <MotiView
              animate={{
                opacity: 1,
                translateY: 0,
              }}
              exit={(userRole) => {
                'worklet'

                if (userRole !== UserRole.BUYER) {
                  return {
                    opacity: 0,
                    translateY: -10,
                  }
                }
                return {
                  opacity: 1,
                  translateY: 0,
                }
              }}
              transition={{
                type: 'spring',
              }}
            > */}
            <Card sx={{ mx: 2, mb: 3, py: 4 }}>
              <H3 sx={{ color: 'text', textAlign: 'center' }}>
                I want to book talent
              </H3>
              <Text
                sx={{
                  mb: 3,
                  textAlign: 'center',
                  fontSize: [2, 3],
                  color: 'mutedText',
                }}
              >
                {'If you want to browse & book talent, continue as a buyer.'}
              </Text>
              <Row sx={{ justifyContent: 'center', mt: 2 }}>
                <Button
                  onPress={() => setUserRole(UserRole.BUYER)}
                  sx={{
                    bg: 'success',
                    color: 'text',
                    borderColor: 'success',
                  }}
                >
                  Continue As Buyer
                </Button>
              </Row>
            </Card>
            {/* </MotiView> */}
          </Sizer>
          <Sizer {...{ flex: [null, 1], mb: [3, 0] }}>
            {/* <MotiView
              animate={{
                opacity: 1,
                translateY: 0,
              }}
              exit={(userRole) => {
                'worklet'

                if (userRole !== UserRole.SELLER) {
                  return {
                    opacity: 0,
                    translateY: 10,
                  }
                }
                return {
                  opacity: 1,
                }
              }}
              transition={{
                type: 'spring',
              }}
            > */}
            <Card sx={{ mx: 2, mb: 3, py: 4 }}>
              <H3 sx={{ color: 'text', textAlign: 'center' }}>
                {"I'm an artist"}
              </H3>
              <Text
                sx={{
                  mb: 3,
                  textAlign: 'center',
                  fontSize: [2, 3],
                  color: 'mutedText',
                }}
              >
                {"If you're an artist, manager or agent, continue as a seller."}
              </Text>
              <Row sx={{ justifyContent: 'center', mt: 2 }}>
                <Button
                  onPress={() => setUserRole(UserRole.SELLER)}
                  sx={{
                    bg: 'accent',
                    color: 'text',
                    borderColor: 'accent',
                  }}
                >
                  Continue As Seller
                </Button>
              </Row>
            </Card>
            {/* </MotiView> */}
          </Sizer>
        </View>
      </Container>
    </ScrollView>
  )
}
