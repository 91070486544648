import React from 'react'
import { Text } from 'dripsy'
import { CarouselProps, Img } from './types'

type Props<I extends Img> = Parameters<
  Required<CarouselProps<I>>['renderTitle']
>[0]

export default function CarouselTitle<I extends Img>({
  cursor,
  count,
  sx = {},
  currentImage, // no-op
  currentIndex, // no-op
  ...props
}: Props<I>) {
  if (!count || count <= 1) return null
  return (
    <Text {...props} sx={{ ...sx }}>
      {cursor} / {count}
    </Text>
  )
}
