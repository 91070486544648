import { Artist, NegotiationAction } from '@beatgig/api-services'
import AnalyticsBase from './segment'
import { CreateUserMutationVariables } from '@beatgig/gql'

/**
 * Helpers...So ugly, I know 😬 Taken from nandorojo/gatsby-store
 *
 * this lets us change Analytics.track({ event: string, ... }) to Analytics.track(event, { ... }) dynamically.
 *
 * If the event has no properties, you juse use Analytics.track(event).
 *
 * Same goes for Analytics.screen('')
 *
 * Using `EventCreator` and `ScreenCreator`, we give it the `'event'` and `'name'` field (respectively), and then add any other parameters.
 *
 * The functions then know, based on your `name`/`event`, which properties your function needs.
 *
 * See  https://artsy.github.io/blog/2018/11/21/conditional-types-in-typescript/ for explanation.
 */
type ExcludeEventKey<K> = K extends 'event' ? never : K
type ExcludeEventField<A> = { [K in ExcludeEventKey<keyof A>]: A[K] }
type ExtractEventParameters<A, T> = A extends { event: T }
  ? ExcludeEventField<A>
  : never

type ExcludeNameKey<K> = K extends 'name' ? never : K
type ExcludeNameField<A> = { [K in ExcludeNameKey<keyof A>]: A[K] }
type ExtractNameParameters<A, T> = A extends { name: T }
  ? ExcludeNameField<A>
  : never
// duplicated from below, bring these all together!
type ExcludeTypeFieldScreen<A> = { [K in ExcludeNameKey<keyof A>]: A[K] }

type ExcludeTypeField<A> = { [K in ExcludeEventKey<keyof A>]: A[K] }

type ExtractSimpleEvent<A> = A extends any
  ? {} extends ExcludeTypeField<A>
    ? A
    : never
  : never

type ExtractComplexEvent<A> = A extends any
  ? {} extends ExcludeTypeField<A>
    ? never
    : A
  : never

// duplicated code, change later
type ExtractSimpleScreen<A> = A extends any
  ? {} extends ExcludeTypeFieldScreen<A>
    ? A
    : never
  : never

type ExtractComplexScreen<A> = A extends any
  ? {} extends ExcludeTypeFieldScreen<A>
    ? never
    : A
  : never
// ok, onto the meat now
type EventOptions =
  | 'View Screen'
  | 'Send SMS'
  | 'Create Account'
  | 'Verify SMS Code'
  | 'Sign Out'
  | 'Create Booking'
  | 'Edit Booking'
  // | 'Decline Booking'
  // | 'Counter Booking'
  // | 'Cancel Booking'
  // | 'Accept Booking'
  | 'Negotiate Booking'
  | 'Create Recommended Booking'
  | 'Edit Recommended Booking'
  | 'Reject Recommended Booking'
  | 'Accept Recommended Booking'
  | 'Cancel Recommended Booking'
  | 'Press Book Artist'
  | 'Like Artist'
  | 'Unlike Artist'
  | 'Open Auth Popup'
  | 'Open Stripe Express Dashboard'
  | 'Press Edit Artist'
  | 'Press Edit Artist Performance Data'
  | 'Show More Artist Cover Songs'
  | 'Show More Artist Cover Bands'
  | 'Copy Text'
  | 'Create External Booking'
  | 'Edit External Booking'
  | 'Delete External Booking'
  | 'Open Public Booking'
  | 'Export Promo Image'
  | 'Update From App Store'

type EventCreator<E extends EventOptions> = { event: E }

type OpenPublicBooking = EventCreator<'Open Public Booking'> & {
  artistName: string
  startTime: string
  venueName?: string
} & (
    | {
        screen: 'Event Discovery'
        source: 'map' | 'sidebar'
      }
    | {
        screen: 'Venue Profile'
        source: 'calendar' | 'list'
      }
  )

type CreateExternalBooking = EventCreator<'Create External Booking'> & {
  artistName: string
}
type EditExternalBooking = EventCreator<'Edit External Booking'> & {
  artistName: string
  externalBookingId: string
}
type DeleteExternalBooking = EventCreator<'Delete External Booking'> & {
  // artistName: string
  externalBookingId: string
}

// auth events
type SendSMS = EventCreator<'Send SMS'> & {
  phone_number: string
}
type CreateAccount = EventCreator<'Create Account'> & {
  user: CreateUserMutationVariables['input']
}

type SignOut = EventCreator<'Sign Out'>
type CreateBooking = EventCreator<'Create Booking'> & {
  slug: string
}
type NegotiateBooking = EventCreator<'Negotiate Booking'> & {
  bookingId: string
  action: NegotiationAction
  offerAmount?: number
  sellerOfferAmount?: number
}
type EditBooking = EventCreator<'Edit Booking'> & {
  slug: string
  bookingId: string
}

type CreateRecommendedBooking = EventCreator<'Create Recommended Booking'> & {
  venueId: string
}
type EditRecommendedBooking = EventCreator<'Edit Recommended Booking'> & {
  recommendedBookingId: string
}
type CancelRecommendedBooking = EventCreator<'Cancel Recommended Booking'> & {
  recommendedBookingId: string
}

type RejectRecommendedBooking = EventCreator<'Reject Recommended Booking'> & {
  recommendedBookingId: string
  rejectionMessage?: string
}
type AcceptRecommendedBooking = EventCreator<'Accept Recommended Booking'> & {
  recommendedBookingId: string
  artistId: string
  // artistName: string
  // price: number
}

type VerifySMS = EventCreator<'Verify SMS Code'> & {
  user_exists: boolean
}

type PressBookArtist = EventCreator<'Press Book Artist'> & {
  slug: string
}
type PressEditArtist = EventCreator<'Press Edit Artist'> & {
  slug: string
}
type ShowMoreArtistCoverSongs = EventCreator<'Show More Artist Cover Songs'> & {
  slug: string
}
type ShowMoreArtistCoverBands = EventCreator<'Show More Artist Cover Bands'> & {
  slug: string
}
type PressEditArtistData =
  EventCreator<'Press Edit Artist Performance Data'> & {
    slug: string
  }
type OpenStripeExpressDashboard =
  EventCreator<'Open Stripe Express Dashboard'> & {
    fromBookingId?: string
    screen: 'Booking'
  }
type CopyText = EventCreator<'Copy Text'> & {
  text: string
}

type LikeArtistScreens =
  | 'Discover'
  | 'Search'
  | 'Similar Artists'
  | 'Artist Profile'
  | 'Artist Profile (Fixed Header)'

type LikeArtist = EventCreator<'Like Artist'> & {
  slug: string
  screen: LikeArtistScreens
}
type UnlikeArtist = EventCreator<'Unlike Artist'> & {
  slug: string
  screen: LikeArtistScreens
}

export type LikeArtistAnalyticsProps = ExcludeEventField<LikeArtist>

type OpenAuthPopup = EventCreator<'Open Auth Popup'>

type ExportPromoImage = EventCreator<'Export Promo Image'> & {
  via: 'sms' | 'email' | 'download' | 'copy url'
  imageUrl: string
  redirectUrl: string
}

type UpdateFromAppStore = EventCreator<'Update From App Store'> & {
  fromVersion: string
  toMajorVersion: number
}

// join all the events together
type Events =
  | SendSMS
  | CreateAccount
  | VerifySMS
  | SignOut
  | CreateBooking
  | CreateRecommendedBooking
  | PressBookArtist
  | LikeArtist
  | UnlikeArtist
  | OpenAuthPopup
  | OpenStripeExpressDashboard
  | PressEditArtist
  | PressEditArtistData
  | ShowMoreArtistCoverSongs
  | ShowMoreArtistCoverBands
  | CopyText
  | EditRecommendedBooking
  | RejectRecommendedBooking
  | AcceptRecommendedBooking
  | CancelRecommendedBooking
  | NegotiateBooking
  | EditBooking
  | CreateExternalBooking
  | EditExternalBooking
  | DeleteExternalBooking
  | OpenPublicBooking
  | ExportPromoImage
  | UpdateFromAppStore

// if we have an empty object, meaning there are no parameters to pass,
// then we don't even request this field
type SimpleEventType = ExtractSimpleEvent<Events>['event']
type ComplexEventType = ExtractComplexEvent<Events>['event']

export type SimpleScreenType = ExtractSimpleScreen<Screens>['name']
type ComplexScreenType = ExtractComplexScreen<Screens>['name']

async function track<E extends SimpleEventType>(event: E): Promise<void>
async function track<E extends ComplexEventType>(
  event: E,
  properties: ExtractEventParameters<Events, E>
): Promise<void>
async function track<E extends SimpleEventType | ComplexEventType>(
  event: E,
  properties?: ExtractEventParameters<Events, E>
) {
  if (properties) {
    return AnalyticsBase.trackWithProperties(event, properties)
  }
  return AnalyticsBase.track(event)
}

// event to track a screen view
export type ScreenName =
  | 'Discover'
  | 'Artist'
  | 'Booking'
  | 'Search'
  | 'Bookings'
  | 'Likes'
  | 'My Account'
  | 'Discover (Fast)'
  | 'Create Booking'
  | 'Auth Flow'
  | 'Venue Calendar Embed'

type ViewScreenCreator<N extends ScreenName> = { name: N }

type ViewScreenDiscover = ViewScreenCreator<'Discover'>
type ViewScreenSearch = ViewScreenCreator<'Search'>
type ViewScreenDiscoverFast = ViewScreenCreator<'Discover (Fast)'>
type ViewScreenArtist = ViewScreenCreator<'Artist'> & Pick<Artist, 'slug'>
type ViewScreenCreateBooking = ViewScreenCreator<'Create Booking'> &
  Pick<Artist, 'slug'>
type ViewScreenMyAccount = ViewScreenCreator<'My Account'>
type ViewScreenBookings = ViewScreenCreator<'Bookings'>
type ViewScreenAuthFlow = ViewScreenCreator<'Auth Flow'>
type ViewScreenBooking = ViewScreenCreator<'Booking'> & {
  bookingId: string
}
type ViewScreenVenueCalendarEmbed =
  ViewScreenCreator<'Venue Calendar Embed'> & {
    venueSlug: string
    iframe: boolean
    from: string
  }

type Screens =
  | ViewScreenDiscover
  | ViewScreenDiscoverFast
  | ViewScreenArtist
  | ViewScreenCreateBooking
  | ViewScreenMyAccount
  | ViewScreenBookings
  | ViewScreenSearch
  | ViewScreenAuthFlow
  | ViewScreenBooking
  | ViewScreenVenueCalendarEmbed

async function screen<N extends SimpleScreenType>(name: N): Promise<void>
async function screen<N extends ComplexScreenType>(
  name: N,
  properties: ExtractNameParameters<Screens, N>
): Promise<void>
async function screen<N extends SimpleScreenType | ComplexScreenType>(
  name: N,
  properties?: ExtractNameParameters<Screens, N>
) {
  if (properties) {
    return AnalyticsBase.screenWithProperties(name, properties)
  }
  return AnalyticsBase.screen(name)
}

const Analytics = {
  track,
  screen,
  identify: AnalyticsBase.identify,
  reset: AnalyticsBase.reset,
}

export default Analytics
