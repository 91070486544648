import React from 'react'
import type { Sx } from 'dripsy'
import { View, Text } from 'dripsy'
import { Variants, Filling } from '../types'
import useGetColors from '../../hooks/use-variant-colors'
import Ionicons, { IconProps } from '../ionicons'
import { Platform } from 'react-native'

interface Props {
  variant?: Variants
  label?: string | boolean | React.ReactNode
  small?: boolean
  /**
   * @deprecated use `filling` instead.
   */
  filled?: boolean
  sx?: Sx
  textSx?: Sx
  labelSx?: Sx
  filling?: Filling
  message?: string
  icon?: IconProps['icon']
  actions?: React.ReactNode
  verticalAlign?: 'top' | 'bottom' | 'center'
}

type NativeAttrs = Omit<Partial<React.ComponentProps<typeof View>>, keyof Props>
export type NoteProps = Props & NativeAttrs

// https://github.com/geist-org/react/blob/master/components/note/note.tsx
const Note: React.FC<React.PropsWithChildren<NoteProps>> = ({
  message,
  children = message,
  variant = 'default',
  label,
  filling = 'outlined',
  filled = filling === 'filled',
  textSx,
  small,
  sx,
  labelSx,
  icon,
  actions,
  verticalAlign,
  ...props
}) => {
  const { color, borderColor, bg } = useGetColors({ variant, filling })
  const paddingY = small ? 1 : 2
  const paddingX = small ? 2 : 3

  return (
    <View
      {...props}
      sx={{
        ...sx,
        paddingX,
        paddingY,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor,
        bg,
        borderRadius: 3,
        flexDirection: ['column', 'row'],
        alignItems: [
          null,
          verticalAlign === 'top'
            ? 'flex-start'
            : verticalAlign === 'center'
            ? 'center'
            : verticalAlign === 'bottom'
            ? 'flex-end'
            : undefined,
        ],
      }}
    >
      <View
        sx={{
          flexDirection: 'row',
          my: 1,
          flexGrow: [null, 1],
          flexBasis: [null, Platform.select({ web: 0 })],
        }}
      >
        {!!icon && (
          <View sx={{ justifyContent: 'center', pr: 2 }}>
            <Ionicons icon={icon} />
          </View>
        )}
        <View sx={{ flexGrow: 1, flexBasis: 0 }}>
          <Text
            sx={{
              fontSize: 3,
              color,
              lineHeight: (theme) => theme.fontSizes?.[3] * 1.8 + 0.00001,
              ...textSx,
            }}
          >
            {!!label && (
              <Text
                sx={{
                  lineHeight: (theme) => theme.fontSizes?.[3] * 1.5 + 0.00001,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  userSelect: 'none',
                  paddingRight: 1,
                  color,
                  ...labelSx,
                }}
              >
                {label}:{' '}
              </Text>
            )}
            {children}
          </Text>
        </View>
      </View>
      {!!actions && <View sx={{ ml: [0, 3], mt: [3, 0] }}>{actions}</View>}
    </View>
  )
}

// Note.defaultProps = defaultProps

// const Note = React.memo(Notez)

export default Note
