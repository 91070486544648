import React from 'react'
import { View } from 'dripsy'

type Props = {
  children: React.ReactNode
}

export default function EntityCheckbox(props: Props) {
  const { children } = props

  return <View sx={{ mr: 3 }}>{children}</View>
}
