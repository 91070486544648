import { createGlobalState } from 'react-hooks-global-state'
import { AlgoliaDefaultFacets } from '../types/facets'
import DefaultBandConfigurations from '../types/default-band-configurations'
import { GenreFilterState, LocationState, RateLowState } from '../types/state'
import { ValueOf } from '@beatgig/api/types'

export const defaultArtistGenreFacetFilters = AlgoliaDefaultFacets.reduce(
  (current, facet) => ({ ...current, [facet]: [] }),
  {} as GenreFilterState
)

const initialState = {
  query: '',
  genreFilters: defaultArtistGenreFacetFilters,
  price: {
    low: null,
    high: null,
  } as RateLowState,
  location: null as LocationState,
  aroundRadiusMiles: 200,
  bandConfigurations: DefaultBandConfigurations,
  algoliaIndexDescription: 'Recommended',
}
const searchState = createGlobalState(initialState)

export const resetGlobalSearchState = () => {
  Object.entries(initialState).forEach(
    ([itemKey, itemInitialState]: [
      keyof typeof initialState,
      ValueOf<typeof initialState>
    ]) => {
      const state = searchState.getGlobalState(itemKey)
      if (state !== itemInitialState) {
        searchState.setGlobalState(itemKey, itemInitialState)
      }
    }
  )
}

export const initialGlobalSearchState = initialState

export default searchState
