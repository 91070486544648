import { View, TextInput } from 'dripsy'
import React, { ComponentProps, useMemo } from 'react'

import FieldLabel from './field-label'
// import Input from './text-input'
import Input from '@beatgig/design/components/input'
import { ErrorMessage, useFormikContext } from 'formik'
import { useFieldFast } from '../hooks/use-fast-field'
import ErrorText from './error'
import useIsFocused from '@beatgig/hooks/use-is-focused'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import { FieldName } from './types'
import AutoComplete from '@beatgig/design/components/auto-complete'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import { AutoCompleteOption } from '@beatgig/design/components/auto-complete/types'
import { ScrollToField } from './scroll-to-field'

export type AutoCompleteFieldProps<T extends object, S extends string> = {
  name: FieldName<T>
  // name: ObjectPathDotted<T, S>
  // name: DottedPathsTest<T, S>
  label?: string
  sx?: React.ComponentProps<typeof View>['sx']
  onFocus?: () => void
  onBlur?: () => void
  validationEvent?: 'submit' | 'touch'
  required?: boolean
  labelPosition?: 'top' | 'left' | 'right'
  inputProps?: React.ComponentProps<typeof TextInput>
  options: (string | AutoCompleteOption)[]
  tooltip?: string | React.ReactNode
} & Omit<React.ComponentProps<typeof AutoComplete>, 'options' | 'keyExtractor'>

export default function FormAutoCompleteField<
  T extends object,
  Path extends string = string
>(props: AutoCompleteFieldProps<T, Path>) {
  const {
    name: nameProp,
    // inputProps = {},
    label,
    sx = {},
    required = false,
    labelPosition = 'top',
    inputProps = {},
    options: _options,
    tooltip,
    ...autoCompleteProps
    // ...fieldProps
  } = props

  const options = useMemo(() => {
    return _options?.map((value) => {
      if (typeof value === 'string') {
        const option: AutoCompleteOption = {
          label: value,
          value,
        }
        return option
      }
      return value
    })
  }, [_options])

  // const { errors, handleBlur, handleChange } = useFormikContext<T>()
  const { ref, isHovered } = useAnimatedHover()
  // const input = React.useRef<typeof TextInput>()
  const [isFocused, focusBindings] = useIsFocused()
  const name = Array.isArray(nameProp) ? nameProp.join('.') : nameProp
  const [
    { value },
    { error, touched },
    { setValue, handleBlur },
  ] = useFieldFast(name as string)
  const { submitCount } = useFormikContext()

  // const hasErrored = !!getIn(errors, name as string)

  const hasErrored = error != undefined && !!(touched || submitCount)

  const textInputProps: ComponentProps<typeof Input> = {
    ...inputProps,
    // validate if there is indeed text
    // onChangeText: (text) => setValue(text, !!text),
    isHovered,
    hasErrored,
    isFocused,
    label: labelPosition === 'left' ? label : undefined,
    labelRight: labelPosition === 'right' ? label : undefined,
    keyboardAppearance: 'dark',
  }

  return (
    <View ref={ref} sx={{ ...sx, zIndex: 2 }}>
      <ScrollToField name={name as string} />
      {!!label && labelPosition === 'top' && (
        <FieldLabel
          isHovered={isHovered}
          hasErrored={hasErrored}
          isFocused={isFocused}
          required={required}
        >
          {label}
        </FieldLabel>
      )}

      <HoverTooltip
        text={tooltip}
        placement="top"
        anchor="right"
        visible={isFocused}
        textSx={{
          bg: 'text',
          color: 'background',
          borderColor: 'text',
        }}
      >
        <AutoComplete<typeof options[number]>
          options={options}
          inputProps={textInputProps}
          onFocus={focusBindings.onFocus}
          onBlur={() => {
            handleBlur()
            focusBindings.onBlur()
          }}
          // {...focusBindings}
          filter
          // sx={{ bg: 'background', borderRadius: 2 }}
          keyExtractor={(item) => item.value}
          // onChangeText={setValue}
          onClear={setValue}
          // value={value}
          {...autoCompleteProps}
          onSelect={(id) => {
            setValue(id)
            autoCompleteProps.onSelect?.(id)
          }}
        />
      </HoverTooltip>
      {/* <TextInput {...textInputProps} /> */}
      <ErrorMessage name={name as string}>
        {(message) => {
          return (
            !!message?.trim() && (
              <View sx={{ zIndex: -1 }}>
                <ErrorText>{message}</ErrorText>
              </View>
            )
          )
        }}
      </ErrorMessage>
    </View>
  )
}
