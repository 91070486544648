import React, { useEffect, useMemo, useState } from 'react'
import RouterContext from './context'
import Router, { useRouter } from 'next/router'
import produce from 'immer'

type Props = {
  children: React.ReactNode
}

// let history: Array<string> = []
// const setHistory = (newHistory: typeof history) => (history = newHistory)

export default function NextRouterProvider(props: Props) {
  const { children } = props

  const { events, beforePopState } = useRouter()
  const [history, setHistory] = useState<Array<string>>([])

  const state = useMemo(
    () => ({
      canGoBack: () => history.length > 0,
    }),
    [history]
  )

  useEffect(() => {
    const handleChange = () => {
      setHistory((history) => {
        if (history[history.length - 1] !== Router.pathname) {
          return [...history, Router.pathname]
        }
        return history
      })
    }
    events.on('routeChangeComplete', handleChange)

    const onPopState = ({ url }: { url: string }) => {
      setHistory((history) => {
        return produce(history, (draft) => {
          if (url === draft[draft.length - 1]) {
            draft.pop()
          }
          return draft
        })
      })
      return true
    }

    beforePopState(onPopState)

    return () => {
      events.off('routeChangeComplete', handleChange)
    }
  }, [beforePopState, events])

  return (
    <RouterContext.Provider value={state}>{children}</RouterContext.Provider>
  )
}
