import { FieldHelperProps, FieldHookConfig, useField } from 'formik'
import { useRef } from 'react'

export function useFieldFast<Val = any, Schema = any>(
  propsOrFieldName: Schema extends undefined
    ? string & {}
    : FieldHookConfig<Val> | keyof Schema
) {
  const [field, meta, helpers] = useField<Val>(propsOrFieldName as string)

  const actualHelpersRef = useRef<FieldHelperProps<Val>>(helpers)

  // On every render save newest helpers to actualHelpersRef
  actualHelpersRef.current.setValue = helpers.setValue
  actualHelpersRef.current.setTouched = helpers.setTouched
  actualHelpersRef.current.setError = helpers.setError

  const consistentHelpersRef = useRef<
    FieldHelperProps<Val> & { handleBlur: () => void }
  >({
    setValue: (...args) => actualHelpersRef.current.setValue(...args),
    setTouched: (value: boolean, shouldValidate?: boolean) =>
      actualHelpersRef.current.setTouched(value, shouldValidate),
    setError: (...args) => actualHelpersRef.current.setError(...args),
    handleBlur: () => actualHelpersRef.current.setTouched(true),
  })

  return [field, meta, consistentHelpersRef.current] as const
}
