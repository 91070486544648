import React, { useCallback, useMemo } from 'react'
import { AuthFlow } from 'react-native-doorman/build/views/Controlled/Auth-Flow'
import { useSx } from 'dripsy'
import { Platform } from 'react-native'
import Analytics from '@beatgig/analytics/typez'
import { User } from '@beatgig/api/user'
import { AuthApi } from '@beatgig/api/auth'
import { useFont } from '@beatgig/theme/use-font'
import useTheme from '@beatgig/theme/use-theme'
import Button from '@beatgig/components/button'
import ErrorNote from '@beatgig/components/ErrorNote'
import { useAsync } from 'react-async-hook'
import { initializePhoneCaptcha } from '@beatgig/providers/Doorman/init'
import { setAuthTokenCookieFromFirebaseUser } from '@beatgig/auth-hooks/auth-cookies'
import * as Urql from 'urql'
import { MeDocument, MeQuery, MeQueryVariables } from '@beatgig/gql'

type Props = React.ComponentProps<typeof AuthFlow> & {}

function useCaptcha() {
  return useAsync(initializePhoneCaptcha, [], {
    onError(e) {
      if (e.message) {
        console.error('[phone-sign-in]', e.message)
      }
    },
  })
}

export default function PhoneAuth({ ...props }: Props) {
  const { colors } = useTheme()
  const font = useFont().body
  const sx = useSx()
  const captcha = useCaptcha()

  const isCaptchaReady = Platform.select({
    default: true,
    web: !!captcha.result,
  })
  if (Platform.OS == 'web') {
    console.log('phone-auth', captcha.result)
  }
  const captchaError = captcha.error

  const urql = Urql.useClient()

  return (
    <>
      <AuthFlow
        phoneScreenProps={useMemo(
          () => ({
            buttonBackgroundColor: colors?.primary,
            buttonTextColor: colors?.background,
            headerProps: {
              style: {
                marginTop: 0,
                paddingTop: 0,
              },
            },
            headerTitleStyle: sx({
              fontWeight: 'bold',
              fontFamily: 'root',
            }),
            containerStyle: {
              paddingTop: 0,
              marginTop: 0,
              marginLeft: 0,
              paddingLeft: 0,
              marginRight: 0,
              paddingRight: 0,
              maxWidth: 400,
              width: '100%',
            },
            title: 'First, enter your phone number.',
            headerText: 'Get Started',
            inputStyle: sx({
              fontFamily: 'root',
              fontWeight: 'bold',
              borderRadius: '$3',
            }),
            inputBackgroundColor: colors.muted2,
            inputTextColor: colors.text,
            buttonStyle: sx({
              fontFamily: 'root',
              textTransform: 'none',
              borderRadius: 'rounded',
            }),
            renderButton({ submit, loading }) {
              return (
                <Button
                  onPress={submit}
                  loading={loading || !isCaptchaReady}
                  color={captchaError ? 'error' : 'primary'}
                >
                  Send
                </Button>
              )
            },
            renderError(message) {
              if (
                message?.includes('TypeError: Failed to fetch') ||
                message.includes('cancelled')
              ) {
                message = `It looks like your internet failed. Maybe try refreshing?`
              }
              return (
                <ErrorNote
                  label={''}
                  sx={{ mt: 3 }}
                  error={{ message, name: 'Phone' }}
                />
              )
            },
          }),
          [
            captchaError,
            colors?.background,
            colors.muted2,
            colors?.primary,
            colors.text,
            isCaptchaReady,
            sx,
          ]
        )}
        disableKeyboardHandler
        confirmScreenProps={React.useMemo(
          () => ({
            inputStyle: sx({
              fontFamily: 'root',
              fontWeight: 'bold',
              bg: 'muted2',
              color: 'text',
              borderRadius: '$3',
            }),
            inputProps: {
              keyboardAppearance: 'dark',
              placeholderTextColor: colors.muted6,
              selectionColor: colors.primary,
            },
            resendStyle: sx({
              fontFamily: 'root',
            }),
            containerStyle: {
              width: '100%',
              maxWidth: 450,
            },
          }),
          [sx, colors]
        )}
        {...{
          titleStyle: sx({
            fontWeight: 'bold',
            fontFamily: 'root',
          }),
          messageStyle: sx({ fontFamily: 'root' }),
        }}
        onSmsSuccessfullySent={useCallback((sms) => {
          Analytics.track('Send SMS', {
            phone_number: sms.phoneNumber,
          })
        }, [])}
        // onCodeVerified={async ({ token }) => {

        // }}
        onUserSuccessfullySignedIn={useCallback(
          async ({ user }) => {
            setAuthTokenCookieFromFirebaseUser(user)
            if (user) {
              if (!AuthApi.hasTokenSet) {
                AuthApi.setApiServicesToken(() => user.getIdToken(false))
              }
              const me = (
                await urql
                  .query<MeQuery, MeQueryVariables>(
                    MeDocument,
                    {},
                    {
                      requestPolicy: 'cache-first',
                    }
                  )
                  .toPromise()
              ).data?.me

              Analytics.track('Verify SMS Code', {
                user_exists: User.hasOnboarded(me),
              })
            }
          },
          [urql]
        )}
        backgroundColor="transparent"
        {...props}
      />
      {Platform.OS === 'web' && (
        <style>
          {`input[type="tel"] { font-family: ${font[500]}!important; font-weight: normal; }`}
        </style>
      )}
    </>
  )
}
