import { Sx, useSx } from 'dripsy'
import { View, MotiProps } from 'moti'
import { ComponentProps } from 'react'
import { StyleSheet } from 'react-native'

type Props = Partial<
  Pick<
    ComponentProps<typeof View>,
    | 'children'
    | 'onLayout'
    | 'accessibilityLabel'
    | 'accessibilityRole'
    | 'focusable'
    | 'href'
    | 'hrefAttrs'
    | 'pointerEvents'
    | 'layout'
    | keyof MotiProps
    | 'style'
  >
> &
  Omit<Sx, keyof MotiProps | 'pointerEvents'> & {
    stretch?: boolean
  }

const MotiView = ({
  children,
  onLayout,
  accessibilityLabel,
  accessibilityRole,
  focusable,
  href,
  hrefAttrs,
  pointerEvents,
  animate,
  animateInitialState,
  delay,
  exit,
  exitTransition,
  from,
  onDidAnimate,
  state,
  stylePriority,
  transition,
  layout,
  stretch,
  style,
  ...sx
}: Props) => {
  const dripsy = useSx()

  return (
    <View
      onLayout={onLayout}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={accessibilityRole}
      focusable={focusable}
      href={href}
      layout={layout}
      hrefAttrs={hrefAttrs}
      pointerEvents={pointerEvents}
      animate={animate}
      animateInitialState={animateInitialState}
      delay={delay}
      exit={exit}
      exitTransition={exitTransition}
      from={from}
      onDidAnimate={onDidAnimate}
      state={state}
      stylePriority={stylePriority}
      transition={transition}
      style={[style, stretch && StyleSheet.absoluteFill, sx && dripsy(sx)]}
    >
      {children}
    </View>
  )
}

export { MotiView }
