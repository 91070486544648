import React from 'react'
import Link from '@beatgig/design/components/link'
import useSignOut from '@beatgig/auth-hooks/use-sign-out'
import useAmISignedIn from '@beatgig/auth-hooks/use-am-i-signed-in'

type Props = Omit<React.ComponentProps<typeof Link>, 'onPress' | 'children'>

export default function SmartSignoutLink(props: Props) {
  const signOut = useSignOut()
  const { amISignedIn } = useAmISignedIn()
  if (!amISignedIn) return null

  return (
    <Link block {...props} onPress={signOut}>
      {signOut.isLoading ? 'Loading' : 'Sign out'}
    </Link>
  )
}
