import React, { useEffect, useMemo } from 'react'
import { urql } from '@beatgig/gql/client'
import { Provider } from 'urql'
import useAuth from '@beatgig/auth-hooks/use-auth'
import { createClient } from 'urql'
import { useAdminContext } from './Admin/context'
import { useLoginMutation } from '@beatgig/gql'

function Login() {
  const { user } = useAuth()

  const uid = user?.uid
  const [, login] = useLoginMutation()

  useEffect(() => {
    if (uid) {
      uid && login()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid])
  return null
}

export function UrqlProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth()

  const uid = user?.uid
  const isAdminApp = Boolean(useAdminContext()?.isAdminApp)

  const client = useMemo(() => {
    const next = urql({ uid: uid || null, isAdminApp }, createClient)

    console.log('[urql] create client', uid)

    return next
  }, [isAdminApp, uid])

  return (
    <Provider value={client}>
      <Login />
      {children}
    </Provider>
  )
}
