import { useAdminTabs } from '@beatgig/navigation/web/admin/use-admin-tabs'
import React, { useMemo } from 'react'
import { useRouter } from 'next/router'

import { View, ScrollView, Text } from 'dripsy'
import AnimateHeight from '@beatgig/design/components/animate-height'
import Ionicons from '@beatgig/design/components/ionicons'
// import Link from '@beatgig/design/components/link'
import SmartSignoutLink from '@beatgig/auth-components/smart-signout-link'
import Logo from '@beatgig/components/logo'
import { Link } from 'expo-next-react-navigation'
import { MotiPressable, useMotiPressable } from 'moti/interactions'
import { StyleSheet } from 'react-native'
import { MotiView } from 'moti'

type ItemProps = Omit<
  ReturnType<typeof useAdminTabs>['tabs'][number],
  'isActive'
> & {
  isActive: boolean
  asPath: string
}

const Background = ({ isActive }: { isActive: boolean }) => {
  const state = useMotiPressable(
    ({ hovered, pressed }) => {
      'worklet'
      let opacity = 0
      if (isActive) {
        opacity = 1
      } else if (pressed && !isActive) {
        opacity = 1
      } else if (hovered) {
        opacity = 0.7
      }

      return {
        opacity,
      }
    },
    [isActive]
  )
  return (
    <MotiView
      transition={useMemo(() => ({ type: 'timing', duration: 150 }), [])}
      state={state}
      style={StyleSheet.absoluteFill}
    >
      <View
        sx={{
          borderRadius: 3,
          flex: 1,
          bg: 'muted',
        }}
      />
    </MotiView>
  )
}

const Item = React.memo(function Item({
  label,
  isActive,
  dropdownItems,
  asPath,
  path,
  iconName,
}: ItemProps) {
  const to = dropdownItems?.[0].path ?? path
  const color = isActive ? 'text' : 'mutedText'
  return (
    <Link
      routeName={to}
      web={{
        path: to,
      }}
      isText={false}
    >
      <MotiPressable disabled>
        <View
          sx={{
            mt: 2,
            mx: 2,
            p: 2,
          }}
        >
          <Background isActive={isActive} />
          <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {!!iconName && (
              <Ionicons
                name={
                  iconName.endsWith('-outline') && isActive
                    ? (iconName.replace('-outline', '') as any)
                    : iconName
                }
                color={isActive ? 'text' : 'muted6'}
                sx={{ mr: 3 }}
                selectable={false}
                // size={18}
              />
            )}
            <View sx={{ flex: 1 }}>
              <Text sx={{ color }}>{label}</Text>
            </View>
            <View
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                justifyContent: 'center',
              }}
            >
              {!!dropdownItems?.length && (
                <Ionicons
                  name="chevron-down"
                  selectable={false}
                  color={color}
                />
              )}
            </View>
          </View>
          {!!dropdownItems?.length && (
            <AnimateHeight hide={!isActive}>
              <View sx={{ mt: 2 }}>
                {dropdownItems?.map((dropdownItem) => (
                  <Item
                    key={dropdownItem.path}
                    {...dropdownItem}
                    isActive={dropdownItem.isActive(asPath)}
                    asPath={asPath}
                  />
                ))}
              </View>
            </AnimateHeight>
          )}
        </View>
      </MotiPressable>
    </Link>
  )
})

function List() {
  const { tabs } = useAdminTabs()
  const { asPath } = useRouter()

  return (
    <>
      {tabs.map((tab) => (
        <Item
          key={tab.path}
          asPath={asPath}
          {...tab}
          isActive={tab.isActive(asPath)}
        />
      ))}
    </>
  )
}

const list = <List />

export default function AdminNav() {
  return (
    <View sx={{ flex: 1, bg: 'background', maxHeight: '100vh' }}>
      <View sx={{ p: 3 }}>
        <Logo size={30} shouldShowLarge={[true]} />
      </View>
      <View sx={{ flex: 1 }}>
        <ScrollView>{list}</ScrollView>
      </View>
      <View sx={{ alignItems: 'flex-end' }}>
        <SmartSignoutLink sx={{ m: 2 }} color="error" />
      </View>
    </View>
  )
}
