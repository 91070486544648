import { pipe, tap } from 'wonka'
import { Exchange, Operation } from '@urql/core'
import { AppState } from 'react-native'

let listener: ReturnType<typeof AppState.addEventListener> | undefined

export const nativeRefocusExchange = (): Exchange => {
  return ({ client, forward }) =>
    (ops$) => {
      const watchedOperations = new Map<number, Operation>()
      const observedOperations = new Map<number, number>()

      let appState = AppState.currentState

      if (listener) {
        listener.remove()
      }

      listener = AppState.addEventListener('change', (nextAppState) => {
        if (
          appState.match(/inactive|background/) &&
          nextAppState === 'active'
        ) {
          watchedOperations.forEach((op, i) => {
            if (i == 0) {
              console.log('[urql][refocus]')
            }
            client.reexecuteOperation(
              client.createRequestOperation('query', op, {
                ...op.context,
                requestPolicy: 'cache-and-network',
              })
            )
          })
        }

        appState = nextAppState
      })

      const processIncomingOperation = (op: Operation) => {
        if (op.kind === 'query' && !observedOperations.has(op.key)) {
          observedOperations.set(op.key, 1)
          watchedOperations.set(op.key, op)
        }

        if (op.kind === 'teardown' && observedOperations.has(op.key)) {
          observedOperations.delete(op.key)
          watchedOperations.delete(op.key)
        }
      }

      return forward(pipe(ops$, tap(processIncomingOperation)))
    }
}
