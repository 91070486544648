import { useAuthGate } from 'react-doorman'
import type { HeadlessFirebaseUser } from 'react-doorman'

export default function useAuth() {
  //  TODO fix!!!
  return useAuthGate() as {
    user: null | HeadlessFirebaseUser
    loading: boolean
  }
}
