import React, { useState, useMemo, createContext, useContext } from 'react'
import type {
  PendingLoaderUpdaterContext,
  PendingLoaderContext,
  PendingLoaderTask,
} from './types'

const noOp = () => {
  //
}

export const createPendingLoader = () => {
  const PendingLoaderUpdaterContext = createContext<PendingLoaderUpdaterContext>(
    {
      addTask: noOp,
      removeTaskId: noOp,
      removeTaskIds: noOp,
      addTasks: noOp,
    }
  )
  const PendingLoaderContext = createContext<PendingLoaderContext>({
    tasks: {},
  })
  const usePendingLoader = () => useContext(PendingLoaderContext)
  const useUpdatePendingLoader = () => useContext(PendingLoaderUpdaterContext)

  function PendingLoaderProvider({ children }: { children: React.ReactNode }) {
    const [tasks, setTasks] = useState<PendingLoaderContext['tasks']>({})

    const updaterContext = useMemo<PendingLoaderUpdaterContext>(
      () => ({
        addTask: (taskId, task) => {
          setTasks((tasks) => ({
            ...tasks,
            [taskId]: { ...task, pending: true },
          }))
        },
        addTasks: (newTasks) => {
          const nextTasks: Record<string, PendingLoaderTask> = {}
          Object.keys(newTasks).forEach((taskId) => {
            nextTasks[taskId] = { ...newTasks[taskId], pending: true }
          })
          setTasks((tasks) => {
            console.log('[set-tasks]', { tasks, nextTasks })
            return {
              ...tasks,
              ...nextTasks,
            }
          })
        },
        removeTaskId: (taskId) => {
          setTasks((tasks) => {
            const nextTasks = { ...tasks }

            delete nextTasks[taskId]

            return nextTasks
          })
        },
        removeTaskIds: (...taskIds) => {
          setTasks((tasks) => {
            const nextTasks = { ...tasks }

            for (const taskId of taskIds) {
              delete tasks[taskId]
            }

            return nextTasks
          })
        },
      }),
      []
    )

    const loaderContext = useMemo<PendingLoaderContext>(
      () => ({
        tasks,
      }),
      [tasks]
    )

    return (
      <PendingLoaderUpdaterContext.Provider value={updaterContext}>
        <PendingLoaderContext.Provider value={loaderContext}>
          {children}
        </PendingLoaderContext.Provider>
      </PendingLoaderUpdaterContext.Provider>
    )
  }

  return {
    usePendingLoader,
    useUpdatePendingLoader,
    PendingLoaderProvider,
  }
}
