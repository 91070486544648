import React from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Box } from '@beatgig/design/components/box'
import Link from '@beatgig/design/components/link'
import { MultiStepFormLayout } from '@beatgig/features/multi-step-form/layout'
import { H2 } from 'dripsy'

// const OnboardingHeader = styled(H2)({
//   textAlign: 'center',
//   py: 4,
// })

export default function OnboardingHeader(
  props: React.ComponentProps<typeof MultiStepFormLayout['H2']>
) {
  const { canGoBack, goBack, getState } = useNavigation()
  const { name } = useRoute()

  const isNotFirstScreenInStack = !!(
    getState()?.routes?.findIndex((route) => route.name === name) !== 0
  )
  return (
    <Box py="$4">
      {true ? (
        <MultiStepFormLayout.H2
          {...props}
          textAlign="center"
          // sx={{ textAlign: 'center', ...sx, fontSize: '$5', fontWeight: '600' }}
        />
      ) : (
        <H2>{props.children}</H2>
      )}

      {canGoBack() && isNotFirstScreenInStack && (
        <MultiStepFormLayout.Presence>
          <Box alignItems="center" mt="$2">
            <Link
              block
              icon
              iconName="chevron-back"
              iconSide="left"
              onPress={goBack}
            >
              Go Back
            </Link>
          </Box>
        </MultiStepFormLayout.Presence>
      )}
    </Box>
  )
}
