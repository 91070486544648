import type { ServerError } from '@beatgig/api-services'
import { useCallback } from 'react'
import { Platform } from 'react-native'
import navigationRef from '@beatgig/navigation/service/ref'

export default function useIntercom() {
  const show = useCallback(function show() {
    if (Platform.OS !== 'web') {
      navigationRef.current?.navigate('LiveChat')
    } else if (typeof window !== 'undefined') {
      console.log('[intercom] will show')
      window.Intercom?.('show')
    }
  }, [])

  const showNewMessage = useCallback(function showNewMessage(
    prepopulatedContent?: string
  ) {
    console.log('[intercom] might show')
    if (Platform.OS !== 'web') {
      navigationRef.current?.navigate('LiveChat', {
        message: prepopulatedContent,
      })
    } else if (typeof window !== 'undefined') {
      console.log('[intercom] will show')
      window.Intercom?.('showNewMessage', prepopulatedContent)
    }
  },
  [])

  const hide = useCallback(function hide() {
    if (Platform.OS === 'web') {
      window.Intercom?.('hide')
    }
  }, [])

  const showNewError = useCallback(
    function showNewError(e?: ServerError) {
      let err =
        e && 'body' in e ? e?.body?.message ?? e?.body?.detail : e?.message
      err = err ? (typeof err == 'string' ? err : JSON.stringify(err)) : ''

      showNewMessage(
        `I just got an error. Could you give me a hand?${
          err
            ? `
  
  
Here's the error: ${err}`
            : ''
        }`
      )
    },
    [showNewMessage]
  )

  return {
    show,
    showNewMessage,
    showNewError,
    hide,
  }
}
