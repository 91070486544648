import { Query } from './index'
import * as Urql from '@urql/exchange-graphcache'

export function invalidateList<
  Resolver extends Urql.UpdateResolver = Urql.UpdateResolver
>(fieldName: keyof Query, resolver?: Resolver): Resolver {
  return ((parent, args, cache, info) => {
    for (const field of cache.inspectFields('Query')) {
      if (field.fieldName == fieldName) {
        cache.invalidate('Query', field.fieldName, field.arguments)
      }
    }
    return resolver?.(parent, args, cache, info)
  }) as Resolver
}
