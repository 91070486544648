import React from 'react'
import { GetUser } from '@beatgig/api-services/user'
import Badge from '@beatgig/design/components/badge'
import { User } from '@beatgig/api/user'

type Props = {
  user?: GetUser | null
  // sx?: SxStyleProp
} & Omit<React.ComponentProps<typeof Badge>, 'variant'>

export default function UserApprovalStatusBadge(props: Props) {
  const { user, sx, ...badge } = props

  if (!User.hasOnboarded(user)) return null

  const isUserApproved = User.isApproved(user)

  let text = ''
  if (isUserApproved) {
    text = 'Approved'
  } else {
    text = 'Pending Approval'
  }
  // return (
  //   <Dot sx={sx} variant={isUserApproved ? 'success' : 'accent'}>
  //     {text}
  //   </Dot>
  // )
  return (
    <Badge
      sx={sx}
      type={isUserApproved ? 'filled' : 'filled'}
      {...badge}
      variant={isUserApproved ? 'success' : 'accent'}
    >
      {text}
    </Badge>
  )
}
