import apolloConfig from '../../apollo.config'
import {
  createClient,
  ssrExchange,
  dedupExchange,
  fetchExchange,
  errorExchange,
  gql,
} from 'urql'

import { simplePagination } from '@urql/exchange-graphcache/extras'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import { retryExchange } from '@urql/exchange-retry'
import { cacheExchange } from '@urql/exchange-graphcache'
import { devtoolsExchange } from '@urql/devtools'
import {
  Colors,
  CreateUserMutation,
  GraphCacheConfig,
  MeDocument,
  MeQuery,
  Venue,
  VenueDocument,
  VenueQuery,
  VenueQueryVariables,
  User,
  Query,
  BookingBilling,
  PublishBookingMutation,
  Booking,
  LikedArtistSlugsQuery,
  LikedArtistSlugsDocument,
  LikedArtistSlugsQueryVariables,
  ArtistAvailability,
  CalendarBooking,
} from './index'
import schema from './schema.json'
import { authExchange } from '@urql/exchange-auth'
import { makeOperation } from '@urql/core'
import { getIdToken } from '@beatgig/providers/Doorman/init'
import { Sentry } from '@beatgig/helpers/sentry'
import { Platform } from 'react-native'
import type * as NextUrql from 'next-urql'
import { refocusExchange } from '@urql/exchange-refocus'
import { invalidateBadges } from './invalidate-badges'
import { nativeRefocusExchange } from './exchanges/refocus'
import { invalidateList } from './invalidate-list'
import { APP_VERSION } from '@beatgig/constants/app-version'
import LogRocket from 'logrocket'

type AuthState = null | { token: string }

const isClient = Platform.select({
  web: typeof window !== 'undefined',
})

/**
 * @web only!
 */
export const urqlSsrCache = ssrExchange({
  isClient,
  initialState:
    // @ts-expect-error https://formidable.com/open-source/urql/docs/advanced/server-side-rendering/
    isClient ? window.__URQL_DATA__ : undefined,
})

const makeQueryKey = <Key extends keyof Query>(key: Key): Key => key

const urql = <
  Factory extends typeof createClient | typeof NextUrql.initUrqlClient
>(
  { uid, isAdminApp }: { uid: string | null; isAdminApp: boolean },
  factory: Factory
): ReturnType<Factory> => {
  const { url } = apolloConfig.client.service

  return factory(
    {
      url,
      fetchOptions() {
        const headers = {}

        if (
          process.env.SKIP_TRACING == '1' ||
          (process.env.NEXT_PUBLIC_BACKEND_ENV == 'live' && !__DEV__)
        ) {
          // this disables the `extensions` from returning on requests to reduce network request size
          headers['SKIP-TRACING'] = '1'
        }

        headers['VERSION'] = APP_VERSION
        headers['PLATFORM'] = Platform.OS
        if (LogRocket.sessionURL?.startsWith('https://')) {
          headers['LOGROCKET'] = LogRocket.sessionURL
        }

        return {
          headers,
        }
      },
      exchanges: [
        devtoolsExchange,
        // (!isAdmin && dedupExchange) as unknown as typeof dedupExchange,
        dedupExchange,
        requestPolicyExchange({
          ttl: isAdminApp ? 3000 : undefined,
          // shouldUpgrade: isAdminApp ? () => true : undefined,
        }),
        Platform.select({
          web: refocusExchange(),
          default: nativeRefocusExchange(),
        }),
        // @ts-expect-error whatever
        cacheExchange<GraphCacheConfig>({
          // https://formidable.com/open-source/urql/docs/graphcache/#installation-and-setup
          keys: {
            CourierChannel() {
              return null
            },
            CourierProvider: () => null,
            Row(data) {
              // discover page row has a name, which is uniquely identifying
              return data.name
            },
            Pipeline: () => null,
            PipelineMetrics: () => null,
            GamificationMetric: () => null,
            GamificationMetrics: () => null,
            Metric: () => null,
            Overview: () => null,
            Metrics: () => null,
            ArtistStats: () => null,
            BarChart: () => null,
            BarChartEdge: () => null,
            BookingByBuyerType: () => null,
            BookingByCity: () => null,
            BookingByVenue: () => null,
            MyBadgeResponse: () => null,
            Button: () => null,
            ArtistLike: () => null,
            ShortMessage: () => null,
            TransferMetadata: () => null,
            ContactInformation: () => null,
            Media: () => null,
            BandConfig: () => null,
            ConfigInfo: () => null,
            Prices: () => null,
            SmallImage: () => null,
            Note: () => null,
            DisplayPrices: () => null,
            NegotiationStepDescription: () => null,
            StatusInformation: () => null,
            StatusNode: () => null,
            BookingBilling: () => null,
            FrontendDirections: () => null,
            BuyerBilling: () => null,
            Card: () => null,
            ReceiptItem: () => null,
            PriceRangeOptional: () => null,
            Location: (location) => location.placeId,
            Image: (image) => {
              // return null
              return (
                image.publicCloudinaryId ||
                (image as any).public_cloudinary_id ||
                null
              )
            },
            CalendarBookingsResponse: () => null,
            Charge: () => null,
            Payout: ({ transferId }) => transferId,
            WhatShouldIDoAlerts: () => null,
            Colors: () => null,
            SocialMedia: () => null,
            NegotiationStep: () => null,
            Metadata: () => null,
            Songs: () => null,
            Images: () => null,
            Approval: () => null,
            DisplayImages: () => null,
            Videos: () => null,
            PriceRange: () => null,
            ExternalUrls: () => null,
            Followers: () => null,
            ImageSpotify: () => null,
            IpResponse: () => null,
            WhenDate: () => null,
            WhenTime: () => null,
            WhenDatespan: () => null,
            WhenTimespan: () => null,
            Participant: () => null,
            User: ({ firebaseId, id, ...user }) => {
              if (firebaseId) {
                return firebaseId
              }
              console.error(
                '[urql] client issue. User has no id. How did this happen?',
                user
              )
              if (id) {
                return id
              }
              return null
            },
            UserDoesNotExist: () => null,
          },
          schema: schema as any,
          resolvers: {
            Query: {
              bookings: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              bookingsViaFilter: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              artists: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              users: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              myBookings(parent, args, cache, info) {
                const result = simplePagination({
                  limitArgument: 'limit',
                  offsetArgument: 'offset',
                  mergeMode: 'after',
                })(parent, args, cache, info)

                return result
              },
              myBookingsWhereActionIsRequired: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              myBookingRequestsWhereActionIsRequired: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              myBookingRequests: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              bookingRequests: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              myBookingsThatNeedReviews: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              myLikedArtists: simplePagination({
                limitArgument: 'limit',
                offsetArgument: 'offset',
                mergeMode: 'after',
              }),
              artistAvailability(parent, args, cache, info) {
                return {
                  __typename: 'ArtistAvailability',
                  id: args.id,
                }
              },
              spotifyArtistFromId(parent, args, cache, info) {
                // https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/#manually-resolving-entities
                return {
                  __typename: 'ArtistSpotify',
                  id: args.id,
                }
              },
              // removed booking resolver to retain backwards compatibility with firebase ids
              // https://beatgigofficial.slack.com/archives/D019220EFBL/p1657661875322569
              // booking(_, args, cache) {
              //   function isUUID(str: string) {
              //     const regexExp =
              //       /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

              //     return regexExp.test(str)
              //   }
              //   const __typename: Booking['__typename'] = 'Booking'

              //   if (isUUID(args.id)) {
              //     return {
              //       __typename,
              //       id: args.id,
              //     }
              //   }

              //   const key: keyof Query = 'booking'

              //   const idKey: keyof Booking = 'id'

              //   const id = cache.resolve('Query', key, {
              //     [idKey]: args.id,
              //   })

              //   console.log(
              //     '[urql][booking][resolver] id:',
              //     id,
              //     'args.id:',
              //     args.id
              //   )

              //   return id || null
              // },
              me(parent, args, cache, info) {
                const key: keyof User = 'firebaseId'
                const typename: Pick<User, '__typename'>['__typename'] = 'User'

                const fragment =
                  uid &&
                  cache.readFragment(
                    gql`fragment _ on ${typename} { ${key} }`,
                    {
                      firebaseId: uid,
                    }
                  )

                if (uid && !fragment) {
                  // cache.readQuery<MeQuery, MeQueryVariables>({
                  //   query: MeDocument,
                  // })
                  console.log('[query][me] missing fragment')
                  // return undefined
                }

                if (!uid) {
                  return undefined
                }

                if (fragment) {
                  return {
                    __typename: 'User',
                    [key]: uid,
                  }
                }

                return {
                  __typename: 'UserDoesNotExist',
                }
              },
            },
          },
          updates: {
            Mutation: {
              createUser(result, args, cache, info) {
                if (result) {
                  const me = (result as unknown as CreateUserMutation)
                    .createUser

                  cache.updateQuery<NonNullable<MeQuery>>(
                    {
                      query: MeDocument,
                    },
                    (data) => {
                      data = data || {
                        __typename: 'Query',
                        me,
                      }

                      if ('exists' in me) {
                        console.log(
                          '[create-user][result] has exists=false still!'
                        )
                        // delete me.exists
                      }
                      data.me = { ...me }

                      console.log('[create-user][result] me', data.me)

                      return { ...data }
                    }
                  )
                }
              },
              exchangeAuthCodeGoogle: invalidateList(
                'myExternalCalendarEvents',
                (result, args, cache) => {
                  const key = makeQueryKey('me')
                  cache.invalidate('Query', key)
                }
              ),
              likeArtist(result, args, cache, info) {
                const fieldName = makeQueryKey('myLikedArtists')

                for (const field of cache.inspectFields('Query')) {
                  if (field.fieldName == fieldName) {
                    cache.invalidate('Query', field.fieldName, field.arguments)
                  }
                }
              },
              // TODO this should be in optimistic right?
              updateVenue(result, args, cache, info) {
                if (args.patch.colors) {
                  // we're updating the venue's colors, so this should be optimistic
                  console.log('[urql][update-venue][resolver] init')
                  const __typename: Venue['__typename'] = 'Venue'

                  const makeFieldKey = <Key extends keyof Venue>(
                    key: Key
                  ): Key => key

                  const slugFieldName = makeFieldKey('slug')

                  const venueSlug = cache.resolve(
                    {
                      __typename,
                      id: args.venueId,
                    },
                    slugFieldName
                  ) as Venue[typeof slugFieldName] | null

                  if (venueSlug) {
                    cache.updateQuery<VenueQuery, VenueQueryVariables>(
                      {
                        query: VenueDocument,
                        variables: {
                          slug: venueSlug,
                        },
                      },
                      (data) => {
                        if (data?.venue) {
                          let colors: Colors | undefined
                          const { colorMode, primary, __typename } = {
                            ...data.venue.colors,
                            ...args.patch.colors,
                          }
                          if (colorMode && primary && __typename) {
                            colors = {
                              colorMode,
                              primary,
                              __typename,
                            }
                            data.venue.colors = colors
                          }
                        }
                        return data
                      }
                    )
                  }
                }
              },

              createVenueFromPlaceId(parent, args, cache, info) {
                invalidateList('myVenues')(parent, args, cache, info)

                invalidateList('venues')(parent, args, cache, info)
              },

              setDefaultBankAccount(parent, args, cache, info) {
                const __typename: Venue['__typename'] = 'Venue'
                const venueFields: Array<keyof Venue> = [
                  'bankAccounts',
                  'defaultBankAccount',
                ]

                cache.invalidate({
                  __typename,
                  id: args.venueId,
                })

                // invalidate any booking billing too in case we just updated from there
                const bookingBillingTypename: BookingBilling['__typename'] =
                  'BookingBilling'
                cache.invalidate({
                  __typename: bookingBillingTypename,
                })
              },

              createArtist(parent, args, cache, info) {
                const key = makeQueryKey('myArtists')

                cache.invalidate('Query', key)
              },

              deleteBankAccount(result, { venueId }, cache, info) {
                const __typename: Venue['__typename'] = 'Venue'
                const fields: Array<keyof Venue> = [
                  'bankAccounts',
                  'defaultBankAccount',
                ]

                for (const field of fields) {
                  cache.invalidate(
                    {
                      __typename,
                      id: venueId,
                    },
                    field
                  )
                }

                // invalidate any booking billing too in case we just updated from there
                const bookingBillingTypename: BookingBilling['__typename'] =
                  'BookingBilling'
                cache.invalidate({
                  __typename: bookingBillingTypename,
                })
              },
              createArtistReview(parent, args, cache, info) {
                const invalidateBookingsThatNeedReviewsCount = () => {
                  const __typename: keyof Query =
                    'myBookingsThatNeedReviewsCount'

                  cache.invalidate('Query', __typename)
                }
                invalidateBookingsThatNeedReviewsCount()

                const invalidateBooking = () => {
                  const __typename: Booking['__typename'] = 'Booking'

                  cache.invalidate({
                    __typename,
                    id: args.bookingId,
                  })
                }
                invalidateBooking()
              },

              updateBookings(parent, args, cache, info) {
                // update booking from the embed calendar
                // we want to invalidate the calendar booking events so they update when we change images here
                const __typename: CalendarBooking['__typename'] =
                  'CalendarBooking'

                const bookingIds = Array.isArray(args.bookingIds)
                  ? args.bookingIds
                  : [args.bookingIds]

                for (const id of bookingIds) {
                  cache.invalidate({
                    __typename,
                    id,
                  })
                }
              },

              createBookingRequestsBuyer: (...params) => {
                invalidateList('myBookingRequests')(...params)
                invalidateList('bookingRequests')(...params)
                invalidateBadges()(...params)
              },
              createBookingsBuyer: invalidateList('myBookings'),

              createBookingRequestsAdmin(...params) {
                invalidateList('myBookingRequests')(...params)
                invalidateList('bookingRequests')(...params)
              },

              // booking request updates
              cancelBookingRequests: invalidateBadges(),
              declineBookingRequest: invalidateBadges(),
              updateBookingRequests: invalidateBadges(),
              undoDeclineBookingRequest: invalidateBadges(),

              // (admin) booking request updates
              addArtistsToBookingRequests: invalidateBadges(),
              removeArtistsFromBookingRequests: invalidateBadges(),

              // bid updates
              acceptBidBuyer: invalidateBadges(),
              createBidSeller: invalidateBadges(),
              rejectBid: invalidateBadges(),
              updateBid: invalidateBadges(),
              withdrawBid: invalidateBadges(),

              // (admin) bid updates
              acceptBidAdmin: invalidateBadges(),
              createBidAdmin: invalidateBadges(),

              // booking updates
              acceptBookingBuyer: invalidateBadges(),
              acceptBookingSeller: invalidateBadges(),
              cancelBooking: invalidateBadges(),
              declineBooking: invalidateBadges(),
              counterBookingSeller: invalidateBadges(),
              counterBookingBuyer: invalidateBadges(),

              // (admin) booking updates
              acceptBookingAdmin: invalidateBadges(),
              cancelBookingAdmin: invalidateBadges(),
              declineBookingAdmin: invalidateBadges(),
              counterBookingAdmin: invalidateBadges(),
              advanceBooking: invalidateBadges(),

              // (admin) booking cancellations
              replaceArtistOnBooking(parent, args, cache, info) {
                const __typename: Booking['__typename'] = 'Booking'

                cache.invalidate({
                  __typename,
                  id: args.bookingId,
                })
              },

              // (admin) availability checks
              createArtistAvailability(parent, args, cache, info) {
                const __typename: Booking['__typename'] = 'Booking'

                if (args.bookingId) {
                  cache.invalidate({
                    __typename,
                    id: args.bookingId,
                  })
                }
              },

              createArtistAvailabilityCheck(parent, args, cache, info) {
                const __typename: ArtistAvailability['__typename'] =
                  'ArtistAvailability'

                cache.invalidate({
                  __typename,
                  id: args.artistAvailabilityId,
                })
              },

              // (seller) availability checks
              giveArtistAvailability: invalidateBadges(),

              // confirmBooking(
              //   parent: BookingConfirmationResponseMutation,
              //   args,
              //   cache,
              //   info
              // ) {
              //   const __typename: Booking['__typename'] = 'Booking'

              //   const field: keyof Booking = 'confirmation'

              //   const variables: MyBookingsToConfirmQueryVariables = {}
              //   cache.updateQuery(
              //     {
              //       query: MyBookingsToConfirmDocument,
              //       variables,
              //     },
              //     (data: MyBookingsToConfirmQuery) => {
              //       if (!data) return data

              //       return immer(data, (draft) => {
              //         for (const booking of draft.myBookingsToConfirm) {
              //           if (
              //             booking.id === args.bookingId &&
              //             parent.confirmBooking
              //           ) {
              //             booking.confirmation = parent.confirmBooking
              //           }
              //         }
              //       })
              //     }
              //   )

              //   cache.invalidate(
              //     {
              //       __typename,
              //       id: args.bookingId,
              //     },
              //     field
              //   )
              // },
            },
          },
          optimistic: {
            publishBooking(vars, cache, info) {
              const optimisticResult: PublishBookingMutation['publishBooking'] =
                {
                  id: vars.bookingId,
                  isPublished: !vars.unpublish,
                  __typename: 'Booking',
                }
              return optimisticResult
            },
            likeArtist(vars, cache, info) {
              cache.updateQuery<
                LikedArtistSlugsQuery,
                LikedArtistSlugsQueryVariables
              >(
                {
                  query: LikedArtistSlugsDocument,
                  variables: {},
                },
                (query) => {
                  const myLikedArtistsSlugs = query?.myLikedArtistsSlugs || []

                  if (vars.unlike) {
                    for (let i = 0; i < myLikedArtistsSlugs.length; i++) {
                      if (myLikedArtistsSlugs[i] === vars.artistSlug) {
                        myLikedArtistsSlugs.splice(i, 1)
                        break
                      }
                    }
                  } else if (vars.artistSlug) {
                    myLikedArtistsSlugs.push(vars.artistSlug)
                  }

                  return {
                    __typename: 'Query',
                    myLikedArtistsSlugs: [...myLikedArtistsSlugs],
                  }
                }
              )

              return !vars.unlike
            },
          },
        }),
        retryExchange({
          // https://formidable.com/open-source/urql/docs/advanced/retry-operations/
          maxNumberAttempts: 5,
          // retryIf(error) {
          //   return Boolean(error.networkError && !error.graphQLErrors?.length)
          // },
        }),
        errorExchange({
          onError(error, operation) {
            error.graphQLErrors.forEach(({ message, path, ...gqlError }) => {
              const op = { ...operation }
              const fetchOptions = {
                ...(typeof op.context.fetchOptions == 'function'
                  ? op.context.fetchOptions()
                  : op.context.fetchOptions),
              }
              if (
                fetchOptions.headers &&
                'Authorization' in fetchOptions.headers
              ) {
                fetchOptions.headers.Authorization = 'hidden-auth-token'
              }
              op.context.fetchOptions = fetchOptions
              Sentry.captureEvent({
                message: message + ' ' + (path || [])?.join('.'),
                extra: {
                  ...gqlError,
                  ...op,
                  path,
                  ...op.context,
                  ...op.context.fetchOptions,
                },
              })
            })
            const status = error.response?.status || 0
            if (error.networkError && status >= 300) {
              Sentry.captureEvent({
                message: status + ' Error',
                extra: {
                  ...error.networkError,
                  message: error.networkError.message,
                },
                request: error.response,
                level: status >= 500 ? ('critical' as any) : undefined,
              })
            }
          },
        }),

        authExchange<AuthState>({
          async getAuth() {
            if (!uid) {
              console.log('[urql][get-auth] no uid')
              return null
            }

            // TODO time this
            const token = await getIdToken(false)
            if (token) {
              return {
                token,
              }
            }
            return null
          },
          willAuthError() {
            // this gets called before the operation
            // force the ID token to "fetch" every time
            // since it's synchronous pre-expiration, that's fine.
            return true
          },
          addAuthToOperation: ({ authState, operation }) => {
            // the token isn't in the auth state, return the operation without changes
            if (!authState?.token) {
              return operation
            }

            // fetchOptions can be a function (See Client API) but you can simplify this based on usage
            const fetchOptions =
              typeof operation.context.fetchOptions === 'function'
                ? operation.context.fetchOptions()
                : operation.context.fetchOptions

            const headers = {
              ...fetchOptions?.headers,
              Authorization: `Bearer ${authState.token}`,
            }

            return makeOperation(operation.kind, operation, {
              ...operation.context,
              fetchOptions: {
                ...fetchOptions,
                headers,
              },
            })
          },
        }),
        Platform.OS == 'web' && (urqlSsrCache as any),
        fetchExchange,
      ].filter(Boolean),
    },
    false
  ) as ReturnType<Factory>
}

export { urql }
