import { pagesPath } from '@beatgig/features/routing/pathpida/$path'
import { createContext, useContext, useMemo } from 'react'
import Router from 'next/router'
import { NewArtistStackParams } from '../native/types'
import { useRouter } from 'next/router'
import { Platform } from 'react-native'
import {
  NavigationRouteContext,
  NavigationContext,
} from '@react-navigation/native'
import { createParam } from '@beatgig/modals/create-modal-stack/use-param'

export type NewArtistStepContext = {
  step: { name: keyof NewArtistStackParams; index: number; params?: object }
  triggerStep: <Step extends keyof NewArtistStackParams>(
    ...step: undefined extends NewArtistStackParams[Step]
      ? [Step]
      : [Step, NewArtistStackParams[Step]]
  ) => void
}

const triggerStepWeb: NewArtistStepContext['triggerStep'] = (...args) => {
  if (args[0] == 'splash') {
    Router.push(pagesPath.dashboard.artists.new.$url())
  } else {
    const [step, query] = args
    if (step in pagesPath.dashboard.artists.new) {
      Router.push(
        pagesPath.dashboard.artists.new[
          step as keyof NewArtistStackParams
        ].$url({
          query,
        })
      )
    }
  }
}

const NewArtistStepContext = createContext<NewArtistStepContext>({
  step: { name: 'splash', index: 0 },
  triggerStep: triggerStepWeb,
})

const { useParam } = createParam<{ step: keyof NewArtistStackParams }>()

export const newArtistSteps: Record<keyof NewArtistStackParams, true> = {
  splash: true,
  name: true,
  spotify: true,
  confirm: true,
}

export function NewArtistStepProvider({ children }) {
  const [name] = useParam('step', {
    initial: 'splash' as keyof NewArtistStackParams,
    parse(_value) {
      const value = Array.isArray(_value) ? _value[0] : _value
      if (!value || !(value in newArtistSteps)) {
        return 'splash' as keyof NewArtistStackParams
      }

      return value as keyof NewArtistStackParams
    },
  })
  const { asPath, query } = useRouter() || {}

  let stepName = Platform.select({
    web: asPath?.split(
      '/dashboard/artists/new/'
    )[1] as keyof NewArtistStackParams,
    default: name,
  })

  if (!stepName) stepName = 'splash'
  if (!newArtistSteps[stepName]) stepName = 'splash'

  const index = Object.keys(newArtistSteps).indexOf(stepName)

  const route = useContext(NavigationRouteContext)
  const navigation = useContext(NavigationContext)

  return (
    <NewArtistStepContext.Provider
      value={{
        step: Platform.select({
          web: { name: stepName, index, params: query as object },
          default: {
            name: stepName,
            index,
            params: route?.params,
          },
        }),
        triggerStep: useMemo(
          () =>
            Platform.select({
              web: triggerStepWeb,
              default: (...args) => {
                const [step, params] = args
                navigation?.setParams({
                  ...params,
                  step,
                })
              },
            }),
          [navigation]
        ),
      }}
    >
      {children}
    </NewArtistStepContext.Provider>
  )
}

export const useNewArtistStepContext = () => useContext(NewArtistStepContext)
