import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { useBuyerBookingPreferencesQuery } from '@beatgig/gql'

export function useBuyerPreferences() {
  const me = useMyAccount().data
  const [{ data, error, fetching, stale }] = useBuyerBookingPreferencesQuery({
    pause: !User.isBuyer(me),
    requestPolicy: 'cache-and-network',
  })

  return {
    preferences:
      data?.me.__typename === 'User' ? data.me.bookingPreferences : null,
    error,
    fetching,
    stale,
  }
}
