import React, { createContext, useContext, ReactNode } from 'react'
import { useNavigation, NavigationProp } from '@react-navigation/native'

type FreezeStateOnBlurContext =
  | {
      shouldFreezeState: boolean
      addListener: NavigationProp<any>['addListener']
    }
  | undefined

const FreezeStateOnBlurContext = createContext<FreezeStateOnBlurContext | null>(
  null
)

export const useShouldFreezeStateOnBlur = () =>
  useContext(FreezeStateOnBlurContext)

type Props = {
  children: ReactNode
}

export function FreezeStateOnBlurProvider({ children }: Props) {
  const { addListener } = useNavigation()

  return (
    <FreezeStateOnBlurContext.Provider
      value={React.useMemo(() => ({ shouldFreezeState: true, addListener }), [
        addListener,
      ])}
    >
      {children}
    </FreezeStateOnBlurContext.Provider>
  )
}
