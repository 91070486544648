import { View, useSx } from 'dripsy'
import React from 'react'
import { Platform, TextStyle } from 'react-native'
import PickerPopup from 'react-native-picker-select'
import type { Sx as SxStyleProp } from 'dripsy'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import useTheme from '@beatgig/theme/use-theme'
import { useFont } from '@beatgig/theme/use-font'

type Props = {
  sx?: SxStyleProp
  webPickerStyle?: TextStyle
  shouldHideBorder?: boolean
  rounded?: boolean
} & React.ComponentProps<typeof PickerPopup>

export default function Picker(props: Props) {
  const {
    items,
    onValueChange,
    value,
    placeholder = {
      label: 'Any',
      value: null,
    },
    sx,
    webPickerStyle = {},
    shouldHideBorder = false,
    rounded = false,
    ...rest
  } = props
  const { colors, space, radii, borderWidths, fontSizes } = useTheme()
  const { body } = useFont()

  const radius = rounded ? 'rounded' : 3

  const drip = useSx()

  return (
    <View
      sx={{
        ...sx,
        ...Platform.select({
          android: {
            borderRadius: radius,
            borderColor: 'mutedText',
            borderWidth: shouldHideBorder ? 0 : 1,
            overflow: 'hidden',
          },
        }),
      }}
    >
      <PickerPopup
        placeholder={placeholder}
        {...rest}
        items={items}
        onValueChange={(value, index) => {
          console.log('picker pop-up onchange', { value, index })
          if (value === (placeholder as any)?.label) {
            onValueChange('', -1)
          } else {
            onValueChange(value, index)
          }
        }}
        itemKey="value"
        style={{
          inputIOS: {
            fontSize: fontSizes[2],
            paddingVertical: 12,
            paddingHorizontal: 10,
            borderWidth: shouldHideBorder ? 0 : 1,
            borderColor: colors?.mutedText,
            borderRadius: radii.$3,
            color: colors?.text,
            backgroundColor: colors?.background,
            paddingRight: 30, // to ensure the text is never behind the icon
            fontFamily: body[400],
          },
          inputAndroid: {
            fontSize: fontSizes[2],
            paddingHorizontal: 10,
            paddingVertical: 8,
            borderWidth: shouldHideBorder ? 0 : 0.5,
            borderColor: colors?.mutedText as string,
            borderRadius: radii.$3,
            color: colors?.text,
            backgroundColor: colors?.background,
            paddingRight: 30, // to ensure the text is never behind the icon
            fontFamily: body[400],
          },
          chevron: { display: 'none' },
          inputAndroidContainer: {},
        }}
        // key={`picker-${toOrFrom}`}
        value={value}
        pickerProps={{
          // @ts-ignore
          style: {
            // borderStyle: 'solid',
            // borderTopWidth: 1,
            // borderBottomWidth: 1,
            ...Platform.select({
              web: {
                // fontSize: '100%',
                appearance: 'none',
                backgroundColor: colors?.background,
                color: colors?.text,
                borderColor: colors?.mutedText,
                textAlign: 'center',
                padding: space?.[2],
                borderRadius: radii.$3,
                borderWidth: borderWidths?.[1],
                textTransform: 'capitalize',
                fontFamily: body.default,
                fontSize: 16,
                paddingRight: space?.[3] + space?.[2],
                ...webPickerStyle,
              },
              default: {},
              android: drip({
                backgroundColor: 'background',
                borderRadius: 3,
                overflow: 'hidden',
                borderWidth: 1,
                borderColor: 'mutedText',
                color: 'text',
                fontFamily: 'root',
              }),
            }),
          },
          itemStyle: {
            textAlign: 'center',
            // color: colors?.text,
            ...Platform.select({
              web: {
                appearance: 'none',
                backgroundColor: 'transparent',
                // padding: space[3],
              },
              default: {},
              android: drip({
                backgroundColor: 'background',
                fontFamily: 'root',
              }),
            }),
          },
        }}
      />
      <View
        sx={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          top: 0,
          pr: 2,
          justifyContent: 'center',
        }}
        pointerEvents="none"
      >
        <Ionicons name="chevron-down" color={colors?.text} size={18} />
      </View>
    </View>
  )
}
