import React from 'react'
import { ResetNativeAppContext } from './context'
import { Platform } from 'react-native'

export function ResetNativeAppOnSignOut({
  children,
}: {
  children: React.ReactNode
}) {
  const [state, setState] = React.useState(0)

  const value = React.useCallback(() => {
    if (Platform.OS === 'web') {
      return
    }
    setState((state) => state + 1)
  }, [])

  if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
    return <>{children}</>
  }

  return (
    <ResetNativeAppContext.Provider value={value}>
      <React.Fragment key={`${state}`}>{children}</React.Fragment>
    </ResetNativeAppContext.Provider>
  )
}
