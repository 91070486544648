import { useCreateUserMutation } from '@beatgig/gql'

export default function useCreateUser() {
  const [{ error }, execute] = useCreateUserMutation()

  return {
    execute,
    error,
  }
}
