import { Sx, Text as DripsyText } from 'dripsy'
import { ComponentProps, createContext, forwardRef, useContext } from 'react'
import { useRef } from 'react'
import type { Text as TextType } from 'react-native'

type TextProps = ComponentProps<typeof DripsyText>

type Props = Pick<
  TextProps,
  | 'variant'
  | 'onLayout'
  | 'selectable'
  | 'numberOfLines'
  | 'ellipsizeMode'
  | 'onPress'
> &
  Sx & {
    children?: React.ReactNode
  }

/**
 * Text should inherit styles from parent text nodes.
 */
const ParentContext = createContext<Sx | undefined>(undefined)

export const Text = forwardRef<TextType, Props>(function Text(
  {
    variant,
    onLayout,
    children,
    selectable,
    numberOfLines,
    ellipsizeMode,
    onPress,
    // @ts-expect-error backwards compat
    sx: _sx,
    ...sx
  },
  ref
) {
  const parentSx = useContext(ParentContext)

  const compoundSx = { ...parentSx, ..._sx, ...sx }

  return (
    <DripsyText
      ref={ref}
      variant={variant}
      selectable={selectable}
      onLayout={onLayout}
      sx={compoundSx}
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
      onPress={onPress}
    >
      <ParentContext.Provider value={compoundSx}>
        {children}
      </ParentContext.Provider>
    </DripsyText>
  )
})

export const useText = () => useRef<TextType>(null)
