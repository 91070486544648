import { View, TextInput } from 'dripsy'
import React, { ComponentProps, useRef } from 'react'
import type { TextInput as InputType } from 'react-native'
import FieldLabel from './field-label'
// import Input from './text-input'
import Input from '@beatgig/design/components/input'
import MaskedInput from '@beatgig/design/components/input/masked-input'
import { ErrorMessage, useFormikContext } from 'formik'
import { useFieldFast } from '../hooks/use-fast-field'
import ErrorText from './error'
import useIsFocused from '@beatgig/hooks/use-is-focused'
import useAnimatedHover from '@beatgig/helpers/use-animated-hover'
import { FieldName } from './types'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import useFormScrollables from '../context/scrollables/use-form-scrollables'
import mergeRefs from 'react-merge-refs'
import { ScrollToField } from './scroll-to-field'

type FormikInputProps = {
  value: string
  error?: string
  touched: boolean
  setValue: (value: string, shouldValidate?: boolean | undefined) => void
  handleBlur: () => void
  submitCount: number
}

export type TextFieldProps<T extends object> = {
  name: FieldName<T>
  label?: string
  sx?: React.ComponentProps<typeof View>['sx']
  onFocus?: () => void
  onBlur?: () => void
  validationEvent?: 'submit' | 'touch'
  required?: boolean
  labelPosition?: 'top' | 'left' | 'right'
  mask?: 'money' | 'number'
  tooltip?: string | React.ReactNode
  inputRef?: React.MutableRefObject<InputType>
  /**
   * only valid when `mask` is used for the masked input.
   */
  customTextInput?: React.ComponentType
  /**
   * valid with `mask="money"`. defaults to `false`
   */
  showsDollarSign?: boolean
} & React.ComponentProps<typeof TextInput>

const TextFieldWrapped = React.memo(function FormTextField<T extends object>(
  props: TextFieldProps<T> & FormikInputProps
) {
  const {
    name: nameProp,
    // inputProps = {},
    label,
    sx = {},
    onBlur,
    onFocus,
    required = false,
    labelPosition = 'top',
    mask,
    tooltip,
    inputRef = null,
    autoFocus,
    submitCount,
    value,
    error,
    setValue,
    handleBlur,
    touched,
    customTextInput,
    showsDollarSign = false,
    ...inputProps
    // ...fieldProps
  } = props
  const name = Array.isArray(nameProp) ? nameProp.join('.') : nameProp
  // const { errors, handleBlur, handleChange } = useFormikContext<T>()
  const { ref, isHovered } = useAnimatedHover()
  // const input = React.useRef<typeof TextInput>()
  const [isFocused, focusBindings] = useIsFocused(autoFocus)
  const localMaskedInputRef = useRef<InputType>(null)
  // const hasErrored = !!getIn(errors, name as string)

  const onBlurProp = React.useRef(onBlur)
  const onFocusProp = React.useRef(onFocus)
  const isMounted = React.useRef(false)
  React.useEffect(() => {
    onBlurProp.current = onBlur
    onFocusProp.current = onFocus
  })
  React.useEffect(() => {
    if (isMounted.current) {
      if (isFocused) {
        onFocusProp.current?.()
      } else {
        onBlurProp.current?.()
      }
    } else {
      isMounted.current = true
    }
  }, [isFocused])

  const { registerRef } = useFormScrollables()

  const hasErrored = error != undefined && !!(touched || submitCount)
  // console.log('[form][text-field]', {
  //   name,
  //   hasErrored,
  //   error,
  //   touched,
  //   submitCount,
  // })
  let textInputProps: ComponentProps<typeof Input> = {
    ...inputProps,
    autoFocus,
    // validate if there is indeed text
    onChangeText: (text) => setValue(text, !!text),
    onFocus: focusBindings.onFocus,
    onBlur: () => {
      handleBlur()
      focusBindings.onBlur()
    },
    isHovered: isHovered,
    value: value ?? '',
    hasErrored,
    isFocused: isFocused,
    label: labelPosition === 'left' ? label : undefined,
    labelRight: labelPosition === 'right' ? label : undefined,
    keyboardAppearance: 'dark',
  } as any

  let TextInput = Input
  if (mask === 'money') {
    TextInput = MaskedInput as any as typeof TextInput
    // @ts-expect-error incorrect types for custom TextInput
    textInputProps = {
      ...textInputProps,
      label: '$',
      options: {
        precision: 0,
        separator: '.',
        delimiter: ',',
        unit: showsDollarSign ? '$' : '',
        suffixUnit: '',
      },
      type: 'money',
      onChangeText: (text) => setValue(text, !isNaN(parseFloat(text))),
      refInput: localMaskedInputRef,
      customTextInput: customTextInput ?? Input, // don't remove this or you'll get fired
    } as ComponentProps<typeof MaskedInput>
  } else if (mask === 'number') {
    TextInput = MaskedInput as any as typeof TextInput
    // @ts-expect-error incorrect types for custom TextInput
    textInputProps = {
      ...textInputProps,
      options: {
        precision: 0,
        separator: '.',
        delimiter: ',',
        unit: '',
        suffixUnit: '',
      },
      type: 'money',
      onChangeText: (text) => setValue(text, !isNaN(parseFloat(text))),
      refInput: localMaskedInputRef,
      customTextInput: customTextInput ?? Input, // don't remove this or you'll get fired
    } as ComponentProps<typeof MaskedInput>
  }

  return (
    <View ref={ref} sx={sx}>
      <ScrollToField name={name as string} />
      {!!label && labelPosition === 'top' && (
        <FieldLabel
          isHovered={isHovered}
          hasErrored={hasErrored}
          isFocused={isFocused}
          required={required}
        >
          {label}
        </FieldLabel>
      )}

      <HoverTooltip
        textSx={{ bg: 'text', color: 'background' }}
        placement="top"
        anchor="right"
        text={tooltip}
        visible={isFocused}
      >
        <TextInput
          {...textInputProps}
          ref={mergeRefs([
            (textInput) =>
              registerRef(name as string, 'input', textInput as any),
            inputRef,
            localMaskedInputRef,
          ])}
          accessibilityLabel={label}
        />
      </HoverTooltip>
      <ErrorMessage name={name as string}>
        {(message) => {
          return !!message?.trim() && <ErrorText>{message}</ErrorText>
        }}
      </ErrorMessage>
    </View>
  )
})

export default function TextField<T extends object>(
  props: Omit<TextFieldProps<T>, 'submitCount' | keyof FormikInputProps>
) {
  const name = Array.isArray(props.name) ? props.name.join('.') : props.name
  const [{ value }, { error, touched }, { setValue, handleBlur }] =
    useFieldFast<string>(name as string)
  const { submitCount } = useFormikContext()

  return (
    <TextFieldWrapped
      value={value}
      error={error}
      touched={touched}
      setValue={setValue}
      handleBlur={handleBlur}
      {...props}
      name={name as string}
      submitCount={submitCount}
    />
  )
}
