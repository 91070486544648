import { createContext } from 'react'
import { EntityProps } from './types'

export type EntityContext = Pick<EntityProps, 'placeholder' | 'indent'> & {
  indentSize?: number
  entityFieldCount?: number
}

const EntityContext = createContext<EntityContext | null>(null)

export default EntityContext
