// @ts-check
/**
 * @type {'dev' | 'prod'}
 */
const stage = process.env.DEV == '1' ? 'dev' : 'prod'
/**
 * @type {'sandbox' | 'live'}
 */
const env = stage == 'dev' ? 'sandbox' : 'live'

const local = process.env.LOCAL == '1'

module.exports = {
  overwrite: true,
  schema: local
    ? 'http://192.168.1.144:8000/api/v1/graphql'
    : `https://bbgql.${stage}.${env}.hackgig.com/api/v1/graphql`,
  generates: {
    'packages/gql/schema.json': {
      documents: './**/*.graphql',
      plugins: ['urql-introspection'],
      config: [
        {
          minify: true,
        },
        {
          nonOptionalTypename: true,
        },
      ],
    },
    'packages/gql/index.ts': {
      documents: './**/*.graphql',
      plugins: [
        'typescript',
        'typescript-operations',
        'typescript-urql',
        'typescript-urql-graphcache',
      ],
      config: {
        scalars: {
          DateTime: './codegen/date-time#DateTime',
        },
        maybeValue: 'T | undefined',
        nonOptionalTypename: true,
        pureMagicComment: true,
        useTypeImports: true,
        namingConvention: {
          enumValues: 'change-case-all#constantCase',
        },
        skipTypename: false,
      },
    },
    'packages/gql/headless-codegen/index.ts': {
      documents: './**/*.headless.graphql',
      plugins: [
        'typescript',
        'typescript-operations',
        'typescript-graphql-request',
      ],
      config: {
        useTypeImports: true,
        maybeValue: 'T | undefined',
        nonOptionalTypename: true,

        namingConvention: {
          enumValues: 'change-case-all#constantCase',
        },
        skipTypename: false,
      },
    },
    // 'packages/gql/react-query/index.ts': {
    //   documents: './**/*.graphql',
    //   plugins: [
    //     'typescript',
    //     'typescript-operations',
    //     'typescript-react-query',
    //   ],
    //   config: {
    //     scalars: {
    //       DateTime: '../codegen/date-time#DateTime',
    //     },
    //     maybeValue: 'T | undefined',
    //     nonOptionalTypename: true,
    //     pureMagicComment: true,
    //     useTypeImports: true,
    //     namingConvention: {
    //       enumValues: 'change-case-all#constantCase',
    //     },
    //     skipTypename: false,
    //     typesPrefix: 'ReactQuery',
    //     documentVariablePrefix: 'ReactQuery',
    //     fragmentVariablePrefix: 'ReactQuery',
    //     operationResultSuffix: 'ReactQuery',
    //     legacyMode: true,
    //   },
    // },
  },
}
