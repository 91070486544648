import Constants from 'expo-constants'
import versionNumber from '@beatgig/helpers/version'
import vercelConstants from '@beatgig/constants/vercel-constants'
import { segmentWeb } from './segment-web'

export default class AnalyticsBase {
  static track(event: string) {
    segmentWeb?.track(
      event,
      {
        sessionId: Constants.sessionId,
      },
      {
        context: {
          versionNumber,
          ...vercelConstants(),
        },
      }
    )
  }
  static screen(name: string) {
    segmentWeb?.page(
      name,
      {
        sessionId: Constants.sessionId,
      },
      {
        context: {
          versionNumber,
          ...vercelConstants(),
        },
      }
    )
  }
  static screenWithProperties(
    name: string,
    properties: Record<string, unknown> = {}
  ) {
    segmentWeb?.page(
      name,
      {
        ...properties,
        sessionId: Constants.sessionId,
      },
      {
        context: {
          versionNumber,
          ...vercelConstants(),
        },
      }
    )
  }
  static trackWithProperties(
    event: string,
    properties: Record<string, unknown> = {}
  ) {
    segmentWeb?.track(
      event,
      {
        ...properties,
        sessionId: Constants.sessionId,
      },
      {
        context: {
          versionNumber,
          ...vercelConstants(),
        },
      }
    )
  }
  static identify({
    uid,
    ...rest
  }: {
    uid: string
    name: string
    phone_number: string
  }) {
    segmentWeb?.identify(
      uid,
      {
        ...rest,
        sessionId: Constants.sessionId,
      },
      {
        context: {
          versionNumber,
          ...vercelConstants(),
        },
      }
    )
  }
  static reset() {
    segmentWeb?.reset()
  }
}
