import { useRef, useCallback, useEffect } from 'react'
import { Sentry } from '@beatgig/helpers/sentry'
import { ServerError } from '@beatgig/api-services'
import useSWR, { SWRResponse, Key } from 'swr'
import type { GetInterface } from './use-get.types'
import useStable from '@beatgig/design/hooks/use-stable'
import { useFreezeSwrStateOnBlur } from '@beatgig/native/freeze-state-on-blur/swr.native'
import { swr } from '@beatgig/providers/swr/cache'

type fetcherFn<Data> = (...args: any) => Data | Promise<Data>

export default function useGet<Data = any>(
  key: Key,
  fn: fetcherFn<Data>,
  config: GetInterface<Data> = {}
): SWRResponse<Data | null, ServerError> & {
  isPullingToRefresh: boolean
  pullToRefresh(): void
  revalidate(): Promise<any>
} {
  //  {
  const noCacheRef = useRef<Date | null>(null)
  if (noCacheRef.current === null) {
    noCacheRef.current = new Date()
  }
  const noCache = config?.cachePolicy === 'network-only'

  const isPullingToRefreshRef = useRef(false)

  const finalConfig = { ...config }

  if (noCache && config.refreshInterval == null) {
    finalConfig.refreshInterval = 0
  }

  const cacheFirst = useStable(config.cachePolicy === 'cache-first')

  const { overrideState, setCachedState } = useFreezeSwrStateOnBlur<Data>()

  const keyMaker = () => {
    // the screen is blurred, so we don't to re-render from the cache
    if (overrideState) return null

    let finalKey = key
    if (typeof key === 'function') {
      finalKey = key()
    }
    if (!finalKey) return null
    if (!Array.isArray(finalKey)) {
      finalKey = [finalKey]
    }
    if (noCache) {
      finalKey = [...finalKey, noCacheRef]
    }

    return finalKey
  }

  const response = useSWR(
    keyMaker,
    async (...key) => {
      let result: Data
      if (swr.cache.get(key) && cacheFirst.current) {
        const fromCache = swr.cache.get(key)
        console.log('[use-get] cache-first', { key, fromCache })
        return fromCache
      }
      try {
        result = await fn(...key)
      } catch (e) {
        Sentry.captureEvent({
          message: `GET error. Key: ${key.map((key) => `${key}`).join(', ')}`,
          extra: {
            error: e,
          },
        })
        throw e
      }
      return result
    },
    {
      ...finalConfig,
      onSuccess(data, key, config) {
        finalConfig.onSuccess?.(data, key, config)
        isPullingToRefreshRef.current = false
      },
      onError(error, key, config) {
        finalConfig.onError?.(error, key, config)
        isPullingToRefreshRef.current = false
      },
    }
  )

  const { mutate } = response

  const pullToRefresh = useCallback(() => {
    isPullingToRefreshRef.current = true
    mutate()
  }, [mutate])

  useEffect(function stopRefreshing() {
    if (isPullingToRefreshRef.current) {
      if (!response.isValidating) {
        isPullingToRefreshRef.current = false
      }
    }
  })

  if (response.data) {
    setCachedState(response.data)
  }

  return Object.assign({}, response, {
    get data() {
      const data = overrideState || response.data
      return data
    },
    pullToRefresh,
    get isPullingToRefresh() {
      return isPullingToRefreshRef.current && response.isValidating
    },
    revalidate: useCallback(() => {
      return mutate()
    }, [mutate]),
  })
}
