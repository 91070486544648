import AsyncStorage from '@react-native-async-storage/async-storage'
import { useEffect, useMemo, useRef } from 'react'

import isEqual from 'react-fast-compare'
import debounce from 'lodash.debounce'
import { connect, FormikProps } from 'formik'
import { gqlHash } from '@beatgig/gql/schema-hash'
import { useLatestCallback } from '@beatgig/helpers/use-latest-callback'
import { APP_VERSION } from '@beatgig/constants/app-version'

const getStorageKeySuffix = (name: string) => {
  return `formik-${name}`
}

const sha = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || ''

const getStorageKey = (name: string) => {
  // build-time variables for persistence
  // if the GQL schema changed, or the package version changed,
  // then we don't use that
  // there's no garbage collection here is the one thing.
  return `${getStorageKeySuffix(name)}-${gqlHash}-${APP_VERSION}${
    // vercel only
    // it's fine since this covers the web use case, and pack.version covers native 😎
    sha && `-${sha}`
  }`
}

const garbageCollectStorageKeys = async (name: string) => {
  try {
    const keys = await AsyncStorage.getAllKeys()
    const suffix = getStorageKeySuffix(name)
    const storageKey = getStorageKey(name)

    const keysToRemove = keys.filter(
      (key) => key.startsWith(suffix) && key !== storageKey
    )

    if (keysToRemove.length) {
      await AsyncStorage.multiRemove(keysToRemove)
    }
  } catch {}
}

// TODO use MMKV with SDK 45

export const FormikPersist = connect<{ name: string }>(function FormikPersist({
  formik,
  name,
}) {
  const key = getStorageKey(name)
  const setValues = useLatestCallback(formik.setValues)

  useEffect(() => {
    const read = async () => {
      try {
        const value = await AsyncStorage.getItem(key)
        if (value) {
          setValues(JSON.parse(value), true)
        }
      } catch {}
    }
    read()
  }, [key, setValues])

  const previousValues = useRef<undefined | typeof formik.values>()

  const saveForm = useMemo(
    () =>
      debounce((data: FormikProps<{}>) => {
        try {
          AsyncStorage.setItem(key, JSON.stringify(data.values))
        } catch {}
      }, 300),
    [key]
  )

  useEffect(
    function write() {
      if (!isEqual(previousValues.current, formik.values)) {
        saveForm(formik)
      }
    },
    [formik, saveForm]
  )

  useEffect(
    function garbageCollect() {
      garbageCollectStorageKeys(name)
    },
    [name]
  )
  return null
})
