import { AnalyticsBrowser } from '@segment/analytics-next'

export let segmentWeb: undefined | ReturnType<typeof AnalyticsBrowser['load']>

export const setSegmentWeb = (
  value: ReturnType<typeof AnalyticsBrowser['load']>
) => {
  segmentWeb = value
  return segmentWeb
}
