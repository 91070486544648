import { ImageSmall } from '@beatgig/api-services/image'
import { CarouselProps, Img } from '@beatgig/components/image-carousel/types'
import { createGlobalState } from 'react-hooks-global-state'
import { UseImageCarouselPopupReturns } from './types'
import { Platform } from 'react-native'
import {
  useRoute,
  useNavigation,
  CommonActions,
} from '@react-navigation/native'
import {
  ImageCarouselNativeRoute,
} from '@beatgig/navigation/stacks/native-stack/types'
import navigationRef from '@beatgig/navigation/service/ref'

type Closed = {
  isOpen: false
  props: Record<string, never>
}

type Open<I extends Img = ImageSmall> = {
  isOpen: true
  props: CarouselProps<I>
}

const onRequestClose = () => void null

export type ImageCarouselState = {
  carousel: { isOpen: boolean; props: CarouselProps<ImageSmall> }
}

const initialState: ImageCarouselState = {
  carousel: {
    isOpen: false,
    props: {
      images: [],
      onRequestClose,
    },
  },
}
export const globalImageCarouselState = createGlobalState(initialState)

export const openGlobalImageCarousel = <I extends ImageSmall = ImageSmall>(
  props: Open<I>['props']
) => {
  if (Platform.OS === 'web') {
    globalImageCarouselState.setGlobalState('carousel', {
      isOpen: true,
      props,
    })
  } else {
    navigationRef.current?.navigate('ImageCarousel', {
      images: props.images,
      initialImageIndex: props.initialImageIndex,
    })
  }
}

export const closeGlobalImageCarousel = () => {
  if (Platform.OS === 'web') {
    globalImageCarouselState.setGlobalState('carousel', {
      isOpen: false,
      props: {
        images: [],
        onRequestClose,
      },
    })
  } else {
    navigationRef.current?.dispatch(CommonActions.goBack())
  }
}

function useImageCarouselPopupWeb(): UseImageCarouselPopupReturns {
  const [state] = globalImageCarouselState.useGlobalState('carousel')

  return state

  // return [
  //   state,
  //   { open: openGlobalImageCarousel, close: closeGlobalImageCarousel },
  // ] as const
}

function useImageCarouselNative(): UseImageCarouselPopupReturns {
  const {
    params: { images, initialImageIndex },
  } = useRoute<ImageCarouselNativeRoute>()
  const { goBack } = useNavigation()

  return {
    props: { images, initialImageIndex, onRequestClose: goBack },
    isOpen: true, // no-op on native
  }
}

const useImageCarouselPopup = Platform.select({
  web: useImageCarouselPopupWeb,
  default: useImageCarouselNative,
})

export default useImageCarouselPopup
