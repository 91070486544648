import { useActionSheet as useExpoSheet } from '@expo/react-native-action-sheet'
import { useCallback } from 'react'
import useTheme from '@beatgig/theme/use-theme'
import { Platform } from 'react-native'
import { useFont } from '@beatgig/theme/use-font'

export default function useActionSheet() {
  const { showActionSheetWithOptions } = useExpoSheet()
  type Props = Parameters<typeof showActionSheetWithOptions>

  const { colors, fontWeights, fontSizes } = useTheme()

  const { body } = useFont()

  const show = useCallback(
    (props: Props[0], callback: Props[1]) => {
      const {
        options,
        cancelButtonIndex,
        title,
        message,
        destructiveButtonIndex,
      } = props
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
          destructiveButtonIndex,
          title,
          message,
          titleTextStyle: {
            fontSize: fontSizes?.[5] as number,
            color: colors?.text,
            // fontWeight: fontWeights?.['bold'],
            fontFamily: body.bold,
          },
          containerStyle: {
            backgroundColor: colors?.muted,
          },
          textStyle: {
            color: colors?.text,
            fontFamily: body.bold,
          },
          messageTextStyle: {
            color: colors?.mutedText as string,
            fontFamily: body.default,
          },
          separatorStyle: {
            backgroundColor: colors?.mutedText as string,
          },
          showSeparators: true,
          tintColor: colors?.primary,
          destructiveColor: colors?.danger as string,
          useModal: Platform.OS === 'web',
          userInterfaceStyle: 'dark',
        },
        callback
      )
    },
    [colors, fontSizes, showActionSheetWithOptions, body]
  )

  return {
    showActionSheetWithOptions: show,
  }
}
