import React from 'react'
import { Modal, Platform } from 'react-native'
import useImageCarouselPopup, {
  closeGlobalImageCarousel,
} from './use-image-carousel-popup'
import ImageCarousel from '@beatgig/components/image-carousel'

export default function ImageCarouselPopup() {
  const { isOpen, props } = useImageCarouselPopup()

  const carousel = (
    <ImageCarousel {...props} onRequestClose={closeGlobalImageCarousel} />
  )

  if (Platform.OS !== 'web') {
    return carousel
  }

  return (
    <Modal
      transparent
      animationType={'fade'}
      visible={isOpen}
      onRequestClose={closeGlobalImageCarousel}
    >
      {carousel}
    </Modal>
  )
}
