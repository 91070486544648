import React, { useEffect, useRef } from 'react'
import { Target, useScrollTo } from '@nandorojo/anchor'
import { useFormikContext } from 'formik'
import useStable from '@beatgig/design/hooks/use-stable'

function isObject(value?: object) {
  return value && typeof value === 'object' && value.constructor === Object
}

function getRecursiveName(object?: object): string {
  if (!object || !isObject(object)) {
    return ''
  }
  const currentKey = Object.keys(object)[0]
  if (!currentKey) {
    return ''
  }
  if (!getRecursiveName(object[currentKey])) {
    return currentKey
  }
  return currentKey + '.' + getRecursiveName(object[currentKey])
}

export function ScrollToField({
  name,
  triggerFocus,
}: {
  name: string
  triggerFocus?: () => void
}) {
  const { submitCount, errors } = useFormikContext()

  const { scrollTo } = useScrollTo()
  const previousSubmitCount = useRef(submitCount)
  const errorPath = getRecursiveName(errors)

  const stableTrigger = useStable(triggerFocus)

  useEffect(
    function scrollOnSubmissionError() {
      if (!errorPath) return

      if (submitCount > previousSubmitCount.current && name) {
        if (name === errorPath) {
          scrollTo(name).then((didScroll) =>
            console.log('[scroll-to-field] did scroll', name, didScroll)
          )

          stableTrigger.current?.()
        }
      }
      previousSubmitCount.current = submitCount
    },
    [errorPath, errors, name, scrollTo, submitCount, stableTrigger]
  )

  return <Target name={name} />
}
