import React from 'react'
import { Formik } from 'formik'
import { View } from 'dripsy'
import { SellerUserType } from '@beatgig/api-services/user'
import { useNavigation } from '@react-navigation/native'
import { SellerTypeNavigation } from '@beatgig/navigation/stacks/seller-onboarding-stack/types'
import PickerField from '@beatgig/forms/fields/picker-field'
import FormRow from '@beatgig/forms/components/row'
import { Platform } from 'react-native'
import { UserOnboardingFormPersistence } from '@beatgig/features/user/onboarding/form-persist'
import OnboardingHeader from '../onboarding-header'
import { Box } from '@beatgig/design/components/box'
import { ScrollView } from '@nandorojo/anchor'
import { OnboardingLayout } from '@beatgig/features/onboarding/layout'
import { MultiStepFormLayout } from '@beatgig/features/multi-step-form/layout'

type SellerType = { userType: SellerUserType | null }

const aliases: Record<string, SellerUserType> = {
  Other: SellerUserType.OTHER_SELLER,
}

const userTypes: string[] = Object.values(SellerUserType).filter(
  (type) => type !== SellerUserType.OTHER_SELLER
)

userTypes.push(...Object.keys(aliases))

export default function SellerType() {
  const { navigate } = useNavigation<SellerTypeNavigation>()

  return (
    <View sx={{ pt: Platform.select({ web: 3, default: 4 }), flex: 1 }}>
      <Formik<{
        userTypeString: string | null
      }>
        initialValues={{ userTypeString: null }}
        onSubmit={async ({ userTypeString }) => {
          if (userTypeString) {
            const userType = aliases[userTypeString] || userTypeString
            const isValidUserType = new Set(Object.values(SellerUserType)).has(
              userType
            )
            if (isValidUserType) {
              navigate('SellerGenericInfo', {
                userType: userType as SellerUserType,
              })
            }
          }
          return
        }}
      >
        {({ values }) => {
          return (
            <Box flex={1}>
              <Box flex={1}>
                <ScrollView>
                  <View
                    sx={{
                      maxWidth: [null, null, 450],
                      alignSelf: [null, null, 'center'],
                      width: [null, null, '100%'],
                      px: '$3',
                    }}
                  >
                    <UserOnboardingFormPersistence name="seller-type" />
                    <OnboardingHeader>
                      {'What describes you best?'}
                    </OnboardingHeader>
                    <MultiStepFormLayout.Presence>
                      <FormRow>
                        <PickerField<SellerType>
                          name="userTypeString"
                          items={userTypes}
                        />
                      </FormRow>
                    </MultiStepFormLayout.Presence>
                    {/* {!!values.userTypeString && (
                <SubmitButton formName="Seller Type">Continue</SubmitButton>
              )} */}
                  </View>
                </ScrollView>
              </Box>
              <OnboardingLayout.Footer>
                {!!values.userTypeString && (
                  <OnboardingLayout.Submit formName="Seller Type">
                    Continue
                  </OnboardingLayout.Submit>
                )}
              </OnboardingLayout.Footer>
            </Box>
          )
        }}
      </Formik>
    </View>
  )
}
