import React, { useContext } from 'react'
import { EntityFieldProps } from './types'
import { View, Text } from 'dripsy'
import Sizer from '@beatgig/components/sizer'
import EntityContext from './entity-context'

export default function EntityField({
  textAlign,
  title,
  isDescription,
  avatar,
  isLastField,
  subtitle,
  width,
  showsDividerOnMobile,
  subtitleProps = {},
  active = true,
  titleSx,
  titleProps,
}: EntityFieldProps) {
  const maybeContext = useContext(EntityContext)
  const placeholder = maybeContext?.placeholder ?? false

  return (
    <Sizer justifyContent="center" width={[null, width]} flexGrow={[null, 1]}>
      <View
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View sx={{ flex: 1, justifyContent: 'center' }}>
          {!!title && (
            <Text
              {...titleProps}
              sx={{
                fontWeight: '500',
                mb: 1,
                color: active && !isDescription ? 'text' : 'mutedText',
                textAlign,
                ...titleSx,
              }}
              variant={isDescription ? 'caps' : undefined}
            >
              {title}
            </Text>
          )}
          {!!subtitle && (
            <Text
              {...subtitleProps}
              sx={{
                color: isDescription ? 'text' : 'mutedText',
                textAlign,
                ...subtitleProps.sx,
              }}
            >
              {subtitle}
            </Text>
          )}
        </View>
        {!!avatar && <View sx={{ ml: 2 }}>{avatar}</View>}
      </View>
    </Sizer>
  )
}
