import {
  TouchableOpacity,
  Platform,
  KeyboardAvoidingView,
  StyleSheet,
} from 'react-native'
import { styled, View, useDripsyTheme, Text, useResponsiveValue } from 'dripsy'
import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import useWebHeaderHeight from '../web-header/use-web-header-height'
import type { Sx as SxStyleProp } from 'dripsy'
import Spacer from '@beatgig/design/components/spacer'
import Button from '../button'
import Sizer from '../sizer'
import Press from '../press'
import Ionicons, { IconProps } from '@beatgig/design/components/ionicons'
import { DripsyTheme } from '@beatgig/theme'
import { useRouter } from 'next/router'
import { ScrollView as AnchorScrollView } from '@nandorojo/anchor'

const ScrollView = styled(AnchorScrollView)()

const Container = View

const KeyboardView = styled(KeyboardAvoidingView)()

type Props = {
  children: React.ReactNode
  onRequestClose: () => void
  header?: ((info: { onClose: () => void }) => React.ReactNode) | boolean
  footer?: (info: { onClose: () => void }) => React.ReactNode
  padded?: boolean
  animated?: boolean
  container?: React.ComponentType
  scrollable?: boolean
  /**
   * @deprecated
   */
  centered?: boolean
  title?: string
  button?: HeaderProps['button']
  maxHeight?: boolean | number
  /**
   * Whether to center the content **within** the `ScrollView`. Not the same as `centered` (@deprecated), which centers it in the screen.
   */
  centerContent?: boolean
  /**
   * If true, sets a `marginTop` equal to the size of the web header when on web.
   */
  safeHeaderTopMargin?: boolean
  isFullMobileScreen?: boolean[] | boolean
  /**
   * For native safe areas on the header bar
   */
  headerTopInset?: number
  headerIcon?: HeaderProps['icon']
  dangerouslyDisableNativeScroll?: boolean
}

type HeaderProps = {
  title?: string
  onClose: () => void
  button?: (() => React.ReactNode) | true | string | React.ReactNode
  icon?: IconProps['icon'] | null
  titleSx?: SxStyleProp
  /*
   * Don't use this, unless you're only using the Header component on its own.
   * If you're using the Modal, use the `headerTopInset` instead, so that the Modal knows the height.
   *
   */
  topInset?: number
  bg?: keyof DripsyTheme['colors'] | 'transparent'
}

const Touchable = styled(TouchableOpacity)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
})

export default function WebModal(props: Props) {
  const { pathname = '' } = useRouter() || {}
  const isDashboardScreen = pathname?.startsWith('/dashboard')
  const {
    children,
    onRequestClose,
    header,
    footer,
    padded = true,
    animated = Platform.OS === 'web',
    dangerouslyDisableNativeScroll = false,
    scrollable = !dangerouslyDisableNativeScroll,
    centered = false,
    title,
    button,
    maxHeight = true,
    centerContent,
    safeHeaderTopMargin = !isDashboardScreen,
    headerTopInset,
    headerIcon,
    isFullMobileScreen: _isFullMobileScreen = Platform.select({
      web: [true, false, false],
      default: [true],
    }),
  } = props

  const isFullMobileScreen = Array.isArray(_isFullMobileScreen)
    ? _isFullMobileScreen
    : [_isFullMobileScreen]

  // if we're on native, we're always scrollable and tall
  const isScrollable = Platform.select({
    web: scrollable,
    default: !dangerouslyDisableNativeScroll,
  })

  const webHeader = useWebHeaderHeight()
  const isFullScreen = useResponsiveValue(isFullMobileScreen)
  const currentWebHeaderHeight = useResponsiveValue(webHeader.height)

  const { bottom } = useSafeAreaInsets()

  const Wrapper = isScrollable ? ScrollView : View
  let wrapperProps = {}
  if (scrollable) {
    wrapperProps = {
      sx: {
        px: padded && [null, null, 3],
        py: padded && 3,
        pb: Platform.select({
          web: padded && 3,
          default: bottom,
        }),
      },
      // keyboardDismissMode: 'on-drag',
      keyboardShouldPersistTaps: 'handled',
      contentContainerStyle: centerContent
        ? {
            flexGrow: 1,
            justifyContent: 'center',
          }
        : {},
    } as React.ComponentProps<typeof ScrollView>
  } else {
    wrapperProps = {
      sx: {
        px: padded && [null, null, 3],
        py: padded && 3,
        flex: 1,
      },
      contentContainerStyle: isScrollable
        ? {
            flex: 1,
          }
        : undefined,
    }
  }

  const renderHeader = () => {
    if (typeof header === 'function') {
      return header?.({ onClose: onRequestClose })
    }
    if (header) {
      return (
        <Header
          button={button}
          title={title}
          topInset={headerTopInset}
          onClose={onRequestClose}
          icon={headerIcon}
        />
      )
    }
    return null
  }

  return (
    <KeyboardView
      behavior={Platform.select({ ios: 'padding', default: undefined })}
      sx={{
        // mt: Platform.OS === 'web' ? height : 0,
        flex: 1,
        zIndex: webHeader.zIndex + 100,
        mt: Platform.select({
          web: !safeHeaderTopMargin
            ? undefined
            : isFullMobileScreen.map((full, index) =>
                full ? currentWebHeaderHeight : 0
              ),
          default: undefined,
        }),
        animationKeyframes:
          animated && Platform.OS === 'web'
            ? {
                from: {
                  opacity: 0,
                },
                to: {
                  opacity: 1,
                },
              }
            : undefined,
        animationDuration: '0.3s',
      }}
    >
      <Touchable
        sx={{
          display: isFullMobileScreen.map((full) => (full ? 'none' : 'flex')),
        }}
        onPress={onRequestClose}
        // onPress={() => alert('closed')}
      />
      <View
        sx={(theme) => ({
          flex: 1,
          // pt: Platform.select({
          //   web: isFullMobileScreen.map((full, index) =>
          //     full || centered ? 0 : webHeader.height[index]
          //   ),
          //   default: undefined,
          // }),
          justifyContent: centered ? undefined : 'center',
        })}
        pointerEvents="box-none"
      >
        <View
          pointerEvents="none"
          style={StyleSheet.absoluteFill}
          sx={(theme) => ({
            backgroundColor: isFullMobileScreen.map((full) =>
              full ? 'background' : `${theme.colors.text}70`
            ),
            opacity: isFullMobileScreen.map((full) => (full ? 1 : 0.7)),
          })}
        />
        <Container
          sx={{
            // no max-width on iPads, but there is on web
            maxWidth: Platform.select({
              web: isFullMobileScreen.map((full) => (full ? '100%' : 600)),
              default: undefined,
            }),
            alignSelf: 'center',
            //   py: 3,
            bg: 'background',
            overflow: 'hidden',
            borderRadius: isFullMobileScreen.map((full) => (full ? null : 4)),
            flex: !centered ? 1 : undefined,
            px: 0,
            my: isFullScreen ? 0 : [null, null, 3, 4],
            width: '100%',
            maxHeight: maxHeight
              ? isFullMobileScreen.map((full) => {
                  if (full) {
                    return Platform.select({ web: 'none', default: '100%' })
                  }
                  return typeof maxHeight === 'number' ? maxHeight : 650
                })
              : undefined,
            justifyContent: isFullMobileScreen.map((full) => {
              if (full) return null
              return 'center'
            }),
          }}
        >
          {renderHeader()}
          <View
            sx={{
              flex: 1,
              // bg: 'primary',
            }}
          >
            <Wrapper {...wrapperProps}>
              <Sizer
                {...{
                  px: padded ? [3, null, 2] : undefined,
                  flex: centered ? undefined : 1,
                }}
              >
                {children}
              </Sizer>
              {/* keyboard offset hack */}
              {Platform.OS !== 'web' && scrollable && <Spacer height={5} />}
            </Wrapper>
          </View>
          <View>{footer?.({ onClose: onRequestClose })}</View>
        </Container>
      </View>
    </KeyboardView>
  )
}

function Header(props: HeaderProps) {
  const { top } = useSafeAreaInsets()
  const {
    title = ' ',
    onClose,
    button,
    icon = Platform.select({
      default: 'close' as const,
      ios: 'chevron-back-outline' as const,
      android: 'arrow-back' as const,
    }),
    titleSx = {},
    topInset = top,
    bg = Platform.select({
      web: 'muted',
      // is a modal on native
      default: topInset ? 'background' : 'muted',
    }),
  } = props
  const { colors } = useDripsyTheme().theme
  const renderButton = () => {
    // if (!button) return null
    if (React.isValidElement(button)) {
      return button
    }
    if (typeof button === 'function') {
      return button?.()
    }
    const text = typeof button === 'string' ? button : 'Send'
    return (
      <Button
        pointerEvents={!button ? 'none' : 'auto'}
        sx={{
          opacity: button ? 1 : 0,
        }}
        onPress={onClose}
        // variant="small"
      >
        {text}
      </Button>
    )
  }
  return (
    <View
      sx={{
        py: 2,
        bg,
        overflow: 'hidden', // for animations, etc
      }}
    >
      <View
        sx={{
          px: [2, 3],
          pt: topInset,
        }}
      >
        <View
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <View sx={{ flex: 0.5, flexBasis: '0%' }}>
            {icon !== null && (
              <Press onPress={onClose}>
                <Ionicons
                  size={30}
                  icon={icon}
                  color={topInset ? 'primary' : 'text'}
                />
              </Press>
            )}
          </View>
          <View sx={{ flex: 0.9, flexBasis: '0%' }}>
            <Text
              sx={{
                color: 'text',
                fontSize: [3, 4],
                fontWeight: '600',
                textAlign: 'center',
                ...titleSx,
              }}
            >
              {title}
            </Text>
          </View>
          <View
            sx={{
              flex: 0.5,
              flexBasis: '0%',
              // height: '100%',
              alignItems: 'flex-end',
              justifyContent: 'center',
              minHeight: 36.3, // avoid layout flicker for optional large button
            }}
          >
            <View sx={{}}>{renderButton?.()}</View>
          </View>
        </View>
      </View>
    </View>
  )
}
WebModal.Header = Header
