import { QueryClient, focusManager, onlineManager } from 'react-query'
import { AppState, Platform } from 'react-native'
import type NetInfo from '@react-native-community/netinfo'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2000, // default dedupe interval from SWR
    },
  },
})

if (Platform.OS != 'web' || typeof window != 'undefined') {
  focusManager.setEventListener((handleFocus) => {
    const listener = AppState.addEventListener('change', handleFocus)

    return () => {
      listener.remove()
    }
  })
}

if (Platform.OS !== 'web') {
  const Network: typeof NetInfo = require('@react-native-community/netinfo')
    .default
  onlineManager.setEventListener(() => {
    return Network.addEventListener((state) => {
      onlineManager.setOnline(!!state.isConnected)
    })
  })
}
