import React from 'react'
import { View, styled } from 'dripsy'
import { Image as Img } from 'react-native'
import useResponsiveValue from '@beatgig/hooks/use-responsive-value'

const Image = styled(Img)({})

type Props = {
  size?: number | number[]
  shouldShowLarge?: boolean[] | boolean
  containerSx?: React.ComponentProps<typeof View>['sx']
  shouldOpenAssetsOnRightClick?: boolean
} & React.ComponentProps<typeof View>

const Logo = React.memo(function Logo(props: Props) {
  const {
    sx = {},
    size = 40,
    shouldShowLarge = [false, false, true],
    containerSx,
    shouldOpenAssetsOnRightClick = true,
    ...rest
  } = props

  const showLarge = useResponsiveValue(
    Array.isArray(shouldShowLarge) ? shouldShowLarge : [shouldShowLarge]
  )

  const aspectRatio = 497 / 107

  return (
    <View
      sx={containerSx}
      // @ts-expect-error missing type
      onContextMenu={(e) => {
        if (shouldOpenAssetsOnRightClick) {
          e.preventDefault()
          document.location.href = `https://drive.google.com/drive/u/3/folders/1PCnHLN3faDyJqbtCwPlfTkeYKC2GfOKS`
        }
      }}
      {...rest}
    >
      {!showLarge ? (
        <Image
          source={require('./beatgig-logo-256.png')}
          sx={{
            size,
            ...sx,
          }}
          resizeMode="contain"
        />
      ) : (
        <Image
          source={require('./beatgig-256.png')}
          sx={{
            height: size,
            width: Array.isArray(size)
              ? size.map((height) => height * aspectRatio)
              : aspectRatio * size,
            ...sx,
          }}
          resizeMode="contain"
        />
      )}
    </View>
  )
})

export default Logo
