import React from 'react'
import { View, Text } from 'dripsy'
import { Sizes, Variants } from '../types'
import type { Sx } from 'dripsy'
import useGetColors from '../../hooks/use-variant-colors'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import Press from '@beatgig/components/press'
import { Platform } from 'react-native'

type Props = {
  checked?: boolean
  onChange?: (next: boolean) => void
  disabled?: boolean
  /**
   * Defaults to the `default` variant
   */
  variant?: Variants
  children?: React.ReactNode
  /**
   * If `true`, the content next to the checkbox will stretch its container.
   *
   * Default: `true`.
   */
  stretchContent?: boolean
  /**
   * Default: `left`.
   */
  boxSide?: 'left' | 'right'
  /**
   * Style the text content.
   */
  textSx?: Sx
  /**
   * Set the checkbox size.
   */
  size?: Sizes
  /**
   * If you want to show a different `variant` when the box is `checked`, you can pass it here.
   *
   * If you don't pass anything here, the checked variant will match the normal `variant` prop.
   */
  checkedVariant?: Variants
  /**
   * Style the `View` that wraps the `Text`.
   */
  textContainerSx?: Sx
  initialChecked?: boolean
  /**
   * If `true`, content next to the checkbox will be vertically-centered.
   *
   * Default: `false`
   */
  centerContent?: boolean
  /**
   * How much space should the box have from its text
   */
  boxMargin?: number | string
} & Omit<React.ComponentProps<typeof View>, 'variant'>

export default function Checkbox(props: Props) {
  const {
    sx = {},
    checked,
    onChange,
    disabled = false,
    variant = 'default',
    stretchContent = true,
    children,
    boxSide = 'left',
    textSx,
    size = 'medium',
    checkedVariant,
    textContainerSx,
    initialChecked,
    centerContent,
    boxMargin = 3,
  } = props

  const [localChecked, setLocalChecked] = React.useState(
    !!(initialChecked ?? checked)
  )

  const onPress = (next: boolean) => {
    onChange?.(next)
    setLocalChecked(next)
  }

  return (
    <View
      sx={{
        flexDirection: boxSide === 'left' ? 'row' : 'row-reverse',
        ...sx,
      }}
    >
      <Box
        {...{
          checked: checked ?? localChecked,
          onChange: onPress,
          disabled,
          variant,
          size,
          checkedVariant,
        }}
      />
      {!!children && (
        <View
          sx={{
            flex: stretchContent ? 1 : undefined,
            ml: boxMargin,
            justifyContent: centerContent ? 'center' : undefined,
            ...textContainerSx,
          }}
        >
          <Text sx={{ fontSize: 2, ...textSx }}>{children}</Text>
        </View>
      )}
    </View>
  )
}

function Box({
  checked,
  onChange,
  disabled,
  variant = 'default',
  size,
  checkedVariant = variant,
}: Pick<
  Props,
  'checked' | 'disabled' | 'variant' | 'size' | 'checkedVariant'
> & {
  onChange: (next: boolean) => void
}) {
  const { bg } = useGetColors({
    variant: checked ? checkedVariant : variant,
    filling: 'filled',
  })

  let iconSize = 25
  switch (size) {
    case 'mini':
      iconSize = 22
      break
    case 'small':
      iconSize = 25
      break
    case 'medium':
      iconSize = 28
      break
    case 'large':
      iconSize = 32
      break
    case 'xl':
      iconSize = 35
      break
    default:
      break
  }

  return (
    <Press
      onPress={() => onChange(!checked)}
      sx={{
        opacity: disabled ? 0.75 : 1,
        cursor:
          (Platform.OS === 'web' && disabled ? 'not-allowed' : 'pointer') ||
          undefined,
      }}
      disabled={disabled}
    >
      {({ hovered }) => {
        return (
          <View
            sx={{
              transform: [
                {
                  scale: hovered ? 1.08 : 1,
                },
              ],
              transitionDuration: '250ms',
              transitionProperty: 'transform',
            }}
          >
            <View>
              <Ionicons
                size={iconSize}
                name={checked ? 'checkbox' : 'square-outline'}
                color={bg}
                selectable={false}
              />
            </View>
          </View>
        )
      }}
    </Press>
  )
}
