import React from 'react'
import { Formik } from 'formik'
import { Container } from 'dripsy'
import * as yup from 'yup'
import { universityList } from './university-list'
import useCreateUser from '@beatgig/auth-hooks/use-create-user'
import { BuyerUserType, UserRole } from '@beatgig/api-services/user'
import { useRoute } from '@react-navigation/native'
import type { BuyerGreekRoute } from '@beatgig/navigation/stacks/buyer-onboarding-stack/types'
import OnboardingHeader from '../onboarding-header'
import Spacer from '@beatgig/design/components/spacer'
import FormRow from '@beatgig/forms/components/row'
import FormTextField from '@beatgig/forms/fields/text-field'
import AutoCompleteField from '@beatgig/forms/fields/auto-complete-field'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { Platform } from 'react-native'
import { UserOnboardingFormPersistence } from '@beatgig/features/user/onboarding/form-persist'
import { UserType } from '@beatgig/gql'
import { useSx } from 'dripsy'
import { MultiStepFormLayout } from '@beatgig/features/multi-step-form/layout'
import { Box } from '@beatgig/design/components/box'
import { OnboardingLayout } from '@beatgig/features/onboarding/layout'
import ErrorNote from '@beatgig/components/ErrorNote'

const { Presence } = MultiStepFormLayout

type GreekInfo = {
  greek_organization: string
  university: string
}

type Props = {
  onSubmit: (data: GreekInfo) => void
}

export default function GreekOrganization() {
  const sx = useSx()
  const { execute, error } = useCreateUser()
  const {
    params: { signUpMessage, userType, ...user },
  } = useRoute<BuyerGreekRoute>()
  return (
    <Formik<GreekInfo>
      initialValues={{ greek_organization: '', university: '' }}
      onSubmit={async ({ greek_organization, university }) => {
        return await execute({
          // this must be accepted to get to this screen
          input: {
            ...user,
            userRole: UserRole.BUYER,
            metadata: {
              userType:
                BuyerUserType.FRATERNITY_SORORITY as unknown as UserType,
              organization: greek_organization,
              location: university,
            },
          },
          signUpMessage,
        })
      }}
      validationSchema={yup.object().shape<GreekInfo>({
        university: yup.string().nullable().required(' '),
        greek_organization: yup.string().nullable().required(' '),
      })}
    >
      <Box flex={1}>
        <KeyboardAwareScrollView
          style={sx({ bg: 'background' })}
          keyboardShouldPersistTaps="handled"
        >
          <UserOnboardingFormPersistence name="greek" />
          <Container
            sx={{
              maxWidth: [null, null, 450],
              alignSelf: [null, null, 'center'],
              width: Platform.select({
                default: undefined,
                web: [null, null, '100%'],
              }),
              py: 3,
              flex: 1,
              bg: 'background',
            }}
          >
            <OnboardingHeader>
              {'Tell us about your organization.'}
            </OnboardingHeader>
            <ErrorNote error={error} my="$3" />
            <Presence>
              <FormRow sx={{ zIndex: 1 }}>
                <AutoCompleteField<GreekInfo>
                  editable
                  required
                  options={universityList}
                  name="university"
                  label="University"
                  placeholder="University of Michigan"
                  allowsCustomValue
                />
              </FormRow>
            </Presence>

            <Presence index={1}>
              <FormRow sx={{ zIndex: 0 }}>
                <FormTextField<GreekInfo>
                  name="greek_organization"
                  label="Organization Name"
                  placeholder="ex: Theta Chi"
                />
              </FormRow>
            </Presence>
            {/* leave this for the picker, whatever */}
            <Spacer height={'$6'} />
          </Container>
        </KeyboardAwareScrollView>
        <OnboardingLayout.Footer>
          <OnboardingLayout.Submit
            formName="Create Greek User"
            sx={{ zIndex: 0 }}
          >
            Continue
          </OnboardingLayout.Submit>
        </OnboardingLayout.Footer>
      </Box>
    </Formik>
  )
}
