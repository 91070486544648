import * as React from 'react'
import {
  createNavigatorFactory,
  StackRouter,
  useNavigationBuilder,
  DefaultNavigatorOptions,
  ParamListBase,
  StackNavigationState,
} from '@react-navigation/native'
import { AnimatePresence, MotiView } from 'moti'
import { View, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

type MotiStackEventMap = {
  onExitComplete: {
    data?: undefined
    canPreventDefault: false
  }
}

const createMotiStack = createNavigatorFactory(function MotiNavigator({
  initialRouteName,
  children,
  screenOptions,
  screenStyle,
  initial,
}: DefaultNavigatorOptions<
  ParamListBase,
  StackNavigationState<ParamListBase>,
  {},
  MotiStackEventMap
> & {
  screenStyle?: React.ComponentProps<typeof View>['style']
} & Pick<React.ComponentProps<typeof AnimatePresence>, 'initial'>) {
  const { state, descriptors, NavigationContent, navigation } =
    useNavigationBuilder<
      StackNavigationState<ParamListBase>,
      {},
      {},
      {},
      MotiStackEventMap
    >(StackRouter, {
      initialRouteName,
      children,
      screenOptions,
    })

  const onExitComplete = () => {
    navigation.emit({
      type: 'onExitComplete',
    })
  }

  const route = state.routes[state.index]

  return (
    <NavigationContent>
      <View style={[styles.container, screenStyle]}>
        <AnimatePresence
          onExitComplete={onExitComplete}
          exitBeforeEnter={true}
          initial={initial}
        >
          <React.Fragment key={route.key}>
            {descriptors[route.key].render()}
            {/**
             * Hack to ensure `exitBeforeEnter` always fires
             */}
            <MotiView
              animate={{ opacity: 0.01 }}
              exit={{ opacity: 0 }}
              exitTransition={{ type: 'timing', duration: 1 }}
              style={{ display: 'none' }}
            />
          </React.Fragment>
        </AnimatePresence>
      </View>
    </NavigationContent>
  )
})

export { createMotiStack }
