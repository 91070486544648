import React from 'react'
import { Formik } from 'formik'
import { Container, View } from 'dripsy'
import * as yup from 'yup'
import { universityList } from './university-list'
import useCreateUser from '@beatgig/auth-hooks/use-create-user'
import {
  BuyerUserType,
  UserRole,
  UserDisplay,
} from '@beatgig/api-services/user'
import { BuyerUPBRoute } from '@beatgig/navigation/stacks/buyer-onboarding-stack/types'
import { useRoute } from '@react-navigation/native'
import OnbarodingHeader from '../onboarding-header'
import Spacer from '@beatgig/design/components/spacer'
import SubmitButton from '@beatgig/forms/fields/submit'
import ErrorText from '@beatgig/forms/fields/error'
import FormRow from '@beatgig/forms/components/row'
import AutoCompleteField from '@beatgig/forms/fields/auto-complete-field'
import FormTextField from '@beatgig/forms/fields/text-field'
import { Platform } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { UserOnboardingFormPersistence } from '@beatgig/features/user/onboarding/form-persist'
import { UserType } from '@beatgig/gql'

type UPBInfo = {
  university: string
  organization: UserDisplay['metadata']['organization']
}

export default function UPB() {
  const { execute, error } = useCreateUser()
  const {
    params: { signUpMessage, userType, ...user },
  } = useRoute<BuyerUPBRoute>()
  return (
    <View
      sx={{
        bg: 'background',
        flex: 1,
        // justifyContent: Platform.select({
        //   default: ['flex-start'],
        //   web: [null, null, 'center'],
        // }),
      }}
    >
      <>
        <Formik<UPBInfo>
          initialValues={{ university: '', organization: '' }}
          onSubmit={async ({ university, organization }) => {
            return execute({
              input: {
                metadata: {
                  userType:
                    BuyerUserType.UNIVERSITY_PROGRAM_BOARD as unknown as UserType,
                  organization: organization,
                  location: university,
                },
                ...user,
                userRole: UserRole.BUYER,
              },
              signUpMessage,
            })
          }}
          validationSchema={yup.object().shape<UPBInfo>({
            university: yup.string().nullable().required(' '),
            organization: yup.string().nullable().required(' '),
          })}
        >
          <KeyboardAwareScrollView keyboardShouldPersistTaps="handled">
            <UserOnboardingFormPersistence name="upb" />
            <Container
              sx={{
                maxWidth: [null, null, 450],
                alignSelf: [null, null, 'center'],
                width: Platform.select({
                  default: undefined,
                  web: [null, null, '100%'],
                }),
                py: 3,
                flex: 1,
                bg: 'background',
              }}
            >
              {!!error && (
                <ErrorText>
                  {(error as any)?.body?.message ??
                    error?.message ??
                    'There was an error here, please try submitting again.'}
                </ErrorText>
              )}
              <OnbarodingHeader>
                {'Which university do you represent?'}
              </OnbarodingHeader>
              <FormRow sx={{ zIndex: 1 }}>
                <AutoCompleteField<UPBInfo>
                  required
                  options={universityList}
                  name="university"
                  label="University"
                  placeholder="University of Michigan"
                />
              </FormRow>
              <FormRow sx={{ zIndex: 0 }}>
                <FormTextField<UPBInfo>
                  required
                  placeholder="Michigan Student Affairs"
                  name="organization"
                  label="Organization Name"
                />
              </FormRow>

              <SubmitButton formName="Create UPB User" sx={{ zIndex: 0 }}>
                Continue
              </SubmitButton>
              {/* leave this for the picker, whatever */}
              <Spacer height={4} />
            </Container>
          </KeyboardAwareScrollView>
        </Formik>
      </>
    </View>
  )
}
