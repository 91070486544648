import React from 'react'
import { Platform } from 'react-native'
import { SellerOnboardingStack } from './types'
import SellerType from '@beatgig/auth-components/seller-onboarding/seller-type'
import GenericSellerInfo from '@beatgig/auth-components/seller-onboarding/generic-info'
import useTheme from '@beatgig/theme/use-theme'
import { createMotiStack } from '@beatgig/navigation/moti/stack'

const { Screen, Navigator } = createMotiStack<SellerOnboardingStack>()

const Empty = () => <></>

export default function SellerOnboarding({
  webFlex,
}: {
  /**
   * Set it to 1 if you are handling your own scroll view, such as outside of a modal.
   */
  webFlex?: number
}) {
  const colors = useTheme().colors
  return (
    <Navigator
      // initialRouteName="Welcome"
      screenOptions={{
        headerShown: false,
        cardStyle: {
          flex: Platform.select({ web: webFlex, default: 1 }),
          // flex: 1,
          backgroundColor: colors.background,
        },
        detachPreviousScreen: false,
        // ...TransitionPresets.SlideFromRightIOS,
      }}
    >
      {/* <Screen name="Empty" component={Empty} /> */}
      <Screen
        name="SellerType"
        component={SellerType}
        // getComponent={() =>
        //   require('@beatgig/auth-components/seller-onboarding/seller-type')
        //     .default
        // }
      />
      <Screen
        name="SellerGenericInfo"
        component={GenericSellerInfo}
        // getComponent={() =>
        //   require('@beatgig/auth-components/seller-onboarding/generic-info')
        //     .default
        // }
      />
    </Navigator>
  )
}
