import React from 'react'
import { View, Text } from 'dripsy'
import { useAutoCompleteContext } from './auto-complete-context'
import Press from '@beatgig/components/press'

type Props = {
  children?: React.ReactNode
  allowsCustomValue?: boolean
  closeOnPress?: boolean
  onPress?: (value?: string) => void
} & React.ComponentProps<typeof View>

export default function AutoCompleteEmpty(props: Props) {
  const {
    sx = {},
    children,
    allowsCustomValue,
    closeOnPress = true,
    onPress,
  } = props

  let text = children
  const { value, updateVisible, updateValue } = useAutoCompleteContext()
  if (allowsCustomValue && value) {
    text = `Custom: "${value}"`
  }

  const canPress =
    onPress || (closeOnPress && updateVisible) || allowsCustomValue

  return (
    <Press
      onPress={
        canPress
          ? () => {
              console.log('[auto-complete-empty][onPress]')
              if (allowsCustomValue && value) {
                updateValue?.({ value, label: value })
              }
              if (closeOnPress) {
                updateVisible?.(false)
              }
              onPress?.(value)
            }
          : undefined
      }
    >
      <Text sx={{ p: 2, textAlign: 'center' }}>{text}</Text>
    </Press>
  )
}
