import React from 'react'
import { View, Text, useDripsyTheme } from 'dripsy'
import Press from '@beatgig/components/press'
import { useAutoCompleteContext } from './auto-complete-context'
import { AutoCompleteOption } from './types'
import { Sx, useSx } from 'dripsy'
import { Target } from '@nandorojo/anchor'

type Props = {
  children: React.ReactNode
  isText?: boolean
  textSx?: React.ComponentProps<typeof View>['sx']
  isFirst?: boolean
  isLast?: boolean
  showSeparator?: boolean
  shouldHighlight?: boolean
  align?: 'center' | 'left'
  isFocused?: boolean
} & AutoCompleteOption

const Separator = <View sx={{ height: 1, bg: 'border' }} />
export default React.memo(function AutoCompleteItem(props: Props) {
  const {
    // sx = {},
    children,
    isText = typeof children === 'string',
    textSx,
    value,
    isFirst = false,
    isLast = false,
    selected = false,
    showSeparator = !isFirst,
    subtitle,
    label,
    shouldHighlight = true,
    align = 'center',
    prefix = null,
    suffix = null,
    isFocused = false,
  } = props

  const sx = useSx()

  if (selected) {
    console.log('[auto-complete-item]', { selected, value })
  }

  const { updateValue } = useAutoCompleteContext()
  const onPress = () => {
    if (value) updateValue?.({ value, label })
  }

  const { colors } = useDripsyTheme().theme

  const color = selected ? colors?.background : colors?.text

  return (
    <Press
      style={({ hovered }) =>
        sx({
          bg: selected
            ? 'primary'
            : isFocused
            ? 'muted2'
            : hovered
            ? 'muted'
            : undefined,
        })
      }
      onPress={onPress}
    >
      <Target name={value} />
      {showSeparator && Separator}
      {(() => {
        const hovered = false
        const containerSx: Sx = {
          color: selected ? 'background' : 'text',
          ...textSx,
          p: 2,
          bg: hovered ? 'muted2' : 'transparent',
          transitionProperty: 'background-color',
          transitionDuration: '0.25s',
          flexDirection: 'row',
          alignItems: 'center',
        }
        const Wrapper = isText ? Text : View
        return (
          <View sx={containerSx} focusable={false}>
            {!!prefix && <View sx={{ mr: 3 }}>{prefix}</View>}
            <View sx={{ flex: 1 }}>
              <Wrapper
                sx={{
                  textAlign: isText ? align : undefined,
                  color: isText ? color : undefined,
                }}
                selectable={false}
              >
                {children as any}
              </Wrapper>
              {!!subtitle &&
                ((
                  <Text
                    sx={{
                      color: selected ? color : 'mutedText',
                      textAlign: align,
                      mt: 1,
                    }}
                    selectable={false}
                  >
                    {subtitle}
                  </Text>
                ) as any)}
            </View>

            {!!suffix && <View sx={{ ml: 3 }}>{suffix}</View>}
          </View>
        )
      })()}
    </Press>
  )
})
