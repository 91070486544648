// @ts-check

const includes = ['./packages/**/*.graphql']
const excludes = [
  './**/*.ts',
  './**/*/node_modules/**/*',
  './packages/gql/index',
]

// for now
let prodOrDev =
  // if we're just building locally, use dev...for now this is unsafe otherwise!
  !process.env.NEXT_PUBLIC_BACKEND_ENV ||
  process.env.NEXT_PUBLIC_BACKEND_ENV == 'sandbox'
    ? 'dev'
    : 'prod'

const { schema } = require('./packages/gql/codegen/config')

let url = schema

// replace dev/prod with the correct stage
url = url.replace(/prod|dev/, prodOrDev)

const liveOrSandbox = prodOrDev == 'dev' ? 'sandbox' : 'live'

// replace live/sandbox with the correct env
url = url.replace(/live|sandbox/, liveOrSandbox)

if (process.env.NEXT_PUBLIC_BACKEND_ENV == 'sandbox') {
  // url = `https://dev-sandbox-gql-4a17ccb9e7ba0351.onporter.run/api/v1/graphql`
}

module.exports = {
  client: {
    service: {
      name: 'beatgig',
      url,

      // url:
      //   process.env.NODE_ENV === 'development'
      //     ? 'http://192.168.1.136:8000/api/v1/graphql'
      //     : url,
      includes,
      excludes,
    },
    includes,

    // optional disable SSL validation check
    skipSSLValidation: true,
    excludes,
  },
}
