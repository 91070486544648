import React, { useMemo, useState, useCallback } from 'react'
import { remToPixels, styled, View, Text, useDripsyTheme } from 'dripsy'
import type { Sx as SxStyleProp } from 'dripsy'
import { Sizes } from '../types'
import { View as NativeView, Platform } from 'react-native'
import Press from '@beatgig/components/press'
import { FastImage as Image } from '@beatgig/components/fast-image'
import { DripsyTheme } from '@beatgig/theme'
import Gradient from '../gradient'

interface Props {
  src?: string
  stacked?: boolean
  text?: string
  size?: Sizes | number | (Sizes | number | null)[]
  isSquare?: boolean
  sx?: SxStyleProp
  containerSx?: SxStyleProp
  textSx?: SxStyleProp
  /**
   * If `false`, disables capitalizing the letters. Default: `true`.
   */
  caps?: boolean
  onPress?: () => void
  /**
   * If `true`, will generate initials from the text provider
   */
  makeInitials?: boolean | number
  bg?:
    | keyof DripsyTheme['colors']
    | {
        gradient: Array<keyof DripsyTheme['colors']>
      }
  disabled?: boolean
  /**
   * To keep the item memoized, you can use the `uri` instead of `source`.
   */
  uri?: string
  borderWidth?: true | number
  Container?: React.ComponentType<any>
  borderColor?: keyof DripsyTheme['colors']
}

// const defaultProps: Props = {
//   stacked: false,
//   text: '',
//   size: 'small',
//   isSquare: false,
// }

const Img = styled(Image)({})

type NativeAttrs = Omit<
  Partial<React.ComponentProps<typeof Image>>,
  keyof Props
>
export type AvatarProps = Props & NativeAttrs

const getSize = (size: Sizes | number): number => {
  const sizes: { [key in Sizes]: number } = {
    mini: remToPixels('1.25rem'),
    small: remToPixels('1.875rem'),
    medium: remToPixels('2.5rem'),
    large: remToPixels('3.75rem'),
    xl: remToPixels('5.625rem'),
  }
  if (typeof size === 'number') return size
  return sizes[size]
}

function useLayout() {
  const [layout, setLayout] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const onLayout = useCallback((e) => setLayout(e.nativeEvent.layout), [])

  return {
    onLayout,
    ...layout,
  }
}

const defaultBg: AvatarProps['bg'] = { gradient: ['accent', 'primary'] }

// https://github.com/geist-org/react/blob/master/components/avatar/avatar.tsx
function Avatar({
  uri,
  source: _source,
  stacked = false,
  text,
  size = 'medium',
  isSquare = false,
  sx,
  onPress,
  caps = true,
  makeInitials = false,
  bg = defaultBg,
  disabled = !onPress,
  borderWidth,
  Container = onPress ? Press : View,
  containerSx,
  textSx,
  borderColor = 'background',
  ...props
}: AvatarProps) {
  const source = uri ? { uri } : _source
  const borderRadius = isSquare ? 2 : 'rounded'
  const marginLeft = stacked ? remToPixels('-.625rem') : 0
  const width = useMemo(() => {
    if (Array.isArray(size)) {
      return size.map(getSize)
    }
    return getSize(size)
  }, [size])
  // const { onLayout, ...layout } = useLayout()
  const {
    theme: { colors },
  } = useDripsyTheme()

  const getFontSize = (size: Props['size'] | number | null) => {
    let fontSize = 3
    if (size === 'small') fontSize = 2
    if (size === 'medium') fontSize = 5
    if (size === 'large') fontSize = 6
    if (size === 'xl') fontSize = 7
    if (size === 'mini') fontSize = 1

    return fontSize
  }

  let fontSize: number | number[]
  if (Array.isArray(size)) {
    fontSize = size.map(getFontSize)
  } else {
    fontSize = getFontSize(size)
  }

  const safeText = (): string => {
    let initials = text
    const initialCount = typeof makeInitials === 'number' ? makeInitials : 2
    if (makeInitials) {
      initials = text
        ?.split(' ')
        ?.map((name) => name?.trim?.()?.[0])
        ?.filter(Boolean)
        ?.join('')
        ?.slice?.(0, 2)

      if (initials?.length) return initials
    }
    if ((text?.length ?? 0) <= initialCount) return text ?? ''
    return text?.slice?.(0, initialCount) ?? ''
  }

  return (
    <Container
      hitSlop={
        onPress
          ? {
              bottom: 10,
              top: 10,
              left: 10,
              right: 10,
            }
          : undefined
      }
      disabled={disabled}
      onPress={onPress}
      sx={containerSx}
    >
      <View
        sx={{
          width,
          height: width,
          overflow: 'hidden',
          bg: !source ? (typeof bg == 'string' ? bg : undefined) : `background`,
          marginLeft,
          justifyContent: 'flex-start',
          borderWidth: borderWidth === true ? 2 : borderWidth,
          // borderStyle: 'solid',
          borderRadius,
          borderColor,
          // borderColor: 'border',
          ...sx,
        }}
      >
        {!source && !!bg && typeof bg == 'object' && (
          <Gradient stretch colors={bg.gradient} />
        )}
        {/* <Gradient
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            ...Platform.select({
              // to prevent outer ring bug on web
              web: {
                transform: source ? [{ scale: 0.99 }] : undefined,
                borderRadius: 'rounded',
                transformOrigin: source && 'center center',
              },
              default: {},
            }),
            // borderRadius,
          }}
          colors={[colors?.blue as string, colors?.accent as string]}
          end={[0.9, 0.9]}
          start={[0.1, 0.1]}
        /> */}
        {/* <View
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            ...Platform.select({
              // to prevent outer ring bug on web
              web: {
                transform: source ? [{ scale: 0.99 }] : undefined,
                borderRadius: source ? 'rounded' : undefined,
                transformOrigin: source && 'center center',
              },
              default: {},
            }),
            bg: 'blue',
            // borderRadius,
          }}
        /> */}
        {!!source && (
          <Img
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              // borderRadius,
            }}
            source={source}
            {...props}
          />
        )}
        {!source && (
          <NativeView
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              // top: '50%',
              // left: '50%',
              // native view here since https://github.com/nandorojo/dripsy/issues/39
              transform: [
                // {
                //   translateX: layout.width / 2,
                // },
                {
                  scale: 0.7,
                },
                // {
                //   translateY: layout.height / 2,
                // },
              ],
              // hide this until we have the layout
              // opacity: layout.height ? 1 : 0,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              // onLayout={onLayout}
              sx={{
                fontSize,
                textAlign: 'center',
                userSelect: Platform.select({
                  web: 'none',
                  default: undefined,
                }),
                whiteSpace: Platform.select({
                  web: 'nowrap',
                  default: undefined,
                }),
                color: 'background',
                fontWeight: '600',
                textTransform: caps ? 'uppercase' : undefined,
                ...textSx,
              }}
              // numberOfLines={1}
              {...props}
            >
              {safeText()}
            </Text>
          </NativeView>
        )}
      </View>
    </Container>
  )
}

// type MemoAvatarComponent<P = {}> = React.NamedExoticComponent<P> & {
//   Group: typeof AvatarGroup
// }

// Avatar.Group = AvatarGroup

// type ComponentProps = Partial<typeof defaultProps> &
//   Omit<Props, keyof typeof defaultProps> &
//   NativeAttrs

// Avatar.defaultProps = defaultProps

export default Avatar
// export default (React.memo(Avatar) as unknown) as MemoAvatarComponent<
//   AvatarProps
// >
// export default Avatar as MemoAvatarComponent<AvatarProps>
