import * as React from 'react'
import { getStateFromPath, getActionFromState } from '@react-navigation/core'
// TODO change for react-navigation-v6. This feels unsafe.
// THIS WILL BREAK ON WEB, SINCE WE ONLY USE src on native!!!
// we need to import from src to access the proper context
import LinkingContext from '@react-navigation/native/src/LinkingContext'
import navigationRef from '../service/ref'

/**
 * Native only usage for now. Web will come later.
 *
 * Doesn't work.
 */
export default function useLinkTo() {
  const linking = React.useContext(LinkingContext)

  const linkTo = React.useCallback(
    (p: string) => {
      const path = p
      if (!path.startsWith('/')) {
        throw new Error(`The path must start with '/' (${path}).`)
      }

      // use the ref instead so we can navigate outside of the stacks
      if (navigationRef.current === undefined) {
        console.error(
          '[use-link-to] navigationRef.current is not yet initialized. hmmm'
        )
        return
      }

      const { options } = linking

      const state = options?.getStateFromPath
        ? options.getStateFromPath(path, options.config)
        : getStateFromPath(path, options?.config)

      if (state) {
        let root = navigationRef.current
        let current

        // Traverse up to get the root navigation
        // TODO change for react-navigation-v6. no longer dangerous
        while ((current = root?.getParent())) {
          root = current
        }

        const action = getActionFromState(state, options?.config)
        console.log('[use-link-to] getActionFromState', action)

        if (action !== undefined) {
          root?.dispatch(action)
        } else {
          root?.reset(state)
        }
      } else {
        throw new Error('Failed to parse the path to a navigation state.')
      }
    },
    [linking]
  )

  return linkTo
}
