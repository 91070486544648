import React from 'react'
import { View } from 'dripsy'
import { ServerError } from '@beatgig/api-services'
import Note from '@beatgig/design/components/note'
import Button from '../button'
import useIntercom from '@beatgig/hooks/use-intercom'
import type { CombinedError } from 'urql'
import { User } from '@beatgig/api/user'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { DripsyTheme } from '@beatgig/theme'

type Props = {
  error?: ServerError | null
  /**
   * Messaage to show if there's no message from the backend.
   */
  message?: string
  /**
   * Defaults to `Error`.
   */
  label?: string
  filling?: React.ComponentProps<typeof Note>['filling']
  disableLiveChat?: boolean
  color?: React.ComponentProps<typeof Note>['variant']
  mt?: keyof DripsyTheme['space']
  mb?: keyof DripsyTheme['space']
  my?: keyof DripsyTheme['space']
} & React.ComponentProps<typeof View>

export default function ErrorNote(props: Props) {
  const isAdmin = User.isAdmin(useMyAccount().data)
  const {
    mt,
    my,
    mb,
    sx = {
      my,
      mb,
      mt,
    },
    error,
    message = '',
    label = 'Error',
    filling = 'faded',
    disableLiveChat = isAdmin,
    color = 'error',
  } = props

  const intercom = useIntercom()

  if (!error) return null

  let errorMessage = ''

  if ('body' in error && error.body?.message) {
    errorMessage = error.body?.message
  } else if ('graphQLErrors' in error) {
    errorMessage = ''
    error.graphQLErrors.forEach((error, i) => {
      if (i > 0) {
        errorMessage += '\n'
      }
      errorMessage += error.message
    })
  } else if ('networkError' in error && (error as CombinedError).networkError) {
    errorMessage = `Looks like there was a network error. Please try again.`
  }

  return (
    <View sx={sx}>
      <Note filling={filling} variant={color} label={label}>
        {errorMessage || `${message ? `${message} ` : ''}${error?.message}`}
      </Note>
      {!!error && !disableLiveChat && (
        <Button
          onPress={() => intercom.showNewError(error)}
          iconLeft={{ name: 'chatbox-ellipses-outline', size: 16 }}
          sx={{ mt: 2, alignSelf: 'flex-start' }}
          color="text"
          variant="small"
        >
          Live Chat Us
        </Button>
      )}
    </View>
  )
}
