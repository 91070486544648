import { ActivityIndicator, Text, useDripsyTheme, View } from 'dripsy'
import React, { ComponentProps } from 'react'
import { Platform, StyleSheet } from 'react-native'
import type { DripsyTheme } from '@beatgig/theme'
import Ionicons, { IconsBaseProps } from '@beatgig/design/components/ionicons'
import Press from '../press'
import HoverPulse from '../hover-pulse'
import useGetColors from '@beatgig/design/hooks/use-variant-colors'
import type { Sx } from 'dripsy'

// const Ionicons = () => <></>

type Props = {
  children?: React.ReactNode | string
  /*
   * @deprecated we should move this over to size, type, etc.
   */
  variant?: keyof DripsyTheme['buttons']
  sx?: Sx
  labelSx?: Sx
  isText?: boolean
  onPress?: ComponentProps<typeof Press>['onPress']
  labelProps?: ComponentProps<typeof Text>
  touchableProps?: ComponentProps<typeof Press>
  iconLeft?: {
    name: IconsBaseProps['name']
    size?: number
    color?: string
  }
  iconRight?: ComponentProps<typeof Ionicons>
  loading?: boolean
  success?: boolean
  disabled?: boolean
  loadingColor?: string
  filling?: Parameters<typeof useGetColors>[0]['filling']
  color?: Parameters<typeof useGetColors>[0]['variant']
} & Omit<ComponentProps<typeof View>, 'variant'>

const textKeys = [
  'color',
  'fontSize',
  'fontWeight',
  'textTransform',
  'textAlign',
]

const Button = React.forwardRef(function Button(props: Props, ref?: any) {
  const {
    children = null,
    variant = 'primary',
    sx = {},
    labelSx = {},
    isText = true,
    onPress,
    labelProps,
    touchableProps,
    iconLeft = {},
    loading = false,
    success = false,
    disabled = false,
    hitSlop = {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10,
    },
    loadingColor,
    iconRight = null,
    filling = 'filled',
    color = 'primary',
    ...viewProps
  } = props

  const variantColors = useGetColors({
    filling,
    variant: color,
  })

  const resolvedSx = { ...sx }
  const resolvedLabelSx: Props['labelSx'] = {
    ...labelSx,
  }
  // anything other than primary isn't checked for in this refactor
  if (
    !resolvedLabelSx.color &&
    (variant === 'primary' || variant === 'small')
  ) {
    resolvedLabelSx.color = variantColors.color
  }
  if (
    !resolvedSx.borderColor &&
    (variant === 'primary' || variant === 'small')
  ) {
    resolvedSx.borderColor = variantColors.borderColor
  }
  if (!resolvedSx.bg && (variant === 'primary' || variant === 'small')) {
    resolvedSx.bg = variantColors.bg
  }
  const resolvedIconLeft: Props['iconLeft'] = {
    color: variantColors.color,
    size: 20,
    ...(iconLeft as any),
  }

  textKeys.forEach((key) => {
    if ((resolvedSx as any)[key]) {
      // if we style this in sx by accident, move it to the label
      (resolvedLabelSx as any)[key] = (sx as any)[key]
      delete (resolvedSx as any)[key]
    }
  })

  if ((resolvedSx as any).color) {
    // if we style this in sx by accident, move it to the label
    (resolvedLabelSx as any).color = (sx as any).color
    delete (resolvedSx as any).color
  }
  if ((resolvedSx as any).fontSize) {
    // if we style this in sx by accident, move it to the label
    (resolvedLabelSx as any).fontSize = (sx as any).fontSize
    delete (resolvedSx as any).fontSize
  }
  if ((resolvedSx as any).textTransform) {
    // if we style this in sx by accident, move it to the label
    (resolvedLabelSx as any).textTransform = (sx as any).textTransform
    delete (resolvedSx as any).textTransform
  }
  const { theme } = useDripsyTheme()

  const { label: labelStyle, ...variantStyle } = theme.buttons[variant] ?? {
    label: {},
  }

  if (resolvedIconLeft?.name && resolvedIconLeft.color) {
    resolvedIconLeft.color = (theme.colors?.[resolvedIconLeft.color] ||
      resolvedIconLeft.color) as string
  }
  const loaderColor =
    loadingColor ??
    (resolvedLabelSx as any)?.color ??
    (resolvedIconLeft as any)?.color ??
    // @ts-expect-error no label here
    labelStyle?.color ??
    'background'

  if (loading) {
    resolvedLabelSx.color = 'transparent'
    ;(resolvedIconLeft as any).color = 'transparent'
  }

  return (
    <Press
      disabled={loading || disabled}
      {...touchableProps}
      onPress={onPress}
      ref={ref}
      hitSlop={hitSlop}
      accessibilityRole="button"
    >
      <HoverPulse
        scaleTo={
          disabled !== true || onPress
            ? Platform.select({
                web: 1.04,
                default: 0.95,
              })
            : 1
        }
      >
        <View
          {...viewProps}
          sx={{
            flexDirection: iconLeft ? 'row' : undefined,
            alignItems: 'center',
            justifyContent: 'center',
            ...variantStyle,
            ...resolvedSx,
          }}
        >
          {!!resolvedIconLeft?.name && (
            <View sx={{ mr: children ? 2 : 0 }}>
              <Ionicons selectable={false} {...resolvedIconLeft} />
            </View>
          )}
          {loading && (
            <View
              sx={{
                ...StyleSheet.absoluteFillObject,
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator
                color={theme.colors?.[loaderColor] ?? loaderColor}
              />
            </View>
          )}
          {isText && children ? (
            <Text
              selectable={false}
              {...labelProps}
              sx={{
                textAlign: 'center',
                ...labelStyle,
                ...resolvedLabelSx,
              }}
            >
              {children}
            </Text>
          ) : (
            children
          )}
          {!!iconRight && (
            <Ionicons
              size={20}
              // @ts-expect-error no label types for the color
              color={resolvedLabelSx.color ?? labelStyle.color ?? 'background'}
              sx={{ ml: children ? 2 : 0 }}
              {...iconRight}
            />
          )}
        </View>
      </HoverPulse>
    </Press>
  )
})

export default Button
