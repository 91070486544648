// type Routes  = {
//     [key: string]: () => RouteProperty
// }
import type { NativeTabsParams } from '@beatgig/navigation/tabs/native-tabs/types'
import { NativeStackParams } from '../stacks/native-stack/types'

export const AuthedRoutes = {
  bookings: () =>
    ({
      routeName: 'bookings',
      web: { path: `/dashboard/bookings` },
      // native: {
      //   screen: 'bookings',
      // },
    } as const),
  artists: () => {
    return {
      routeName: 'artists',
      web: { path: `/dashboard/artists` },
    } as const
  },
  venues: () =>
    ({
      routeName: 'venues',
      web: { path: `/dashboard/venues` },
    } as const),
  booking: (id: string | null) =>
    id === null
      ? ({ routeName: 'booking' } as const)
      : ({
          // routeName: 'bookings',
          web: {
            path: `/dashboard/bookings/[id]`,
            as: `/dashboard/bookings/${id}`,
          },
          params: { id },
          // native: {
          //   screen: 'booking',
          // },
          routeName: 'booking',
        } as const),
  dashboard: () =>
    ({
      routeName: 'dashboard',
    } as const),
  account: () =>
    ({
      routeName: 'account',
      web: { path: '/dashboard/account' },
    } as const),
  automatedBookings: () => ({
    routeName: 'automatedBookings',
    web: { path: '/dashboard/magic' },
  }),
  likes: () =>
    ({
      routeName: 'likes',
      web: {
        path: '/dashboard/likes',
      },
    } as const),
  calendar: ({ venueSlug }: { venueSlug?: string } = {}) => {
    // if (venueId) {
    //   // TODO move this to a `useNavigateToCalendar` hook to avoid side effects of this in render!!
    //   // globalSelectedVenueState.setGlobalState('selectedVenueId', venueId)
    // }
    return {
      routeName: 'calendar',
      web: {
        path: '/dashboard/calendar',
        as: venueSlug ? `/dashboard/calendar?venue=${venueSlug}` : undefined,
      },
      params: venueSlug ? { venue: venueSlug } : undefined,
    } as const
  },
  venueApplicationsInbox: () =>
    ({
      routeName: 'inbox',
      web: {
        path: 'dashboard/inbox',
      },
    } as const),
  venueApplicationsOutbox: () =>
    ({
      routeName: 'outbox',
      web: {
        path: 'dashboard/outbox',
      },
    } as const),
  refer: () =>
    ({
      routeName: 'refer',
      web: {
        path: 'dashboard/refer',
      },
    } as const),
  bookingRequests: () =>
    ({
      routeName: 'bookingRequests',
      web: {
        path: '/dashboard/requests',
      },
    } as const),
  sellerBookingRequest: ({
    bookingRequestId,
  }: {
    bookingRequestId: string
  }) => {
    const routeName: keyof NativeTabsParams = 'bookingRequests'
    return {
      routeName,
      web: {
        path: `/dashboard/requests?bookingRequestId=${bookingRequestId}`,
      },
    } as const
  },
  promoStudio: (params?: NativeStackParams['PromoStudio']) => {
    const routeName: keyof NativeTabsParams = 'promo'

    return {
      routeName,
      web: {
        path: `/dashboard/promo`,
      },
      params,
    } as const
  },
  invoices: () => {
    return {
      routeName: 'invoices',
      web: {
        path: '/dashboard/invoices',
      },
    } as const
  },
  venueTheme({ venueSlug }: { venueSlug: string }) {
    return {
      routeName: 'venueTheme', // no-op
      web: {
        path: `/dashboard/venue-theme`,
      },
      params: {
        slug: venueSlug,
      },
    } as const
  },
  sellerPayouts(params: { artistSlug: string } | null) {
    const routeName: keyof Pick<NativeStackParams, 'artistPayouts'> =
      'artistPayouts'
    return {
      routeName,
      web: {
        path: `/dashboard/artists/payouts`,
      },
      params: params
        ? ({
            artist: params.artistSlug,
          } as NativeStackParams['artistPayouts'])
        : undefined,
    } as const
  },
  // sellerPromoStudio: (params?: NativeStackParams['PromoStudio']) => {
  //   const routeName: keyof NativeTabsParams = 'promo'

  //   return {
  //     routeName,
  //     web: {
  //       path: `/dashboard/artist-promo`,
  //     },
  //     params,
  //   } as const
  // },
} as const
