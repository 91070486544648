import React, { useContext } from 'react'
import { View } from 'dripsy'
import { EntityProps } from './types'
import EntityContext from './entity-context'
import { pickChild } from '../../helpers/collections'
import EntityField from './entity-field'
import Sizer from '@beatgig/components/sizer'
import EntityCheckbox from './entity-checkbox'
import EntityMenu from './entity-menu'

type Props = EntityProps & React.ComponentProps<typeof View>

/**
 * TODO add variant
 */
export default function Entity(props: Props) {
  const isNestedEntity = Boolean(useContext(EntityContext))
  const {
    menuItems,
    footer,
    actions,
    children,
    placeholder,
    thumbnail = null,
    checkbox,
    indent,
    thumbnailSize = 32,
    wrap = false,
    sx = {},
    border = !isNestedEntity,
    alignFirstRow,
    __isList = false,
    padding = isNestedEntity ? '$0' : '$3',
    thumbnailMarginRight = 3,
    ...viewProps
  } = props

  const [, entityFieldChildren] = pickChild(children, EntityField)

  const firstEntityField = entityFieldChildren?.[0]
  const entityFieldsAfterFirst = [...(entityFieldChildren ?? [])]
  entityFieldsAfterFirst.shift()

  // const entityFieldCount = React.Children.count(entityFieldChildren)

  const renderFirstRow = () => {
    if (!firstEntityField) return null

    return (
      <Sizer alignItems={alignFirstRow} flexDirection={'row'}>
        {!!checkbox && <EntityCheckbox>{checkbox}</EntityCheckbox>}
        {!!thumbnail && (
          <View
            sx={{
              width: indent ? thumbnailSize : undefined,
              mr: thumbnailMarginRight,
            }}
          >
            {thumbnail}
          </View>
        )}
        <View sx={{ flex: 1 }}>
          <Sizer display={['flex', 'none']}>{!wrap && firstEntityField}</Sizer>
          <Sizer display={['none', 'flex']} flexDirection={'row'}>
            {entityFieldChildren}
          </Sizer>
        </View>
        {!!actions && <View sx={{ ml: 3 }}>{actions}</View>}
        <EntityMenu menuItems={menuItems} />
      </Sizer>
    )
  }

  const renderFieldRows = () => {
    const fieldChildrenAfterFirst = entityFieldChildren?.slice(1)
    if (!wrap && !fieldChildrenAfterFirst) {
      return null
    }

    return (
      <Sizer display={['flex', 'none']}>
        {!!wrap && !!firstEntityField && (
          <View sx={{ mt: 3 }}>{firstEntityField}</View>
        )}
        {!!fieldChildrenAfterFirst &&
          React.Children.map(fieldChildrenAfterFirst, (child, index) => {
            return (
              <View
                sx={{
                  mt: 3,
                  pt: 3,
                  borderTopWidth: '1',
                  borderColor: 'border',
                }}
              >
                {child}
              </View>
            )
          })}
      </Sizer>
    )
  }

  return (
    <EntityContext.Provider
      value={{
        indent,
        // entityFieldCount,
        placeholder,
        indentSize: thumbnailSize,
      }}
    >
      <View
        {...viewProps}
        sx={{
          borderRadius: __isList || !border ? 0 : 3,
          borderWidth: __isList || !border ? 0 : 1,
          borderColor: 'mutedText',
          padding,
          ...sx,
        }}
      >
        {renderFirstRow()}
        {renderFieldRows()}
        {!!footer && footer}
      </View>
    </EntityContext.Provider>
  )
}
