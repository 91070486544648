import { initializeApp } from 'firebase/app'
import {
  initializeAuth,
  Auth,
  browserPopupRedirectResolver,
  browserLocalPersistence,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
  RecaptchaVerifier,
  ConfirmationResult,
} from 'firebase/auth'
import { makeHeadless } from 'react-doorman'
import { AuthApi } from '@beatgig/api/auth'
import { SMS_RECAPTCHA_ID } from './captcha'
import { Sentry } from '@beatgig/helpers/sentry'

let miniAuth: Auth

const auth = () => miniAuth

declare global {
  interface Window {
    recaptchaVerifier: RecaptchaVerifier
    smsConfirmationResult: ConfirmationResult
    recaptchaWidgetId?: number
  }
}

if (typeof window !== 'undefined') {
  const firebaseApp = initializeApp(AuthApi.firebaseConfig)

  miniAuth = initializeAuth(firebaseApp, {
    popupRedirectResolver: browserPopupRedirectResolver,
    persistence: browserLocalPersistence,
  })
}

export const initFirebase = () => {}

makeHeadless({
  signInWithCustomToken: async (token) => {
    return firebaseSignInWithCustomToken(auth(), token)
  },
  signOut: () => {
    return auth().signOut()
  },
  idTokenListener: (callback) => {
    return auth().onIdTokenChanged(callback)
  },
})

const initializePhoneCaptcha = async (): Promise<RecaptchaVerifier> => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      SMS_RECAPTCHA_ID,
      {
        size: 'invisible',
        'expired-callback': () => {
          throw new Error('expired')
        },
      },
      auth()
    )
  }
  return window.recaptchaVerifier
}

const signInWithPhoneNumber = async (phoneNumber: string) => {
  const verification = await initializePhoneCaptcha()
  await window.recaptchaVerifier.verify()
  return firebaseSignInWithPhoneNumber(auth(), phoneNumber, verification)
    .then((result) => {
      window.smsConfirmationResult = result
      return result
    })
    .catch((error) => {
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId
      })
      Sentry.captureException({
        message: 'signInWithPhoneNumber error ' + error.message,
        extra: {
          error,
        },
      })

      throw error
    })
}

const verifyPhoneCode = async (code: string) => {
  return window.smsConfirmationResult.confirm(code)
}

const getIsSignedIn = () => !!auth()?.currentUser

const getIdToken = (forceRefresh = false) =>
  auth().currentUser?.getIdToken(forceRefresh)

const signInWithCustomToken = (token: string) =>
  firebaseSignInWithCustomToken(auth(), token)

export {
  signInWithPhoneNumber,
  verifyPhoneCode,
  initializePhoneCaptcha,
  getIsSignedIn,
  getIdToken,
  signInWithCustomToken,
}
