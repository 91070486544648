import { Image } from 'dripsy'
import React from 'react'
import { NativeFastImageProps } from './types'
import { cacheControl, priority, resizeMode } from './constants'

export const FastImage = ({
  style,
  source,
  sx,
  resizeMode,
  ...props
}: NativeFastImageProps) => {
  return (
    <Image
      {...props}
      // @ts-expect-error messed up types
      source={
        typeof source === 'object' && 'uri' in source
          ? { uri: source.uri }
          : source
      }
      style={style as any}
      sx={sx}
      resizeMode={resizeMode}
    />
  )
}

FastImage.priority = priority
FastImage.cacheControl = cacheControl
FastImage.resizeMode = resizeMode
