import useAuth from '@beatgig/auth-hooks/use-auth'
import { FormikPersist } from '@beatgig/forms/persist'
import { UserRole } from '@beatgig/gql'

export function UserOnboardingFormPersistence({
  name,
}: {
  name: 'buyer-type' | 'seller-type' | `generic-${UserRole}` | 'greek' | 'upb'
}) {
  const { user } = useAuth()

  if (!user) return null

  // the user certainly exists here
  return <FormikPersist name={`${name}-${user?.uid}`} />
}
