import { Sentry } from '@beatgig/helpers/sentry'
import { getIdToken } from '@beatgig/providers/Doorman/init'
import { GraphQLClient } from 'graphql-request'
import apolloConfig from '../../apollo.config'
import { getSdk } from './headless-codegen'
import { Platform } from 'react-native'

const { url } = apolloConfig.client.service

const graphql = getSdk(new GraphQLClient(url), async (action) => {
  let token: string | undefined
  // don't fetch the token on the server using a client-only library
  // only on native or browser
  if (Platform.OS != 'web' || typeof window != 'undefined') {
    token = await getIdToken()
  }

  if (!token) {
    return action().catch((e) => {
      Sentry.captureEvent({
        message: 'Headless graphql error. No token: ' + e.message,
      })
      return e
    })
  }
  const result = await action({
    Authorization: `Bearer ${token}`,
  }).catch((e) => {
    Sentry.captureMessage('Headless graphql error: ' + e.message, {
      extra: JSON.parse(JSON.stringify(e)),
    })
    return e
  })

  return result
})

export { graphql }

// import gql from 'graphql-tag'
// import { getIdToken } from '@beatgig/providers/Doorman/init'
// import { Sentry } from '@beatgig/helpers/sentry'

// export async function mutation<Data, Variables>(
//   query: ReturnType<typeof gql>,
//   variables: Variables
// ): Promise<{ data: Data }> {
//   const headers = new Headers()
//   headers.set('Content-Type', 'application/json')
//   headers.set('Authorization', `Bearer ${await getIdToken()}`)

//   return fetch(url, {
//     method: 'POST',
//     body: JSON.stringify({
//       query,
//       variables,
//     }),
//     headers,
//   })
//     .then((r) => {
//       return r.json()
//     })
//     .catch((e) => {
//       Sentry.captureException('mutation' + e.message, {
//         extra: {
//           cause: e.cause,
//           variables,
//           query: query.definitions,
//         },
//       })
//     })
// }
