import React from 'react'
import { AdminContext } from './context'

type Props = {
  children: React.ReactNode
}

const value = {
  isAdminApp: true,
}

export default function AdminProvider({ children }: Props) {
  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}
