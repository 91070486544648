import React from 'react'
import { BuyerOnboardingStack } from './types'
import { createMotiStack } from '@beatgig/navigation/moti/stack'

const { Screen, Navigator } = createMotiStack<BuyerOnboardingStack>()

export default function BuyerOnboarding() {
  return (
    <Navigator initial initialRouteName="BuyerType">
      {/* <Screen
        name="Welcome"
        getComponent={() =>
          require('@beatgig/auth-components/buyer-onboarding/welcome').default
        }
      /> */}
      <Screen
        name="BuyerType"
        getComponent={() =>
          require('@beatgig/auth-components/buyer-onboarding/buyer-type')
            .default
        }
      />
      <Screen
        name="BuyerGenericInfo"
        getComponent={() =>
          require('@beatgig/auth-components/buyer-onboarding/generic-info')
            .default
        }
      />
      <Screen
        name="Greek"
        getComponent={() =>
          require('@beatgig/auth-components/buyer-onboarding/greek-organization')
            .default
        }
      />
      <Screen
        name="UPB"
        getComponent={() =>
          require('@beatgig/auth-components/buyer-onboarding/upb').default
        }
      />
    </Navigator>
  )
}
